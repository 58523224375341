import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'

import { useToast } from '@chakra-ui/react'

import { baseErrorToastOptions } from '../../utils/functions.utils'
import {
    ChecklistHumanInteractionTypeBO,
    CreateChecklistHumanInteractionTypeCommand,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI, generalPutAPI } from '../API/general.api'
import { useLoading } from '../contexts/Loading.context'

interface IChecklistInteractionContext {
    humanInteractionTypes: ChecklistHumanInteractionTypeBO[] | undefined
    isLoading: boolean
    getInteractions: Function
    createInteraction: Function
}

interface IChecklistInteractionContextProviderProps {
    children: ReactNode
}
export const ChecklistInteractionContext = createContext(
    {} as IChecklistInteractionContext
)

export const useChecklistInteractions = (): IChecklistInteractionContext =>
    useContext(ChecklistInteractionContext)

function useProviderChecklistInteractions(): IChecklistInteractionContext {
    const toast = useToast()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [isLoading, setIsLoading] = useState(true)
    const [interactions, setInteractions] =
        useState<ChecklistHumanInteractionTypeBO[]>()

    async function createInteraction(
        data: CreateChecklistHumanInteractionTypeCommand
    ): Promise<void> {
        const loading = globalLoading()
        const response = await generalPutAPI(
            API_ENDPOINTS.checklistHumanTypes,
            data
        )
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loading)
    }

    async function getInteractions(): Promise<void> {
        const response = await generalGetAPI(API_ENDPOINTS.checklistHumanTypes)
        if (response.isOk) {
            setInteractions(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        setIsLoading(false)
    }

    return {
        humanInteractionTypes: interactions,
        isLoading,
        getInteractions,
        createInteraction,
    }
}

export const ChecklistInteractionContextProvider = ({
    children,
}: IChecklistInteractionContextProviderProps): ReactElement => {
    const checklist = useProviderChecklistInteractions()
    return (
        <ChecklistInteractionContext.Provider value={checklist}>
            {children}
        </ChecklistInteractionContext.Provider>
    )
}
