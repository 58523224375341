import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Flex } from '@chakra-ui/react'

interface IEditContractTemplateActions {
    onSave: Function
}

export default function EditContractTemplateActions({
    onSave,
}: IEditContractTemplateActions): ReactElement {
    const translate = useTranslation().t
    const navigate = useNavigate()

    return (
        <Flex
            alignItems={'center'}
            justifyContent="space-between"
            width={'100%'}
        >
            <Button
                onClick={() => {
                    navigate('/setup/templates/')
                }}
                size={'md'}
                mb={2}
                variant={'ghost'}
                px={8}
            >
                {translate('back')}
            </Button>
            <Button
                onClick={() => {
                    onSave()
                }}
                size={'md'}
                mb={2}
                px={8}
            >
                {translate('save')}
            </Button>
        </Flex>
    )
}
