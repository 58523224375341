import AssetTypesPage from '../../pages/setup/assetTypes/AssetTypes.page'
import ChecklistInteractions from '../../pages/setup/checklistInteractions/ChecklistInteractions.page'
import EditContractTemplate from '../../pages/setup/contractTemplates/contractTemplateDetails/ContractTemplateDetails.page'
import ContractTemplates from '../../pages/setup/contractTemplates/ContractTemplates.page'
import SetupGeneral from '../../pages/setup/general/SetupGeneral.page'
import InsuranceSetupPage from '../../pages/setup/insurance/Insurance.page'
import IntegrationsPage from '../../pages/setup/integrations/Integrations.page'
import LocationsPage from '../../pages/setup/locations/Locations.page'
import PartnerDetailsPage from '../../pages/setup/partner/Partner.page'
import EditServiceSetupPage from '../../pages/setup/services/EditServiceSetup.page'
import ServicesSetupPage from '../../pages/setup/services/ServicesSetup.page'
import TransientFeesPage from '../../pages/setup/transient-fees/TransientFees.page'
import UsersPage from '../../pages/setup/users/Users.page'
import { IBaseRoute } from './base.routes'

const SETUP_ROUTES: IBaseRoute[] = [
    {
        path: 'general',
        Component: SetupGeneral,
        breadcrumb: ['setup', 'general'],
        breadcrumbName: 'General',
        translateKey: 'general',
    },
    {
        path: 'partner',
        Component: PartnerDetailsPage,
        breadcrumb: ['setup', 'partner'],
        breadcrumbName: 'Partner',
        translateKey: 'partner',
    },
    {
        path: 'asset-types',
        Component: AssetTypesPage,
        breadcrumb: ['setup', 'assetTypes'],
        breadcrumbName: 'Asset types',
        translateKey: 'assetTypes',
    },
    {
        path: 'integrations',
        Component: IntegrationsPage,
        breadcrumb: ['setup', 'integrations'],
        breadcrumbName: 'integrations',
        translateKey: 'integrations',
    },
    {
        path: 'templates',
        Component: ContractTemplates,
        translateKey: 'contractTemplates',
    },
    {
        path: 'checklist-interactions',
        Component: ChecklistInteractions,
        translateKey: 'checklistInteractions',
    },
    {
        path: 'templates/:id',
        Component: EditContractTemplate,
        translateKey: 'template',
        hideFromMenu: true,
    },
    {
        path: 'locations',
        Component: LocationsPage,
        breadcrumb: ['setup', 'locations'],
        breadcrumbName: 'locations',
        translateKey: 'locations',
    },
    {
        path: 'services-setup',
        Component: ServicesSetupPage,
        breadcrumb: ['setup', 'services-setup'],
        breadcrumbName: 'services-setup',
        translateKey: 'services',
    },
    {
        path: 'services-setup/:id',
        Component: EditServiceSetupPage,
        breadcrumb: ['setup', 'services-setup'],
        breadcrumbName: 'services-setup',
        translateKey: 'services',
        hideFromMenu: true,
    },

    {
        path: 'users',
        Component: UsersPage,
        breadcrumb: ['setup'],
        breadcrumbName: 'users',
        translateKey: 'users',
    },
    {
        path: 'transient-fees',
        Component: TransientFeesPage,
        breadcrumb: ['setup', 'transient-fees'],
        breadcrumbName: 'transient-fees',
        translateKey: 'transientFees',
    },
    {
        path: 'insurance',
        Component: InsuranceSetupPage,
        breadcrumb: ['setup', 'insurance'],
        breadcrumbName: 'Insurance',
        translateKey: 'insurance',
    },
]

export default SETUP_ROUTES
