export interface ICodeEditorConfig {
    name: string
    slug: DocumentTemplateContentDTOEnum
    height: number
}

enum DocumentTemplateContentDTOEnum {
    HEADER = 'headerContent',
    BODY = 'bodyContent',
    FOOTER = 'footerContent',
}

export const codeEditorConfig: Array<ICodeEditorConfig> = [
    {
        name: 'header',
        slug: DocumentTemplateContentDTOEnum.HEADER,
        height: 170,
    },
    {
        name: 'body',
        slug: DocumentTemplateContentDTOEnum.BODY,
        height: 170,
    },
    {
        name: 'footer',
        slug: DocumentTemplateContentDTOEnum.FOOTER,
        height: 170,
    },
]
