// theme/index.js
import { extendTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

import buttonTheme from './components/button.theme'
import { checkboxTheme } from './components/checkbox.theme'
import inputTheme from './components/input.theme'
import { menuTheme } from './components/menu.theme'
import { selectTheme } from './components/select.theme'
import { switchTheme } from './components/switch.theme'
import { tabsTheme } from './components/tabs.theme.utils'
import colors from './theme.colors'
import fontStyles from './theme.fonts'

const ABSTRACT_THEME = (primary: string, secondary: string): any =>
    extendTheme({
        styles: {
            global: (props: StyleFunctionProps) => ({
                body: {
                    fontFamily: 'body',
                    color: mode('blackAlpha.900', 'whiteAlpha.900')(props),
                    bg: mode('white', 'secondary.900')(props),
                    lineHeight: 'base',
                },
                a: {
                    color: mode('primary.500', 'secondary.500')(props),
                    _hover: {
                        textDecoration: 'none',
                    },
                },
            }),
        },
        ...fontStyles,
        ...colors(primary, secondary),
        components: {
            Input: inputTheme,
            Tabs: tabsTheme,
            Menu: menuTheme,
            Button: buttonTheme,
            Switch: switchTheme,
            Select: selectTheme,
            Checkbox: checkboxTheme,
        },
    })

export default ABSTRACT_THEME
