import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { generalGetAPI } from '../API/general.api'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { ModuleDTO, ModuleType } from '../../utils/types/types'
import { useUser } from './user.context'

interface IModuleContext {
    ensureAccess: (module: ModuleType, redirect?: string) => void
    hasAccess: (module: ModuleType) => boolean
}

interface IModuleContextProviderProps {
    children: ReactNode
}

export const moduleContext = createContext({} as IModuleContext)

export const useModule = (): IModuleContext => useContext(moduleContext)

const useProviderModule = (): IModuleContext => {
    const [items, setItems] = useState<ModuleDTO[]>([])
    const navigate = useNavigate()
    const { user } = useUser()

    const getModules = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.modules)
        if (response.isOk) setItems(response.data)
        else toast.error(response.message)
    }

    useEffect(() => {
        if (user.name) getModules()
    }, [user])

    function ensureAccess(module: ModuleType, redirect?: string): void {
        if (!hasAccess(module)) navigate(redirect || '/')
    }

    function hasAccess(mod: ModuleType): boolean {
        const modules = items.filter((item) => mod === item.moduleType)
        if (!modules.length) return false
        return modules?.some((item) => item.isAccessEnabled)
    }

    return {
        ensureAccess,
        hasAccess,
    }
}

export const ModuleContextProvider = ({
    children,
}: IModuleContextProviderProps): ReactElement => {
    const context: IModuleContext = useProviderModule()

    return (
        <moduleContext.Provider value={context}>
            {children}
        </moduleContext.Provider>
    )
}
