import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Select,
} from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import { UserGroupMap } from '../../pages/profile/Profile.config'
import { validateEmail } from '../../utils/functions.utils'
import { CountryCulturesList } from '../../utils/localization/culture.utils'
import { UserDTO } from '../../utils/types/types'

interface UserContainerInterface extends ModalSharedProps {
    userObject: any
    handleUserSave(user: UserDTO): void
}

const isUserValid = (user: UserDTO): boolean =>
    !(
        !user.email ||
        !validateEmail(user?.email) ||
        !user?.branchName ||
        !user?.name ||
        !user?.culture
    )

const userValidatorExtended = (user: UserDTO): { [key: string]: string[] } => {
    const errorList: { [key: string]: string[] } = {}
    const add = (key: string): void => {
        errorList[key] = []
    }
    const assign = (key: string, value: string) => {
        if (!Object.keys(errorList).includes(key)) {
            add(key)
        }
        errorList[key].push(value)
    }
    const userArr = [user]
    userArr
        .filter((u) => !isUserValid(u))
        .forEach((u) => {
            if (!u?.email) {
                assign('email', 'EmailRequiredRule')
            }
            if (!validateEmail(u?.email ?? '')) {
                assign('email', 'EmailNotValid')
            }
            if (!u?.branchName) {
                assign('branchName', 'RequiredField')
            }
            if (!u?.name) {
                assign('name', 'RequiredField')
            }
            if (!u?.culture) {
                assign('culture', 'RequiredField')
            }
        })

    return errorList
}

function UserContainer({
    userObject,
    handleUserSave,
}: UserContainerInterface): ReactElement {
    const [userInstance, setUserInstance] = useState<any>(userObject)
    const [errorCollection, setErrorCollection] = useState<{
        [key: string]: string[]
    }>({})
    const translate = useTranslation().t

    useEffect(() => {
        setUserInstance(userObject)
    }, [JSON.stringify(userObject)])

    const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault()
        if (!isUserValid(userInstance)) {
            const errorMap: { [key: string]: string[] } =
                userValidatorExtended(userInstance)
            setErrorCollection(errorMap)
            return
        }
        setErrorCollection({})
        handleUserSave(userInstance)
    }

    const isFieldInvalid = (key: string): boolean =>
        Object.keys(errorCollection).includes(key)

    function handleInstanceChange(type: string, value: any): void {
        setUserInstance({ ...userInstance, [type]: value })
    }

    const renderErrorMessage = (key: string): ReactNode => {
        if (!Object.keys(errorCollection).includes(key)) {
            return <FormErrorMessage></FormErrorMessage>
        }
        return (
            <FormErrorMessage>
                <ul>
                    {errorCollection[key].map((err: string, index: number) => (
                        <li key={index}>{translate(err)}</li>
                    ))}
                </ul>
            </FormErrorMessage>
        )
    }

    const CultureKeys = Object.keys(CountryCulturesList)
    const CultureValues = Object.values(CountryCulturesList)
    const UserGroupKeys = Object.keys(UserGroupMap)
    const UserGroupValues = Object.values(UserGroupMap)
    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Grid
                    gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    gap={4}
                    mb={4}
                >
                    <FormControl isInvalid={isFieldInvalid('name')}>
                        <FormLabel>{translate('name')}</FormLabel>
                        <Input
                            type="text"
                            value={userInstance?.name}
                            onChange={(e: any): void => {
                                handleInstanceChange('name', e.target.value)
                            }}
                        />
                        {renderErrorMessage('name')}
                    </FormControl>
                    <FormControl isInvalid={isFieldInvalid('email')}>
                        <FormLabel>{translate('email')}</FormLabel>
                        <Input
                            type="text"
                            value={userInstance?.email}
                            isDisabled={userInstance.userNumber?.length > 1}
                            onChange={(e: any): void => {
                                handleInstanceChange('email', e.target.value)
                            }}
                        />
                        {renderErrorMessage('email')}
                    </FormControl>
                    <FormControl isInvalid={isFieldInvalid('userGroup')}>
                        <FormLabel>{translate('userGroup')}</FormLabel>
                        <Select
                            value={userInstance?.userGroup}
                            onChange={(e: any) => {
                                handleInstanceChange(
                                    'userGroup',
                                    parseInt(e.target.value)
                                )
                            }}
                        >
                            {UserGroupKeys.map((key, index) => (
                                <option value={key} key={index}>
                                    {translate(UserGroupValues[index])}
                                </option>
                            ))}
                        </Select>
                        {renderErrorMessage('userGroup')}
                    </FormControl>
                    <FormControl isInvalid={isFieldInvalid('branchName')}>
                        <FormLabel>{translate('branchName')}</FormLabel>
                        <Input
                            value={userInstance?.branchName}
                            onChange={(e: any) => {
                                handleInstanceChange(
                                    'branchName',
                                    e.target.value
                                )
                            }}
                            type="text"
                        />
                        {renderErrorMessage('branchName')}
                    </FormControl>
                    {/* 
                    <FormControl p={4}>
                        <FormLabel>{translate('allowedPartners')}</FormLabel>

                        <MultiSelect
                            isMulti
                            useBasicStyles
                            value={selectedPartners}
                            onChange={(event: any): void =>
                                setSelectedPartners(event)
                            }
                            name="partners"
                            options={
                                partners?.map((partner: PartnerDTO) => ({
                                    label: partner.name,
                                    value: partner.id,
                                })) || []
                            }
                            closeMenuOnSelect={false}
                        />
                    </FormControl> */}

                    <FormControl isInvalid={isFieldInvalid('culture')}>
                        <FormLabel>{translate('localization')}</FormLabel>
                        <Select
                            value={userInstance?.culture}
                            onChange={(e: any) => {
                                handleInstanceChange(
                                    'culture',
                                    parseInt(e.target.value)
                                )
                            }}
                        >
                            {CultureKeys.map((key, index) => (
                                <option value={key} key={index}>
                                    {CultureValues[index]}
                                </option>
                            ))}
                        </Select>
                        {renderErrorMessage('culture')}
                    </FormControl>

                    <FormControl isInvalid={isFieldInvalid('formatting')}>
                        <FormLabel>{translate('format')}</FormLabel>
                        <Select
                            value={userInstance?.formatting}
                            onChange={(e: any) => {
                                handleInstanceChange(
                                    'formatting',
                                    parseInt(e.target.value)
                                )
                            }}
                        >
                            {CultureKeys.map((key, index) => (
                                <option value={key} key={index}>
                                    {CultureValues[index]}
                                </option>
                            ))}
                        </Select>
                        {renderErrorMessage('formatting')}
                    </FormControl>
                </Grid>
                <Button type="submit">{translate('save')}</Button>
            </form>
        </Box>
    )
}

export default withModalHOC<UserContainerInterface>(UserContainer)
