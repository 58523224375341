/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import assetsReportDownloadConfig from '../../../../app/config/download/assetsReport.config.json'
import DownloadActions from '../../../../components/downloadActions/DownloadActions.component'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { AssetBalanceDTO } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { columnsConfig } from './AssetReport.config'

interface IAssetReportTable {
    items: AssetBalanceDTO[]
    isLoading: boolean
}

function AssetReportTableComponent({ items }: IAssetReportTable): ReactElement {
    const [selectedData, setSelectedData] = useState<AssetBalanceDTO[]>([])
    const translate = useTranslation().t
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const { formatValue } = useNumberFormatterContext()

    const selectAllElements = async (): Promise<void> => {
        setSelectedData([...items])
    }

    return (
        <Box pt={8} w="100%">
            <DynamicGrid
                tableId="assetReportTable"
                columns={columnsConfig(
                    translate,
                    isDownloadEnabled,
                    formatValue
                )}
                rowData={items}
                pagination={true}
                rowMultiSelectWithClick={isDownloadEnabled}
                headers={
                    <DownloadActions
                        isDownloadEnabled={isDownloadEnabled}
                        setDownloadEnabled={setDownloadEnabled}
                        selectAllElements={selectAllElements}
                        selectedItems={selectedData}
                        downloadConfig={assetsReportDownloadConfig}
                        resetSelectedItems={() => setSelectedData([])}
                    />
                }
                onSelectionChanged={(data: any) => {
                    const selectedNodes = data.api.getSelectedNodes()
                    const selectedData = selectedNodes?.map(
                        (node: any) => node.data
                    )
                    setSelectedData([...selectedData])
                }}
            />
        </Box>
    )
}
export default AssetReportTableComponent
