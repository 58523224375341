import { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC from '../../../../hoc/modal.hoc'
import { SignerBaseDto } from '../../../../utils/types/types'
import addSignerFormFields from './AddSigner.config'

function AddSignerContainer(props: any): ReactElement {
    const signer: Partial<SignerBaseDto> = props?.editSignerData ?? {}

    return (
        <Box>
            <DynamicForm<Partial<SignerBaseDto>>
                data={signer}
                formFields={addSignerFormFields()}
                onSubmit={(data: SignerBaseDto): void => {
                    props.handleSaveSigner(data)
                }}
                submitText={'confirm'}
                submitButtonProps={{ float: 'right', mt: 2 }}
            />
        </Box>
    )
}

export default withModalHOC(AddSignerContainer)
