import orderBy from 'lodash/orderBy'

import { IFormField } from '../../features/dynamicForm/DynamicForm.interfaces'
import {
    buildListOfKeyValues,
    ColumnActionConfiguration,
} from '../../utils/functions.utils'
import { CountryCultures } from '../../utils/localization/culture.utils'
import { CountryCode, CustomerDTO } from '../../utils/types/types'

enum SignatureReceiverType {
    Signer = 0,
    Copy = 1,
}

enum SignatureDigitalTypeEnum {
    Digital = 0,
    Touch = 1,
}

export interface IInvoice {
    receiver: SignatureReceiverType
    digitalType: SignatureDigitalTypeEnum
    email: string
    name: string
}

export const CUSTOMER_FORM = (
    culture: CountryCode,
    readOnly?: boolean
): IFormField<Partial<CustomerDTO>>[] => [
    {
        slug: 'customerNumber',
        label: 'customerNumber',
        isOptional: true,
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        ...(!readOnly ? { leftAddonText: '@' } : {}),
        slug: 'email',
        label: 'email',
        inputProps: { type: 'email', disabled: readOnly, isRequired: true },
    },
    {
        slug: 'cvr',
        label: 'customerCvr',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'name',
        label: 'name',
        inputProps: { type: 'text', disabled: readOnly, isRequired: true },
    },
    {
        slug: 'street',
        label: 'address',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'building',
        label: 'building',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'floor',
        label: 'floor',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'postalCode',
        label: 'postalCode',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'city',
        label: 'city',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'country',
        label: 'country',
        inputProps: { type: 'select', disabled: readOnly },
        skipTranslation: true,
        optionsKeysValues: buildListOfKeyValues<typeof CountryCode>(
            CountryCode
        ).map((item) => {
            const regionNames = new Intl.DisplayNames(
                CountryCultures[culture],
                {
                    type: 'region',
                }
            )
            return [item[0], regionNames.of(item[1].toLocaleUpperCase())]
        }),
    },
    {
        slug: 'phone',
        label: 'phone',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        ...(!readOnly ? { leftAddonText: '@' } : {}),
        slug: 'invoicingEmail',
        label: 'invoicingEmail',
        inputProps: { type: 'email', disabled: readOnly },
    },
    {
        slug: 'contactName',
        label: 'contactName',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'externalReferenceString1',
        label: 'externalReferenceString1',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'externalReferenceString2',
        label: 'externalReferenceString2',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'externalReferenceString3',
        label: 'externalReferenceString3',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'externalReferenceString4',
        label: 'externalReferenceString4',
        inputProps: { type: 'text', disabled: readOnly },
    },
    {
        slug: 'externalReferenceText1',
        label: 'externalReferenceText1',
        inputProps: { type: 'text', disabled: readOnly },
    },
]

export const getCustomerSignersColumns = (
    translate: Function,
    actionsFn: Function
) => [
    {
        field: 'receiver',
        filter: 'agTextColumnFilter',
        headerName: translate('signatureReceiver'),
    },
    {
        field: 'digitalType',
        filter: 'agTextColumnFilter',
        headerName: translate('digitalType'),
    },
    {
        field: 'email',
        filter: 'agTextColumnFilter',
        headerName: translate('signerEmail'),
    },
    {
        field: 'name',
        filter: 'agTextColumnFilter',
        headerName: translate('signerName'),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => actionsFn(params),
            100
        ),
    },
]

export const getCustomerSignersRows = (invoices: IInvoice[]): any[] =>
    orderBy(invoices, 'contractNumber', 'desc')?.map((elem) => ({
        receiver: SignatureReceiverType[elem?.receiver],
        digitalType: SignatureDigitalTypeEnum[elem?.digitalType],
        email: elem?.email,
        name: elem?.name,
        item: elem,
    }))
