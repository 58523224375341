import {
    MutableRefObject,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'

import { Box, Flex, Stack } from '@chakra-ui/react'

import DynamicForm from '../../../../../features/dynamicForm/DynamicForm.feature'
import PopupActionButton, {
    ButtonVariant,
} from '../../../../../features/popupActionButton/PopupActionButton.feature'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { usePartner } from '../../../../../services/contexts/partner.context'
import { useVAT } from '../../../../../services/contexts/vat.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    BaseTransientFeeDTO,
    ContractStatus,
    TransientFeeDTO,
} from '../../../../../utils/types/types'
import { TransientFeesTable as BaseTransientFeesTable } from '../../../../setup/transient-fees/TransientFees.component'
import EditTransientFeeComponent from '../editTransientFee/EditTransientFee.component'
import TransientFeesTable from '../transientFeesTable/TransientFeesTable.component'
import TransientFeeFormFields from './ContractDetailTransientFees.config'

export default function ContractDetailsTransientFees(): ReactElement {
    const [selectedBaseTransientFees, setSelectedBaseTransientFees] = useState<
        BaseTransientFeeDTO[]
    >([])
    const [newFee, setNewFee] = useState<Partial<TransientFeeDTO>>({
        text: '',
        value: undefined,
        vatId: undefined,
    })
    const [editFee, setEditFee] = useState<Partial<TransientFeeDTO>>({})
    const { contract, product, getContract } = useContractService()
    const { VAT } = useVAT()
    const { activePartner } = usePartner()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const VATOptions: any[][] = useMemo(
        () => VAT.map((item) => [item.id, `${item?.name || 0}`]),
        [JSON.stringify(VAT)]
    )

    const translate = useTranslation().t
    const popupCloseReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    function popupConfirmEvent(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    function selectPopupConfirmEvent(): void {
        const loadingID = globalLoading()

        const temps = selectedBaseTransientFees.map((base) => {
            const vatPercentage = VAT.filter((v) => v.id === base.vatId)[0]
                .percentage
            const tempFee = {
                id: uuid.v4(),
                contractNumber: contract.contractNumber,
                baseTransientFeeNumber: base.baseTransientFeeNumber,
                value: base.value,
                text: base.description,
                vatId: base.vatId,
                vatPercentage,
                isConsumed: false,
            }
            return tempFee
        })
        Promise.all(
            temps.map((fee) =>
                generalPostAPI(
                    API_ENDPOINTS.contractTransientFee(
                        contract.contractNumber as string
                    ),
                    fee
                )
            )
        ).then(() => {
            getContract()
            setSelectedBaseTransientFees([])
            stopGlobalLoading(loadingID)
            popupCloseReference.current?.click()
        })
    }

    const addTransientFee = (data: TransientFeeDTO): void => {
        try {
            const loadingID = globalLoading()
            const vatPercentage = data?.vatId
                ? VAT.filter((v) => v.id === data.vatId)[0].percentage
                : 0

            const tempFee = {
                ...data,
                vatPercentage,
                id: uuid.v4(),
                contractNumber: contract.contractNumber,
                discount: {
                    value: data.discount,
                },
            }

            generalPostAPI(
                API_ENDPOINTS.contractTransientFee(
                    contract.contractNumber as string
                ),
                tempFee
            ).then(() => {
                setNewFee({})
                getContract()
                stopGlobalLoading(loadingID)
            })

            popupCloseReference.current?.click()
        } catch (e) {
            console.error(e)
        }
    }

    const onDeleteTransientFee = (data: TransientFeeDTO): void => {
        const loadingID = globalLoading()

        if (!contract?.transientFees) {
            stopGlobalLoading(loadingID)
            return
        }

        generalDeleteAPI(
            `${API_ENDPOINTS.contractTransientFee(
                contract.contractNumber as string
            )}/${data.id}`
        ).then(() => {
            getContract()
            stopGlobalLoading(loadingID)
        })
    }

    const onEditTransientFee = (data: TransientFeeDTO): void => {
        const loadingID = globalLoading()

        generalPutAPI(
            `${API_ENDPOINTS.contractTransientFee(
                contract.contractNumber as string
            )}/${data.id}`,
            { ...data, contractNumber: contract.contractNumber }
        ).then(() => {
            setEditFee({})
            getContract()
            stopGlobalLoading(loadingID)
        })
    }

    return (
        <Stack spacing={4}>
            <Flex justifyContent={'flex-end'} gap={2}>
                {contract.status !== ContractStatus.Closed && (
                    <PopupActionButton
                        title={translate('selectTransientFee')}
                        actionName={translate('selectTransientFee')}
                        content={
                            <Box>
                                <BaseTransientFeesTable
                                    hideAdd={true}
                                    hasActions={false}
                                    hasSelection
                                    onSelectionChanged={(
                                        data: BaseTransientFeeDTO[]
                                    ): void =>
                                        setSelectedBaseTransientFees(data)
                                    }
                                />
                            </Box>
                        }
                        buttonVariant={ButtonVariant.GHOST}
                        popupCloseReference={popupCloseReference}
                        onConfirm={selectPopupConfirmEvent}
                    />
                )}
                {contract.status !== ContractStatus.Closed && (
                    <PopupActionButton
                        title={translate('addTransientFee')}
                        actionName={translate('addTransientFee')}
                        content={
                            <DynamicForm
                                formSubmitReference={formSubmitReference}
                                data={newFee}
                                formFields={TransientFeeFormFields(
                                    newFee as TransientFeeDTO,
                                    activePartner,
                                    VATOptions
                                )}
                                hideSubmit={true}
                                onSubmit={addTransientFee}
                            />
                        }
                        buttonVariant={ButtonVariant.GHOST}
                        popupCloseReference={popupCloseReference}
                        onConfirm={popupConfirmEvent}
                    />
                )}
            </Flex>
            <EditTransientFeeComponent
                transientFee={editFee}
                editTransientFee={onEditTransientFee}
                VATOptions={VATOptions}
                activePartner={activePartner}
                isModalOpen={!!editFee.id}
                modalTitle={translate('editTransientFee')}
                onClose={() => setEditFee({})}
                hasDiscount={product?.canBeDiscounted}
            />
            <TransientFeesTable
                transientFees={contract?.transientFees ?? []}
                onEditTransientFee={(data) => {
                    setEditFee(data)
                }}
                onDeleteTransientFee={onDeleteTransientFee}
                hasDiscount={product?.canBeDiscounted}
            />
        </Stack>
    )
}
