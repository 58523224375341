import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/switch'

import DatePicker from '../../../components/datePicker/DatePicker.component'
import { offsetTimeZoneDifference } from '../../../utils/functions.utils'
import { IAssetForm } from '../AssetForm.utils'

export default function AssetPartsForm({
    handleInputChange,
    contractAsset,
    actionType,
}: IAssetForm): ReactElement {
    const translate = useTranslation().t

    function renderPartsFields(): ReactElement {
        return (
            <>
                <FormControl>
                    <FormLabel>{translate('registrationValue')}</FormLabel>
                    <Input
                        value={contractAsset.registrationValue}
                        onChange={(e): void => {
                            handleInputChange(
                                e.target.value,
                                'registrationValue'
                            )
                        }}
                    ></Input>
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('firstRegistrationDate')}</FormLabel>
                    <DatePicker
                        name="firstRegistrationDateTime"
                        isDisabled={actionType === 'view'}
                        value={
                            contractAsset?.firstRegistrationDateTime ??
                            undefined
                        }
                        max={new Date()}
                        onChange={(date: Date): void => {
                            if (date) {
                                handleInputChange(
                                    offsetTimeZoneDifference(date),
                                    'firstRegistrationDateTime'
                                )
                            }
                        }}
                    />
                </FormControl>
            </>
        )
    }
    return (
        <>
            {contractAsset?.assetTypeData?.isMotor && (
                <FormControl>
                    <FormLabel>{translate('isPartRegistration')}</FormLabel>
                    <Switch
                        isChecked={contractAsset.isPartRegistration}
                        onChange={(): void =>
                            handleInputChange(
                                !contractAsset.isPartRegistration,
                                'isPartRegistration'
                            )
                        }
                    ></Switch>
                </FormControl>
            )}
            {contractAsset?.isPartRegistration &&
                !contractAsset.assetTypeData?.isMotor &&
                renderPartsFields()}
        </>
    )
}
