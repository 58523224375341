/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'

import withModalHOC from '../../hoc/modal.hoc'
import CustomerTableComponent from '../../pages/contractDetail/components/customer/CustomerTable.component'
import { CUSTOMER_FORM } from '../../pages/customerDetails/CustomerDetails.config'
import { useUser } from '../../services/contexts/user.context'
import {
    CustomerChangeContextProvider,
    useCustomerChange,
} from '../../services/customer/CustomerChange.service'
import { deepCopy } from '../../utils/functions.utils'
import {
    CountryCode,
    CustomerDTO,
    InvoiceCommunicationType,
} from '../../utils/types/types'
import DynamicForm from '../dynamicForm/DynamicForm.feature'

export const DEFAULT_CUSTOMER_DTO: CustomerDTO = {
    customerNumber: '',
    customerType: 0,
    canBeDeleted: false,
    invoiceCommunication: InvoiceCommunicationType.None,
    country: CountryCode.DK,
}

function AddCustomerContainer(props: any): ReactElement {
    const [customer, setCustomer] = useState(
        props?.customer ? props.customer : deepCopy(DEFAULT_CUSTOMER_DTO)
    )
    const { user } = useUser()
    const {
        creditSafeCustomers,
        customer: selectedCustomer,
        customerSearch,
        customerSearchPending,
        handleCustomerSearch,
        handleResetCustomerSearch,
        setCustomerSearch,
    } = useCustomerChange()
    const translate = useTranslation().t
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if (selectedCustomer) {
            setTabIndex(0)
            setCustomer({ ...selectedCustomer })
        }
    }, [selectedCustomer])

    return (
        <Tabs
            index={tabIndex}
            mt={2}
            onChange={(index): void => setTabIndex(index)}
        >
            <TabList>
                <Tab> {translate('customerDetails')}</Tab>
                <Tab> {translate('customerSelect')}</Tab>
            </TabList>

            <TabPanels>
                <TabPanel p={6} width={'100%'} textAlign={'center'}>
                    <Box>
                        <DynamicForm<Partial<CustomerDTO>>
                            columns={[1, 1, 2]}
                            submitText="saveChanges"
                            onSubmit={(data: CustomerDTO): void => {
                                props.handleSaveProduct(data)
                                props.onClose()
                            }}
                            data={customer}
                            formFields={CUSTOMER_FORM(
                                user.culture,
                                props?.readOnly
                            )}
                            hideSubmit={props?.readOnly}
                        />
                    </Box>
                </TabPanel>
                <TabPanel p={6} width={'100%'} textAlign={'left'}>
                    <Box w={'67%'}>
                        <Heading size={'md'} mb={4}>
                            {translate('customer')}
                        </Heading>
                        <form
                            onSubmit={(event): void => {
                                event.preventDefault()
                                handleCustomerSearch()
                            }}
                        >
                            <Flex gap={2} alignItems={'end'} w={'100%'}>
                                <InputGroup>
                                    <Input
                                        w={'100%'}
                                        placeholder={
                                            translate('searchCustomer') || ''
                                        }
                                        value={customerSearch}
                                        onChange={(event): void =>
                                            setCustomerSearch(
                                                event.target.value
                                            )
                                        }
                                        isDisabled={customerSearchPending}
                                    />
                                    {customerSearch.length > 0 && (
                                        <InputRightElement
                                            onClick={handleResetCustomerSearch}
                                        >
                                            <CloseIcon />
                                        </InputRightElement>
                                    )}
                                </InputGroup>
                                <Button onClick={handleCustomerSearch} px={'8'}>
                                    {translate('searchCustomer')}
                                </Button>
                            </Flex>
                        </form>
                    </Box>
                    {creditSafeCustomers !== undefined && (
                        <CustomerTableComponent
                            customers={creditSafeCustomers}
                            enableSearch={false}
                        />
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

const AddCustomerContainerWithContext = (props: any) => (
    <CustomerChangeContextProvider>
        <AddCustomerContainer {...props} />
    </CustomerChangeContextProvider>
)

export default withModalHOC(AddCustomerContainerWithContext)
