/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRowNode } from 'ag-grid-community'
import { Button } from '@chakra-ui/react'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import { useVAT } from '../../../../../services/contexts/vat.context'
import {
    BaseInsuranceDto,
    ContractAssetDTO,
    InsurancePolicyDto,
} from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import { insuranceHeaders } from './AddContractAssetInsurance.config'
import { useNumberFormatterContext } from '../../../../../services/contexts/NumberFormatter.context'

interface IAddContractAssetInsurance extends ModalSharedProps {
    handleSave: Function
    insurances: BaseInsuranceDto[]
    contractAsset: ContractAssetDTO
}

function AddContractAssetInsurance({
    insurances,
    handleSave,
    contractAsset,
}: IAddContractAssetInsurance): ReactElement {
    const { VAT } = useVAT()
    const translate = useTranslation().t
    const [insurancePolicies, setInsurancePolicies] = useState<
        InsurancePolicyDto[]
    >([])
    const [selectedItems, setSelectedItems] = useState<InsurancePolicyDto[]>([])
    const gridRef = useRef<any>()
    const { formatValue } = useNumberFormatterContext()

    console.log(
        'AddContractAssetInsurance',
        JSON.stringify(insurances),
        JSON.stringify(contractAsset)
    )
    console.log('selectedItems', selectedItems)

    function generateInsurancePolicies() {
        const { purchaseValue, insurancePolicies: existingPolicies } =
            contractAsset // Assuming purchaseValue is used for comparison
        let insurancePolicies: any[] = []
        if (insurances) {
            insurancePolicies = insurances.map((insurance) => {
                // Find the step that has maxValue >= purchaseValue
                const bestStep =
                    insurance.baseInsurancePriceSteps.find(
                        (step) => purchaseValue <= step.maxValue
                    ) ||
                    insurance.baseInsurancePriceSteps[
                        insurance.baseInsurancePriceSteps.length - 1
                    ] // If no step matches, take the last step

                const insuranceExistsInAsset = existingPolicies.some(
                    (policy) =>
                        policy.baseInsuranceNumber ===
                        insurance.baseInsuranceNumber
                )

                // Return the formatted insurance policy
                return {
                    id: insurance.productInsurances[0]?.productId || 0, // Assuming there's always one productInsurance
                    baseInsuranceNumber: insurance.baseInsuranceNumber,
                    premiumPrice: bestStep.premiumPrice,
                    deductiblePrice: bestStep.deductiblePrice,
                    netPrice: bestStep.netPrice,
                    isEnabled: insuranceExistsInAsset, // Assuming all insurances are enabled by default, modify as needed
                    vatId: insurance.vatId,
                    description: insurance.description,
                }
            })
        }

        return insurancePolicies
    }

    useEffect(() => {
        const insurancePolicies = generateInsurancePolicies()
        setInsurancePolicies(insurancePolicies)
    }, [])

    useEffect(() => {
        if (insurancePolicies && insurancePolicies?.length) {
            const nodesToSelect: IRowNode[] = []
            gridRef.current.api.forEachNode((node: IRowNode) => {
                if (node.data && node.data.isEnabled) {
                    nodesToSelect.push(node)
                }
            })
            gridRef.current.api.setNodesSelected({
                nodes: nodesToSelect,
                newValue: true,
            })
        }
    }, [insurancePolicies])

    console.log(insurancePolicies)

    return (
        <>
            <DynamicGrid
                externalGridRef={gridRef}
                tableId="insurancesSetupPageTable"
                columns={insuranceHeaders(translate, VAT, formatValue)}
                rowData={insurancePolicies}
                pagination={true}
                onSelectionChanged={(data: any): void => {
                    const selectedNodes = data.api.getSelectedNodes()
                    const selectedData = selectedNodes.map(
                        (node: any) => node.data
                    )
                    setSelectedItems(selectedData)
                }}
            />
            <Button
                float={'right'}
                px={8}
                onClick={() => {
                    const contractAssetInsurancePolicies = selectedItems.map(
                        (ip) => ({
                            id: ip.id,
                            baseInsuranceNumber: ip.baseInsuranceNumber,
                            premiumPrice: ip.premiumPrice,
                            deductiblePrice: ip.deductiblePrice,
                            netPrice: ip.netPrice,
                            isEnabled: ip.isEnabled,
                        })
                    )
                    handleSave(contractAssetInsurancePolicies)
                }}
            >
                {translate('save')}
            </Button>
        </>
    )
}

export default withModalHOC<IAddContractAssetInsurance>(
    AddContractAssetInsurance
)
