import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { TransientFeeDTO } from '../../../../../utils/types/types'
import { columnsConfig } from './TransientFeesTable.config'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import { useNumberFormatterContext } from '../../../../../services/contexts/NumberFormatter.context'

interface ITransientFeesTable {
    transientFees: TransientFeeDTO[]
    onEditTransientFee?: (transientFee: TransientFeeDTO) => void
    onDeleteTransientFee?: (transientFee: TransientFeeDTO) => void
    hasDiscount?: boolean
}

export default function TransientFeesTable({
    transientFees,
    onEditTransientFee,
    onDeleteTransientFee,
    hasDiscount = false,
}: ITransientFeesTable): ReactElement {
    const translation = useTranslation().t
    const { formatValue } = useNumberFormatterContext()

    return (
        <DynamicGrid
            tableId="transientFeesTable"
            columns={columnsConfig(
                translation,
                formatValue,
                hasDiscount,
                onEditTransientFee,
                onDeleteTransientFee
            )}
            rowData={transientFees}
        />
    )
}
