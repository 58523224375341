import { MutableRefObject, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@chakra-ui/react'

import DatePicker from '../../../../../../../components/datePicker/DatePicker.component'
import withModalHOC, {
    ModalSharedProps,
} from '../../../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../../../../services/API/general.api'
import { InvoiceDTO } from '../../../../../../../utils/types/types'

interface MarkInvoicePaid extends ModalSharedProps {
    invoice: InvoiceDTO
}

function MarkInvoicePaid({ invoice, ...props }: MarkInvoicePaid): ReactElement {
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const translate = useTranslation().t
    const currentDate = new Date()
    const [selectedDate, setSelectedDate] = useState(currentDate)
    return (
        <Box>
            <DatePicker
                max={currentDate}
                value={selectedDate}
                onChange={(date: Date): void => {
                    setSelectedDate(date)
                }}
            ></DatePicker>
            <Button
                mt={6}
                onClick={async (): Promise<void> => {
                    if (formSubmitReference) {
                        const queryUpdateInvoicePaymentStatus = {
                            invoiceNumberList: [invoice.invoiceNumber],
                            isPaid: true,
                            paymentDate: selectedDate.toISOString(),
                        }
                        await generalPostAPI(
                            API_ENDPOINTS.invoiceActionsUpdatePaidStatus,
                            queryUpdateInvoicePaymentStatus
                        )

                        props.onClose()
                    }
                }}
                size={'md'}
                float="right"
                mb={2}
            >
                {translate('confirm')}
            </Button>
        </Box>
    )
}

export default withModalHOC(MarkInvoicePaid)
