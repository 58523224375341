import { IFormField } from '../../../../../features/dynamicForm/DynamicForm.feature'
import { ParameterDTO, ValueType } from '../../../../../utils/types/types'
import { ParameterAndDefinition } from '../../SetupGeneral.page'

export default function editProductParameterFields(
    item: ParameterAndDefinition
): IFormField<Partial<Partial<ParameterDTO>>>[] {
    function getTypeOfField(value: ValueType): string {
        switch (value) {
            case ValueType.Boolean:
                return 'boolean'
            case ValueType.Date:
                return 'date'
            case ValueType.Numeric:
                return 'number'
            default:
                return 'text'
        }
    }
    function canShowField(keyValue: string | null) {
        return keyValue !== null
    }
    return [
        {
            slug: 'value',
            enableDecimal: false,
            label: 'value',
            stringValue: true,
            inputProps: {
                type: getTypeOfField(item.valueType || item.valueType),
            },
        },
        ...[
            ...(canShowField(item?.key1 || null)
                ? [
                      {
                          slug: 'value1',
                          label: 'value1',
                          enableDecimal: false,
                          stringValue: true,
                          inputProps: {
                              type: getTypeOfField(
                                  item.valueType1 || item.valueType
                              ),
                          },
                      } as IFormField<Partial<Partial<ParameterDTO>>>,
                  ]
                : []),
        ],
        ...[
            ...(canShowField(item?.key2 || null)
                ? [
                      {
                          slug: 'value2',
                          label: 'value2',
                          enableDecimal: false,
                          stringValue: true,
                          inputProps: {
                              type: getTypeOfField(
                                  item.valueType2 || item.valueType
                              ),
                          },
                      } as IFormField<Partial<Partial<ParameterDTO>>>,
                  ]
                : []),
        ],
        ...[
            ...(canShowField(item?.key3 || null)
                ? [
                      {
                          slug: 'value3',
                          label: 'value3',
                          enableDecimal: false,
                          stringValue: true,
                          inputProps: {
                              type: getTypeOfField(
                                  item.valueType3 || item.valueType
                              ),
                          },
                      } as IFormField<Partial<Partial<ParameterDTO>>>,
                  ]
                : []),
        ],
        ...[
            ...(canShowField(item?.key4 || null)
                ? [
                      {
                          slug: 'value4',
                          label: 'value4',
                          enableDecimal: false,
                          stringValue: true,
                          inputProps: {
                              type: getTypeOfField(
                                  item.valueType4 || item.valueType
                              ),
                          },
                      } as IFormField<Partial<Partial<ParameterDTO>>>,
                  ]
                : []),
        ],
        ...[
            ...(canShowField(item?.key5 || null)
                ? [
                      {
                          slug: 'value5',
                          label: 'value5',
                          enableDecimal: false,
                          stringValue: true,
                          inputProps: {
                              type: getTypeOfField(
                                  item.valueType5 || item.valueType
                              ),
                          },
                      } as IFormField<Partial<Partial<ParameterDTO>>>,
                  ]
                : []),
        ],
    ]
}
