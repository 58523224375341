const fontStyles = {
    // fontSizes: {
    //     xs: '.625rem',
    //     sm: '.75rem',
    //     md: '.875rem',
    //     lg: '1rem',
    //     xl: '1.125rem',
    //     '2xl': '1.375rem',
    //     '3xl': '1.625rem',
    //     '4xl': '2.125rem',
    //     '5xl': '2.75rem',
    //     '6xl': '3.75rem',
    // },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        normal: 'normal',
        none: '1',
        shorter: '1.25',
        short: '1.375',
        base: '1.5',
        tall: '1.625',
        taller: '2',
    },
    letterSpacings: {
        tighter: '-0.05em',
        tight: '-0.025em',
        normal: '0',
        wide: '0.025em',
        wider: '0.05em',
        widest: '0.1em',
    },
}

export default fontStyles
