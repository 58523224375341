/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import { useLocationsContext } from '../../services/contexts/LocationServices.context'
import { useContractRulesService } from '../../services/rules/ProductRulesEnforcer.services'
import { ContractAssetDTO } from '../../utils/types/types'
import AssetFormFeature from '../addAssetCustom/AssetForm.feature'

interface AssetContainerInterface extends ModalSharedProps {
    key: string
    actionType: 'add' | 'edit' | 'view'
    assetItem: ContractAssetDTO
    handleSaveAsset?: Function // fix types
    isBaseAsset?: boolean
    isUsageEnabled?: boolean
}

export function AddAssetWithoutModal({
    key,
    actionType = 'edit',
    assetItem,
    handleSaveAsset,
    isBaseAsset = false,
    isUsageEnabled = false,
    onClose,
}: AssetContainerInterface): ReactElement {
    const { locations } = useLocationsContext()
    const { productRules } = useContractRulesService()

    return (
        <Box key={key + productRules?.length}>
            <AssetFormFeature
                actionType={actionType}
                defaultContractAsset={assetItem}
                isBaseAsset={isBaseAsset}
                isUsageEnabled={isUsageEnabled}
                productRules={productRules}
                handleSubmit={(assetToSubmit: ContractAssetDTO): void => {
                    const selectedLocation = locations.find(
                        (location) =>
                            location.locationNumber ===
                            assetToSubmit.locationNumber
                    )
                    if (selectedLocation) {
                        assetToSubmit.location = selectedLocation
                    }

                    handleSaveAsset?.(assetToSubmit)
                    onClose()
                }}
            />
        </Box>
    )
}

export const AddAssetModal =
    withModalHOC<AssetContainerInterface>(AddAssetWithoutModal)
