import { ReactElement, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'

import { addAuthorization } from '../../services/API'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../services/API/general.api'
import { usePartner } from '../../services/contexts/partner.context'
import { useUser } from '../../services/contexts/user.context'
import useIsMounted from '../../services/hooks/isMounted'
import Content from './Content'

const AppContent = (): ReactElement => {
    const {
        user: auth0user,
        isLoading: isAuth0Loading,
        isAuthenticated,
        loginWithRedirect,
        getAccessTokenSilently,
    } = useAuth0()
    const { user, setUser } = useUser()
    const isMounted = useIsMounted()
    const authTries = useRef(0)
    const { getPartnerDetails } = usePartner()

    useEffect(() => {
        if (isAuth0Loading) {
            return
        }

        if (!isAuthenticated) {
            loginWithRedirect()
            return
        }

        const handleAuthorization = async (): Promise<void> => {
            try {
                const token = await getAccessTokenSilently()
                addAuthorization(token)

                const response: any = await generalGetAPI(
                    API_ENDPOINTS.userSelf
                )
                if (response?.data && response?.isOk) {
                    if (isMounted()) {
                        setUser(response.data)
                        getPartnerDetails()
                    }
                }
            } catch (error: any) {
                toast.error(`error.message`)
            }
        }

        if (authTries.current < 5) {
            authTries.current += 1
            handleAuthorization()
        }
    }, [isAuth0Loading, isAuthenticated, isMounted])

    if (auth0user && user && user.email) {
        return <Content />
    }

    return <></>
}

export default AppContent
