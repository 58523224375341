/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

/* eslint-disable react/no-children-prop */
import {
    chakra,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    ListItem,
    SimpleGrid,
    Switch,
    UnorderedList,
} from '@chakra-ui/react'

import DatePicker from '../../../../components/datePicker/DatePicker.component'
import { useUser } from '../../../../services/contexts/user.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    getObjectFields,
    offsetTimeZoneDifference,
    SelectAllOnFocus,
    toCamelCase,
} from '../../../../utils/functions.utils'
import { formatNumber } from '../../../../utils/localization/culture.utils'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../../../utils/localization/localization.utils'
import {
    ContractDTO,
    ContractStatus,
    ProductRuleDTO,
    ProductRuleType,
    Recurrence,
    UserGroup,
} from '../../../../utils/types/types'
import RichText from '../../../../components/richText/RichText'

interface IContractDetailsErrors {
    leasingPeriod: string[]
    startDate: string[]
    creationFee: string[]
    deposit: string[]
    interest: string[]
    marginInterest: string[]
}

interface ICustomContractDetails {
    contract: Partial<ContractDTO>
    productRules: ProductRuleDTO[]
}

export default function CustomContractDetails({
    productRules,
    contract,
}: ICustomContractDetails): ReactElement {
    const translate = useTranslation().t
    const { user } = useUser()
    const { product, updateContract, setHasErrors, setEnableContractSave } =
        useContractService()
    const [errors, setErrors] = useState<IContractDetailsErrors>()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})
    const [interest, setInterest] = useState<number | undefined>(
        contract?.interest ?? 0
    )
    const [marginInterest, setMarginInterest] = useState<number | undefined>(
        typeof contract.interest !== 'undefined' &&
            typeof contract.variableInterest !== 'undefined'
            ? contract.interest - contract.variableInterest
            : 0
    )

    // Leasing Period
    const leasingPeriodRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.LeasingPeriod
    )

    // Creation Fee
    const creationFeeRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.CreationFee
    )

    // Creation Fee
    const depositRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.Deposit
    )

    // Interest
    const interestRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.Interest
    )
    // VariableInterest
    const variableInterestRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.VariableInterest
    )
    // IsVariableInterest
    const isVariableInterestRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.IsVariableInterest
    )

    // IncludeCreationFee
    const includeCreationFeeRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.IncludeCreationFee
    )

    const externalReferenceTextRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.ExternalReferenceText
    )

    const externalReferenceStringRules: Record<string, ProductRuleDTO> =
        useMemo(
            () =>
                productRules
                    .filter((pr) =>
                        [
                            ProductRuleType.ExternalReferenceString1,
                            ProductRuleType.ExternalReferenceString2,
                            ProductRuleType.ExternalReferenceString3,
                            ProductRuleType.ExternalReferenceString4,
                        ].includes(pr.ruleType)
                    )
                    .reduce(
                        (prev, curr) => ({
                            ...prev,
                            [ProductRuleType[curr.ruleType].toLowerCase()]:
                                curr,
                        }),
                        {}
                    ),
            [productRules]
        )

    const hasExternalReferenceRuleEnabled = useMemo(
        () =>
            Object.values(externalReferenceStringRules).filter((r) => r.hidden)
                .length !== 4,
        [externalReferenceStringRules]
    )

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [user])

    useEffect(() => {
        handleInputChange(interest, 'interest')
    }, [interest])

    useEffect(() => {
        setInterest(contract?.interest ?? 0)
    }, [contract.interest])

    useEffect(() => {
        contract.status === ContractStatus.Active &&
            setEnableContractSave(hasExternalReferenceRuleEnabled)
    }, [hasExternalReferenceRuleEnabled])

    const handleInputChange = (value: any, field: keyof ContractDTO): void => {
        if (
            contract.status === ContractStatus.Active &&
            !/^externalReferenceString\d+$/.test(field)
        ) {
            return
        }
        if (contract && contract[field] !== value) {
            updateContract({ ...contract, [field]: value })
        }
    }

    function buildErrorList(key: keyof IContractDetailsErrors): ReactElement {
        return errors?.[key] ? (
            <UnorderedList>
                {errors?.[key].map((item: string, index: number) => (
                    <ListItem key={index} color="red">
                        {item}
                    </ListItem>
                ))}
            </UnorderedList>
        ) : (
            <></>
        )
    }

    function validateValues(): void {
        let hasError = false
        const localErrors: IContractDetailsErrors = {
            leasingPeriod: [],
            startDate: [],
            creationFee: [],
            deposit: [],
            interest: [],
            marginInterest: [],
        }
        if (contract) {
            if (
                leasingPeriodRules &&
                contract &&
                contract?.leasingPeriod &&
                leasingPeriodRules?.minimumValue &&
                Number(contract?.leasingPeriod) <
                    Number(leasingPeriodRules?.minimumValue)
            ) {
                hasError = true
                localErrors.leasingPeriod.push('Value is lower than minimum')
            }
            if (
                leasingPeriodRules &&
                contract &&
                contract?.leasingPeriod &&
                leasingPeriodRules?.maximumValue &&
                Number(contract?.leasingPeriod) >
                    Number(leasingPeriodRules?.maximumValue)
            ) {
                hasError = true
                localErrors.leasingPeriod.push('Value is higher than maximum')
            }
        }
        if (
            creationFeeRules &&
            contract &&
            contract?.creationFee &&
            creationFeeRules?.minimumValue &&
            Number(contract?.creationFee) <
                Number(creationFeeRules?.minimumValue)
        ) {
            hasError = true
            localErrors.creationFee.push('Value is lower than minimum')
        }
        if (
            creationFeeRules &&
            contract &&
            contract?.creationFee &&
            creationFeeRules?.maximumValue &&
            Number(contract?.creationFee) >
                Number(creationFeeRules?.maximumValue)
        ) {
            hasError = true
            localErrors.creationFee.push('Value is higher than maximum')
        }
        if (
            depositRules &&
            contract &&
            contract?.deposit &&
            depositRules?.minimumValue &&
            Number(contract?.deposit) < Number(depositRules?.minimumValue)
        ) {
            hasError = true
            localErrors.deposit.push('Value is lower than minimum')
        }
        if (
            depositRules &&
            contract &&
            contract?.deposit &&
            depositRules?.maximumValue &&
            Number(contract?.deposit) > Number(depositRules?.maximumValue)
        ) {
            hasError = true
            localErrors.deposit.push('Value is higher than maximum')
        }
        if (
            interestRules &&
            interestRules?.minimumValue &&
            Number(interest) < Number(interestRules?.minimumValue)
        ) {
            hasError = true
            localErrors.interest.push('Value is lower than minimum')
        }
        if (
            interestRules &&
            interestRules?.maximumValue &&
            Number(interest) > Number(interestRules?.maximumValue)
        ) {
            hasError = true
            localErrors.interest.push('Value is higher than maximum')
        }
        setErrors(localErrors)
        setHasErrors(hasError)
    }

    useEffect(() => {
        validateValues()
    }, [JSON.stringify(contract)])

    return (
        <form>
            <SimpleGrid columns={[1, 1, 2, 2, 3]} gap={4}>
                {!leasingPeriodRules?.hidden && (
                    <FormControl>
                        <FormLabel>
                            {leasingPeriodRules?.customText ||
                                translate('recurrenceAndPeriods')}
                        </FormLabel>
                        <InputGroup>
                            {product?.recurrence && (
                                <InputLeftAddon
                                    children={translate(
                                        toCamelCase(
                                            Recurrence[product?.recurrence]
                                        )
                                    )}
                                />
                            )}
                            <NumericFormat
                                onFocus={SelectAllOnFocus}
                                type="text"
                                thousandSeparator={format.thousands}
                                decimalSeparator={format.decimals}
                                customInput={Input}
                                isDisabled={
                                    leasingPeriodRules?.locked ||
                                    contract?.isLocked ||
                                    contract.status === ContractStatus.Active
                                }
                                defaultValue={leasingPeriodRules?.defaultValue}
                                value={contract?.leasingPeriod}
                                min={0}
                                borderLeftRadius={0}
                                onValueChange={(e) =>
                                    handleInputChange(
                                        e.floatValue,
                                        'leasingPeriod'
                                    )
                                }
                            ></NumericFormat>
                        </InputGroup>
                        {buildErrorList('leasingPeriod')}
                    </FormControl>
                )}
                <FormControl>
                    <FormLabel>{translate('startDate')}</FormLabel>
                    <DatePicker
                        name="startDate"
                        isDisabled={contract.status === ContractStatus.Active}
                        value={contract?.startDate ?? undefined}
                        onChange={(date: Date) => {
                            if (date) {
                                handleInputChange(
                                    offsetTimeZoneDifference(date),
                                    'startDate'
                                )
                            }
                        }}
                    />
                    {buildErrorList('startDate')}
                </FormControl>
                {!creationFeeRules?.hidden && (
                    <FormControl>
                        <FormLabel>
                            {creationFeeRules?.customText ||
                                translate('creationFee')}
                        </FormLabel>
                        <NumericFormat
                            onFocus={SelectAllOnFocus}
                            type="text"
                            thousandSeparator={format.thousands}
                            decimalSeparator={format.decimals}
                            customInput={Input}
                            fixedDecimalScale
                            decimalScale={2}
                            isDisabled={
                                creationFeeRules?.locked ||
                                contract?.isLocked ||
                                contract.status === ContractStatus.Active
                            }
                            defaultValue={creationFeeRules?.defaultValue}
                            value={contract?.creationFee}
                            min={0}
                            onValueChange={(e) =>
                                handleInputChange(e.floatValue, 'creationFee')
                            }
                        ></NumericFormat>
                        {buildErrorList('creationFee')}
                    </FormControl>
                )}
                {!depositRules?.hidden && (
                    <FormControl>
                        <FormLabel>
                            {depositRules?.customText ?? translate('deposit')}
                        </FormLabel>
                        <NumericFormat
                            onFocus={SelectAllOnFocus}
                            type="text"
                            thousandSeparator={format.thousands}
                            decimalSeparator={format.decimals}
                            customInput={Input}
                            fixedDecimalScale
                            decimalScale={2}
                            isDisabled={
                                depositRules?.locked ||
                                contract?.isLocked ||
                                contract.status === ContractStatus.Active
                            }
                            defaultValue={depositRules?.defaultValue}
                            value={contract?.deposit}
                            min={0}
                            onValueChange={(e) =>
                                handleInputChange(e.floatValue, 'deposit')
                            }
                        ></NumericFormat>
                        {buildErrorList('deposit')}
                    </FormControl>
                )}
                {!interestRules?.hidden && (
                    <FormControl>
                        <FormLabel>
                            {interestRules?.customText ??
                                translate('contractRate')}
                        </FormLabel>
                        <InputGroup>
                            <InputLeftAddon children="%" />
                            <NumericFormat
                                as="input"
                                onFocus={SelectAllOnFocus}
                                type="text"
                                thousandSeparator={format.thousands}
                                decimalSeparator={format.decimals}
                                customInput={Input}
                                fixedDecimalScale
                                decimalScale={5}
                                isDisabled={
                                    interestRules?.locked ||
                                    contract?.isLocked ||
                                    contract.status === ContractStatus.Active
                                }
                                value={interest}
                                borderLeftRadius={0}
                                min={0}
                                onValueChange={(e): void => {
                                    const { floatValue } = e
                                    setInterest(floatValue)
                                    setMarginInterest(
                                        typeof floatValue !== 'undefined' &&
                                            typeof contract.variableInterest !==
                                                'undefined'
                                            ? floatValue -
                                                  contract.variableInterest
                                            : 0
                                    )
                                }}
                            ></NumericFormat>
                        </InputGroup>
                        {!variableInterestRules?.hidden && (
                            <chakra.p mt="1">
                                {`${
                                    variableInterestRules?.customText ||
                                    translate('variableInterest')
                                }: ${formatNumber(
                                    contract.variableInterest || 0
                                )}%`}
                            </chakra.p>
                        )}
                        {buildErrorList('interest')}
                    </FormControl>
                )}
                {!interestRules?.hidden && (
                    <FormControl>
                        <FormLabel>{translate('marginInterest')}</FormLabel>
                        <InputGroup>
                            <InputLeftAddon children="%" />
                            <NumericFormat
                                onFocus={SelectAllOnFocus}
                                type="text"
                                thousandSeparator={format.thousands}
                                decimalSeparator={format.decimals}
                                customInput={Input}
                                fixedDecimalScale
                                decimalScale={5}
                                borderLeftRadius={0}
                                isDisabled={
                                    interestRules?.locked ||
                                    contract?.isLocked ||
                                    contract.status === ContractStatus.Active
                                }
                                value={marginInterest}
                                min={0}
                                onValueChange={(e) => {
                                    const { floatValue } = e
                                    setMarginInterest(floatValue)
                                    setInterest(
                                        typeof floatValue !== 'undefined' &&
                                            typeof contract.variableInterest !==
                                                'undefined'
                                            ? floatValue +
                                                  contract.variableInterest
                                            : 0
                                    )
                                }}
                            ></NumericFormat>
                            {buildErrorList('marginInterest')}
                        </InputGroup>
                    </FormControl>
                )}
                {isVariableInterestRules &&
                    !isVariableInterestRules?.hidden && (
                        <FormControl>
                            <FormLabel>
                                {translate('isVariableInterest')}
                            </FormLabel>
                            <Switch
                                checked={!!contract?.isVariableInterest}
                                defaultChecked={!!contract?.isVariableInterest}
                                disabled={isVariableInterestRules?.locked}
                                float={'left'}
                                name={'isVariableInterest'}
                                onChange={(event: any): void => {
                                    handleInputChange(
                                        event?.target?.checked === true,
                                        'isVariableInterest'
                                    )
                                }}
                            />
                        </FormControl>
                    )}
                {!includeCreationFeeRules?.hidden && (
                    <FormControl>
                        <FormLabel>{translate('includeCreationFee')}</FormLabel>
                        <Switch
                            float={'left'}
                            name={'includeCreationFee'}
                            checked={!!contract?.includeCreationFee}
                            defaultChecked={!!contract?.includeCreationFee}
                            disabled={!!includeCreationFeeRules?.locked}
                            onChange={(event: any): void => {
                                handleInputChange(
                                    event?.target?.checked === true,
                                    'includeCreationFee'
                                )
                            }}
                        />
                    </FormControl>
                )}
                {Object.keys(
                    getObjectFields(contract, /^externalReferenceString\d+$/)
                )
                    .filter(
                        (field: string) =>
                            !externalReferenceStringRules[field.toLowerCase()]
                                ?.hidden
                    )
                    .map((field: string) => (
                        <FormControl key={field}>
                            <FormLabel>
                                {externalReferenceStringRules[
                                    field.toLowerCase()
                                ]?.customText ?? translate(field)}
                            </FormLabel>
                            <Input
                                type="text"
                                value={(contract as any)[field]}
                                isDisabled={
                                    externalReferenceStringRules[
                                        field.toLowerCase()
                                    ]?.locked ||
                                    (user.userGroup ===
                                        UserGroup.RestrictedUser &&
                                        contract.status !==
                                            ContractStatus.Offer)
                                }
                                onChange={(event: any) =>
                                    handleInputChange(
                                        event?.target?.value,
                                        field as keyof ContractDTO
                                    )
                                }
                            />
                            {buildErrorList((contract as any)[field])}
                        </FormControl>
                    ))}
            </SimpleGrid>
            {!externalReferenceTextRules?.hidden && (
                <div style={{ margin: '5px 0 5px 0', width: '100%' }}>
                    <FormLabel>
                        {externalReferenceTextRules?.customText ||
                            translate('externalReferenceText')}
                    </FormLabel>
                    <RichText
                        readOnly={externalReferenceTextRules?.locked}
                        value={
                            contract?.externalReferenceText ||
                            externalReferenceTextRules?.defaultValue ||
                            ''
                        }
                        onChange={(value: string) =>
                            handleInputChange(value, 'externalReferenceText')
                        }
                    />
                </div>
            )}
        </form>
    )
}
