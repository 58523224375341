/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFile } from 'react-icons/fa'
import { NumericFormat } from 'react-number-format'
import { FileUpload, useFileUpload } from 'use-file-upload'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightAddon,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'

import CodeEditor from '../../../../../../components/codeEditor/CodeEditor.component'
import { IApiResponse } from '../../../../../../interfaces/interfaces'
import { useUser } from '../../../../../../services/contexts/user.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    buildListOfKeyValues,
    isEmpty,
    SelectAllOnFocus,
    toCamelCase,
} from '../../../../../../utils/functions.utils'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../../../../../utils/localization/localization.utils'
import {
    DocumentTemplateContentDTO,
    DocumentTemplateDTO,
    DocumentType,
    ProductDTO,
} from '../../../../../../utils/types/types'
import {
    createDocumentTemplate,
    fetchAllDocumentTemplates,
    updateDocumentTemplate,
} from './ContractTemplateDocuments.api'
import { codeEditorConfig } from './ContractTemplateDocuments.config'

interface IEditContractTemplateDocuments {
    product: ProductDTO
    enableSave: boolean
    setEnableSave: Function
}

export default function EditContractTemplateDocuments({
    product,
    enableSave,
    setEnableSave,
}: IEditContractTemplateDocuments): ReactElement {
    const toast = useToast()
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})
    const translate = useTranslation().t
    const [documentName, setDocumentName] = useState('')
    const [headerHeight, setHeaderHeight] = useState(2)
    const [footerHeight, setFooterHeight] = useState(1)

    const [file, selectFile] = useFileUpload()
    const [isLoading, setIsLoading] = useState(false)
    const [partnerTemplates, setPartnerTemplates] = useState<
        DocumentTemplateDTO[]
    >([])
    const [productTemplates, setProductTemplates] = useState<
        DocumentTemplateDTO[]
    >([])
    const [selectedNewProductDocumentType, setSelectedNewProductDocumentType] =
        useState<string>()
    const [
        selectedExistingProductDocumentType,
        setExistingProductDocumentType,
    ] = useState<string>()
    const [
        selectedExistingPartnerDocumentType,
        setSelectedExistingPartnerDocumentType,
    ] = useState<string>()
    const [disableEditor, setDisableEditor] = useState<boolean>(true)

    const [templateData, setTemplateData] = useState<DocumentTemplateDTO>()
    const [updatedTemplateContent, setUpdatedDocumentTemplateContent] =
        useState<DocumentTemplateContentDTO | null>(null)
    const [expandItems, setExpandItems] = useState<boolean[]>([
        true,
        true,
        true,
    ])
    const documentTypeList = buildListOfKeyValues(DocumentType)

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [user])

    useEffect(() => {
        if (enableSave) {
            onSaveChanges(
                templateData && selectedExistingProductDocumentType
                    ? updatedTemplateContent?.documentTemplateId
                    : undefined
            )
            setEnableSave(false)
        }
    }, [enableSave])

    useEffect(() => {
        if (
            !isEmpty(selectedNewProductDocumentType) ||
            !isEmpty(selectedExistingProductDocumentType)
        ) {
            setDisableEditor(false)
        } else {
            setDisableEditor(true)
        }
    }, [selectedNewProductDocumentType])

    useEffect(() => {
        if (!isEmpty(selectedNewProductDocumentType)) {
            setTemplateData(undefined)
        } else if (!isEmpty(selectedExistingProductDocumentType)) {
            const tpl = productTemplates.find(
                (productTemplate) =>
                    productTemplate.id.toString() ===
                    selectedExistingProductDocumentType
            )
            setTemplateData(tpl)
            setDocumentName(tpl?.name || '')
            setFooterHeight(parseFloat(tpl?.footerHeight || '2'))
            setHeaderHeight(parseFloat(tpl?.headerHeight || '1'))
        }
        if (!isEmpty(selectedExistingPartnerDocumentType)) {
            setTemplateData(
                partnerTemplates.find(
                    (partnerTemplate) =>
                        partnerTemplate.id.toString() ===
                        selectedExistingPartnerDocumentType
                )
            )
        }
    }, [
        selectedNewProductDocumentType,
        selectedExistingProductDocumentType,
        selectedExistingPartnerDocumentType,
    ])

    useEffect(() => {
        fetchAllDocumentTemplates(
            fetchAllProductDocumentsCallback,
            setIsLoading,
            product.id
        )
        fetchAllDocumentTemplates(
            fetchAllPartnerDocumentsCallback,
            setIsLoading
        )
    }, [])

    function fetchAllProductDocumentsCallback(
        data: DocumentTemplateDTO[]
    ): void {
        setProductTemplates([...data])
    }

    function fetchAllPartnerDocumentsCallback(
        data: DocumentTemplateDTO[]
    ): void {
        setPartnerTemplates([...data])
    }

    const handleCreateSuccessfulResponse = (response: IApiResponse): void => {
        toast(baseSuccessToastOptions('documentTemplateCreated'))
        fetchAllDocumentTemplates(
            fetchAllProductDocumentsCallback,
            setIsLoading,
            product.id
        )
    }

    const handleUpdateSuccessfulResponse = (response: IApiResponse): void => {
        toast(baseSuccessToastOptions('documentTemplateUpdated'))
        fetchAllDocumentTemplates(
            fetchAllProductDocumentsCallback,
            setIsLoading,
            product.id
        )
    }

    const handleUnsuccessfulResponse = (response: IApiResponse): void => {
        toast(baseErrorToastOptions(response.message))
    }

    const onSaveChanges = async (templateId?: number): Promise<void> => {
        if (templateId) {
            updateDocumentTemplate(
                templateId,
                updatedTemplateContent,
                handleUpdateSuccessfulResponse,
                handleUnsuccessfulResponse,
                setIsLoading,
                file as FileUpload,
                documentName,
                headerHeight.toString(),
                footerHeight.toString()
            )
        } else {
            selectedNewProductDocumentType &&
                createDocumentTemplate(
                    Number(selectedNewProductDocumentType),
                    updatedTemplateContent,
                    product.id,
                    handleCreateSuccessfulResponse,
                    handleUnsuccessfulResponse,
                    setIsLoading,
                    documentName,
                    headerHeight.toString(),
                    footerHeight.toString(),
                    file as FileUpload
                )
        }
    }

    function buildOptionList(): ReactElement[] {
        return documentTypeList.map((docType, index) => (
            <option key={index} value={docType[0]}>
                {translate(toCamelCase(docType[1]))}
            </option>
        ))
    }

    function renderDocumentSelection(): ReactElement {
        return (
            <Flex
                my={2}
                justifyContent={'space-between'}
                alignItems={'baseline'}
                wrap={'wrap'}
            >
                <Flex alignItems={'baseline'} gap={8} wrap={'wrap'}>
                    <Box flex={1}>
                        <Text size={'lg'} fontWeight={600} mb={'8px'}>
                            {translate('createNewDocument')}
                        </Text>
                        <Flex gap={2}>
                            <Select
                                value={selectedNewProductDocumentType}
                                placeholder={translate('selectDocumentType')}
                                onChange={(e: any): void => {
                                    setSelectedExistingPartnerDocumentType('')
                                    setExistingProductDocumentType('')
                                    setSelectedNewProductDocumentType(
                                        e.target.value
                                    )
                                }}
                            >
                                {buildOptionList()}
                            </Select>
                            <Select
                                disabled={
                                    !isEmpty(
                                        selectedExistingProductDocumentType
                                    ) || isEmpty(selectedNewProductDocumentType)
                                }
                                value={selectedExistingPartnerDocumentType}
                                placeholder={translate(
                                    'selectExistingPartnerDocument'
                                )}
                                onChange={(value): void => {
                                    setSelectedExistingPartnerDocumentType(
                                        value.currentTarget.value
                                    )
                                    setUpdatedDocumentTemplateContent(
                                        partnerTemplates.find(
                                            (partnerTemplate) =>
                                                partnerTemplate.id.toString() ===
                                                value.target.value
                                        )
                                            ?.documentTemplateContent as DocumentTemplateContentDTO
                                    )
                                }}
                            >
                                {partnerTemplates
                                    .filter(
                                        (item) =>
                                            item.documentType.toString() ===
                                            selectedNewProductDocumentType
                                    )
                                    .map((partnerTemplate, index) => (
                                        <option
                                            value={partnerTemplate.id}
                                            key={index}
                                        >
                                            {partnerTemplate.name}
                                        </option>
                                    ))}
                            </Select>
                        </Flex>
                    </Box>
                    <Box flex={1}>
                        <Text size={'lg'} fontWeight={600} mb={'8px'}>
                            {translate('editExistingProductDocument')}
                        </Text>
                        <Select
                            value={selectedExistingProductDocumentType}
                            placeholder={translate('selectDocumentType')}
                            onChange={(e: any): void => {
                                setSelectedNewProductDocumentType('')
                                setSelectedExistingPartnerDocumentType('')
                                setExistingProductDocumentType(e.target.value)
                                setUpdatedDocumentTemplateContent(
                                    productTemplates.find(
                                        (productTemplate) =>
                                            productTemplate.id.toString() ===
                                            e.target.value
                                    )
                                        ?.documentTemplateContent as DocumentTemplateContentDTO
                                )
                            }}
                        >
                            {productTemplates.map((productTemplate, index) => (
                                <option value={productTemplate.id} key={index}>
                                    {productTemplate.name}
                                </option>
                            ))}{' '}
                        </Select>
                    </Box>
                </Flex>

                <Box>
                    <Text size={'lg'} fontWeight={600} mb={'8px'}>
                        {translate('appendix')}
                    </Text>
                    <Input
                        onClick={(): void => {
                            selectFile(
                                {
                                    accept: '*',
                                    multiple: false,
                                },
                                () => {}
                            )
                        }}
                        as={Button}
                        variant={'solid'}
                        isDisabled={!selectedNewProductDocumentType}
                        type={'file'}
                        multiple={false}
                    >
                        {translate('upload')}
                    </Input>
                    {file ? (
                        <Flex alignItems={'center'} gap={2}>
                            <FaFile /> {(file as FileUpload)?.name}
                        </Flex>
                    ) : (
                        <p>{translate('noFileSelected')}</p>
                    )}
                </Box>
            </Flex>
        )
    }

    function increaseHeightBasedOnVisibleItems(height: number): string {
        const hiddenItems = expandItems.filter((item) => !item).length
        const multiplier = 1 + hiddenItems * 0.5
        const newHeight = height * multiplier
        return `${newHeight}px`
    }

    function renderDocumentContent(): ReactElement {
        return (
            <Flex flexDirection={'column'} width={'100%'} pr={32}>
                <Flex gap={8} mb={4}>
                    <FormControl>
                        <FormLabel>{translate('name')}</FormLabel>

                        <Input
                            placeholder={translate('name')}
                            value={documentName}
                            onChange={(event) =>
                                setDocumentName(event.currentTarget.value)
                            }
                        ></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{translate('headerHeight')}</FormLabel>
                        <InputGroup>
                            <NumericFormat
                                onFocus={SelectAllOnFocus}
                                type="text"
                                thousandSeparator={format.thousands}
                                decimalSeparator={format.decimals}
                                customInput={Input}
                                fixedDecimalScale
                                decimalScale={2}
                                defaultValue={0}
                                value={headerHeight}
                                onValueChange={(e) => {
                                    e.floatValue &&
                                        setHeaderHeight(e.floatValue)
                                }}
                            ></NumericFormat>
                            <InputRightAddon children={translate('cm')} />
                        </InputGroup>
                    </FormControl>

                    <FormControl>
                        <FormLabel>{translate('footerHeight')}</FormLabel>
                        <InputGroup>
                            <NumericFormat
                                onFocus={SelectAllOnFocus}
                                type="text"
                                thousandSeparator={format.thousands}
                                decimalSeparator={format.decimals}
                                customInput={Input}
                                fixedDecimalScale
                                decimalScale={2}
                                defaultValue={0}
                                value={footerHeight}
                                onValueChange={(e) => {
                                    e.floatValue &&
                                        setFooterHeight(e.floatValue)
                                }}
                            ></NumericFormat>

                            <InputRightAddon children={translate('cm')} />
                        </InputGroup>
                    </FormControl>
                </Flex>

                {codeEditorConfig.map((config, index) => (
                    <Box key={index} mb={6}>
                        <Flex gap={2}>
                            <Heading size={'sm'} mb={2}>
                                {translate(config.name)}
                            </Heading>
                            <ChevronUpIcon
                                cursor={'pointer'}
                                as={
                                    expandItems[index]
                                        ? ChevronUpIcon
                                        : ChevronDownIcon
                                }
                                _hover={{ fontSize: '1.5rem' }}
                                onClick={(): void => {
                                    const newExpandItems = [...expandItems]
                                    newExpandItems[index] = !expandItems[index]
                                    setExpandItems(newExpandItems)
                                }}
                            />
                        </Flex>

                        {expandItems[index] && (
                            <CodeEditor
                                height={increaseHeightBasedOnVisibleItems(
                                    config.height
                                )}
                                isDisabled={disableEditor}
                                initialCode={
                                    templateData
                                        ? (templateData.documentTemplateContent[
                                              config.slug
                                          ] as string)
                                        : ''
                                }
                                onSave={(code: string): void => {
                                    setUpdatedDocumentTemplateContent({
                                        ...updatedTemplateContent,
                                        [config.slug]: code,
                                    } as DocumentTemplateContentDTO)
                                }}
                            />
                        )}
                    </Box>
                ))}
            </Flex>
        )
    }

    return (
        <Flex>
            <Box width={'100%'}>
                {renderDocumentSelection()}
                {renderDocumentContent()}
            </Box>
        </Flex>
    )
}
