/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    HStack,
    Stack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'

import NotExportedInvoiceLines from '../../../../../../app/config/download/notExportedInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import HighlightComponent from '../../../../../../components/highlight/Highlight.component'
import { CustomerDetailInvoiceLineConfig } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.config'
import PopupActionButton, {
    ButtonVariant,
} from '../../../../../../features/popupActionButton/PopupActionButton.feature'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { useLoading } from '../../../../../../services/contexts/Loading.context'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import { SizeOptions } from '../../../../../../utils/enums.utils'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
} from '../../../../../../utils/functions.utils'
import { InvoiceDTO } from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'

export default function NotExportedPage(): ReactElement {
    const selectionActions: MutableRefObject<any> = useRef(null)
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const { isGlobalLoading, globalLoading, stopGlobalLoading } = useLoading()
    const [data, setData] = useState<InvoiceDTO[]>()
    const [dataEndpoint, setDataEndpoint] = useState<string>(
        `${API_ENDPOINTS.invoiceExport}`
    )
    const translate = useTranslation().t
    const toast = useToast()
    const [interestRates, setInterestRates] = useState<any[]>([])
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const { formatValue } = useNumberFormatterContext()

    const getInterestData = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.variableInterest)
        if (response.isOk) {
            setInterestRates(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    const onAddToQueue = async (): Promise<void> => {
        const loadingId = globalLoading()

        const exportedInvoicesList = selectedItems.map(
            (selectedInvoice: InvoiceDTO) => selectedInvoice.invoiceNumber
        )
        const response = await generalPostAPI(API_ENDPOINTS.export, {
            exportedInvoices: [...exportedInvoicesList],
        })
        stopGlobalLoading(loadingId)

        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
            return
        }
        if (selectionActions.current?.resetSelectedItems) {
            selectionActions.current.resetSelectedItems()
        }
        setData([])
        getData()
        toast(baseSuccessToastOptions(translate('invoiceQueueOk')))
    }

    const onGenerateNext = async (): Promise<void> => {
        const loadingId = globalLoading()
        const response = await generalPostAPI(
            API_ENDPOINTS.invoiceGenerateNext,
            {}
        )
        stopGlobalLoading(loadingId)
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
        }
        if (response.data?.totalCount === 0) {
            toast(baseInfoToastOptions(translate('invoiceLinesAreUpToDate')))
        } else {
            setData([])
            getData()
            toast(
                baseSuccessToastOptions(
                    `${translate('generatedCount')}: ${
                        response.data.totalCount
                    }`
                )
            )
            setDataEndpoint(
                `${API_ENDPOINTS.invoiceExport}?refresh=${response.data?.totalCount}`
            )
        }
    }

    const calculateTotal = () => {
        let tempTotal = 0
        selectedItems.forEach((item: any) => {
            tempTotal += item.amountExcludingVat
        })
        return tempTotal.toFixed(2)
    }

    async function getData(): Promise<void> {
        let allData: any[] = []

        let currentPage = 1
        let totalPages = 1

        while (currentPage <= totalPages) {
            const response = await generalGetAPI(
                `${dataEndpoint}?pageNumber=${currentPage}`
            )
            if (response.isOk) {
                const {
                    items,
                    currentPage: currentPageNum,
                    totalPages: totalPagesNum,
                } = response.data

                allData = [...allData, ...items]

                currentPage = currentPageNum
                totalPages = totalPagesNum // Update the totalPages variable
            } else {
                return
            }
            currentPage++
        }
        setData(allData)
    }

    useEffect(() => {
        getInterestData()
    }, [])

    useEffect(() => {
        getData().then(() => {})
    }, [dataEndpoint])

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const onConfirmHandler = (): void => {
        generalPostAPI(`${API_ENDPOINTS.invoiceGenerateNext}`, {
            generateUpUntilToday: true,
        }).then(() => {
            getData()
        })
    }

    const fields = EXPORT_HEADERS(translate, formatValue, true, true)

    return (
        <>
            <Box>
                <Box display={'flex'} gap={4}>
                    <HighlightComponent
                        title={translate('totalExcludingVat')}
                        info={calculateTotal()}
                    />
                    <HighlightComponent
                        title={translate('selectedItems')}
                        info={selectedItems.length}
                        hasBorder={true}
                    />
                </Box>
            </Box>
            <Stack spacing={4}>
                {data && (
                    <DynamicGrid
                        tableId="notExportedTable"
                        columns={[...fields]}
                        masterDetail={true}
                        detailCellRendererParams={{
                            detailGridOptions: {
                                columnDefs: CustomerDetailInvoiceLineConfig(
                                    translate,
                                    formatValue
                                ),
                            },
                            getDetailRowData: (params: any) => {
                                params.successCallback(params.data.lines)
                            },
                        }}
                        rowData={data}
                        onSelectionChanged={(values: any): void => {
                            const selectedNodes = values.api.getSelectedNodes()
                            const selectedData = selectedNodes.map(
                                (node: any) => node.data
                            )
                            setSelectedItems(selectedData)
                        }}
                        headers={
                            <Flex gap={2}>
                                <DownloadActions
                                    isDownloadEnabled={isDownloadEnabled}
                                    setDownloadEnabled={setDownloadEnabled}
                                    selectAllElements={selectAllElements}
                                    selectedItems={selectedItems}
                                    downloadConfig={NotExportedInvoiceLines}
                                    resetSelectedItems={() =>
                                        setSelectedItems([])
                                    }
                                />
                                <Button
                                    isDisabled={
                                        !selectedItems?.length ||
                                        isGlobalLoading
                                    }
                                    onClick={onAddToQueue}
                                >
                                    {translate('addToQueue')}
                                </Button>
                                <PopupActionButton
                                    actionName={translate(
                                        'generateAllInvoicesUpToToday'
                                    )}
                                    buttonVariant={ButtonVariant.SOLID}
                                    content={
                                        <VStack mt={5}>
                                            <Box py={'16px'}>
                                                {translate(
                                                    'areYouSureInvoiceGenerateupToToday'
                                                )}
                                            </Box>
                                        </VStack>
                                    }
                                    title={translate('confirmation')}
                                    size={SizeOptions.SM}
                                    onConfirm={onConfirmHandler}
                                />
                                <PopupActionButton
                                    actionName={translate('generateNext')}
                                    buttonVariant={ButtonVariant.SOLID}
                                    content={
                                        <VStack mt={5}>
                                            <HStack>
                                                <Text fontWeight={'medium'}>
                                                    {translate('referenceRate')}
                                                    :
                                                </Text>
                                                <Text>
                                                    {interestRates[0]?.value ??
                                                        0}
                                                </Text>
                                            </HStack>
                                            <Box py={'16px'}>
                                                {translate(
                                                    'areYouSureInvoiceGenerate'
                                                )}
                                            </Box>
                                        </VStack>
                                    }
                                    title={translate('confirmation')}
                                    size={SizeOptions.SM}
                                    onConfirm={onGenerateNext}
                                ></PopupActionButton>
                            </Flex>
                        }
                    />
                )}
            </Stack>
        </>
    )
}
