import { ReactElement } from 'react'
import { FiMenu } from 'react-icons/fi'

import { useAuth0 } from '@auth0/auth0-react'
import {
    Avatar,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    useColorModeValue,
} from '@chakra-ui/react'

import { useLayout } from '../../../../services/contexts/LayoutServices.context'
import ColorModeSwitcher from '../../../colorModeSwitcher/ColorModeSwitcher.component'
import UserActions from '../userActions/UserActions'

function HeaderComponent(): ReactElement {
    const { user: auth0user } = useAuth0()
    const { openMenu } = useLayout()
    return (
        <Flex
            display={'flex'}
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            bg={useColorModeValue('primary.700', 'secondary.850')}
            height="20"
            alignItems="center"
            borderBottomWidth="1px"
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
        >
            <HStack spacing={{ base: '0', md: '6' }} gap={'1rem'}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <Avatar size={'sm'} src={auth0user?.picture} />
                        </MenuButton>
                        <MenuList>
                            {UserActions().map((Item, index) => (
                                <div key={index}>{Item}</div>
                            ))}
                        </MenuList>
                    </Menu>
                </Flex>
                <ColorModeSwitcher
                    justifySelf="flex-end"
                    color={useColorModeValue('white', 'secondary.50')}
                    bg={useColorModeValue('primary.700', 'secondary.900')}
                    border={'1px solid'}
                    borderColor={useColorModeValue(
                        'primary.50',
                        'secondary.50'
                    )}
                    rounded={'full'}
                />
            </HStack>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={openMenu}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
        </Flex>
    )
}

export default HeaderComponent
