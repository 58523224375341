import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPatchAPI } from '../../../../../services/API/general.api'
import { AssetServiceDTO } from '../../../../../utils/types/types'

export const updateContractAssetService = async (
    contractAssetNumber: string,
    assetServices: AssetServiceDTO[],
    onSuccess: Function
): Promise<void> => {
    const response = await generalPatchAPI(
        API_ENDPOINTS.contractAssetServices(contractAssetNumber),
        {
            contractAssetNumber,
            assetServices,
        }
    )
    if (response.isOk) {
        onSuccess(response.data)
    }
}
