import { CustomServiceType } from '../../../../../services/contract/Contract.services.config'
import {
    AssetServiceDTO,
    BaseServiceDTO,
} from '../../../../../utils/types/types'

export function matchAssetServices(
    assetServices: AssetServiceDTO[],
    services: BaseServiceDTO[]
): CustomServiceType[] {
    const result: CustomServiceType[] = []
    assetServices.forEach((item: AssetServiceDTO) => {
        services?.some((serviceItem) => {
            if (serviceItem.baseServiceNumber === item.baseServiceNumber) {
                result.push({
                    ...serviceItem,
                    ...item,
                })
                return true
            }
            return false
        })
    })

    return result ?? []
}
