/* eslint-disable no-unused-vars */
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
/* eslint-disable object-shorthand */
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, FormLabel } from '@chakra-ui/react'

import withModalHOC from '../../../../hoc/modal.hoc'
import { IApiResponse } from '../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPutAPI } from '../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { offsetTimeZoneDifference } from '../../../../utils/functions.utils'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'

interface IUsageAmounts {
    assetNumber: string
    usageAmount: number
}
interface IUsagePopupProps {
    assets?: any[]
    hasActions?: boolean
    hasSelection?: boolean
    onSubmitFinish?: Function
}
function UsagePopupComponent({
    assets = [],
    onSubmitFinish = () => {},
}: IUsagePopupProps): ReactElement {
    const [usageAmounts, setUsageAmounts] = useState<IUsageAmounts[]>([])
    const [usageSubmitDate, setUsageSubmitDate] = useState(new Date())
    const [isUsageSubmitLoading, setUsageSubmitLoading] =
        useState<boolean>(false)
    const { formatValue } = useNumberFormatterContext()

    const translate = useTranslation().t

    const onChangeUsageAmount = (usage: number, assetNumber: string) => {
        setUsageAmounts((prevValues) => {
            let values = JSON.parse(JSON.stringify(prevValues))
            if (
                !values.some(
                    (usageValue: IUsageAmounts) =>
                        usageValue.assetNumber === assetNumber
                )
            ) {
                values.push({
                    assetNumber: assetNumber,
                    usageAmount: usage,
                })
            } else {
                values = prevValues.map((usageValue: IUsageAmounts) => {
                    if (usageValue.assetNumber === assetNumber) {
                        return {
                            assetNumber: assetNumber,
                            usageAmount: usage,
                        }
                    }
                    return usageValue
                })
            }
            return values
        })
    }

    const handleSubmitUsage = () => {
        setUsageSubmitLoading(true)
        const promiseArray: Promise<IApiResponse>[] = []
        usageAmounts.forEach((usageItem) => {
            promiseArray.push(
                generalPutAPI(
                    `${API_ENDPOINTS.usage}/${usageItem.assetNumber}`,
                    {
                        assetNumber: usageItem.assetNumber,
                        value: usageItem.usageAmount,
                        lastUpdateTime: usageSubmitDate.toISOString(),
                        isMultipleEntry: false,
                    }
                )
            )
        })
        Promise.all(promiseArray).finally(() => {
            onSubmitFinish()
        })
    }

    const headers = [
        { headerName: translate('itemId'), field: 'baseAssetNumber' },
        { headerName: translate('contractAssetId'), field: 'assetNumber' },
        { headerName: translate('make'), field: 'make' },
        { headerName: translate('model'), field: 'model' },
        { headerName: translate('serialNumber'), field: 'serialNumber' },
        {
            headerName: translate('priceUnitOverLimit'),
            field: 'pricePerUnitOverUsageLimit',
            valueFormatter: (params: any) =>
                formatValue(params.data.pricePerUnitOverUsageLimit),
        },
        {
            headerName: translate('priceUnitUnderLimit'),
            field: 'pricePerUnitUnderUsageLimit',
            valueFormatter: (params: any) =>
                formatValue(params.data.pricePerUnitUnderUsageLimit),
        },
        { headerName: translate('usageType'), field: 'usageType' },
        {
            headerName: translate('usageLimit'),
            field: 'usageLimit',
            valueFormatter: (params: any) =>
                formatValue(params.data.usageLimit),
        },

        {
            onCellValueChanged: (params: any) => {
                onChangeUsageAmount(
                    params.data.usageAmount,
                    params.data.assetNumber
                )
            },
            editable: true,
            pinned: 'right',
            cellEditor: 'agNumberCellEditor',
            field: 'usageAmount',
            cellClass: 'ln-editable-cell',
            cellEditorParams: {
                showStepperButtons: true,
            },
            headerName: translate('usageAmount'),
            valueFormatter: (params: any) =>
                formatValue(params.data.usageAmount),
        },
    ]

    return (
        <Box>
            <DynamicGrid
                columns={headers}
                rowData={assets}
                tableId={'usagePopupTable'}
            />
            <Box mt={4}>
                <FormLabel htmlFor={'usageDate'}>{translate('date')}</FormLabel>
                <Box maxW={425}>
                    <SingleDatepicker
                        name="usageDate"
                        maxDate={new Date()}
                        date={usageSubmitDate}
                        onDateChange={(date: Date) => {
                            if (date) {
                                setUsageSubmitDate(
                                    offsetTimeZoneDifference(date)
                                )
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box mt={4} display={'flex'} justifyContent={'right'}>
                <Button
                    isLoading={isUsageSubmitLoading}
                    onClick={handleSubmitUsage}
                >
                    {translate('submit')}
                </Button>
            </Box>
        </Box>
    )
}

export default withModalHOC<any>(UsagePopupComponent)
