import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import ContractsReportDownloadConfig from '../../../../app/config/download/contractsReport.config.json'
import DownloadActions from '../../../../components/downloadActions/DownloadActions.component'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { AssetBalanceDTO } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { StyledLink } from '../../../demo/StyledComponents.component'

interface IContractReportTable {
    items: AssetBalanceDTO[] | undefined
    navigate: Function
}

function ContractReportTableComponent({
    items,
    navigate,
}: IContractReportTable): ReactElement {
    const [selectedItems, setSelectedItems] = useState<AssetBalanceDTO[]>([])
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const selectAllElements = async (): Promise<void> => {
        items && setSelectedItems([...items])
    }

    const headers = [
        {
            headerName: translate('contractNumber'),
            field: 'contractNumber',
            filter: true,
            cellRenderer: (params: any) => (
                <StyledLink
                    onClick={(): void =>
                        navigate(`/contracts/${params.data.contractNumber}`)
                    }
                >
                    {params.data.contractNumber}
                </StyledLink>
            ),
        },
        {
            headerName: translate('initialBalance'),
            field: 'initialBalance',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.initialBalance),
        },
        { headerName: translate('period'), field: 'period' },
        {
            headerName: translate('endBalance'),
            field: 'endBalance',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.endBalance),
        },
        {
            headerName: translate('periodStart'),
            field: 'periodStartDate',
            filter: true,
            valueFormatter: (params: any) =>
                formatDate(params.data.periodStartDate),
        },
        {
            headerName: translate('periodEnd'),
            field: 'periodEndDate',
            filter: true,
            valueFormatter: (params: any) =>
                formatDate(params.data.periodEndDate),
        },
        {
            headerName: translate('monthlyPayment'),
            field: 'monthlyPayment',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.monthlyPayment),
        },
        {
            headerName: translate('depreciation'),
            field: 'depreciation',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.depreciation),
        },
        {
            headerName: translate('interestPayed'),
            field: 'interestPayed',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.interestPayed),
        },
    ]

    return (
        <Box pt={8} w="100%">
            <DynamicGrid
                tableId="contractReportTable"
                columns={headers}
                rowData={items}
                onSelectionChanged={(values: any): void => {
                    const selectedNodes = values.api.getSelectedNodes()
                    const selectedData = selectedNodes.map(
                        (node: any) => node.data
                    )
                    setSelectedItems(selectedData)
                }}
                headers={
                    <>
                        <DownloadActions
                            isDownloadEnabled={isDownloadEnabled}
                            setDownloadEnabled={setDownloadEnabled}
                            selectAllElements={selectAllElements}
                            selectedItems={selectedItems}
                            downloadConfig={ContractsReportDownloadConfig}
                            resetSelectedItems={() => setSelectedItems([])}
                        />
                    </>
                }
            />
        </Box>
    )
}
export default ContractReportTableComponent
