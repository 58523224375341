import camelCase from 'lodash/camelCase'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import {
    sumDepreciation,
    sumInterest,
} from '../../../../features/genericTables/invoicesTable/InvoicesTable.config'
import { ColumnActionConfiguration } from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { InvoiceType } from '../../../../utils/types/types'
import { StyledLink } from '../../../demo/StyledComponents.component'

export const columnsConfig = (
    translate: Function,
    navigate: Function,
    formatValue: Function
) => [
    {
        field: 'contractNumber',
        headerName: translate('contractNumber'),
        filter: 'agTextColumnFilter',
        editable: false,
        pinned: 'left',
        cellRenderer: (params: any) => (
            <StyledLink
                onClick={(): void =>
                    navigate(`/contracts/${params.data.contractNumber}`)
                }
            >
                {params.data.contractNumber}
            </StyledLink>
        ),
    },
    {
        field: 'invoiceNumber',
        headerName: translate('invoiceNumber'),
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: (params: any) => (
            <StyledLink
                onClick={(): void =>
                    navigate(`/financial/invoices/${params.data.invoiceNumber}`)
                }
            >
                {params.data.contractNumber}
            </StyledLink>
        ),
    },
    {
        field: 'invoiceType',
        headerName: translate('invoiceType'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            translate(camelCase(InvoiceType[params?.data?.invoiceType])),
    },
    {
        field: 'amountExcludingVat',
        headerName: translate('amountExcludingVat'),
        filter: 'agTextColumnFilter',
        valueFormatter: (params: any) =>
            formatValue(params, 'amountExcludingVat'),
    },
    {
        field: 'creationDateTime',
        headerName: translate('creationDate'),
        valueGetter: (params: any) =>
            formatDate(params?.date?.creationDateTime),
        filter: 'agDateColumnFilter',
    },
    {
        field: 'periodStartDate',
        headerName: translate('periodStart'),
        valueGetter: (params: any) => formatDate(params?.date?.periodStartDate),
        filter: 'agDateColumnFilter',
    },
    {
        field: 'depreciation',
        headerName: translate('depreciation'),
        valueGetter: (params: any) =>
            formatValue(sumDepreciation(params?.data)),
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'earnings',
        headerName: translate('earnings'),
        valueGetter: (params: any) => formatValue(sumInterest(params?.data)),
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'isPaid',
        headerName: translate('isPaid'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            translate(camelCase(params?.data?.isPaid)),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('view')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="view"
                        size="sm"
                        variant={'outline'}
                        onClick={() =>
                            navigate(
                                `/financial/invoices/${params?.data?.invoiceNumber}`
                            )
                        }
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
