import { MutableRefObject, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'

import withModalHOC from '../../../hoc/modal.hoc'
import { CreateProductCommand, ProductDTO } from '../../../utils/types/types'
import DynamicForm from '../../dynamicForm/DynamicForm.feature'
import { addProductFormFields } from './ProductActions.config'

function AddProductContainer({ assetTypes = [], ...props }): ReactElement {
    const [tabIndex, setTabIndex] = useState(0)
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const translate = useTranslation().t

    return (
        <Box>
            <Tabs
                index={tabIndex}
                mt={2}
                onChange={(index): void => setTabIndex(index)}
            >
                <TabList>
                    <Tab> {translate('details')}</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel p={6} width={'100%'} textAlign={'center'}>
                        <DynamicForm<Partial<CreateProductCommand>>
                            data={{
                                validFromDateTime: new Date().toISOString(),
                            }}
                            formFields={addProductFormFields(assetTypes)}
                            onSubmit={(data: ProductDTO): void => {
                                props.handleSaveProduct(data)
                                props.onClose()
                            }}
                            formSubmitReference={formSubmitReference}
                            hideSubmit
                        />
                    </TabPanel>
                    <Button
                        onClick={(): void => {
                            setTabIndex(0)
                            if (formSubmitReference) {
                                setTimeout(() => {
                                    formSubmitReference.current?.click()
                                }, 250)
                            }
                        }}
                        size={'md'}
                        float="right"
                        mb={2}
                    >
                        {translate('confirm')}
                    </Button>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default withModalHOC(AddProductContainer)
