import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid, Heading, HStack } from '@chakra-ui/react'

import StepButton from '../../components/stepper/StepButton.component'
import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import {
    InsuranceContextProvider,
    useInsurance,
} from '../../services/contexts/Insurance.context'
import {
    StepperContextProvider,
    useStepper,
} from '../../services/contexts/Stepper.context'
import { BaseInsuranceDto } from '../../utils/types/types'
import { VATContextProvider } from '../../services/contexts/vat.context'

type OptionalBaseInsuranceDTO = Partial<BaseInsuranceDto>

interface InsuranceContainerInterface extends ModalSharedProps {
    value: OptionalBaseInsuranceDTO
    handleInsuranceSave(insurance: OptionalBaseInsuranceDTO): void
}

function InsuranceContainer({
    value,
    handleInsuranceSave,
}: InsuranceContainerInterface): ReactElement {
    const { currentStep, moveNext, movePrev } = useStepper()
    const { steps, insurance, initialDataSettling } = useInsurance()
    const translate = useTranslation().t
    const isEditingMode = true

    useEffect(() => {
        initialDataSettling(value)
    }, [JSON.stringify(value)])

    return (
        <Box>
            <HStack justifyContent={'space-between'} gap={0}>
                {steps.map((step, key) => {
                    const stepIteration = key + 1
                    return (
                        <Grid
                            key={key}
                            justifyItems={'center'}
                            w={stepIteration === steps.length ? 'auto' : '100%'}
                            alignItems={'center'}
                            gridTemplateColumns={'40px 1fr'}
                            m={0}
                            py={2}
                            px={0}
                        >
                            <StepButton
                                activeStep={currentStep}
                                step={stepIteration}
                                totalSteps={steps.length}
                            />
                        </Grid>
                    )
                })}
            </HStack>

            {currentStep && steps ? (
                <Box marginTop={'2rem'}>
                    <Heading as="h3" size="lg">
                        {translate(steps[currentStep - 1]?.label)}
                    </Heading>
                    <Box marginY="16px">{steps[currentStep - 1]?.content}</Box>
                </Box>
            ) : (
                <></>
            )}

            <HStack mt={8}>
                <Button
                    isDisabled={currentStep === 1}
                    onClick={movePrev}
                    textTransform={'capitalize'}
                >
                    {translate('previous')}
                </Button>
                {currentStep !== steps.length && (
                    <Button
                        isDisabled={
                            steps[currentStep - 1]?.isDisabled ||
                            currentStep === steps.length
                        }
                        onClick={moveNext}
                        textTransform={'capitalize'}
                    >
                        {translate('next')}
                    </Button>
                )}
                {currentStep === steps.length && (
                    <Button
                        isDisabled={false}
                        onClick={(): void => {
                            handleInsuranceSave(insurance)
                        }}
                        textTransform={'capitalize'}
                        isLoading={false}
                    >
                        {isEditingMode
                            ? translate('save')
                            : translate('finish')}
                    </Button>
                )}
            </HStack>
        </Box>
    )
}
const InsuranceContainerWithContext = (
    props: InsuranceContainerInterface
): ReactElement => {
    console.log('props', props)
    return (
        <VATContextProvider>
            <InsuranceContextProvider>
                <StepperContextProvider length={4}>
                    <InsuranceContainer {...props} />
                </StepperContextProvider>
            </InsuranceContextProvider>
        </VATContextProvider>
    )
}

export default withModalHOC<InsuranceContainerInterface>(
    InsuranceContainerWithContext
)
