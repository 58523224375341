/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useBoolean } from '@chakra-ui/react'

import HighlightComponent from '../../../../components/highlight/Highlight.component'
import NumberFormatterKeyInformation from '../../../../features/numberFormatterKeyInformation/numberFormatterKeyInformation.feature'
import { ContractStatus, ProductType } from '../../../../utils/types/types'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { useContractRulesService } from '../../../../services/rules/ProductRulesEnforcer.services'
import { useContractService } from '../../../../services/contract/Contract.services'
import { usePartner } from '../../../../services/contexts/partner.context'

export default function KeyInformationFeature(): ReactElement {
    const { activePartner } = usePartner()
    const translate = useTranslation().t
    const { contract, financing } = useContractService()
    const { productType } = useContractRulesService()
    const startDate =
        contract.status === ContractStatus.Active
            ? contract?.startActualDate
            : financing?.startDate
    const endDate =
        contract.status === ContractStatus.Closed
            ? contract?.endActualDate
            : contract?.endDate

    const [hideRestValue, setHideRestValue] = useBoolean(false)

    useEffect(() => {
        if (
            productType === ProductType.Rental ||
            productType === ProductType.Subscription
        ) {
            setHideRestValue.on()
        } else setHideRestValue.off()
    }, [productType])

    const monthlyPaymentExclVAT =
        productType === ProductType.ShortTermRental
            ? financing?.totalRentalPrice
            : financing?.totalPeriodicPaymentWithAll

    return (
        <>
            <NumberFormatterKeyInformation
                title={translate('monthlyPaymentExclVAT')}
                value={monthlyPaymentExclVAT || 0}
                currency={activePartner?.currencyName}
            />

            {/* {!hideRestValue && (
                <NumberFormatterKeyInformation
                    title={translate('totalRestValue')}
                    value={financing?.totalRestValue || 0}
                    currency={activePartner?.currencyName}
                    hasBorder={true}
                />
            )} */}

            {startDate && (
                <HighlightComponent
                    title={translate('startDateHeader')}
                    info={formatDate(startDate)}
                    hasBorder={true}
                />
            )}
            {endDate && productType !== ProductType.Subscription && (
                <HighlightComponent
                    title={translate('endDate')}
                    info={formatDate(endDate)}
                    hasBorder={true}
                />
            )}
        </>
    )
}
