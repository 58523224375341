import { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { useContractService } from '../../../services/contract/Contract.services'
import {
    columnsConfig,
    getParametersAndDefinitionsRows,
} from './InventoryAssetsTable.config'

interface IInventoryAssetsTable {
    assets?: any
}
export default function InventoryAssetsTable({
    assets,
}: IInventoryAssetsTable): ReactElement {
    const translate = useTranslation().t
    const navigate = useNavigate()
    const gridApiRef = useRef(null)
    const { contract } = useContractService()

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    return (
        <DynamicGrid
            tableId={'inventoryAssetsTable'}
            onGridReady={onGridReady}
            pagination={true}
            rowMultiSelectWithClick={true}
            columns={columnsConfig(translate, (params: any) => (
                <Tooltip label={translate('view')} placement="top" hasArrow>
                    <IconButton
                        aria-label="View"
                        size="sm"
                        variant={'outline'}
                        onClick={(): any => {
                            navigate(
                                `/contracts/${params?.data.contractNumber}`,
                                {
                                    state: params?.data,
                                }
                            )
                        }}
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ))}
            rowData={getParametersAndDefinitionsRows(
                assets || (contract.contractAssets ?? []),
                translate
            )}
        ></DynamicGrid>
    )
}
