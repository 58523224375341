import {
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import { formatDate } from '../../../utils/localization/culture.utils'
import {
    AssetStatus,
    ContractStatus,
    GetAssetDetail,
} from '../../../utils/types/types'

export const columnsConfig = (translate: Function, actionsFn: Function) => [
    {
        field: 'contractNumber',
        filter: 'agTextColumnFilter',
        headerName: translate('contract'),
    },
    {
        field: 'contractStatus',
        filter: 'agTextColumnFilter',
        headerName: translate('status'),
        valueGetter: (params: any) => translate(params.data.contractStatus),
    },
    {
        field: 'contractEndDate',
        filter: 'agTextColumnFilter',
        headerName: translate('endDate'),
        valueGetter: (params: any) => formatDate(params?.data?.contractEndDate),
    },
    {
        field: 'location',
        filter: 'agTextColumnFilter',
        headerName: translate('location'),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(translate, (params: any) =>
            actionsFn(params)
        ),
    },
]

export function getParametersAndDefinitionsRows(
    items: GetAssetDetail[],
    translate: Function
): any[] {
    return items.map((assetItem) => ({
        contractNumber: assetItem?.contractNumber || '',
        contractStatus:
            getSpecificKeyFromObject<typeof ContractStatus>(
                ContractStatus,
                assetItem?.contractStatus
            ) || '',
        contractEndDate: assetItem?.contractEndDate
            ? formatDate(assetItem?.contractEndDate)
            : '',
        location: translate(
            assetItem?.assetStatus === AssetStatus.InUse
                ? 'atCustomer'
                : assetItem?.location?.locationName || 'unspecified'
        ),
    }))
}
