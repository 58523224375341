import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Fade, Flex, Heading, useToast } from '@chakra-ui/react'

import ColorModeSwitcher from '../../components/colorModeSwitcher/ColorModeSwitcher.component'
import GenericBox from '../../components/genericBox/genericBox'
import PageContainer from '../../components/pageContainer/PageContainer.component'
import DynamicForm from '../../features/dynamicForm/DynamicForm.feature'
import ErrorBoundaryWrapper from '../../features/errorBoundary/ErrorBoundary.feature'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalPutAPI } from '../../services/API/general.api'
import { usePartner } from '../../services/contexts/partner.context'
import { useUser } from '../../services/contexts/user.context'
import { baseSuccessToastOptions } from '../../utils/functions.utils'
import { CreateUserCommand } from '../../utils/types/types'
import { getFormFieldsForProfile, UserWithPartner } from './Profile.config'

export default function ProfilePage(): ReactElement {
    const { user, updateUser } = useUser()
    const translate = useTranslation().t
    const toast = useToast()
    const { partners, activePartner } = usePartner()
    const [userWithPartnerData, setUserWithPartnerData] = useState<
        Partial<UserWithPartner>
    >({})
    const [isLoading, setLoading] = useState(true)

    const retrieveCurrentPartner = async (): Promise<void> => {
        if (activePartner?.id) {
            setUserWithPartnerData({ ...user, partnerId: activePartner.id })
            setLoading(false)
        }
    }

    useEffect((): void => {
        retrieveCurrentPartner()
    }, [user, activePartner])

    const handleUserAndPartnerUpdate = async (
        data: UserWithPartner
    ): Promise<void> => {
        setLoading(true)
        data.partnerId !== userWithPartnerData.partnerId &&
            (await generalPutAPI(API_ENDPOINTS.userPartner, {
                newPartnerId: data.partnerId,
            }))

        const updatedUser: Partial<CreateUserCommand> = {
            ...user,
            ...data,
            changePasswordOnNextLogin: false,
        }
        await updateUser(updatedUser)

        setUserWithPartnerData(data)
        window.location.reload()
        toast(baseSuccessToastOptions(translate('profileUpdated')))
        setLoading(false)
    }

    return (
        <ErrorBoundaryWrapper id="profile-page-error">
            <PageContainer
                isLoading={isLoading}
                pageHeader={
                    <Flex w={'100%'} alignItems={'center'}>
                        <Heading
                            as="h2"
                            size="xl"
                            textAlign={'left'}
                            fontWeight={'md'}
                            letterSpacing={'wide'}
                            mb={2}
                        >
                            {translate('profile')}
                        </Heading>
                        <Box>
                            {!process.env.NODE_ENV ||
                                (process.env.NODE_ENV === 'development' && (
                                    <ColorModeSwitcher />
                                ))}
                        </Box>
                    </Flex>
                }
            >
                <Flex gap={8} wrap={'wrap'}>
                    {!isLoading && (
                        <GenericBox p={6} width={'100%'} maxW={'xl'}>
                            <Fade in>
                                <DynamicForm<UserWithPartner>
                                    data={
                                        userWithPartnerData as UserWithPartner
                                    }
                                    formFields={getFormFieldsForProfile(
                                        userWithPartnerData as UserWithPartner,
                                        partners,
                                        user.culture
                                    )}
                                    onSubmit={handleUserAndPartnerUpdate}
                                    submitButtonProps={{ width: '100%' }}
                                />
                            </Fade>
                        </GenericBox>
                    )}
                </Flex>
            </PageContainer>
        </ErrorBoundaryWrapper>
    )
}
