import React from 'react'
import Pdf from 'react-pdf-js'

import { Box, Image, Text, VStack } from '@chakra-ui/react'

interface FilePreviewProps {
    url: string
    shouldRemoveSuffix?: boolean
}

const FilePreview: React.FC<FilePreviewProps> = ({
    url,
    shouldRemoveSuffix = false,
}) => {
    const fileType = url.split('.').pop()

    const parsedURL = shouldRemoveSuffix ? url.replace(/\.[^/.]+$/, '') : url

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileType || '')
    const isVideo = ['mp4', 'webm'].includes(fileType || '')
    const isText = ['txt'].includes(fileType || '')
    const isPDF = ['pdf'].includes(fileType || '')

    return (
        <VStack spacing={2} align="stretch">
            <Box overflow="hidden">
                {isImage && <Image src={url} alt="file preview" />}
                {isVideo && (
                    <video width="320" height="240" controls>
                        <source src={url} type={`video/${fileType}`} />
                        Your browser does not support the video tag.
                    </video>
                )}
                {isText && (
                    <Box p={4}>
                        {/* Here you can fetch and display the text content */}
                        <Text>{`Text file preview for ${parsedURL}`}</Text>
                    </Box>
                )}
                {isPDF && (
                    <Box>
                        <Pdf file={parsedURL} />
                    </Box>
                )}
            </Box>
            {!isImage && !isVideo && !isText && !isPDF && (
                <Box p={4}>
                    <Text>Unsupported file type</Text>
                </Box>
            )}
        </VStack>
    )
}

export default FilePreview
