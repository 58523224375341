import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import DynamicGrid from '../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import useIsMounted from '../../services/hooks/isMounted'
import { getAllPagesHandlerPrompt } from '../../utils/pagination/pagination.util'
import {
    AssetStatus,
    ContractAssetDTO,
    GetBaseAssetDetail,
} from '../../utils/types/types'
import { ASSET_LIST_HEADERS } from './existingAsset.feature.config'

interface AddExistingAssetInterface extends ModalSharedProps {
    onSelected(item: GetBaseAssetDetail): void
    existingContractAssets?: ContractAssetDTO[]
}

function AddExistingAssetContainer({
    onSelected,
    existingContractAssets = [],
}: AddExistingAssetInterface): ReactElement {
    const [baseAssets, setBaseAssets] = useState<GetBaseAssetDetail[]>()
    const isMounted = useIsMounted()
    const translate = useTranslation().t

    function removedAddedAssets(
        items: GetBaseAssetDetail[]
    ): GetBaseAssetDetail[] {
        let filteredItems: GetBaseAssetDetail[] = [...items]
        existingContractAssets.forEach((asset) => {
            filteredItems = filteredItems.filter(
                (item) => item.baseAssetNumber !== asset.baseAssetNumber
            )
        })
        return filteredItems
    }

    const retrieveBaseAssets = async (): Promise<void> => {
        await getAllPagesHandlerPrompt(
            API_ENDPOINTS.assetBasePaginated,
            (data: GetBaseAssetDetail[] | undefined) => {
                if (data) {
                    setBaseAssets((prevAssets) =>
                        removedAddedAssets([...(prevAssets || []), ...data])
                    )
                }
            }
        )
    }

    useEffect((): void => {
        if (isMounted()) {
            retrieveBaseAssets()
        }
    }, [isMounted])

    const sortExistingAssets = (
        a: GetBaseAssetDetail,
        b: GetBaseAssetDetail
    ): number => {
        // First, sort by assetStatus with "Ready" (1) first
        if (
            a.assetStatus === AssetStatus.Ready &&
            b.assetStatus !== AssetStatus.Ready
        ) {
            return -1
        }
        if (
            a.assetStatus !== AssetStatus.Ready &&
            b.assetStatus === AssetStatus.Ready
        ) {
            return 1
        }

        // If assetStatus is the same, sort by baseAssetNumber in descending order
        if (a.baseAssetNumber && b.baseAssetNumber) {
            return b.baseAssetNumber.localeCompare(a.baseAssetNumber) // Descending order
        }

        return 0 // In case baseAssetNumber is missing in one of the objects
    }

    return (
        <Box mt={8}>
            <DynamicGrid
                columns={ASSET_LIST_HEADERS(translate, true, false, onSelected)}
                rowData={baseAssets?.sort(sortExistingAssets)}
                tableId={'existingAssetTable'}
            />
        </Box>
    )
}

export default withModalHOC<AddExistingAssetInterface>(
    AddExistingAssetContainer
)
