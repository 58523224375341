import { getRandomAssetNumber } from './functions.utils'
import { ContractAssetDTO, ContractAssetStatus, FuelType } from './types/types'

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

export const ExtensionTypes = {
    BMP: 'image/bmp',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    GIF: 'image/gif',
    JPEG: 'image/jpeg',
    JSON: 'application/json',
    PNG: 'image/png',
    PDF: 'application/pdf',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS: 'application/vnd.ms-excel',
    CSV: 'application/csv',
}

export const ExtensionDownloadTypes = {
    BMP: 'image/bmp',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    GIF: 'image/gif',
    JPEG: 'image/jpeg',
    JSON: 'application/json',
    PNG: 'image/png',
    PDF: 'application/pdf',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS: 'application/vnd.ms-excel',
    ZIP: 'application/zip',
    CSV: 'application/csv',
}

export const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const DEFAULT_ASSET: ContractAssetDTO = {
    ageInMonths: 0,
    assetNumber: getRandomAssetNumber('temp_'),
    assetServices: [],
    assetTypeData: undefined,
    assetTypeId: undefined,
    deliveryCost: 0,
    downpayment: 0,
    excessPaymentFee: 0,
    expectedDeliveryDate: new Date().toISOString(),
    exteriorColor: '',
    firstRegistrationDateTime: new Date().toISOString(),
    fuelType: FuelType.Gasoline,
    gtin: '',
    insurancePolicies: [],
    interiorColor: '',
    isAssetUsed: true,
    isPartRegistration: false,
    make: '',
    model: '',
    modelYear: '',
    originalPurchaseValue: 0,
    periodicPayment: 0,
    power: '',
    pricePerUnitOverUsageLimit: 0,
    pricePerUnitUnderUsageLimit: 0,
    principal: 0,
    productNumber: '',
    purchaseValue: 0,
    registrationNumber: '',
    registrationValue: 0,
    restValue: 0,
    status: ContractAssetStatus.Virtual,
    usageLimit: 0,
    usageStandAtStart: 0,
    variant: '',
    vin: '',
}

export const BREAK_POINTS_WIDTH = {
    MOBILE: 768,
    DESKTOP: 1140,
}

export const Z_INDEX = {
    TABLE_HEADER: 4,
    MENU_LIST: 3,
}

export const BadgeColorSchemes = {
    default: 'gray',
    success: 'green',
    warning: 'yellow',
    danger: 'red',
    link: 'blue',
}

export type TagType = 'default' | 'success' | 'warning' | 'danger' | 'link'
