import { formatDate } from '../../../../utils/localization/culture.utils'

export const getColumnsConfig = (
    translate: Function,
    formatValue: Function
) => [
    {
        field: 'startDate',
        filter: 'agDateColumnFilter',
        headerName: translate('startDate'),
        valueGetter: (params: any) => formatDate(params?.data?.startDate),
    },
    {
        field: 'endDate',
        filter: 'agDateColumnFilter',
        headerName: translate('endDate'),
        valueGetter: (params: any) => formatDate(params?.data?.endDate),
    },
    {
        field: 'value',
        filter: 'agNumberColumnFilter',
        headerName: translate('referenceRate'),
        valueFormatter: (params: any) => formatValue(params, 'value', 5),
        resizable: false,
        editable: false,
        suppressMovable: true,
    },
]
