import camelCase from 'lodash/camelCase'

import { Button } from '@chakra-ui/react'

import {
    getContractStatusTagType,
    getSpecificKeyFromObject,
} from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { ContractStatus } from '../../../../utils/types/types'
import { StyledBadge } from '../../../demo/StyledComponents.component'

export const columnsConfig = (translate: Function, navigate: Function) => [
    {
        field: 'contractNumber',
        headerName: translate('contractNumber'),
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: (params: any) => (
            <Button
                justifyContent={'start'}
                variant={'link'}
                cursor={'pointer'}
                onClick={(): void =>
                    navigate(`/contracts/${params.data.contractNumber}`)
                }
            >
                {params.data.contractNumber}
            </Button>
        ),
    },
    {
        field: 'status',
        headerName: translate('status'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            translate(camelCase(ContractStatus[params?.data?.status])),
        cellRenderer: (params: any) => (
            <StyledBadge
                variant={getContractStatusTagType(params?.data?.status)}
            >
                {getSpecificKeyFromObject<typeof ContractStatus>(
                    ContractStatus,
                    params.data.status
                )}
            </StyledBadge>
        ),
    },
    {
        field: 'createdByName',
        headerName: translate('createdByName'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'endDate',
        headerName: translate('endDate'),
        valueGetter: (params: any) =>
            params?.data?.endDate ? formatDate(params.data.endDate) : '',
        filter: 'agDateColumnFilter',
    },
]
