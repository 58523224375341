import './polyfills'
import './index.css'

import { LicenseManager } from 'ag-grid-enterprise'
import { createRoot } from 'react-dom/client'

import App from './app/content/App'
import reportWebVitals from './reportWebVitals'

LicenseManager.setLicenseKey(
    'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064931}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LENEO_A/S}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Leneo}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Leneo}_need_to_be_licensed___{Leneo}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[0102]_MTc1NDk1MzIwMDAwMA==9b52952ccdb9702b9f38ee5144cccfab'
)
const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
