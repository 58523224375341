import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, Container, Stack, Text } from '@chakra-ui/react'

import { BadgeColorSchemes } from '../../../../../../utils/constants.utils'
import { InvoiceDTO, InvoiceType } from '../../../../../../utils/types/types'

interface ISingleInvoiceHeader {
    invoice: InvoiceDTO
}

export default function SingleInvoiceHeader({
    invoice,
}: ISingleInvoiceHeader): ReactElement {
    const translate = useTranslation().t

    return (
        <Container maxW={'4xl'} marginLeft={0}>
            <Stack spacing={6} direction="row" alignItems={'baseline'}>
                <Text fontSize={'xl'} fontWeight={600} letterSpacing={'wide'}>
                    {invoice.invoiceNumber}
                </Text>
                <Text fontSize={'xl'}>{invoice.customerName}</Text>
                <Badge
                    as={'div'}
                    colorScheme={BadgeColorSchemes.default}
                    px="2"
                    py="1"
                    rounded="lg"
                    textTransform={'capitalize'}
                    variant="subtle"
                    width={'fit-content'}
                >
                    {translate(camelCase(InvoiceType[invoice?.invoiceType]))}
                </Badge>
                <Badge
                    as={'div'}
                    colorScheme={BadgeColorSchemes.default}
                    px="2"
                    py="1"
                    rounded="lg"
                    textTransform={'capitalize'}
                    variant="subtle"
                    width={'fit-content'}
                >
                    {translate(invoice?.isPaid ? 'paid' : 'notPaid')}
                </Badge>
            </Stack>
        </Container>
    )
}
