import { IFormField } from '../../../features/dynamicForm/DynamicForm.interfaces'
import { regionNames } from '../../../utils/constants.utils'
import { buildListOfKeyValues } from '../../../utils/functions.utils'
import { CountryCode, LocationDTO } from '../../../utils/types/types'

export const FORM_FIELDS_LOCATION: IFormField<Partial<LocationDTO>>[] = [
    {
        slug: 'locationName',
        label: 'locationName',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'street',
        label: 'street',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'building',
        label: 'building',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'floor',
        label: 'floor',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'postalCode',
        label: 'postalCode',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'countryCode',
        label: 'countryCode',
        inputProps: {
            type: 'select',
            isRequired: true,
        },
        optionsKeysValues: buildListOfKeyValues<typeof CountryCode>(
            CountryCode
        ).map((elem) => {
            const option: any = elem
            option[1] = regionNames.of(elem[1])
            return option
        }),
        skipTranslation: true,
    },
    {
        slug: 'city',
        label: 'city',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'contactName',
        label: 'contactName',
        inputProps: { type: 'text', isRequired: true },
    },
    {
        slug: 'phone',
        label: 'phone',
        inputProps: { type: 'text', isRequired: true },
    },
]
