import Excel from 'exceljs'

import {
    formatCurrency,
    formatDate,
    formatNumber,
} from './localization/culture.utils'
import {
    AssetStatus,
    ContractStatus,
    CountryCode,
    ExportStatus,
    FuelType,
    InvoiceLineType,
    UserGroup,
} from './types/types'

type FormatType =
    | 'date'
    | 'number'
    | 'currency'
    | { type: string; values: string[] }

const downloadTableData = async (
    tableData: any[],
    config: any,
    translate: (key: string) => string
): Promise<void> => {
    if (!tableData[0]) return

    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet(config?.sheetName ?? 'Leneo')
    const worksheetColumns: any[] = []

    if (config?.fields) {
        const headers = getHeaders(config, translate)
        headers.forEach((h: any) => {
            worksheetColumns.push({
                ...h,
            })
        })
        worksheet.getRow(1).height = config?.header?.height ?? 15
        worksheet.getRow(1).model = {
            ...worksheet.getRow(1).model,
            style: {
                font: {
                    size: config?.header?.fontSize ?? 12,
                    bold: config?.header?.bold ?? false,
                    color: { argb: config?.header?.color ?? 'ffffff' },
                },
                border: {
                    bottom: {
                        style: 'thin',
                        color: { argb: config?.borderBottomColor ?? '000000' },
                    },
                },
                alignment: {
                    horizontal: config?.header?.horizontalAlign ?? 'left',
                    vertical: config?.header?.verticalAlign ?? 'bottom',
                },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: config?.header?.bgColor ?? 'cccccc',
                    },
                },
            },
        }
    } else {
        const headers = Object.keys(tableData[0])
        headers.forEach((h: string, key: number) => {
            worksheetColumns.push({
                header: h.toUpperCase(),
                key,
                width: h.length + 5,
            })
        })
    }
    // return
    worksheet.columns = worksheetColumns
    const configuredData = config?.fields
        ? configureData(tableData, config)
        : tableData

    configuredData.forEach((td) => {
        worksheet.addRow({ ...td })
    })

    const data = await workbook.xlsx.writeBuffer()

    const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = config?.fileName
        ? `${config?.fileName}-${new Date().toLocaleDateString()}.xlsx`
        : `download-${new Date().toLocaleDateString()}.xlsx`
    anchor.click()
    window.URL.revokeObjectURL(url)
}

const configureData = (data: any[], config: any): any[] => {
    const filteredData: any[] = []
    if (!config?.fields) return filteredData
    const { fields } = config
    data.forEach((element: any) => {
        const filteredElement = {}
        const elementKeys = Object.keys(element)
        const elementValues = Object.values(element)
        elementKeys.forEach((key, index) => {
            if (!Object.prototype.hasOwnProperty.call(fields, key)) return
            let value = elementValues[index]
            if (fields[key].format) {
                value = getFormattedValue(value as string, fields[key].format)
            }
            if (fields[key].enumObject) {
                value = getEnumeratedValue(
                    value as string,
                    fields[key].enumObject
                )
            }
            filteredElement[key] = value
        })
        filteredData.push(filteredElement)
    })
    return filteredData
}

const getHeaders = (config: any, translate: Function): Array<any> => {
    const headers: any = []
    if (!config?.fields) return headers
    const { fields } = config
    const fieldsKeys: string[] = Object.keys(fields)
    const fieldsValues: any = Object.values(fields)
    fieldsKeys.forEach((key, index) => {
        headers.push({
            header: translate(fieldsValues[index].translationKey),
            key,
            width:
                fieldsValues[index]?.width ?? config.header.defaultWidth ?? 15,
            bold: true,
        })
    })
    return headers
}

const getFormattedValue = (
    value: string | number,
    formatType: FormatType
): string | number => {
    if (formatType === 'date') {
        return formatDate(value as string)
    }
    if (formatType === 'number') {
        return formatNumber(Number(value)) // TODO: check need for formated values
    }
    if (formatType === 'currency') {
        return formatCurrency(Number(value))
    }
    if (typeof formatType === 'object' && formatType.type === 'json') {
        const keys = Object.keys(value)
        const values = Object.values(value).filter((v, i) =>
            formatType.values.includes(keys[i])
        )
        return values.join(' - ')
    }
    return value
}

const getEnumeratedValue = (
    data: string | number,
    enumObject: string
): string => {
    const value = typeof data === 'number' ? data : Number(data)
    if (enumObject === 'ContractStatus') {
        return ContractStatus[value]
    }
    if (enumObject === 'AssetStatus') {
        return AssetStatus[value ?? 0]
    }
    if (enumObject === 'FuelType') {
        return FuelType[value ?? 0]
    }
    if (enumObject === 'CountryCode') {
        return CountryCode[value ?? 0]
    }
    if (enumObject === 'UserGroup') {
        return UserGroup[value ?? 0]
    }
    if (enumObject === 'ExportStatus') {
        return ExportStatus[value ?? 0]
    }
    if (enumObject === 'InvoiceLineType') {
        return InvoiceLineType[value ?? 0]
    }
    return value.toString()
}

export default downloadTableData
