import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { numericFormatter } from 'react-number-format'

import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../utils/localization/localization.utils'
import { useUser } from './user.context'

interface NumberFormatterContext {
    formatValue: (params: any, field?: string, decimalScale?: number) => string
}

interface INumberFormatterProviderProps {
    children: ReactNode
}

const NumberFormatterContext = createContext<NumberFormatterContext>(
    {} as NumberFormatterContext
)
export const NumberFormatterProvider = ({
    children,
}: INumberFormatterProviderProps) => {
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [JSON.stringify(user)])

    function formatValue(params: any, field?: string, decimalScale = 2) {
        return numericFormatter(
            params?.data && field
                ? params.data[field]?.toString() || ''
                : params?.toString() || '',
            {
                fixedDecimalScale: true,
                decimalScale,
                thousandSeparator: format.thousands,
                decimalSeparator: format.decimals,
            }
        )
    }
    return (
        <NumberFormatterContext.Provider value={{ formatValue }}>
            {children}
        </NumberFormatterContext.Provider>
    )
}

export const useNumberFormatterContext = (): NumberFormatterContext =>
    useContext(NumberFormatterContext)
