import { buildListOfKeyValues } from '../../../utils/functions.utils'
import {
    AssetTypeBO,
    CreateProductCommand,
    ProductType,
    Recurrence,
    UpdateProductCommand,
} from '../../../utils/types/types'
import { IFormField } from '../../dynamicForm/DynamicForm.feature'

export const addProductFormFields = (
    assetTypes: AssetTypeBO[]
): IFormField<Partial<CreateProductCommand>>[] => [
    {
        slug: 'name',
        label: 'name',
        inputProps: {
            type: 'text',
            isRequired: true,
        },
    },
    {
        slug: 'description',
        label: 'description',
        inputProps: {
            type: 'text',
            isRequired: true,
        },
    },
    {
        slug: 'productType',
        label: 'productType',
        inputProps: { type: 'select', isRequired: true },
        optionsKeysValues:
            buildListOfKeyValues<typeof ProductType>(ProductType),
    },
    {
        slug: 'recurrence',
        label: 'recurrence',
        inputProps: { type: 'select', isRequired: true },
        optionsKeysValues: buildListOfKeyValues<typeof Recurrence>(Recurrence),
    },
    {
        slug: 'maxAllowedAssets',
        label: 'assetsLimit',
        enableDecimal: false,
        inputProps: { type: 'number', min: 0 },
        validation: (value: number) =>
            !Number.isNaN(value) && value > 0 && value !== null,
    },
    {
        slug: 'isUsageEnabled',
        label: 'enableUsage',
        inputProps: { type: 'boolean' },
    },
    {
        slug: 'validFromDateTime',
        label: 'validFromDateTime',
        inputProps: { type: 'date' },
    },
    {
        slug: 'validToDateTime',
        label: 'validToDateTime',
        inputProps: { type: 'date', min: new Date() },
    },
    {
        slug: 'isShadow',
        label: 'isShadow',
        inputProps: { type: 'boolean' },
    },
    {
        slug: 'defaultAssetTypeId',
        label: 'defaultAssetTypeId',
        inputProps: { type: 'select', isRequired: true },
        optionsKeysValues: assetTypes.map((asset) => [asset.id, asset.name]),
    },
    {
        slug: 'canBeDiscounted',
        label: 'canBeDiscounted',
        inputProps: { type: 'boolean' },
    },
]
export type CustomUpdateProductCommandDTO = UpdateProductCommand & {
    disable?: boolean
}

export function editProductFormFields(
    product: Partial<CustomUpdateProductCommandDTO>,
    assetTypes: AssetTypeBO[]
): IFormField<Partial<CustomUpdateProductCommandDTO>>[] {
    return [
        {
            slug: 'name',
            label: 'name',
            inputProps: { type: 'text', isRequired: true },
            value: product.name,
        },
        {
            slug: 'description',
            label: 'description',
            inputProps: { type: 'text', isRequired: true },
            value: product.description,
        },
        {
            slug: 'recurrence',
            label: 'recurrence',
            inputProps: {
                type: 'select',
                disabled: true,
            },
            optionsKeysValues:
                buildListOfKeyValues<typeof Recurrence>(Recurrence),
        },
        {
            slug: 'maxAllowedAssets',
            label: 'assetsLimit',
            inputProps: {
                type: 'number',
                isRequired: true,
            },
            enableDecimal: false,
            value: product.maxAllowedAssets,
            validation: (value: number) =>
                !Number.isNaN(value) && value >= 0 && value !== null,
        },
        {
            slug: 'isUsageEnabled',
            label: 'enableUsage',
            inputProps: { type: 'boolean' },
            value: product?.isUsageEnabled,
        },
        {
            slug: 'validFromDateTime',
            label: 'enabledFrom',
            inputProps: { type: 'date' },
            value: product?.validFromDateTime,
        },
        {
            slug: 'validToDateTime',
            label: 'enabledUntil',
            inputProps: {
                type: 'date',
                min: new Date(),
            },
            value: product?.validToDateTime,
        },
        {
            slug: 'disable',
            label: 'disable',
            inputProps: { type: 'boolean' },
            value: product?.disable || false,
        },
        {
            slug: 'isShadow',
            label: 'isShadow',
            inputProps: { type: 'boolean' },
            value: product?.isShadow || false,
        },
        {
            slug: 'defaultAssetTypeId',
            label: 'defaultAssetTypeId',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues: assetTypes.map((asset) => [
                asset.id,
                asset.name,
            ]),
        },
        {
            slug: 'canBeDiscounted',
            label: 'canBeDiscounted',
            inputProps: { type: 'boolean' },
        },
    ]
}
