import { DocumentType } from '../../../../utils/types/types'

export interface RefreshPenneoStatus {
    penneoDocumentStatus: number
    documentId: number
}

export interface AvailableDocumentType {
    key: string | undefined
    value: DocumentType | undefined
}

export const ACCEPTED_DOCUMENT_TYPES = [
    DocumentType.Contract,
    DocumentType.Offer,
    DocumentType.Guarantor,
    DocumentType.ConcentForm,
    DocumentType.ChangeTotalPrincipal,
    DocumentType.CheckList,
    DocumentType.PurchaseOrder,
    DocumentType.DeliveryAcceptance,
    DocumentType.Appendices,
]
