import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, Grid, Input, Select } from '@chakra-ui/react'

import { useService } from '../../../../services/contexts/Service.context'
import { ExternalPartnerSetup } from '../../../../utils/types/types'

function ERPProductsComponents(): JSX.Element {
    const { service, ERPProducts, ERPSetupType, handleServiceUpdate } =
        useService()
    const translate = useTranslation().t
    return (
        <Box mt={8}>
            <Grid
                gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={4}
            >
                <FormControl>
                    {ERPSetupType !==
                        ExternalPartnerSetup.ErpAlternativeFileCreation && (
                        <Select
                            value={service?.erpProductId}
                            onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                const { value } = event.target
                                handleServiceUpdate({ erpProductId: value })
                            }}
                        >
                            <option value={0}>{translate('select')}</option>
                            {ERPProducts?.map((ERPProduct, index) => (
                                <option
                                    key={index}
                                    value={ERPProduct.productNumber}
                                >
                                    {ERPProduct.name}
                                </option>
                            ))}
                        </Select>
                    )}

                    {ERPSetupType ===
                        ExternalPartnerSetup.ErpAlternativeFileCreation && (
                        <Input
                            value={service?.erpProductId ?? ''}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const { value } = event.target
                                handleServiceUpdate({ erpProductId: value })
                            }}
                        />
                    )}
                </FormControl>
            </Grid>
        </Box>
    )
}

export default ERPProductsComponents
