import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import { getAllPagesHandlerPrompt } from '../../../../utils/pagination/pagination.util'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { columnsConfig } from './CustomerDetailsInvoices.config'

export default function CustomerDetailsInvoices(): ReactElement {
    const translate = useTranslation().t
    const navigate = useNavigate()
    const toast = useToast()
    const [invoices, setInvoices] = useState<any[]>()
    const params = useParams<{ customerNumber?: string }>()
    const CustomerNumber = params.customerNumber
    const { formatValue } = useNumberFormatterContext()

    const fetchInvoices = async () => {
        try {
            await getAllPagesHandlerPrompt(
                `${API_ENDPOINTS.invoice}?CustomerNumber=${CustomerNumber}`,
                (data: any) =>
                    setInvoices((prevValue) => [...(prevValue || []), ...data])
            )
        } catch (error: any) {
            toast(
                baseErrorToastOptions(`${translate('error')}, ${error.message}`)
            )
        }
    }

    useEffect(() => {
        fetchInvoices()
    }, [])

    return (
        <DynamicGrid
            tableId="customerDetailsInvoicesTable"
            columns={columnsConfig(translate, navigate, formatValue)}
            rowData={invoices ?? []}
            pagination={true}
            rowMultiSelectWithClick={false}
        />
    )
}
