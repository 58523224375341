import { MutableRefObject, ReactElement, useRef } from 'react'

import { Box } from '@chakra-ui/react'

import DynamicForm from '../../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import { TransientFeeDTO } from '../../../../../utils/types/types'
import TransientFeeFormFields from '../addTransientFees/ContractDetailTransientFees.config'

interface IEditTransientFee extends ModalSharedProps {
    activePartner: any
    editTransientFee: Function
    transientFee: Partial<TransientFeeDTO>
    VATOptions: any[][]
    hasDiscount: boolean
}

function EditTransientFee({
    activePartner,
    editTransientFee,
    transientFee,
    VATOptions,
    hasDiscount = false,
}: IEditTransientFee): ReactElement {
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    return (
        <Box>
            <DynamicForm
                formSubmitReference={formSubmitReference}
                data={transientFee}
                formFields={TransientFeeFormFields(
                    transientFee as TransientFeeDTO,
                    activePartner,
                    VATOptions,
                    hasDiscount
                )}
                hideSubmit={false}
                onSubmit={editTransientFee}
            />
        </Box>
    )
}

export default withModalHOC(EditTransientFee)
