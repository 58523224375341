/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable arrow-body-style */
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import {
    DeleteIcon,
    EditIcon,
    LockIcon,
    PlusSquareIcon,
    ViewIcon,
} from '@chakra-ui/icons'
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import { AddAssetModal } from '../../../../features/addAsset/addAsset.feature'
import CopyAssetFeature from '../../../../features/existingAsset/copyAsset.feature'
import AddExistingAssetContainer from '../../../../features/existingAsset/existingAsset.feature'
import ContractAssetsTable from '../../../../features/genericTables/contractAssetsTable/ContractAssetsTable.component'
import { IApiResponse } from '../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { useUser } from '../../../../services/contexts/user.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import { DEFAULT_ASSET } from '../../../../utils/constants.utils'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
    getRandomAssetNumber,
} from '../../../../utils/functions.utils'
import {
    ContractAssetDTO,
    ContractAssetStatus,
    ContractStatus,
    GetBaseAssetDetail,
    ProductType,
} from '../../../../utils/types/types'
import StartContractComponent from '../actions/startContract/StartContract.component'
import ContractAssetsActions from './actions/ContractAssetsActions.component'
import ActivateAsset from './activate/ActivateAsset.component'
import AmortizationTable from './amortization/AmortizationTable.component'
import AssetFutureEnd from './assetFutureEnd/assetFutureEnd'
import ContractAssetServices from './services/ContractAssetServices.component'
import SubscriptionPopupFeature from './subscription/subscriptionPopup.feature'
import AddContractAssetInsuranceComponent from './insurances/AddContractAssetInsurance.component'

export default function ContractDetailsAssets(): ReactElement {
    const { user } = useUser()
    const {
        actionsAllowed,
        contract,
        getContract,
        product,
        updateContract,
        isLoading,
    } = useContractService()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const translate = useTranslation().t
    const toast = useToast()

    const [isAssetServicesOpen, assetServicesOpen] = useBoolean()
    const [isExistingAssetsPopupOpen, setExistingAssetsPopupOpen] = useBoolean()
    const [isCopyAssetsPopupOpen, setCopyAssetsPopupOpen] = useBoolean()
    const [isActivateAssetOpen, activateAssetOpen] = useBoolean()
    const [isAssetEditOpen, setAssetEditOpen] = useBoolean()
    const [isAssetAddOpen, setAssetAddOpen] = useBoolean()
    const [isBalanceOpen, setBalanceOpen] = useBoolean()
    const [isStartContractOpen, setStartContractOpen] = useBoolean()
    const [isSubscriptionClosePopupOpen, setSubscriptionClosePopupOpen] =
        useBoolean()
    const [isInsurancePopupOpen, setInsurancePopupOpen] = useBoolean()

    const [assetToEdit, setAssetToEdit] = useState<ContractAssetDTO | any>()
    const [selectedAsset, setSelectedAsset] = useState<ContractAssetDTO | null>(
        null
    )
    const [contractAssetServicesKey, setContractAssetServicesKey] =
        useState<string>(v4())
    const [disableAddAssets, setDisableAddAssets] = useState(false)
    const [parentContract, setParentContract] = useState()
    const [insurances, setInsurances] = useState([])

    const existingContractAssets =
        contract?.contractAssets?.filter(
            (a) => !a.assetNumber || !a.assetNumber.includes('tmp_')
        ) ?? []

    const getParentContract = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.contract}/${contract.parentContractNumber}`
        )
        if (response.isOk) setParentContract(response.data)
    }
    const [showFutureEndModal, setShowFutureEndModal] = useState(false)

    useEffect(() => {
        getInsurances()
    }, [])

    useEffect(() => {
        if (contract?.parentContractNumber) {
            getParentContract()
        }
    }, [contract?.parentContractNumber])

    useEffect(() => {
        setContractAssetServicesKey(v4())
        if (contract?.contractAssets) {
            if (product?.maxAllowedAssets) {
                setDisableAddAssets(
                    product.maxAllowedAssets <= contract?.contractAssets?.length
                )
            } else {
                setDisableAddAssets(false)
            }
        }
    }, [
        JSON.stringify(product),
        JSON.stringify(contract?.contractAssets),
        JSON.stringify(selectedAsset?.assetServices),
    ])

    const onDeleteContractAsset = async (
        assetItem: ContractAssetDTO
    ): Promise<void> => {
        const filteredContractAssets = contract?.contractAssets?.filter(
            (asset) => assetItem.assetNumber !== asset.assetNumber
        )
        updateContract({
            ...contract,
            contractAssets: filteredContractAssets,
        })
        if (assetItem.assetNumber && !assetItem.assetNumber.includes('tmp_')) {
            await generalDeleteAPI(
                `${API_ENDPOINTS.asset}/${assetItem.assetNumber}`
            )
            await getContract()
        }
    }

    const handleSaveAsset = (assetItem: ContractAssetDTO): void => {
        setAssetEditOpen.off()
        setAssetAddOpen.off()
        let updatedAssets = contract.contractAssets ?? []

        const idx = updatedAssets.findIndex(
            (asset) => asset.assetNumber === assetItem.assetNumber
        )
        if (idx >= 0) {
            updatedAssets[idx] = assetItem
        } else {
            updatedAssets = [...updatedAssets, assetItem]
        }
        setAssetToEdit(assetItem)
        updateContract(
            { ...contract, contractAssets: [...updatedAssets] },
            true
        )
        toast(baseSuccessToastOptions(translate('assetUpdated')))
    }

    const handleCreateAsset = (): void => {
        setAssetToEdit({
            ...DEFAULT_ASSET,
            assetNumber: getRandomAssetNumber('tmp_'),
            assetType: {
                id: product?.defaultAssetTypeId,
            },
        })
        setAssetAddOpen.on()
    }

    const handleAddExistingAsset = (
        item: GetBaseAssetDetail
    ): void | string => {
        if (!item) {
            toast(baseInfoToastOptions(translate('error')))
            return
        }
        setExistingAssetsPopupOpen.off()
        const addedAsset: ContractAssetDTO = {
            ...DEFAULT_ASSET,
            ...item,
            assetNumber: getRandomAssetNumber('tmp_'),
        }
        updateContract(
            {
                ...contract,
                contractAssets: [
                    ...(contract?.contractAssets ?? []),
                    addedAsset,
                ],
            },
            true
        )
        setTimeout(() => {
            setAssetToEdit(addedAsset)
            setAssetAddOpen.on()
        }, 250)
    }

    // TODO: TEMPORARY FIX, NEEDS NEW BE ENDPOINT
    const handleCopyAsset = async (
        copyData: ContractAssetDTO[]
    ): Promise<void> => {
        const promisesArray: Promise<IApiResponse>[] = []
        const delayIncrement = 1000
        let delay = 0
        copyData.forEach(async (copyAsset) => {
            promisesArray.push(
                // eslint-disable-next-line no-promise-executor-return
                new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
                    generalPostAPI(API_ENDPOINTS.copyContractAsset, {
                        contractAssetNumber: copyAsset.assetNumber,
                        copyServices: true,
                    })
                )
            )
            delay += delayIncrement
        })

        Promise.all(promisesArray).then((values) => {
            const newAssets: ContractAssetDTO[] = []
            values.forEach((response) => {
                if (response.isOk) {
                    newAssets.push(response.data)
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            })
            if (newAssets.length > 0) {
                const { contractAssets } = contract
                updateContract({
                    ...contract,
                    contractAssets: [
                        ...(contractAssets as ContractAssetDTO[]),
                        ...newAssets,
                    ],
                })
            }
        })
    }

    const scheduleFutureEnd = async (
        contractAssetNumber: string,
        date: Date
    ) => {
        if (contractAssetNumber) {
            try {
                const response = await generalPutAPI(
                    API_ENDPOINTS.assetScheduleFutureEnd(contractAssetNumber),
                    {
                        contractAssetNumber,
                        endDate: date.toISOString(),
                    }
                )
                if (response.isOk) {
                    toast(baseSuccessToastOptions(response.message))
                    getContract()
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            } catch (e: any) {
                console.error(e.message)
            }
        }
    }

    const getInsurances = async () => {
        const response = await generalGetAPI(API_ENDPOINTS.insurance)
        if (response.isOk) {
            setInsurances(response.data)
        }
    }

    const getTableActions = (isLocked: boolean) => {
        const tableActions = (params: any): ReactElement => (
            <div>
                {contract.status === ContractStatus.Offer && (
                    <Tooltip label={translate('edit')} placement="top" hasArrow>
                        <IconButton
                            mr={2}
                            aria-label="Edit"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => {
                                if (params.data) {
                                    setAssetToEdit(params.data)
                                    setAssetEditOpen.on()
                                }
                            }}
                            icon={<EditIcon />}
                        />
                    </Tooltip>
                )}
                {contract.status === ContractStatus.Offer && (
                    <Tooltip
                        label={translate('delete')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            mr={2}
                            isDisabled={isLocked}
                            aria-label="Delete"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => {
                                if (params.data) {
                                    onDeleteContractAsset(params.data)
                                }
                            }}
                            icon={<DeleteIcon />}
                        />
                    </Tooltip>
                )}
                {contract.status !== ContractStatus.Offer && (
                    <Tooltip label={translate('view')} placement="top" hasArrow>
                        <IconButton
                            aria-label="View"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => {
                                if (params.data) {
                                    setSelectedAsset(params.data)
                                    setBalanceOpen.on()
                                }
                            }}
                            icon={<ViewIcon />}
                        />
                    </Tooltip>
                )}

                {contract.status === ContractStatus.Active &&
                    product.productType === ProductType.Subscription &&
                    params.data.status === ContractAssetStatus.Active && (
                        <Tooltip
                            label={translate('scheduleFutureEnd')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                aria-label="View"
                                size="sm"
                                variant={'outline'}
                                ml={2}
                                onClick={(): void => {
                                    setSelectedAsset(params.data)
                                    setShowFutureEndModal(true)
                                }}
                                icon={<LockIcon />}
                            />
                        </Tooltip>
                    )}
            </div>
        )
        return tableActions
    }

    const serviceActions = (params: any) => {
        // eslint-disable-next-line no-unused-vars
        const getName = (): string => {
            if (!params.data?.assetServices?.length) {
                return ''
            }
            return `# ${params.data.assetServices?.length ?? 0}`
        }

        const isTemporaryAsset =
            params.data?.assetNumber && params.data.assetNumber.includes('tmp')
        if (isTemporaryAsset) return
        return (
            <Tooltip label={translate('addService')} placement="top" hasArrow>
                {params.data.assetServices?.length > 0 ? (
                    <Button
                        mr={2}
                        aria-label="Add Service"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void => {
                            if (params.data) {
                                setSelectedAsset(params.data)
                                assetServicesOpen.on()
                            }
                        }}
                        leftIcon={<PlusSquareIcon />}
                    >
                        {getName()}
                    </Button>
                ) : (
                    <IconButton
                        mr={2}
                        aria-label="Add Service"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void => {
                            if (params.data) {
                                setSelectedAsset(params.data)
                                assetServicesOpen.on()
                            }
                        }}
                        icon={<PlusSquareIcon />}
                    />
                )}
            </Tooltip>
        )
    }

    const insuranceActions = (params: any) => {
        // eslint-disable-next-line no-unused-vars
        return (
            <Tooltip label={translate('addInsurance')} placement="top" hasArrow>
                <IconButton
                    mr={2}
                    aria-label="Select Insurance"
                    size="sm"
                    variant={'outline'}
                    onClick={(): void => {
                        if (params.data) {
                            setSelectedAsset(params.data)
                            setInsurancePopupOpen.on()
                        }
                    }}
                    icon={<PlusSquareIcon />}
                />
            </Tooltip>
        )
    }

    const renderActions = (
        <ContractAssetsActions
            actionsAllowed={actionsAllowed}
            handleCreateAsset={handleCreateAsset}
            isActivateAssetDisabled={contract?.status === ContractStatus.Closed}
            isAddAssetDisabled={disableAddAssets}
            isCopyAssetDisabled={
                disableAddAssets || !existingContractAssets?.length
            }
            isLoading={isLoading}
            isLocked={!!contract.isLocked}
            openActivateAsset={activateAssetOpen.on}
            openAddExistingAsset={setExistingAssetsPopupOpen.on}
            openCopyAsset={setCopyAssetsPopupOpen.on}
            openStartContract={setStartContractOpen.on}
            productType={product.productType}
            userGroup={user.userGroup}
        />
    )

    const handleSaveInsurancePolicy = async (data: any) => {
        if (selectedAsset && selectedAsset?.assetNumber) {
            const promises = data.map(async (item: any) => {
                const insurancePolicy = {
                    contractAssetNumber: selectedAsset.assetNumber,
                    baseInsuranceNumber: item.baseInsuranceNumber,
                }

                const response = await generalPostAPI(
                    API_ENDPOINTS.contractAssetInsurancePolicy(
                        selectedAsset.assetNumber as any
                    ),
                    insurancePolicy
                )

                if (!response.isOk) {
                    toast(baseErrorToastOptions(response.message))
                }
                return response.isOk
            })

            const results = await Promise.all(promises)

            if (results.every((result) => result)) {
                getContract()
            }
        }
        setInsurancePopupOpen.off()
    }

    return (
        <Box pb={8}>
            <ContractAssetsTable
                variation={product?.isUsageEnabled ? 'usage' : 'default'}
                tableActions={getTableActions(!!contract?.isLocked)}
                serviceActions={serviceActions}
                insuranceActions={insuranceActions}
                assets={contract.contractAssets}
                additionalActions={renderActions}
            />
            {!isLoading && (
                <ActivateAsset
                    isModalOpen={isActivateAssetOpen}
                    modalTitle={translate('activateAssets')}
                    onClose={activateAssetOpen.off}
                />
            )}

            {/* Modals */}

            <ContractAssetServices
                key={contractAssetServicesKey}
                assetItem={selectedAsset as ContractAssetDTO}
                isOpen={isAssetServicesOpen}
                onClose={useMemo(
                    () => (): void => {
                        assetServicesOpen.off()
                        setSelectedAsset(null)
                    },
                    []
                )}
                setSelectedAsset={useMemo(
                    () => (data: any) => setSelectedAsset(data),
                    []
                )}
                onConfirmResult={useMemo(
                    () => (): void => {
                        const loadingID = globalLoading()
                        setTimeout(() => stopGlobalLoading(loadingID), 500)
                    },
                    []
                )}
            />

            {showFutureEndModal && (
                <AssetFutureEnd
                    contractAssetNumber={selectedAsset?.assetNumber as string}
                    isModalOpen={showFutureEndModal}
                    modalTitle={`${translate('asset')} ${
                        selectedAsset?.assetNumber
                    }`}
                    onClose={function (): void {
                        setShowFutureEndModal(false)
                        setSelectedAsset(null)
                    }}
                    onConfirm={(date: Date) => {
                        setShowFutureEndModal(false)
                        scheduleFutureEnd(
                            selectedAsset?.assetNumber as string,
                            date
                        )
                        setSelectedAsset(null)
                    }}
                ></AssetFutureEnd>
            )}

            {(isAssetEditOpen || isAssetAddOpen) && assetToEdit && (
                <AddAssetModal
                    key={contractAssetServicesKey}
                    actionType={isAssetAddOpen ? 'add' : 'edit'}
                    modalTitle={translate('asset')}
                    isModalOpen={isAssetEditOpen || isAssetAddOpen}
                    onClose={(): void => {
                        setAssetEditOpen.off()
                        setAssetAddOpen.off()
                    }}
                    assetItem={assetToEdit}
                    handleSaveAsset={handleSaveAsset}
                    isUsageEnabled={product?.isUsageEnabled}
                />
            )}
            {isExistingAssetsPopupOpen && (
                <AddExistingAssetContainer
                    modalTitle={translate('addExistingAsset')}
                    isModalOpen={isExistingAssetsPopupOpen}
                    onClose={setExistingAssetsPopupOpen.off}
                    onSelected={handleAddExistingAsset}
                    existingContractAssets={contract?.contractAssets ?? []}
                />
            )}
            {isCopyAssetsPopupOpen && (
                <CopyAssetFeature
                    copyAsset={handleCopyAsset}
                    isModalOpen={isCopyAssetsPopupOpen}
                    modalTitle={translate('copyExistingAssets')}
                    onClose={setCopyAssetsPopupOpen.off}
                    existingContractAssets={existingContractAssets}
                />
            )}
            {isStartContractOpen && (
                <StartContractComponent
                    isModalOpen={isStartContractOpen}
                    maxWidth="md"
                    modalTitle={translate('startContract')}
                    onClose={(): void => setStartContractOpen.off()}
                    parentContract={parentContract}
                />
            )}
            {selectedAsset?.assetNumber && (
                <AmortizationTable
                    assetData={selectedAsset}
                    assetNumber={selectedAsset.assetNumber}
                    isModalOpen={isBalanceOpen}
                    modalTitle={`${selectedAsset.assetNumber} ${translate(
                        'assetDetails'
                    )}`}
                    onClose={setBalanceOpen.off}
                />
            )}
            {isSubscriptionClosePopupOpen && (
                <SubscriptionPopupFeature
                    onClose={setSubscriptionClosePopupOpen.off}
                    isModalOpen={isSubscriptionClosePopupOpen}
                    onConfirm={(asset) => {
                        asset && handleSaveAsset(asset)
                    }}
                    asset={selectedAsset}
                    modalTitle={`Asset ${selectedAsset?.assetNumber} - ${selectedAsset?.make} ${selectedAsset?.model}`}
                />
            )}
            {selectedAsset && isInsurancePopupOpen && (
                <AddContractAssetInsuranceComponent
                    onClose={setInsurancePopupOpen.off}
                    isModalOpen={isInsurancePopupOpen}
                    handleSave={async (data: any) => {
                        handleSaveInsurancePolicy(data)
                    }}
                    contractAsset={selectedAsset}
                    insurances={insurances}
                    modalTitle={`Select Insurance`}
                />
            )}
        </Box>
    )
}
