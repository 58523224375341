import { ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { GetAssetDetail } from '../../../../utils/types/types'
import InventoryAssetsTable from '../../../../features/genericTables/inventoryAssetsTable/InventoryAssetsTable.component'

interface IInventoryAssets {
    isLoading: boolean
    contractAssets: GetAssetDetail[]
}
function InventoryAssets({ contractAssets }: IInventoryAssets): ReactElement {
    return (
        <Box>
            <InventoryAssetsTable
                assets={contractAssets}
            ></InventoryAssetsTable>
        </Box>
    )
}

export default InventoryAssets
