import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Stack } from '@chakra-ui/react'

import ExportedInvoiceLines from '../../../../../../app/config/download/exportedInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import HighlightComponent from '../../../../../../components/highlight/Highlight.component'
import { CustomerDetailInvoiceLineConfig } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.config'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import { ExportStatus, InvoiceDTO } from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'

export default function ExportedPage(): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const [data, setData] = useState<InvoiceDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t

    function calculateTotal(): string {
        let tempTotal = 0
        selectedItems.forEach((item: InvoiceDTO) => {
            tempTotal += item.amountExcludingVat!
        })
        return tempTotal.toFixed(2)
    }

    useEffect(() => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Exported}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }, [])

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const fields = EXPORT_HEADERS(
        translate,
        formatValue,
        false,
        isDownloadEnabled
    )

    return (
        <>
            <Box>
                <Box display={'flex'} gap={4}>
                    <HighlightComponent
                        title={translate('totalExcludingVat')}
                        info={calculateTotal()}
                    />
                    <HighlightComponent
                        title={translate('selectedItems')}
                        info={selectedItems.length}
                        hasBorder={true}
                    />
                </Box>
            </Box>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="exportedTable"
                    columns={[...fields]}
                    masterDetail={true}
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: CustomerDetailInvoiceLineConfig(
                                translate,
                                formatValue
                            ),
                        },
                        getDetailRowData: (params: any) => {
                            params.successCallback(params.data.lines)
                        },
                    }}
                    rowData={data}
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <>
                            <DownloadActions
                                isDownloadEnabled={isDownloadEnabled}
                                setDownloadEnabled={setDownloadEnabled}
                                selectAllElements={selectAllElements}
                                selectedItems={selectedItems}
                                downloadConfig={ExportedInvoiceLines}
                                resetSelectedItems={() => setSelectedItems([])}
                            />
                        </>
                    }
                />
            </Stack>
        </>
    )
}
