import { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, useToast } from '@chakra-ui/react'

import { BaseInsurancePriceStepDto } from '../../../utils/types/types'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { baseErrorToastOptions } from '../../../utils/functions.utils'

function InsuranceStepsTable({
    steps = [],
    setSteps,
}: {
    steps: BaseInsurancePriceStepDto[]
    setSteps: Function
}): ReactElement {
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()
    const gridRef = useRef<any>()
    const toast = useToast()

    const headers = [
        {
            headerName: translate('maxValue'),
            field: 'maxValue',
            filter: true,
            valueFormatter: (params: any) => formatValue(params.data.maxValue),
            editable: true,
        },
        {
            headerName: translate('deductiblePrice'),
            field: 'deductiblePrice',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.deductiblePrice),
            editable: true,
        },
        {
            headerName: translate('netPrice'),
            field: 'netPrice',
            filter: true,
            valueFormatter: (params: any) => formatValue(params.data.netPrice),
            editable: true,
        },
        {
            headerName: translate('premiumPrice'),
            field: 'premiumPrice',
            filter: true,
            valueFormatter: (params: any) =>
                formatValue(params.data.premiumPrice),
            editable: true,
        },
        {
            headerName: translate('note'),
            editable: true,
            field: 'note',
            filter: true,
            flex: 1,
        },
    ]

    const onCellValueChanged = (updatedItem: BaseInsurancePriceStepDto) => {
        // Check if maxValue already exists
        const duplicateMaxValue = steps.some(
            (step) => step.maxValue === updatedItem.maxValue
        )
        if (duplicateMaxValue) {
            toast(baseErrorToastOptions(translate('duplicateMaxValueError')))
        } else {
            const updatedSteps = steps.map((step) =>
                step.id === updatedItem.id ? updatedItem : step
            )
            // eslint-disable-next-line no-param-reassign
            steps = updatedSteps
        }
    }

    const addRowAndEdit = () => {
        const newRow = {
            maxValue: 0,
            premiumPrice: 0,
            deductiblePrice: 0,
            netPrice: 0,
            note: '',
        }

        const newSteps = [...steps, newRow]
        setSteps(newSteps)

        // Wait for the steps state to update, then ensure the new row is visible and start editing
        setTimeout(() => {
            const newRowIndex = newSteps.length - 1 // Index of the new row
            gridRef.current.api.ensureIndexVisible(newRowIndex) // Ensure the new row is visible
            gridRef.current.api.startEditingCell({
                rowIndex: newRowIndex,
                colKey: 'maxValue', // Start editing the 'maxValue' field
                keyPress: null, // No key press required here
                charPress: null, // Start in edit mode but do not pre-fill with characters
            })
        }, 100)
    }

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                externalGridRef={gridRef}
                headers={
                    <Button onClick={addRowAndEdit}>
                        {translate('addRow')}
                    </Button>
                }
                cellValueChanged={(event: any) => {
                    if (event?.data) {
                        onCellValueChanged(event.data)
                    }
                }}
                tableId="insuranceStepsTable"
                columns={headers}
                rowData={steps}
            />
        </Flex>
    )
}

export default InsuranceStepsTable
