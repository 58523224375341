/* eslint-disable no-use-before-define */
import camelCase from 'lodash/camelCase'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import {
    Button,
    chakra,
    Heading,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react'
import PageContainer from '../../../components/pageContainer/PageContainer.component'
import PageSkeleton from '../../../components/skeleton/page/page.skeleton.component'

import { ErpLineMappingDTO, ERPParametersDTO } from '../../../utils/types/types'
import ERPAccountsFormComponent from './accounts/ERPAccountsForm.component'
import { AvailablePartnerSetup } from './ERPSetup.config'
import ERPSetupProduct from './product/ERPSetupProduct.component'
import ERPSetupSettings from './settings/ERPSetupSettings.component'
import { useERP } from '../../../services/erp/ERP.services'

interface IERPSetupPage {
    defaultProduct?: ERPParametersDTO | null
}

export default function ERPSetupPage({
    defaultProduct,
}: IERPSetupPage): ReactElement {
    const {
        ERPSetup,
        activeSetup,
        defaultERPSetup,
        getERPSetupByProduct,
        isLoading,
        onUpdateERPSetup,
        product: ERPProduct,
        updateGroupInvoiceLinesByMapping,
    } = useERP()
    const product = defaultProduct || ERPProduct
    const [invoiceLines, setInvoiceLines] = useState<ErpLineMappingDTO[]>([])
    const [isGroupERPChecked, setIsGroupERPChecked] = useState(false)
    const translate = useTranslation().t
    const headingText = translate(
        `${camelCase(AvailablePartnerSetup[activeSetup])}`
    )
    const iconColor = useColorModeValue('primary.500', 'secondary.450')

    useEffect(() => {
        if (product?.productId) {
            getERPSetupByProduct(product.productId, defaultERPSetup)
        }
    }, [defaultERPSetup, JSON.stringify(product)])

    return (
        <PageContainer>
            {isLoading && <PageSkeleton />}
            {!isLoading && ERPSetup && (
                <>
                    <Heading
                        alignItems="center"
                        display="flex"
                        mb={4}
                        size={'lg'}
                    >
                        {headingText}
                        <Tooltip
                            label={translate('eRPIntegrationsSupport')}
                            hasArrow
                            placement="right"
                        >
                            <chakra.span
                                color={iconColor}
                                fontSize={'lg'}
                                mx={2}
                            >
                                <FaInfoCircle />
                            </chakra.span>
                        </Tooltip>
                    </Heading>
                    {ERPSetup && product && <ERPSetupProduct />}
                    {product?.productId && (
                        <ERPAccountsFormComponent
                            activeSetup={activeSetup}
                            ERPSetup={ERPSetup}
                            product={product}
                            setInvoiceLines={setInvoiceLines}
                        />
                    )}
                    {product?.productId && (
                        <ERPSetupSettings
                            ERPSetup={ERPSetup}
                            product={product}
                            setIsGroupERPChecked={setIsGroupERPChecked}
                            isGroupERPChecked={isGroupERPChecked}
                        />
                    )}

                    <Button
                        isDisabled={!invoiceLines?.length}
                        width={'fit-content'}
                        onClick={() => {
                            onUpdateERPSetup(invoiceLines)
                            updateGroupInvoiceLinesByMapping(isGroupERPChecked)
                        }}
                    >
                        {translate('save')}
                    </Button>
                </>
            )}
        </PageContainer>
    )
}
