import React from 'react'

import { Box, Checkbox, SimpleGrid, Spinner } from '@chakra-ui/react'
import { useInsurance } from '../../../services/contexts/Insurance.context'
import { useProduct } from '../../../services/contexts/Product.context'
import { ProductInsuranceDto } from '../../../utils/types/types'

function ProductsComponent(): JSX.Element {
    const { insurance, handleInsuranceUpdate } = useInsurance()
    const { products } = useProduct()

    const handleProductUpdate = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        const product = products?.find(
            (iteratedProduct) => iteratedProduct.id === parseInt(value)
        )
        if (!product) return
        const newProductInsuranceDTO: ProductInsuranceDto = {
            isSelectable: true,
            productId: product.id,
        }
        if (!insurance?.productInsurances) {
            handleInsuranceUpdate({
                productInsurances: [newProductInsuranceDTO],
            })
            return
        }
        if (
            insurance.productInsurances.some(
                (productService) =>
                    productService.productId ===
                    newProductInsuranceDTO.productId
            )
        ) {
            const newProducts = insurance.productInsurances.filter(
                (productInsurance) =>
                    productInsurance.productId !==
                    newProductInsuranceDTO.productId
            )
            handleInsuranceUpdate({ productInsurances: [...newProducts] })
        } else {
            handleInsuranceUpdate({
                productInsurances: [
                    ...insurance.productInsurances,
                    newProductInsuranceDTO,
                ],
            })
        }
    }

    const isCheckboxChecked = (productId: number): boolean => {
        if (!insurance?.productInsurances) return false
        return insurance.productInsurances.some(
            (ps) => ps.productId === productId
        )
    }

    return (
        <Box mt={8}>
            <SimpleGrid columns={[1, 1, 2, 3, 3]}>
                {!products && <Spinner />}
                {products?.map((product, index) => (
                    <Checkbox
                        isChecked={isCheckboxChecked(product.id)}
                        key={index}
                        my={1}
                        onChange={handleProductUpdate}
                        value={product.id}
                    >
                        {product?.name ?? ''}
                    </Checkbox>
                ))}
            </SimpleGrid>
        </Box>
    )
}
export default ProductsComponent
