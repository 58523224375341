import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react'

import DatePicker from '../../../components/datePicker/DatePicker.component'
import {
    buildListOfKeyValues,
    offsetTimeZoneDifference,
    SelectAllOnFocus,
} from '../../../utils/functions.utils'
import { LocalizationConfiguration } from '../../../utils/localization/localization.utils'
import { FuelType } from '../../../utils/types/types'
import { IAssetForm } from '../AssetForm.utils'

interface IAssetVehicleForm extends IAssetForm {
    format: Partial<LocalizationConfiguration>
}

export default function AssetVehicleForm({
    handleInputChange,
    contractAsset,
    actionType,
    format,
}: IAssetVehicleForm): ReactElement {
    const translate = useTranslation().t

    return (
        <>
            <FormControl>
                <Flex w={'100%'} gap={4}>
                    <FormControl>
                        <FormLabel>{translate('exteriorColor')}</FormLabel>
                        <Input
                            type="color"
                            w={'100%'}
                            isDisabled={actionType === 'view'}
                            padding={'4px'}
                            value={contractAsset?.exteriorColor}
                            onChange={(e: any) =>
                                handleInputChange(
                                    e.target.value,
                                    'exteriorColor'
                                )
                            }
                        ></Input>
                    </FormControl>

                    <FormControl>
                        <FormLabel>{translate('interiorColor')}</FormLabel>
                        <Input
                            padding={'4px'}
                            isDisabled={actionType === 'view'}
                            type="color"
                            w={'100%'}
                            value={contractAsset?.interiorColor}
                            onChange={(e: any) =>
                                handleInputChange(
                                    e.target.value,
                                    'interiorColor'
                                )
                            }
                        ></Input>
                    </FormControl>
                </Flex>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('power')}</FormLabel>
                <Input
                    type="text"
                    isDisabled={actionType === 'view'}
                    value={contractAsset?.power}
                    onChange={(e: any) =>
                        handleInputChange(e.target.value, 'power')
                    }
                ></Input>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('fuelType')}</FormLabel>
                <Select
                    value={contractAsset?.fuelType}
                    isDisabled={actionType === 'view'}
                    placeholder={translate('select')}
                    onChange={(e) => {
                        handleInputChange(Number(e.target.value), 'fuelType')
                    }}
                >
                    {buildListOfKeyValues<typeof FuelType>(FuelType).map(
                        (option: string[], index: number) => (
                            <option key={index} value={option[0]}>
                                {option[1]}
                            </option>
                        )
                    )}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('firstRegistrationDate')}</FormLabel>
                <DatePicker
                    name="firstRegistrationDateTime"
                    isDisabled={actionType === 'view'}
                    value={
                        contractAsset?.firstRegistrationDateTime ?? undefined
                    }
                    max={new Date()}
                    onChange={(date: Date) => {
                        if (date) {
                            handleInputChange(
                                offsetTimeZoneDifference(date),
                                'firstRegistrationDateTime'
                            )
                        }
                    }}
                />
            </FormControl>
            <FormControl>
                <FormLabel>{translate('registrationFee')}</FormLabel>
                <NumericFormat
                    onFocus={SelectAllOnFocus}
                    isDisabled={actionType === 'view'}
                    type="text"
                    thousandSeparator={format.thousands}
                    decimalSeparator={format.decimals}
                    customInput={Input}
                    fixedDecimalScale
                    decimalScale={2}
                    defaultValue={0}
                    value={contractAsset?.registrationValue}
                    onValueChange={(e) => {
                        handleInputChange(e.floatValue, 'registrationValue')
                    }}
                ></NumericFormat>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('vin')}</FormLabel>
                <Input
                    type="text"
                    isDisabled={actionType === 'view'}
                    value={contractAsset?.vin}
                    onChange={(e: any) =>
                        handleInputChange(e.target.value, 'vin')
                    }
                ></Input>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('usageStandAtStart')}</FormLabel>
                <NumericFormat
                    onFocus={SelectAllOnFocus}
                    isDisabled={actionType === 'view'}
                    type="text"
                    thousandSeparator={format.thousands}
                    decimalSeparator={format.decimals}
                    customInput={Input}
                    fixedDecimalScale
                    decimalScale={2}
                    defaultValue={0}
                    value={contractAsset?.usageStandAtStart}
                    onValueChange={(e) => {
                        handleInputChange(e.floatValue, 'usageStandAtStart')
                    }}
                ></NumericFormat>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('usageLimit')}</FormLabel>
                <NumericFormat
                    onFocus={SelectAllOnFocus}
                    isDisabled={actionType === 'view'}
                    type="text"
                    thousandSeparator={format.thousands}
                    decimalSeparator={format.decimals}
                    customInput={Input}
                    fixedDecimalScale
                    decimalScale={2}
                    defaultValue={0}
                    value={contractAsset?.usageLimit}
                    onValueChange={(e) => {
                        handleInputChange(e.floatValue, 'usageLimit')
                    }}
                ></NumericFormat>
            </FormControl>
        </>
    )
}
