import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Heading } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../../services/API/general.api'
import { ParameterDTO } from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'

export default function ContractTemplateParameters({
    productId,
    updateProductName,
}: any) {
    const translate = useTranslation().t
    const [parameters, setParameters] = useState<ParameterDTO[]>()

    async function getParameters(): Promise<void> {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.parameter}/${productId}`
        )
        if (response.isOk) {
            setParameters(response.data)
        }
    }

    useEffect(() => {
        getParameters()
    }, [productId])

    const PARAMETER_COLUMN = [
        {
            headerName: translate('key'),
            field: 'key',
        },
        {
            headerName: translate('value'),
            field: 'value',
        },
    ]

    return (
        <>
            <Heading size={'md'} mb={4}>
                {updateProductName}
            </Heading>
            <DynamicGrid
                tableId="contractTemplateParamsTable"
                columns={PARAMETER_COLUMN}
                rowData={parameters}
            />
        </>
    )
}
