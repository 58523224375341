import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    control: {
        _checked: {
            bg: mode('primary.500', 'secondary.450')(props),
            borderColor: mode('primary.500', 'secondary.450')(props),
            _hover: {
                bg: mode('primary.500', 'secondary.450')(props),
                borderColor: mode('primary.500', 'secondary.450')(props),
                opacity: 0.7,
            },
        },
    },
    icon: {
        color: mode('white', 'white')(props),
        bg: mode('primary.500', 'secondary.450')(props),
        border: 'none',
    },
}))

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })
