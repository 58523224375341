import { IApiResponse } from '../../interfaces/interfaces'
import { DEFAULT_CONTRACT_POST } from '../../pages/contractDetail/components/actions/copyContract/CopyContract.config'
import { OfferInterface } from '../../pages/offer/Offer.config'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../services/API/general.api'
import { deepCopy, isAssetValid } from '../functions.utils'
import {
    ContractAssetDTO,
    ContractDTO,
    CountryCode,
    CustomerDTO,
    CustomerType,
    InvoiceCommunicationType,
    ProductRuleDTO,
    ProductRuleType,
    UserDTO,
} from '../types/types'

const DEFAULT_CUSTOMER: CustomerDTO = {
    name: '',
    contactName: '',
    email: '',
    invoiceCommunication: InvoiceCommunicationType.None,
    street: '',
    building: '',
    floor: '',
    postalCode: '',
    country: CountryCode.AD,
    city: '',
    phone: '',
    bankAccountNumber: '',
    bankRegistrationNumber: '',
    bsAgreementNumber: '',
    bsReferenceNumber: '',
    externalReferenceString1: '',
    externalReferenceString2: '',
    externalReferenceString3: '',
    externalReferenceString4: '',
    externalReferenceText1: '',
    customerNumber: '',
    advisor: {
        userNumber: '',
    },
    canBeDeleted: false,
    customerType: CustomerType.Company,
    cvr: '',
    cpr: '',
}
export const ASSET_CONVERT_KEYS = [
    'downpayment',
    'purchaseValue',
    'restValue',
    'originalPurchaseValue',
    'pricePerUnitOverUsageLimit',
    'pricePerUnitUnderUsageLimit',
    'usageLimit',
]

// TODO: Should be service
export const createCustomer = async (
    customer: CustomerDTO | any,
    user: UserDTO
): Promise<IApiResponse> => {
    const response = await generalPostAPI(API_ENDPOINTS.customerPost, {
        ...DEFAULT_CUSTOMER,
        ...customer,
        advisorId: customer.advisorId ?? user.userNumber,
        creditSafeConnectId: customer.creditSafeConnectId ?? customer.connectId,
        cvr: customer.cvr ?? customer.registrationNumber,
    })
    return response
}

export const filterCustomers = (
    companyCustomers: CustomerDTO[],
    search: string,
    creditSafeCustomers?: any[]
): CustomerDTO[] | undefined => {
    const filteredCustomers = companyCustomers?.filter((customer) => {
        const searchValue = search?.toLowerCase() || ''
        return (
            customer?.name?.toLowerCase().includes(searchValue) ||
            customer?.cvr?.includes(searchValue) ||
            customer?.email?.includes(searchValue)
        )
    })
    if (filteredCustomers?.length === 0) return creditSafeCustomers
    if (
        creditSafeCustomers &&
        creditSafeCustomers?.length > 0 &&
        filteredCustomers
    ) {
        const combined = filteredCustomers?.concat(
            creditSafeCustomers!.filter(
                (customer) =>
                    !filteredCustomers.find(
                        (companyCustomer) =>
                            companyCustomer.cvr === customer.registrationNumber
                    )
            )
        )
        return combined
    }
    return filteredCustomers
}
const cleanAssets = (assets: ContractAssetDTO[]): ContractAssetDTO[] =>
    assets.map((asset) => {
        ASSET_CONVERT_KEYS.forEach((convertKey) => {
            if (typeof asset[convertKey] === 'string') {
                asset[convertKey] = Number(asset[convertKey])
            }
        })

        if (asset?.assetNumber && asset.assetNumber?.length > 10) {
            return { ...asset, assetNumber: '' }
        }
        return { ...asset }
    })

export const makeContractFromOffer = (
    offerItem: OfferInterface,
    productRules: ProductRuleDTO[]
): ContractDTO => {
    const interestRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.Interest
    )

    let contract = deepCopy(DEFAULT_CONTRACT_POST)
    contract = {
        ...contract,
        contractAssets: cleanAssets(offerItem.assets),
        productId: offerItem.productId,
        leasingPeriod: offerItem.leasingPeriod,
        creationFee: offerItem.creationFee,
        interest: interestRules?.defaultValue ?? 3,
        customerNumber: offerItem.customer.customerNumber,
    }
    return contract
}

export const makeNewOffer = (
    offerItem: OfferInterface,
    productRules: ProductRuleDTO[]
): ContractDTO => {
    const leasingPeriodRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.LeasingPeriod
    )
    const creationFeeRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.CreationFee
    )
    const depositRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.Deposit
    )
    const interestRules = productRules.find(
        (pr) => pr.ruleType === ProductRuleType.Interest
    )
    let contract = deepCopy(DEFAULT_CONTRACT_POST)
    contract = {
        ...contract,
        contractAssets: cleanAssets(offerItem.assets),
        productId: offerItem.productId,
        leasingPeriod:
            leasingPeriodRules?.defaultValue ?? offerItem.leasingPeriod,
        creationFee: creationFeeRules?.defaultValue ?? offerItem.creationFee,
        deposit: depositRules?.defaultValue ?? 0,
        interest: offerItem?.interest ?? interestRules?.defaultValue ?? 3,
        customerNumber: offerItem.customer.customerNumber,
    }
    return contract
}

export const areAssetsValid = (assets: ContractAssetDTO[]): boolean =>
    assets.every(isAssetValid)
export { isAssetValid }
