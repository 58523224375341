import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'

import { GetVatDetail } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI } from '../API/general.api'
import { useUser } from './user.context'

const VATContext = createContext<{
    VAT: Array<GetVatDetail>
}>({
    VAT: [],
})
interface IVATContextProviderProps {
    children: ReactNode
}
// Remove API context provider, make it a regular custom hook
export const VATContextProvider = ({ children }: IVATContextProviderProps) => {
    const [VAT, setVAT] = useState<Array<GetVatDetail>>([])
    const { user } = useUser()

    useEffect(() => {
        let isMounted = true
        if (user && user.email) {
            const getVAT = async (): Promise<void> => {
                const response: any = await generalGetAPI(API_ENDPOINTS.vat)
                if (response && response.data && response.isOk) {
                    if (isMounted) setVAT(response.data)
                }
            }
            getVAT()
        }
        return (): void => {
            isMounted = false
        }
    }, [])

    return (
        <VATContext.Provider
            value={{
                VAT,
            }}
        >
            {children}
        </VATContext.Provider>
    )
}

export const useVAT = () => useContext(VATContext)
