import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Container,
    Divider,
    Flex,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'

import GenericBox from '../../../../../../components/genericBox/genericBox'
import { formatDate } from '../../../../../../utils/localization/culture.utils'
import { InvoiceDTO, InvoiceType } from '../../../../../../utils/types/types'

interface ISingleInvoiceSummary {
    invoice: InvoiceDTO
}

export default function SingleInvoiceSummary({
    invoice,
}: ISingleInvoiceSummary): ReactElement {
    const translate = useTranslation().t

    const backgroundColor = useColorModeValue('primary.50', 'secondary.700')

    const invoiceGrid = [
        [
            {
                name: 'invoiceType',
                value: translate(camelCase(InvoiceType[invoice.invoiceType])),
            },
            {
                name: 'start',
                value: invoice.periodStartDate
                    ? formatDate(invoice.periodStartDate)
                    : '',
            },
            {
                name: 'end',
                value: invoice.periodEndDate
                    ? formatDate(invoice.periodEndDate)
                    : '',
            },
            {
                name: 'dueDate',
                value: invoice.dueDate ? formatDate(invoice.dueDate) : '',
            },
        ],
        [
            {
                name: 'reminder',
                value: invoice.reminderCountAlreadySent,
            },
            {
                name: 'amount',
                value: invoice.lines
                    ?.reduce((acc, line) => line.amountExcludingVat + acc, 0)
                    .toFixed(2),
            },
            {
                name: 'totalVat',
                value: invoice.lines
                    ?.reduce((acc, line) => line.vatAmount + acc, 0)
                    .toFixed(2),
            },
            {
                name: 'total',
                value: invoice.lines
                    ?.reduce((acc, line) => line.amountIncludingVat + acc, 0)
                    .toFixed(2),
            },
        ],
    ]

    const GridItem = ({ name, value }: any) => (
        <Flex justifyContent={'space-between'} gap={2}>
            <Text>{translate(name)}</Text>
            <Text fontWeight={600}>{value}</Text>
        </Flex>
    )

    return (
        <Container maxW="unset" marginLeft={0} px={0}>
            <GenericBox p={8} backgroundColor={backgroundColor} shadow={'xs'}>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    gap={{ base: 4, md: 8 }}
                >
                    <Box width={'100%'}>
                        {invoiceGrid[0].map((row) => (
                            <GridItem
                                key={row.name}
                                name={row.name}
                                value={row?.value ?? ''}
                            />
                        ))}
                    </Box>
                    <Box display={{ base: 'none', md: 'block' }}>
                        <Divider
                            key={2}
                            orientation="vertical"
                            color={'white'}
                            height={'100%'}
                        />
                    </Box>
                    <Box width={'100%'}>
                        {invoiceGrid[1].map((row) => (
                            <GridItem
                                key={row.name}
                                name={row.name}
                                value={row?.value ?? ''}
                            />
                        ))}
                    </Box>
                </Flex>
            </GenericBox>
        </Container>
    )
}
