import { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { columnsConfig } from './CustomerDetailsCases.config'

export default function CustomerDetailsCases(): ReactElement {
    const params = useParams<{ customerNumber?: string }>()
    const translate = useTranslation().t
    const CustomerNumber = params.customerNumber
    const [cases, setCases] = useState([])
    const navigate = useNavigate()

    const getCases = async (): Promise<void> => {
        const response: any = await generalGetAPI(
            `${API_ENDPOINTS.contract}?customerNumber=${CustomerNumber}`
        )
        if (response?.data && response?.isOk) {
            setCases(response.data?.items)
        }
    }

    useEffect(() => {
        getCases()
    }, [CustomerNumber])

    return (
        <DynamicGrid
            tableId="customerDetailsCasesTable"
            columns={columnsConfig(translate, navigate)}
            rowData={cases ?? []}
            pagination={true}
            rowMultiSelectWithClick={false}
        />
    )
}
