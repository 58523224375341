import { useFormikContext } from 'formik'
import { ReactElement, useEffect } from 'react'

interface IFormObserver {
    onFormChange: Function
    skipValidation: boolean
}

export default function FormObserver({
    onFormChange,
    skipValidation,
}: IFormObserver): ReactElement {
    const { values, isValid, errors } = useFormikContext()

    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            Object.keys(errors)?.length > 0 && console.log(errors)
        }
    }, [errors])

    useEffect(() => {
        if (skipValidation) {
            onFormChange(values)
        } else {
            isValid && onFormChange(values)
        }
    }, [JSON.stringify(values), skipValidation, isValid])

    return <></>
}
