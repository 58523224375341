import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import { ProductDTO } from '../../../utils/types/types'

export const columnsConfig = (
    translate: Function,
    actionsFn: Function,
    products?: ProductDTO[]
): any[] => {
    function getProductNameFromId(id: number): string {
        return products?.find((item) => item.id === id)?.name as string
    }

    return [
        {
            field: 'productId',
            filter: 'agTextColumnFilter',
            headerName: translate('product'),
            valueGetter: (params: any) =>
                params.data?.productId
                    ? getProductNameFromId(params.data.productId) ||
                      translate('missingProduct')
                    : translate('all'),
        },
        {
            field: 'key',
            filter: 'agTextColumnFilter',
            headerName: translate('key'),
            valueGetter: (params: any) => translate(params?.data?.key) || 'NA',
            suppressMovable: true,
        },
        {
            field: 'value',
            headerName: translate('value'),
            valueGetter: (params: any) => translate(params?.data?.value),
            suppressMovable: true,
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => actionsFn(params),
                100
            ),
        },
    ]
}
