import { FieldHookConfig, useField } from 'formik'
import { ReactElement } from 'react'

import {
    chakra,
    Flex,
    Input,
    InputProps,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react'

export type CustomFormikProps = InputProps & FieldHookConfig<'input'>

export default function FormInput({
    name,
    ...props
}: CustomFormikProps): ReactElement {
    const [field, errors] = useField(name)
    const { ...otherFieldProps } = field
    const isColor = props.type === 'color'
    if (isColor) {
        props.bgColor = field.value
    }

    return (
        <chakra.div flexDirection={'column'} width="100%">
            <Flex gap={2} alignItems="center">
                <Input
                    {...(otherFieldProps as any)}
                    {...props}
                    name={field.name}
                    value={field.value || ''}
                    isInvalid={!!errors?.error}
                    borderLeftRadius={props?.borderLeftRadius}
                    borderRightRadius={props?.borderRightRadius}
                />
                {isColor && <Text>{field.value}</Text>}
            </Flex>
            {errors.error && (
                <UnorderedList>
                    {JSON.parse(errors.error).map(
                        (errorStr: string, index: number) => {
                            if (errorStr) {
                                return (
                                    <ListItem key={index} color="red">
                                        {errorStr}
                                    </ListItem>
                                )
                            }
                            return null
                        }
                    )}
                </UnorderedList>
            )}
        </chakra.div>
    )
}
