import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, TabPanel, TabPanels } from '@chakra-ui/react'

import PageContainer, {
    TabListType,
} from '../../components/pageContainer/PageContainer.component'
import PageSkeleton from '../../components/skeleton/page/page.skeleton.component'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI, generalPutAPI } from '../../services/API/general.api'
import { useProduct } from '../../services/contexts/Product.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    standaloneToast,
} from '../../utils/functions.utils'
import {
    AssetTypeBO,
    ContractAssetStatus,
    GetBaseAssetDetail,
    ProductDTO,
    UpdateBaseAssetCommand,
} from '../../utils/types/types'
import InventoryHeaderComponent from './components/header/InventoryHeader.component'
import InventoryAssets from './components/inventoryAssets/InventoryAssets.component'
import InventoryFormComponent from './components/inventoryForm/InventoryForm.component'

export default function InventoryDetailPage(): ReactElement {
    const { id } = useParams()
    const translate = useTranslation().t
    const tabs: TabListType[] = [
        { text: 'details', id: 0 },
        { text: 'assetHistory', id: 1 },
    ]
    const [openDetails, setOpenDetails] = useState(false)
    const [baseAsset, setBaseAsset] = useState<GetBaseAssetDetail>()
    const [productHasUsage, setProductHasUsage] = useState<boolean>(false)
    const [isBaseAssetLoading, setBaseAssetLoading] = useState<boolean>(true)
    const { products, isLoading } = useProduct()

    const retrieveProducts = async (): Promise<void> => {
        const hasUsage = products?.some(
            (productItem: ProductDTO) => productItem.isUsageEnabled
        )
        setProductHasUsage(hasUsage)
    }

    const retrieveBaseAsset = async (): Promise<void> => {
        const request = await generalGetAPI(`${API_ENDPOINTS.assetBase}/${id}`)
        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(translate('apiFail')))
            return
        }
        setBaseAsset(request.data)
        setBaseAssetLoading(false)
    }

    const [existingAssetTypes, setExistingAssetTypes] = useState<AssetTypeBO[]>(
        []
    )
    console.log(existingAssetTypes)

    async function fetchAssetTypes() {
        const result = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (result?.isOk) {
            setExistingAssetTypes(result.data)
        }
    }

    const handleInventoryUpdate = async (
        data: GetBaseAssetDetail
    ): Promise<void> => {
        const { assetType, ...dataWithoutAssetTypeData } = data
        console.log('handleInventoryUpdate', assetType)
        const request = await generalPutAPI(API_ENDPOINTS.assetBase, {
            ...dataWithoutAssetTypeData,
            locationNumber: data?.location?.locationNumber,
            assetTypeId: data.assetType?.id,
        } as UpdateBaseAssetCommand)

        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(request.message))
        }
        standaloneToast(baseSuccessToastOptions(translate('inventoryUpdated')))
    }

    useEffect(() => {
        retrieveBaseAsset()
        retrieveProducts()
        fetchAssetTypes()
    }, [])

    if (isBaseAssetLoading || !baseAsset) {
        return <PageSkeleton />
    }
    return (
        <PageContainer
            tabsList={tabs}
            pageHeader={<InventoryHeaderComponent baseAsset={baseAsset} />}
        >
            <TabPanels>
                <TabPanel px={0}>
                    <InventoryFormComponent
                        baseAsset={baseAsset}
                        handleInventoryUpdate={handleInventoryUpdate}
                        productHasUsage={productHasUsage}
                    />
                </TabPanel>
                <TabPanel px={0}>
                    <InventoryAssets
                        isLoading={isLoading}
                        contractAssets={baseAsset.contractAssets.filter(
                            (a) => a.status !== ContractAssetStatus.Cancelled
                        )}
                    ></InventoryAssets>
                </TabPanel>
            </TabPanels>
            {openDetails && (
                <>
                    <Button
                        mb={8}
                        onClick={(): void => setOpenDetails(false)}
                        variant="outline"
                    >
                        {translate('back')}
                    </Button>
                </>
            )}
        </PageContainer>
    )
}
