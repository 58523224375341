import { ReactElement } from 'react'

import { TabPanel, TabPanels } from '@chakra-ui/react'

import PageContainer from '../../../../components/pageContainer/PageContainer.component'
import { EXPORT_TABS } from './Export.config'
import ExportedPage from './pages/exported/Exported.page'
import ExportQueuePage from './pages/exportQueue/ExportQueue.page'
import FailedExportPage from './pages/failedExport/FailedExport.page'
import NotExportedPage from './pages/notExported/NotExported.page'
import ExportArchive from '../invoices/exportArchive/ExportArchive.component'

export default function ExportPage(): ReactElement {
    return (
        <PageContainer
            data-testid="export-page"
            id="export-page"
            tabsList={EXPORT_TABS}
        >
            <TabPanels>
                <TabPanel px={0}>
                    <NotExportedPage />
                </TabPanel>
                <TabPanel px={0}>
                    <ExportQueuePage />
                </TabPanel>
                <TabPanel px={0}>
                    <ExportedPage />
                </TabPanel>
                <TabPanel px={0}>
                    <FailedExportPage />
                </TabPanel>
                <TabPanel px={0}>
                    <ExportArchive />
                </TabPanel>
            </TabPanels>
        </PageContainer>
    )
}
