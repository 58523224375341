import './DynamicForm.feature.scss'

import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    FormLabel,
    Heading,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    SimpleGrid,
    Stack,
    Text,
} from '@chakra-ui/react'

import GenericBox from '../../components/genericBox/genericBox'
import { GenericObjectType } from '../../interfaces/interfaces'
import DatePickerField from './components/formDatePicker/DatePicker.component'
import FormNumberInput from './components/formNumberInput/FormNumberInput.component'
import FormObserver from './components/formObserver/FormObserver.component'
import FormSelectField from './components/formSelect/FormSelect.component'
import SwitchField from './components/formSwitch/Switch.component'
import FormInput from './components/formTextInput/FormTextInput.component'
import { IDynamicForm, IFormField } from './DynamicForm.interfaces'

function DynamicForm<T>({
    data,
    formFields,
    formFieldsWithSections,
    sectionsLayout = 'vertical',
    formSubmitReference,
    hideSubmit = false,
    onSubmit,
    submitButtonProps = {},
    columns = [1],
    submitText,
    submitInline = true,
    onErrorHandler,
    onFormValidate,
    onFormChange,
    skipValidation = false,
    onBlur = (): any => {},
    readOnly = false,
}: IDynamicForm<T>): ReactElement {
    const translate = useTranslation().t
    const [initialValues, setInitialValues] = useState(data)

    useEffect(() => {
        JSON.stringify(data) !== JSON.stringify(initialValues) &&
            setInitialValues(data)
    }, [data])

    function onSubmitHandler(values: FormikValues & T): void {
        const validation = validationHandler(values)
        if (Object.keys(validation)?.length === 0) {
            onSubmit(values)
        }
    }

    function validationHandler(values: FormikValues & T): GenericObjectType {
        const errors: GenericObjectType = {}

        Object.entries(values).forEach((pair) => {
            const formFieldIndex = formFields?.findIndex(
                (field: IFormField<T>) => field.slug === pair[0]
            )
            if (
                !formFieldIndex ||
                formFieldIndex < 0 ||
                formFields === undefined
            ) {
                return
            }
            const { validation, errorMessage } = formFields[formFieldIndex]
            switch (typeof validation) {
                case 'function':
                    if (!validation(pair[1])) {
                        errors[pair[0]] = JSON.stringify([errorMessage])
                    }
                    break
                case 'object':
                    if (
                        !validation.test(
                            (pair[1] as string | number).toString()
                        )
                    ) {
                        errors[pair[0]] = JSON.stringify([errorMessage])
                    }
                    break
                default:
                    break
            }
        })
        if (onErrorHandler) {
            onErrorHandler(errors)
        }
        return errors
    }

    function renderFieldType(field: IFormField<T | any>): ReactElement {
        const extraProps = {
            'data-testid': field.slug,
            disabled: readOnly || field?.disabled || field.inputProps?.disabled,
            isDisabled: readOnly,
        }
        switch (field.inputProps.type) {
            case 'boolean':
                return (
                    <SwitchField
                        {...field.inputProps}
                        name={field.slug as string}
                        {...extraProps}
                    />
                )
            case 'date':
                return (
                    <DatePickerField
                        {...field.inputProps}
                        value={field.value}
                        name={field.slug}
                        onBlur={onBlur}
                        {...extraProps}
                    />
                )
            case 'select':
                return (
                    <FormSelectField
                        skipTranslation={!!field?.skipTranslation}
                        isValueString={field.isValueString}
                        name={field.slug as string}
                        selectOptions={field.optionsKeysValues as string[][]}
                        props={field.inputProps}
                        {...extraProps}
                    ></FormSelectField>
                )
            case 'number':
                return (
                    <InputGroup mb={3}>
                        {field?.leftAddonText && (
                            <InputLeftAddon>
                                {field.leftAddonText}
                            </InputLeftAddon>
                        )}

                        <FormNumberInput
                            value={field.value}
                            borderLeftRadius={field?.leftAddonText ? 0 : 'md'}
                            borderRightRadius={field?.rightAddonText ? 0 : 'md'}
                            name={field.slug}
                            onBlur={onBlur}
                            type={field.inputProps.type}
                            enableDecimal={field.enableDecimal}
                            decimalHouses={field.decimalHouses}
                            props={field.inputProps}
                            stringValue={field.stringValue}
                            {...extraProps}
                        />
                        {field?.rightAddonText && (
                            <InputRightAddon>
                                {field.rightAddonText}
                            </InputRightAddon>
                        )}
                    </InputGroup>
                )
            default:
                return (
                    <InputGroup mb={3}>
                        {field?.leftAddonText && (
                            <InputLeftAddon>
                                {field.leftAddonText}
                            </InputLeftAddon>
                        )}

                        <FormInput
                            {...field.inputProps}
                            value={field.value}
                            borderLeftRadius={field?.leftAddonText ? 0 : 'md'}
                            borderRightRadius={field?.rightAddonText ? 0 : 'md'}
                            name={field.slug}
                            {...extraProps}
                        />
                        {field?.rightAddonText && (
                            <InputRightAddon>
                                {field.rightAddonText}
                            </InputRightAddon>
                        )}
                    </InputGroup>
                )
        }
    }

    function renderFormFields(): ReactElement[] | ReactElement {
        return (
            formFields?.map((field: IFormField<T>, index: number) => (
                <Box key={index} hidden={!!field?.isFieldHidden}>
                    <FormLabel htmlFor={(field.slug as string) || ''}>
                        <Flex gap={2}>
                            {translate(field.label)}
                            {field?.isOptional && (
                                <Text fontSize="xs">
                                    {translate('optional')}
                                </Text>
                            )}
                        </Flex>
                    </FormLabel>
                    {renderFieldType(field)}
                    {field?.bottomLabel && <>{field.bottomLabel}</>}
                </Box>
            )) || <></>
        )
    }

    function renderFormFieldsWrapper(): ReactElement {
        return (
            <SimpleGrid columns={columns} spacing={4}>
                {formFields && renderFormFields()}
                {submitInline && (
                    <Flex alignItems={'center'}>
                        <Button
                            mt={4}
                            ref={formSubmitReference}
                            type="submit"
                            display={hideSubmit ? 'none' : 'block'}
                            {...submitButtonProps}
                        >
                            {translate(submitText || 'submit')}
                        </Button>
                    </Flex>
                )}
            </SimpleGrid>
        )
    }

    function renderFormFieldsWithSections(): ReactElement[] | ReactElement {
        if (formFieldsWithSections) {
            const sectionKeys = Object.keys(formFieldsWithSections)
            const elementsToRender = sectionKeys.map((key) => {
                const title: string | undefined =
                    formFieldsWithSections?.[key].title || undefined
                const sections = formFieldsWithSections?.[key].fields?.map(
                    (field: IFormField<T>, index: number) => (
                        <Box
                            key={index}
                            hidden={!!field?.isFieldHidden}
                            mb={'20px'}
                        >
                            <FormLabel htmlFor={(field.slug as string) || ''}>
                                {translate(field.label)}
                            </FormLabel>
                            {renderFieldType(field)}
                            {field?.bottomLabel && <>{field.bottomLabel}</>}
                        </Box>
                    )
                )

                return {
                    elements: sections,
                    title,
                    columns: formFieldsWithSections?.[key].columns || 1,
                }
            })

            return (
                <Flex
                    gap={4}
                    flexDirection={
                        sectionsLayout === 'vertical' ? 'column' : 'row'
                    }
                    flexWrap={'wrap'}
                >
                    {elementsToRender.map((section: any, index) => (
                        <>
                            {section?.title && (
                                <Heading size="md">
                                    {translate(section.title)}
                                </Heading>
                            )}
                            <GenericBox
                                key={index}
                                p={'20px'}
                                flex={1}
                                minW={'300px'}
                            >
                                <SimpleGrid
                                    columns={section?.columns || 1}
                                    spacing={4}
                                >
                                    {section?.elements}
                                    {submitInline &&
                                        index ===
                                            elementsToRender.length - 1 && (
                                            <Flex alignItems={'center'}>
                                                <Button
                                                    mt={4}
                                                    ref={formSubmitReference}
                                                    type="submit"
                                                    display={
                                                        hideSubmit
                                                            ? 'none'
                                                            : 'block'
                                                    }
                                                    {...submitButtonProps}
                                                >
                                                    {translate(
                                                        submitText || 'submit'
                                                    )}
                                                </Button>
                                            </Flex>
                                        )}
                                </SimpleGrid>
                            </GenericBox>
                        </>
                    ))}
                </Flex>
            )
        }
        return <></>
    }

    return (
        <Stack spacing={24} className="ln-dynamic-form">
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmitHandler}
                validate={onFormValidate || validationHandler}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(props: FormikProps<any>): ReactElement => (
                    <Form onSubmit={props.handleSubmit}>
                        {onFormChange && (
                            <FormObserver
                                onFormChange={onFormChange}
                                skipValidation={skipValidation}
                            />
                        )}
                        {formFields
                            ? renderFormFieldsWrapper()
                            : renderFormFieldsWithSections()}

                        {!submitInline && (
                            <Button
                                mt={4}
                                ref={formSubmitReference}
                                type="submit"
                                display={hideSubmit ? 'none' : 'block'}
                                {...submitButtonProps}
                            >
                                {translate(submitText || 'submit')}
                            </Button>
                        )}
                    </Form>
                )}
            </Formik>
        </Stack>
    )
}

export default DynamicForm
