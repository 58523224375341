import { ReactElement } from 'react'

import { chakra, Spinner, useColorModeValue } from '@chakra-ui/react'

import { useLoading } from '../../services/contexts/Loading.context'

export default function GlobalLoader(): ReactElement {
    const { isGlobalLoading } = useLoading()
    const color = useColorModeValue('primary.500', 'secondary.500')
    return isGlobalLoading ? (
        <chakra.div
            onClick={(e) => e.preventDefault()}
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            zIndex={99999}
            pointerEvents="none"
            left={0}
            backgroundColor="#000000AA"
            className="ln-global-loader"
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={color}
                size="xl"
                position="absolute"
                top="calc(50% - 33px)"
                left="calc(50% - 33px)"
            />
        </chakra.div>
    ) : (
        <></>
    )
}
