import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'

import { isEmpty, toCamelCase } from '../../utils/functions.utils'
import {
    ContractDTO,
    CreateContract,
    ProductRuleDTO,
    ProductRuleType,
    ProductType,
} from '../../utils/types/types'
import { useContractService } from '../contract/Contract.services'

interface BaseServiceContextInterface {
    productRules: ProductRuleDTO[]
    isLoading: boolean
    getFieldsHandler: (productId: number) => void
    productType: ProductType
}

const ProductRulesEnforcer = createContext<BaseServiceContextInterface>(
    {} as BaseServiceContextInterface
)

interface IProductRulesServiceProvider {
    children: ReactNode
}

export const ProductRulesEnforcerServiceProvider = ({
    children,
}: IProductRulesServiceProvider): ReactElement => {
    const { contract, product } = useContractService()
    const [productRules, setProductRules] = useState<ProductRuleDTO[]>([])
    const [productType, setProductType] = useState<ProductType>(
        ProductType.FinancialLeasing
    )
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const getFieldsHandler = async (): Promise<void> => {
        setIsLoading(true)
        setProductRules(product?.productRules ?? [])
        setIsLoading(false)
    }

    useEffect(() => {
        product && setProductType(product?.productType)
    }, [product])

    useEffect(() => {
        if (contract?.productId && product?.id) {
            getFieldsHandler()
        }
    }, [contract.productId, product])

    return (
        <ProductRulesEnforcer.Provider
            value={{
                productRules,
                getFieldsHandler,
                productType,
                isLoading,
            }}
        >
            {children}
        </ProductRulesEnforcer.Provider>
    )
}

export const useContractRulesService = (): BaseServiceContextInterface =>
    useContext(ProductRulesEnforcer)

export function formFieldValiationRules(
    productRuleType: ProductRuleType,
    productRules: ProductRuleDTO[],
    parameter: 'minimumValue' | 'maximumValue',
    valueToValidate: number,
    parentValue?: number
): boolean {
    const rule = productRules?.find((item) => item.ruleType === productRuleType)
    let comparison = false
    const isPercentage = rule?.targeted

    if (rule?.[parameter] !== undefined && rule?.[parameter] !== null) {
        if (parameter === 'minimumValue') {
            const minimum = rule?.minimumValue as number
            if (isPercentage && parentValue !== undefined) {
                const calculatedMinimumValue = parentValue * (minimum / 100)
                comparison = valueToValidate >= calculatedMinimumValue
            } else {
                comparison = valueToValidate >= minimum
            }
            return !comparison
        }
        const maximum = rule?.maximumValue as number
        if (isPercentage && parentValue !== undefined) {
            const calculatedMaximumValue = parentValue * (maximum / 100)
            comparison = valueToValidate <= calculatedMaximumValue
        } else {
            comparison = valueToValidate <= maximum
        }
        return !comparison
    }
    return comparison
}

export const applyRulesToOffer = (
    rules: ProductRuleDTO[],
    offer: CreateContract
): CreateContract => {
    const updatedOffer: any = { ...offer }

    if (rules && !isEmpty(rules)) {
        rules.forEach((rule) => {
            const field = toCamelCase(
                ProductRuleType[rule.ruleType]
            ) as keyof ContractDTO
            if (updatedOffer[field] !== undefined) {
                if (
                    rule.ruleType === ProductRuleType.IsVariableInterest ||
                    rule.ruleType === ProductRuleType.IncludeCreationFee
                ) {
                    const toBool = (val: any) =>
                        Number.isNaN(val)
                            ? !!rule?.defaultValue
                            : !!parseFloat(val)
                    const value = rule?.defaultValue
                        ? toBool(rule?.defaultValue)
                        : offer[field]
                    updatedOffer[field] = value
                } else {
                    const value = rule?.defaultValue
                        ? rule.defaultValue
                        : offer[field]
                    updatedOffer[field] = value
                }
            }
        })
    }

    return updatedOffer
}
