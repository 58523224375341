import { InvoiceDTO, InvoiceLineDTO } from '../../../utils/types/types'

export const sumDepreciation = (invoice: InvoiceDTO) => {
    let sum = 0
    if (!invoice.lines && typeof invoice.lines !== 'object') return sum
    invoice.lines.forEach((inv: InvoiceLineDTO) => {
        sum += inv.depreciation
    })
    return sum.toFixed(2)
}

export const sumInterest = (invoice: InvoiceDTO) => {
    let sum = 0
    if (!invoice.lines && typeof invoice.lines !== 'object') return sum
    invoice.lines.forEach((inv: InvoiceLineDTO) => {
        sum += inv.interest
    })
    return sum.toFixed(2)
}
