import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ViewIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'

import { useCustomerChange } from '../../../../services/customer/CustomerChange.service'
import { ColumnActionConfiguration } from '../../../../utils/functions.utils'
import { CustomerDTO } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'

function CustomerTableComponent({
    customers,
    enableSearch = true,
}: {
    customers?: CustomerDTO[]
    enableSearch?: boolean
}): ReactElement {
    const { setCustomer } = useCustomerChange()
    const translate = useTranslation().t

    const customersHeaders = [
        { headerName: translate('customerNumber'), field: 'customerNumber' },
        { headerName: translate('name'), field: 'name' },
        { headerName: translate('customerCvr'), field: 'cvr' },
        { headerName: translate('street'), field: 'postalCode' },
        { headerName: translate('city'), field: 'city' },
        {
            ...ColumnActionConfiguration(translate, (params: any) => (
                <IconButton
                    icon={<ViewIcon />}
                    aria-label={'select'}
                    variant={'outline'}
                    onClick={() => setCustomer({ ...params.data })}
                ></IconButton>
            )),
        },
    ]

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                enableSearch={enableSearch}
                tableId="customerTable"
                columns={customersHeaders}
                rowData={customers}
            />
        </Flex>
    )
}

export default CustomerTableComponent
