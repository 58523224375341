import { IFormField } from '../../../../../../features/dynamicForm/DynamicForm.interfaces'
import { buildListOfKeyValues } from '../../../../../../utils/functions.utils'
import {
    ChecklistHumanInteractionTypeBO,
    ChecklistItemDTO,
    ChecklistItemType,
    DomainOperationType,
} from '../../../../../../utils/types/types'

export function checklistItemFormFields(
    data: ChecklistItemDTO,
    availableItemTypes: any,
    interactions: ChecklistHumanInteractionTypeBO[],
    edit?: boolean
): IFormField<Partial<ChecklistItemDTO>>[] {
    const isHumanType =
        data?.itemType === ChecklistItemType.HumanInteractionStatus

    function buildValues(): (string | number)[][] {
        return interactions.map((item) => [item.id, item.name])
    }

    const formFields: IFormField<Partial<ChecklistItemDTO>>[] = []

    if (!edit) {
        formFields.push({
            slug: 'itemType',
            label: 'itemType',
            optionsKeysValues: availableItemTypes,
            inputProps: { type: 'select' },
        })
    }
    if (isHumanType) {
        formFields.push({
            slug: 'checklistHumanInteractionTypeId',
            inputProps: { type: 'select' },
            optionsKeysValues: buildValues(),
            label: 'checklistHumanInteractionType',
            disabled: true,
        })
    }

    formFields.push({
        slug: 'isActive',
        label: 'isActive',
        inputProps: { type: 'boolean' },
    })
    formFields.push({
        slug: 'isOptional',
        label: 'isOptional',
        inputProps: { type: 'boolean' },
    })

    return formFields
}

export interface CreateCheckListDTO {
    productId: number
    domainOperationType: DomainOperationType
    description?: string
    isActive: boolean
    checklistItems: ChecklistItemDTO[]
}

export function checklistFormFields(): IFormField<
    Partial<CreateCheckListDTO>
>[] {
    return [
        {
            slug: 'description',
            label: 'description',
            inputProps: { type: 'text' },
        },
        {
            slug: 'domainOperationType',
            label: 'domainOperationType',
            optionsKeysValues:
                buildListOfKeyValues<typeof DomainOperationType>(
                    DomainOperationType
                ),
            inputProps: {
                type: 'select',
            },
        },
        {
            slug: 'productId',
            disabled: true,
            label: 'productId',
            inputProps: { type: 'text' },
        },

        {
            slug: 'isActive',
            label: 'isActive',
            inputProps: { type: 'boolean' },
        },
    ]
}
