import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle((props) => ({
    // define the part you're going to style
    button: {
        // this will style the MenuButton component
        fontSize: 'sm',
        color: mode('white', 'white')(props),
        _hover: {
            color: mode('primary.250', 'secondary.250')(props),
        },
    },
    list: {
        // this will style the MenuList component
        backgroundColor: mode('primary.500', 'secondary.500')(props),
        color: mode('white', 'white')(props),
        py: '4',
        borderRadius: 'xl',
        border: 'none',
        bg: mode('red', 'blue')(props),
    },
    item: {
        // this will style the MenuItem and MenuItemOption components
        color: mode('primary.50', 'secondary.50')(props),
        bg: 'transparent',
        _hover: {
            bg: mode('primary.400', 'secondary.400')(props),
        },
        _focus: {
            bg: mode('primary.400', 'secondary.400')(props),
        },
    },
    groupTitle: {
        // this will style the text defined by the title prop
        // in the MenuGroup and MenuOptionGroup components
        textTransform: 'uppercase',
        color: mode('white', 'white')(props),
        backgroundColor: mode('primary.600', 'secondary.600')(props),
        textAlign: 'center',
        letterSpacing: 'wider',
        opacity: '0.7',
    },
    command: {
        // this will style the text defined by the command
        // prop in the MenuItem and MenuItemOption components
        opacity: '0.8',
        fontFamily: 'mono',
        fontSize: 'sm',
        letterSpacing: 'tighter',
        pl: '4',
    },
    divider: {
        // this will style the MenuDivider component
        my: '4',
        borderColor: mode('primary.900', 'secondary.900')(props),
        borderBottom: '2px dotted',
    },
}))
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })
