import orderBy from 'lodash/orderBy'

import find from 'lodash/find'
import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import { BaseInsuranceDto, GetVatDetail } from '../../../utils/types/types'

type OptionalBaseInsuranceDto = Partial<BaseInsuranceDto>

export const insuranceHeaders = (
    selectionEnabled: boolean,
    translate: any,
    actionsFn: any,
    VAT: GetVatDetail[]
): any[] => {
    const headers: any = [
        {
            headerCheckboxSelection: selectionEnabled,
            checkboxSelection: selectionEnabled,
            field: 'baseInsuranceNumber',
            filter: 'agTextColumnFilter',
            headerName: translate('id'),
        },
        {
            field: 'description',
            filter: 'agTextColumnFilter',
            headerName: translate('description'),
        },
        {
            field: 'vatId',
            filter: 'agTextColumnFilter',
            headerName: translate('vat'),
            valueGetter: (params: any) =>
                `${find(VAT, { id: params.data.vatId })?.percentage}%` || '',
            flex: 1,
        },
    ]

    if (actionsFn !== undefined) {
        headers.push({
            ...ColumnActionConfiguration(
                translate,
                (params: any) => actionsFn(params),
                200
            ),
        })
    }
    return headers
}
export const getInsuranceRows = (items?: Partial<BaseInsuranceDto>[]): any =>
    items ? orderBy(items, 'maxValue', 'asc') : undefined
export const DEFAULT_INSURANCE_ITEM: OptionalBaseInsuranceDto = {}
