import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Stack,
    useBoolean,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react'

import DatePicker from '../../../../../components/datePicker/DatePicker.component'
import ErrorBoundaryWrapper from '../../../../../features/errorBoundary/ErrorBoundary.feature'
import ContractAssetsTable from '../../../../../features/genericTables/contractAssetsTable/ContractAssetsTable.component'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalPostAPI,
    generalPutAPI,
} from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    baseWarningToastOptions,
    offsetTimeZoneDifference,
} from '../../../../../utils/functions.utils'
import {
    ContractAssetDTO,
    ContractAssetStatus,
} from '../../../../../utils/types/types'

function ActivateAsset({
    isModalOpen,
    onClose,
}: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { contract, getContract, updateContract, fetchDocuments } =
        useContractService()
    const [selectedAssets, setSelectedAssets] = useState<ContractAssetDTO[]>([])
    const [activationDate, setActivationDate] = useState(new Date())
    const [isGeneratingDocument, generatingDocument] = useBoolean()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [assets, setAssets] = useState<ContractAssetDTO[]>()
    const filterNotActivatedAssets = (a: ContractAssetDTO): boolean =>
        !a?.dateTimeActivation &&
        !!a?.assetNumber &&
        !a?.assetNumber.includes('_') &&
        a?.status !== ContractAssetStatus.Closed

    useEffect((): void => {
        setSelectedAssets([])
    }, [isModalOpen])

    useEffect(() => {
        setAssets(
            contract?.contractAssets?.filter((a) =>
                filterNotActivatedAssets(a)
            ) ?? undefined
        )
    }, [JSON.stringify(contract?.contractAssets)])

    const approveAssets = async (): Promise<void | string> => {
        const updatedAssets = selectedAssets.forEach((asset) => ({
            ...asset,
            dateTimeActivation: activationDate,
        }))

        onClose()

        if (selectedAssets.length) {
            const simulate = await generalPostAPI(API_ENDPOINTS.assetSimulate, {
                assetsToActivate: selectedAssets.map(
                    (asset) => asset.assetNumber
                ),
                assetsToCancel: [],
            })

            if (!simulate.isOk) {
                return toast(baseErrorToastOptions(simulate.err)) as string
            }
            let confirmation = true
            if (simulate.data) {
                confirmation = window.confirm(
                    translate('activationAssetsConfirm')
                )
            }

            if (!simulate.data || (simulate.data && confirmation)) {
                const loadingID = globalLoading()
                const activateAssetsRequest = await generalPutAPI(
                    API_ENDPOINTS.assetActivate,
                    {
                        assetsToActivate: selectedAssets.map(
                            (asset) => asset.assetNumber
                        ),
                        activationDate: activationDate
                            .toISOString()
                            .split('T')[0],
                    }
                )

                stopGlobalLoading(loadingID)
                if (!activateAssetsRequest.isOk) {
                    return toast(
                        baseErrorToastOptions(
                            activateAssetsRequest.message ??
                                translate('problemAssetsActivation')
                        )
                    ) as string
                }

                updateContract({
                    ...contract,
                    contractAssets: updatedAssets,
                })
                getContract(contract?.contractNumber)
                toast(
                    baseSuccessToastOptions(
                        translate('successfulAssetsActivation')
                    )
                )
            }
        }
    }

    async function generateDeliveryDocuments(): Promise<void> {
        generatingDocument.on()
        toast(baseWarningToastOptions(translate('generatingDeliveryDocument')))
        const assetNumbers: string[] = []
        selectedAssets.forEach(
            (asset) => asset.assetNumber && assetNumbers.push(asset.assetNumber)
        )
        const requestData = {
            contractNumber: contract?.contractNumber,
            assetNumbers,
            deliveryDate: activationDate,
        }
        const response = await generalPostAPI(
            API_ENDPOINTS.documentGenerateDeliveryAcceptance,
            requestData
        )
        if (response.isOk) {
            toast(
                baseSuccessToastOptions(translate('generatedDeliveryDocument'))
            )
            fetchDocuments()
        }
        generatingDocument.off()
    }

    const onSelectionChanged = (data: any): void => {
        const selectedNodes = data.api.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedAssets([...selectedData])
    }

    const borderColor = useColorModeValue('primary.500', 'secondary.500')

    return (
        <Box>
            <ErrorBoundaryWrapper id="activate-assets-error">
                <Stack spacing={8}>
                    <Box data-testid="contract-assets-table">
                        <ContractAssetsTable
                            assets={assets}
                            hasSelection={true}
                            onSelectionChanged={onSelectionChanged}
                            variation="activate"
                        />
                    </Box>
                    <Box>
                        <FormLabel htmlFor={'activationDate'}>
                            {translate('activationDate')}
                        </FormLabel>
                        <Box maxW={425}>
                            <DatePicker
                                data-testid="activation-date-input"
                                name="activationDate"
                                max={new Date()}
                                value={activationDate}
                                onChange={(date: Date) => {
                                    if (date) {
                                        setActivationDate(
                                            offsetTimeZoneDifference(date)
                                        )
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Stack>
            </ErrorBoundaryWrapper>

            <Flex
                mt={4}
                pt={4}
                gap={4}
                borderTop={'1px solid'}
                borderColor={borderColor}
                justifyContent={'flex-end'}
                data-testid="activate-asset-actions"
            >
                <Button
                    isDisabled={!selectedAssets.length}
                    isLoading={isGeneratingDocument}
                    onClick={generateDeliveryDocuments}
                    variant={'solid'}
                    data-testid="generate-delivery-documents-button"
                >
                    {translate('createAOD')}
                </Button>
                <Button
                    isDisabled={!selectedAssets.length}
                    leftIcon={<CheckIcon />}
                    mr={3}
                    onClick={approveAssets}
                    data-testid="activate-button"
                >
                    {translate('activate')}
                </Button>
            </Flex>
        </Box>
    )
}

export default withModalHOC<ModalSharedProps>(ActivateAsset)
