import { ReactElement } from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'

import {
    IconButton,
    IconButtonProps,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react'

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>

export default function ColorModeSwitcher(
    props: ColorModeSwitcherProps
): ReactElement {
    const { toggleColorMode } = useColorMode()

    return (
        <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={toggleColorMode}
            icon={useColorModeValue(<FaMoon />, <FaSun />)}
            aria-label={useColorModeValue('dark', 'light')}
            {...props}
        />
    )
}
