import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import downloadTableData from '../../utils/table.download.utils'

interface UseDownloadInterface {
    handleDownloadSelected: (items: any) => void
    handleDownloadAll: Function
    handleClose: Function
}

export default function useDownload(
    data: any,
    downloadFileConfig: string,
    setDownloadEnabled: Dispatch<SetStateAction<boolean>>
): UseDownloadInterface {
    const translate = useTranslation().t
    const [currentTableData, setCurrentTableData] = useState<any>([])
    const [fileConfig, setFileConfig] = useState<any>()

    useEffect(() => {
        setCurrentTableData(data)
        if (downloadFileConfig) {
            setFileConfig(downloadFileConfig)
        }
    }, [JSON.stringify(data), JSON.stringify(downloadFileConfig)])

    function handleClose(): void {
        setDownloadEnabled(false)
    }

    function handleDownloadSelected(selectedItems: any): void {
        if (selectedItems.length === 0) return
        downloadTableData(selectedItems, fileConfig, translate)
        handleClose()
    }

    function handleDownloadAll(): void {
        downloadTableData(currentTableData, fileConfig, translate)
        handleClose()
    }

    return {
        handleDownloadSelected,
        handleDownloadAll,
        handleClose,
    }
}
