import { chakra, useColorModeValue } from '@chakra-ui/react'

import { TagType } from '../../utils/constants.utils'

export const StyledLink = ({ children, ...props }: any) => (
    <chakra.a
        justifyContent={'start'}
        cursor={'pointer'}
        textDecoration="none"
        _hover={{
            textDecoration: 'underline',
        }}
        color={useColorModeValue('primary.600', 'primary.200')}
        {...props}
    >
        {children}
    </chakra.a>
)

export const getBadgeColor = (tagType: TagType): string => {
    switch (tagType) {
        case 'link':
            return 'blue'
        case 'success':
            return 'green'
        case 'warning':
            return 'yellow'
        case 'danger':
            return 'red'
        default:
            return 'gray'
    }
}

export const StyledBadge = ({ children, ...props }: any) => (
    <chakra.span
        borderRadius={'lg'}
        px={3}
        py={1}
        fontSize={'small'}
        fontWeight={'semibold'}
        color={useColorModeValue(
            `${getBadgeColor(props.variant)}.800`,
            `${getBadgeColor(props.variant)}.100`
        )}
        background={useColorModeValue(
            `${getBadgeColor(props.variant)}.100`,
            `${getBadgeColor(props.variant)}.800`
        )}
        {...props}
    >
        {children}
    </chakra.span>
)
