import { ReactElement, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Fade } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import { useUser } from '../../../../services/contexts/user.context'
import { useCustomer } from '../../../../services/customer/Customer.services'
import { CustomerDTO } from '../../../../utils/types/types'
import { CUSTOMER_FORM } from '../../CustomerDetails.config'

interface ICustomerDetailsInfo {
    parentCustomerNumber?: string
    readOnly?: boolean
}

export default function CustomerDetailsInfo({
    parentCustomerNumber,
    readOnly,
}: ICustomerDetailsInfo): ReactElement {
    const { customer, retrieveCustomer, updateCustomer } = useCustomer()
    const { customerNumber } = useParams<{ customerNumber: string }>()
    const { user } = useUser()
    const navigate = useNavigate()

    const loadCustomer = async (): Promise<void> => {
        await retrieveCustomer(parentCustomerNumber ?? customerNumber)
    }

    useEffect((): void => {
        loadCustomer()
    }, [parentCustomerNumber])

    const onUpdateCustomer = async (value: CustomerDTO) => {
        await updateCustomer(customerNumber, value)
        customerNumber !== value.customerNumber &&
            navigate(`/customers/${value.customerNumber}`, { replace: true })
    }

    return (
        <GenericBox
            p={6}
            width={'100%'}
            textAlign={'center'}
            animation="alternate"
            borderRadius={'md'}
        >
            <Fade in>
                {customer && Object.keys(customer).length > 0 && (
                    <DynamicForm<Partial<CustomerDTO>>
                        columns={[1, 1, 2, 3]}
                        submitText="saveChanges"
                        onSubmit={(value: CustomerDTO): void => {
                            onUpdateCustomer(value)
                        }}
                        data={customer}
                        formFields={CUSTOMER_FORM(user.culture, readOnly)}
                        submitButtonProps={{
                            float: 'right',
                        }}
                        hideSubmit={readOnly}
                    />
                )}
            </Fade>
        </GenericBox>
    )
}
