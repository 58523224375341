import { Descendant, Text } from 'slate'
import { ELEMENT_TAGS, MARK_TAGS, getKeyByValue } from './elements'

const escapeHtml = (unsafe: string) =>
    unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')

const getStyleObj = (style: any) =>
    style.split(';').reduce((acc, item) => {
        const [key, value] = item.split(':').map((i) => i.trim())
        if (key) acc[key] = value
        return acc
    }, {})

// Slate to HTML
const STYLE_TAGS = {
    align: 'text-align',
}

// Slate value translation
const STYLE_VAL = {}

// slate to html
export const serialize = (node: Descendant | Descendant[]): string => {
    if (Array.isArray(node)) {
        return node.map((n) => serialize(n)).join('')
    }

    if (Text.isText(node)) {
        // Text nodes (leaf)
        let string = escapeHtml(node.text)

        string = Object.entries(MARK_TAGS).reduce(
            (acc, [key, value]) =>
                node[value] ? `<${key}>${acc}</${key}>` : acc,
            string
        )
        return string
    }

    const children = node.children.map((n) => serialize(n)).join('')

    const htmlTag = getKeyByValue(ELEMENT_TAGS, node.type) || ('p' as string)

    const styles = Object.entries(STYLE_TAGS).reduce(
        (acc, [key, value]) =>
            node[key]
                ? [`${value}: ${STYLE_VAL[node[key]] || node[key]}`, ...acc]
                : acc,
        []
    )
    const styleProp = styles.length ? `style="${styles.join('')}"` : ''

    return `<${[htmlTag, styleProp]
        .filter(Boolean)
        .join(' ')}>${children}</${htmlTag}>`
}

export const deserialize = (htmlString: string): any => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html')
    const slate = Array.from(doc.body.childNodes)
        .map((node) => deserializeImpl(node))
        .flat()
        .filter((child) => child !== null) // Filter out null values
    return slate
}

const deserializeImpl = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return { text: el.textContent, ...markAttributes }
    }
    if (el.nodeType !== Node.ELEMENT_NODE) {
        // should not occur
        return null
    }

    let nodeAttributes = { ...markAttributes }

    const tagName = el.nodeName.toLowerCase()

    // Apply style/mark tags
    if (MARK_TAGS[tagName]) {
        nodeAttributes = { ...nodeAttributes, [MARK_TAGS[tagName]]: true }
    }

    // Apply style properties
    const style = el.getAttribute('style')
    if (style) {
        const styleObj = getStyleObj(style)
        nodeAttributes = Object.entries(STYLE_TAGS).reduce(
            (acc, [key, value]) =>
                styleObj[value]
                    ? { ...nodeAttributes, [key]: styleObj[value] }
                    : acc,
            nodeAttributes
        )
    }

    // Recursively serialize children
    let children = Array.from(el.childNodes)
        .map((node) => deserializeImpl(node, nodeAttributes))
        .flat()
        .filter((child) => child !== null) // Filter out null values
    children = children.length > 0 ? children : [{ text: '' }]

    // Construct final object
    if (ELEMENT_TAGS[tagName]) {
        return {
            type: ELEMENT_TAGS[tagName],
            ...nodeAttributes,
            children,
        }
    }
    return children
}
