import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useToast } from '@chakra-ui/react'

import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC from '../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import { usePenneo } from '../../../services/contexts/penneo.context'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
} from '../../../utils/functions.utils'

interface IPenneoSetupContainer {
    modalTitle: string
    isModalOpen: boolean
    onClose: Function
}

interface IPenneoConfig {
    publicKey: string
    secretKey: string
    apiUrl: string
}

function PenneoSetupContainer(props: IPenneoSetupContainer): ReactElement {
    const translate = useTranslation().t
    const [isLoading, setLoading] = useState<boolean>(true)
    const [requestPending, setRequestPending] = useState<boolean>(false)
    const { setPenneoPublicToken, setPenneoStatus } = usePenneo()
    const toast = useToast()

    const onSubmit = async (data: IPenneoConfig): Promise<void> => {
        const { publicKey, secretKey, apiUrl } = data
        if (requestPending) {
            toast(baseErrorToastOptions(translate('requestPending')))
            props.onClose()
            return
        }

        setRequestPending(true)
        const response = await generalPostAPI(`${API_ENDPOINTS.penneo}/setup`, {
            BaseApiIntegrationUrl: apiUrl,
            clientKey: publicKey,
            clientSecret: secretKey,
            partnerId: 3,
            setupType: 200,
        })
        setRequestPending(false)

        if (!response.isOk) {
            toast(baseErrorToastOptions(translate('invalidPublicSecretKey')))
            props.onClose()
            return
        }

        setPenneoPublicToken(publicKey)
        setPenneoStatus(true)
        toast(baseInfoToastOptions(translate('penneoConfigUpdated')))
        props.onClose()
    }

    const loadPenneoSetup = async (): Promise<void> => {
        const response = await generalGetAPI(`${API_ENDPOINTS.penneo}/setup`)
        if (response.isOk) {
            setPenneoStatus(true)
        }
        setLoading(false)
    }

    useEffect((): void => {
        loadPenneoSetup()
    }, [])

    return (
        <Box w={'100%'} p={4}>
            <DynamicForm
                columns={[1, 1, 3]}
                data={{ publicKey: '', secretKey: '', apiUrl: '' }}
                formFields={[
                    {
                        slug: 'publicKey',
                        label: translate('penneoPublicKey'),
                        inputProps: { type: 'text' },
                        validation: (value: string) =>
                            value && value.length > 0,
                        errorMessage: translate('pleaseSetPublicKey'),
                    },
                    {
                        slug: 'secretKey',
                        label: translate('penneoSecretKey'),
                        inputProps: { type: 'text' },
                        validation: (value: string) =>
                            value && value.length > 0,
                        errorMessage: translate('pleaseSetSecretKey'),
                    },
                    {
                        slug: 'apiUrl',
                        label: 'aPI URL',
                        inputProps: { type: 'text' },
                    },
                ]}
                hideSubmit={false}
                onSubmit={onSubmit}
                submitButtonProps={{ isLoading: isLoading || requestPending }}
                submitText={translate('connect')}
            />
        </Box>
    )
}

export default withModalHOC(PenneoSetupContainer)
