import { MutableRefObject, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@chakra-ui/react'

import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../hoc/modal.hoc'
import {
    ChecklistHumanInteractionTypeBO,
    CreateChecklistHumanInteractionTypeCommand,
    UpdateChecklistHumanInteractionTypeCommand,
} from '../../../utils/types/types'

interface ChecklistInteractionEditInterface extends ModalSharedProps {
    interaction: ChecklistHumanInteractionTypeBO
    onSubmit: Function
}

function ChecklistInteractionEdit({
    interaction,
    onSubmit,
}: ChecklistInteractionEditInterface): ReactElement {
    const translate = useTranslation().t
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    return (
        <Box p={2}>
            <DynamicForm<UpdateChecklistHumanInteractionTypeCommand>
                formFields={[
                    {
                        slug: 'name',
                        label: 'name',
                        inputProps: { type: 'text' },
                    },
                    {
                        slug: 'description',
                        label: 'description',
                        inputProps: { type: 'text' },
                    },
                    {
                        slug: 'isActive',
                        label: 'active',
                        inputProps: { type: 'boolean' },
                    },
                ]}
                hideSubmit={true}
                formSubmitReference={formSubmitReference}
                data={interaction}
                onSubmit={(
                    data: CreateChecklistHumanInteractionTypeCommand
                ): void => onSubmit(data)}
            ></DynamicForm>
            <Button
                float={'right'}
                mt={'4'}
                onClick={() => {
                    if (formSubmitReference) {
                        formSubmitReference.current?.click()
                    }
                }}
            >
                {translate('submit')}
            </Button>
        </Box>
    )
}

export default withModalHOC<ChecklistInteractionEditInterface>(
    ChecklistInteractionEdit
)
