/* eslint-disable no-unused-vars */
import { ReactElement, useEffect } from 'react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import ProductsTable from '../../../features/genericTables/productsTable/ProductsTable.component'
import { useProduct } from '../../../services/contexts/Product.context'

export default function ContractTemplates(): ReactElement {
    const { products, getProducts, isLoading } = useProduct()

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <PageContainer>
            <ProductsTable
                products={products}
                isLoading={isLoading}
                fetchProducts={getProducts}
            />
        </PageContainer>
    )
}
