import { ReactElement, useEffect } from 'react'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import {
    ContractAssetDTO,
    ProductRuleDTO,
    ProductRuleType,
} from '../../../utils/types/types'
import { getProductRuleByType } from '../../../utils/functions.utils'

interface ILockInAssetFormFeature {
    contractAsset: ContractAssetDTO
    handleInputChange: Function
    actionType: any
    productRules: ProductRuleDTO[]
}

export default function LockInAssetFormFeature({
    contractAsset,
    handleInputChange,
    actionType,
    productRules,
}: ILockInAssetFormFeature): ReactElement {
    const translate = useTranslation().t

    const lockedInPeriodRules = getProductRuleByType(
        ProductRuleType.LockedInPeriod,
        productRules
    )

    const cancellationPeriodRules = getProductRuleByType(
        ProductRuleType.CancellationPeriod,
        productRules
    )

    useEffect(() => {
        if (!contractAsset?.lockInPeriod && lockedInPeriodRules?.defaultValue) {
            handleInputChange(lockedInPeriodRules?.defaultValue, 'lockInPeriod')
        }
        if (
            !contractAsset?.cancellationPeriod &&
            cancellationPeriodRules?.defaultValue
        ) {
            handleInputChange(
                cancellationPeriodRules?.defaultValue,
                'cancellationPeriod'
            )
        }
    }, [])

    return (
        <>
            <FormControl>
                <FormLabel>{translate('lockInPeriod')}</FormLabel>
                <Input
                    value={contractAsset.lockInPeriod}
                    type="number"
                    min={0}
                    defaultValue={lockedInPeriodRules?.defaultValue}
                    isDisabled={actionType === 'view'}
                    onChange={(e): void => {
                        handleInputChange(e.target.value, 'lockInPeriod')
                    }}
                ></Input>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('cancellationPeriod')}</FormLabel>
                <Input
                    value={contractAsset.cancellationPeriod}
                    type="number"
                    min={0}
                    defaultValue={cancellationPeriodRules?.defaultValue}
                    isDisabled={actionType === 'view'}
                    onChange={(e): void => {
                        handleInputChange(e.target.value, 'cancellationPeriod')
                    }}
                ></Input>
            </FormControl>
        </>
    )
}
