import {
    Flex,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Z_INDEX } from '../../../../../utils/constants.utils'
import {
    ActionsAllowed,
    ContractStatus,
    ProductType,
    UserGroup,
} from '../../../../../utils/types/types'
import { useContractService } from '../../../../../services/contract/Contract.services'

interface IContractAssetsActions {
    actionsAllowed: ActionsAllowed
    handleCreateAsset: any
    isActivateAssetDisabled: boolean
    isAddAssetDisabled: boolean
    isCopyAssetDisabled: boolean
    isLoading: boolean
    isLocked: boolean
    openActivateAsset: any
    openAddExistingAsset: any
    openCopyAsset: any
    openStartContract: any
    productType: ProductType
    userGroup: UserGroup
}

export default function ContractAssetsActions({
    actionsAllowed,
    handleCreateAsset,
    isActivateAssetDisabled,
    isAddAssetDisabled,
    isCopyAssetDisabled,
    isLoading,
    isLocked,
    openActivateAsset,
    openAddExistingAsset,
    openCopyAsset,
    openStartContract,
    productType,
    userGroup,
}: IContractAssetsActions): ReactElement {
    const { contract } = useContractService()
    const translate = useTranslation().t

    return (
        <Flex justifyContent={'flex-end'} gap={4} pb={4} flexWrap={'wrap'}>
            {actionsAllowed?.canStartManually && (
                <Button onClick={openStartContract} isLoading={isLoading}>
                    {translate('startContract')}
                </Button>
            )}
            {productType !== ProductType.Sale &&
                userGroup > UserGroup.RestrictedUser &&
                contract.status === ContractStatus.Offer && (
                    <Button
                        onClick={openActivateAsset}
                        isLoading={isLoading}
                        isDisabled={isActivateAssetDisabled}
                    >
                        {translate('activateAssets')}
                    </Button>
                )}

            {!isLocked && isLoading && (
                <Button isLoading={true}>{translate('addAsset')}</Button>
            )}
            {!isLocked && !isLoading && (
                <Menu>
                    <MenuButton as={Button} isDisabled={isAddAssetDisabled}>
                        {translate('addAsset')}
                    </MenuButton>
                    <MenuList zIndex={Z_INDEX.MENU_LIST}>
                        <MenuItem
                            onClick={handleCreateAsset}
                            isDisabled={isAddAssetDisabled}
                        >
                            {translate('addAsset')}
                        </MenuItem>
                        <MenuItem onClick={openAddExistingAsset}>
                            {translate('addExistingAsset')}
                        </MenuItem>
                    </MenuList>
                </Menu>
            )}
            {!isLocked && (
                <Button
                    isDisabled={isCopyAssetDisabled}
                    onClick={openCopyAsset}
                >
                    {translate('copyExistingAsset')}
                </Button>
            )}
        </Flex>
    )
}
