import Cookies from 'js-cookie'
import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'

interface ColorsContext {
    primary: string
    definePrimary: Function
    defineSecondary: Function
    secondary: string
}

const ColorsContext = createContext<ColorsContext>({} as ColorsContext)

interface IColorsContextProvider {
    children: ReactNode
}

export const ColorsContextProvider = ({
    children,
}: IColorsContextProvider): ReactElement => {
    const [primary, setPrimary] = useState('#1BB384')
    const [secondary, setSecondary] = useState('#333')

    function definePrimary(value: string): void {
        Cookies.set('primary', value)
        setPrimary(value)
    }

    function defineSecondary(value: string): void {
        Cookies.set('secondary', value)
        setSecondary(value)
    }

    return (
        <ColorsContext.Provider
            value={{
                primary,
                definePrimary,
                defineSecondary,
                secondary,
            }}
        >
            {children}
        </ColorsContext.Provider>
    )
}

export const useColorContext = (): ColorsContext => useContext(ColorsContext)
