import camelCase from 'lodash/camelCase'

import {
    sumDepreciation,
    sumInterest,
} from '../../../../features/genericTables/invoicesTable/InvoicesTable.config'
import { translateWithPrefix } from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { ExportStatus, InvoiceType } from '../../../../utils/types/types'

interface ExportTabInterface {
    id: ExportStatus
    text: string
}

export const EXPORT_TABS: ExportTabInterface[] = [
    {
        id: 0,
        text: 'notExported',
    },
    {
        id: 1,
        text: 'exportQueue',
    },
    {
        id: 2,
        text: 'exported',
    },
    {
        id: 3,
        text: 'failed',
    },
    {
        id: 4,
        text: 'exportArchive',
    },
]

export const EXPORT_HEADERS = (
    translate: Function,
    formatValue: Function,
    simple: boolean,
    isDownloadEnabled: boolean
): any => [
    {
        headerName: translate('invoiceNumber'),
        field: 'invoiceNumber',
        headerCheckboxSelection: isDownloadEnabled,
        checkboxSelection: isDownloadEnabled,
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
    },
    {
        headerName: translate('invoiceType'),
        filter: 'agTextColumnFilter',
        field: 'invoiceType',
        valueGetter: (params: any) =>
            translateWithPrefix(
                translate,
                'invoiceType',
                InvoiceType[params.data.invoiceType]
            ),
    },
    {
        headerName: translate('contractNumber'),
        field: 'contractNumber',
        filter: 'agTextColumnFilter',
    },
    {
        headerName: translate('customerNumber'),
        field: 'customerNumber',
        filter: 'agTextColumnFilter',
    },
    {
        headerName: translate('customerName'),
        field: 'customerName',
        filter: 'agTextColumnFilter',
    },
    {
        headerName: translate('periodStart'),
        field: 'periodStartDate',
        valueFormatter: (params: any) =>
            formatDate(params.data.periodStartDate),
        filter: 'agTextColumnFilter',
    },
    {
        headerName: translate('amountExcludingVat'),
        field: 'amountExcludingVat',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            formatValue(params.data.amountExcludingVat),
    },
    {
        headerName: translate('depreciation'),
        field: 'depreciation',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => formatValue(sumDepreciation(params.data)),
    },
    {
        headerName: translate('interest'),
        field: 'interest',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => formatValue(sumInterest(params.data)),
        ...(simple ? { flex: 1 } : {}),
    },
    ...(!simple
        ? [
              {
                  headerName: translate('externalInvoiceId'),
                  field: 'externalInvoiceId',
                  filter: 'agTextColumnFilter',
              },
              {
                  headerName: translate('exportStatus'),
                  field: 'exportStatus',
                  filter: 'agTextColumnFilter',
                  valueGetter: (params: any) =>
                      translate(
                          camelCase(ExportStatus[params.data.exportStatus])
                      ),
              },
              {
                  headerName: translate('exportDate'),
                  field: 'exportDate',
                  filter: 'agTextColumnFilter',
                  valueFormatter: (params: any) =>
                      formatDate(params.data.exportDate),
              },
              {
                  headerName: translate('initializedUser'),
                  field: 'initializedUser',
                  filter: 'agTextColumnFilter',
                  flex: 1,
              },
          ]
        : []),
]
