import { ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Layout from '../../components/layout/layout.component'
import ErrorBoundaryWrapper from '../../features/errorBoundary/ErrorBoundary.feature'
import { useUser } from '../../services/contexts/user.context'
import BASE_ROUTES, { IBaseRoute } from '../routes/base.routes'
import { useERP } from '../../services/erp/ERP.services'

export default function Content(): ReactElement {
    const { user } = useUser()
    const { activeSetup } = useERP()

    const buildSubRoutes = (routeList: any[]): ReactElement[] =>
        routeList.map(({ path, Component, subRoutes }) => (
            <Route
                key={path}
                path={path}
                element={
                    <ErrorBoundaryWrapper id="content-error">
                        <Component />
                    </ErrorBoundaryWrapper>
                }
            >
                {subRoutes && buildSubRoutes(subRoutes)}
            </Route>
        ))

    return (
        <Layout bg="red">
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/contracts" replace />}
                />
                {BASE_ROUTES(user?.userGroup, activeSetup).map(
                    ({
                        path,
                        Component,
                        subRoutes,
                        isUnauthorized,
                    }: IBaseRoute) => {
                        if (isUnauthorized) return <></>

                        return (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <ErrorBoundaryWrapper id="content-route-error">
                                        <Component />
                                    </ErrorBoundaryWrapper>
                                }
                            >
                                {subRoutes && buildSubRoutes(subRoutes)}
                            </Route>
                        )
                    }
                )}
                <Route
                    path="*"
                    element={<Navigate to="/contracts" replace />}
                />
            </Routes>
        </Layout>
    )
}
