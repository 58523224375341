import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import { Divider, MenuItem, Text } from '@chakra-ui/react'

import { usePartner } from '../../../../services/contexts/partner.context'
import { useUser } from '../../../../services/contexts/user.context'
import { UserGroup } from '../../../../utils/types/types'

function UserActions(): ReactElement[] {
    const navigate = useNavigate()
    const { activePartner } = usePartner()
    const { logout, getAccessTokenSilently } = useAuth0()
    const { user } = useUser()

    const copyAuthTokenHandler = async (): Promise<void> => {
        const authToken = await getAccessTokenSilently()
        navigator.clipboard.writeText(`Bearer ${authToken}`)
        toast.success('Auth Token Copied')
    }
    const dropdownOptions = [
        {
            item: activePartner?.name ?? 'Loading...',
            authLevel: UserGroup.RestrictedUser,
        },
        {
            item: 'Profile',
            onClick: () => navigate('/profile'),
            authLevel: UserGroup.RestrictedUser,
        },
        {
            authLevel: UserGroup.AdminUser,
            item: 'Copy Auth Token',
            onClick: () => copyAuthTokenHandler(),
        },
        {
            item: 'Logout',
            onClick: () => logout(),
            authLevel: UserGroup.RestrictedUser,
        },
    ]
    return dropdownOptions
        .filter((option) => user.userGroup >= option.authLevel)
        .map((option, key) =>
            key === 0 ? (
                <Text padding={3} width={'100%'} key={key}>
                    {option.item}
                </Text>
            ) : (
                <>
                    <MenuItem
                        key={key}
                        padding={3}
                        width={'100%'}
                        onClick={option.onClick}
                    >
                        {option.item}
                    </MenuItem>
                    {key === 0 && <Divider />}
                </>
            )
        )
}

export default UserActions
