import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { getAllPagesHandlerPrompt } from '../../../../../utils/pagination/pagination.util'
import { DocumentType } from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import { getColumnsConfig } from './ExportArchive.config'

export default function ExportArchive(): ReactElement {
    const translate = useTranslation().t
    const [documents, setDocuments] = useState<any[]>()

    useEffect(() => {
        getExportedArchiveDocuments()
    }, [])

    const getExportedArchiveDocuments = async () => {
        try {
            await getAllPagesHandlerPrompt(
                API_ENDPOINTS.documentByType(
                    DocumentType.QueuedInvoicesCsvFile
                ),
                (data: any) => {
                    setDocuments((prevValue) => [...(prevValue || []), ...data])
                }
            )
        } catch (error: any) {
            toast.error(`Error fetching documents: ${error.message}`)
        }
    }

    return (
        <DynamicGrid
            tableId="exportArchiveTable"
            columns={getColumnsConfig(translate)}
            rowData={documents}
            pagination={true}
            rowMultiSelectWithClick={false}
        />
    )
}
