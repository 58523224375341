import i18next from 'i18next'
import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { toast } from 'react-toastify'

import { DEFAULT_USER } from '../../pages/setup/users/Users.config'
import { CreateUserCommand, UserDTO } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI, generalPutAPI } from '../API/general.api'
import { updateCurrentLanguage } from '../i18n.service'

interface IUserContext {
    user: UserDTO
    setUser(value: any): void
    updateUser: (updatedUser: Partial<CreateUserCommand>) => Promise<boolean>
}

interface IUserContextProviderProps {
    children: ReactNode
}

export const UserContext = createContext({} as IUserContext)

export const useUser = (): IUserContext => useContext(UserContext)

const useProviderUser = (): IUserContext => {
    const [user, setUser] = useState<UserDTO>(DEFAULT_USER)

    useEffect(() => {
        if (user?.userNumber && user?.culture && i18next?.isInitialized) {
            sessionStorage.setItem('culture_id', user.culture.toString())
            sessionStorage.setItem('formatting', user.formatting.toString())
            updateCurrentLanguage(user.culture)
        }
    }, [JSON.stringify(user), i18next?.isInitialized])

    const retrieveUser = async () => {
        const data = await generalGetAPI(API_ENDPOINTS.userSelf)
        if (!data.isOk) return
        setUser(data.data)
    }

    const updateUser = async (
        updatedUser: Partial<CreateUserCommand>
    ): Promise<boolean> => {
        sessionStorage.setItem('culture_id', `${updatedUser.culture}`)
        const response = await generalPutAPI(API_ENDPOINTS.user, updatedUser)
        if (!response.isOk) {
            toast.error(response.message)
        }
        if (updatedUser?.culture) {
            updateCurrentLanguage(updatedUser.culture)
        }
        retrieveUser()
        return response.isOk
    }
    return { user, setUser, updateUser }
}

export const UserContextProvider = ({
    children,
}: IUserContextProviderProps): ReactElement => {
    const user: IUserContext = useProviderUser()

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
