import { DocumentType } from '../../utils/types/types'

const API_ENDPOINTS = {
    activatedAssetsVolume: '/api/dashboard/activated-assets-volume',
    actualContractsPaymentPerMonth:
        '/api/dashboard/actual-contracts-payment-per-months',
    asset: '/api/asset',
    assetPaginated: '/api/asset/',
    assetActivate: '/api/asset/active',
    assetBalance: '/api/assetBalance',
    assetBalanceReport: '/api/assetBalance/asset-balance-report',
    assetBase: '/api/asset/base',
    assetBasePaginated: '/api/asset/base',
    assetsAvailable: '/api/Asset/base/available',
    assetSimulate: '/api/asset/simulate',
    baseTransientFees: '/api/BaseTransientFees',
    checklists: '/api/Checklist',
    checklistCreateHumanType: '/api/Checklist/HumanInteractionType',
    checklistHumanTypes: '/api/Checklist/HumanInteractionTypes',
    checklistCreateHumanRecord: (
        checklistId: number,
        checklistItemId: number
    ): string =>
        `/api/Checklist/${checklistId}/CheckListItem/${checklistItemId}/HumanInteractionRecord`,
    checklistDomainOperationType: '/api/Checklist/ItemTypes/ByDomainOperation/',
    checklistProduct: (productId: number) =>
        `/api/Checklist/Products/${productId}`,
    checklistContract: (
        productId: number,
        domainOperationType: string
    ): string =>
        `/api/Checklist/Products/${productId}/DomainOperation/${domainOperationType}/EvaluateAgainst`,
    checklistContractUpdate: (checklistId: number, checklistItemId: number) =>
        `/api/Checklist/${checklistId}/CheckListItem/${checklistItemId}/HumanInteractionRecord`,
    checklistProducts: (
        productId: string,
        domainOperationType: string
    ): string =>
        `/api/Checklist/Products/${productId}/DomainOperation/${domainOperationType}`,
    contractAssetsPreterminateOffer: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/preterminate-offer`,
    contractAssetsPreterminateOfferBalances: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/preterminate-offer/preterminate/balances`,
    contractAssetsPreterminateOfferEstimate: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/preterminate-offer/estimate`,
    contractAssetInsurancePolicy: (contractAssetNumber: string): string =>
        `/api/ContractAssets/${contractAssetNumber}/Insurances`,
    contract: '/api/Contract',
    contractTransientFee: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/transientfees`,
    contractProlongation: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/prolongation/offer`,
    contractActionsCalculate: '/api/contract/Actions/calculate',
    contractActionsCancelContract: '/api/contract/Actions/cancel-contract',
    contractActionsCheckContract: '/api/contract/Actions/check-contract-action',
    contractActionsCloseContract: '/api/contract/Actions/close-contract',
    contractActionsStartContract: '/api/contract/Actions/start-contract',
    contractActionsCheckWarnings: '/api/contract/Actions/check-warnings',
    contractActionsCreateBundle: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/actions/create-bundle`,
    contractActionsCreateBundleContract: (
        contractBundleId: string,
        customerNumber: string
    ): string =>
        `api/ContractBundle/${contractBundleId}/create-contract?customerNumber=${customerNumber}`,
    contractActionsScheduleFutureEnd: (contractNumber: string): string =>
        `api/contract/${contractNumber}/actions/schedule-future-end`,
    contractAssetServices: (contractAssetNumber: string): string =>
        `/api/ContractAssets/${contractAssetNumber}/Services`,
    contractBalanceReport: '/api/ContractBalance/contract-balance-report',
    contractBundle: '/api/ContractBundle',
    contractFinance: 'api/Contract/contract-finance',
    contractFinanceEmail: '/api/ContractFinance/email',
    contractFinanceReport: '/api/ContractFinance/report',
    contractPost: 'api/contract/',
    dashboardContractsPerProduct: '/api/dashboard/contracts-per-product',
    dashboardContractsPerStatus: '/api/dashboard/contracts-per-status',
    dashboardActivatedAssetVolume: '/api/dashboard/activated-assets-volume',
    dashboardMonthlyRevenue: '/api/dashboard/monthly-recurring-revenue',
    dashboardTotalRevenue: '/api/dashboard/total-revenue',
    dashboardCustomerChurnRate: '/api/dashboard/customer-churn-rate',
    dashboardActivatedContracts: '/api/dashboard/activated-contracts-per-month',
    dashboardCustomerContractsPerStatus:
        '/api/dashboard/customer-contracts-per-status',
    contractTrace: '/api/ContractTrace',
    contractTraceTimeline: '/api/ContractTrace/ContractTimeline',
    creditSafe: '/api/CreditSafe',
    customerSearch: (searchTerm: string, searchOnline: boolean): string =>
        searchOnline
            ? `/api/customer?searchOnline=${searchOnline}&searchTerm=${searchTerm}`
            : `/api/customer?searchTerm=${searchTerm}`,
    customer: '/api/customer',
    customerPut: (customerNumber: string) => `/api/Customer/${customerNumber}`,
    customerAdvisor: 'api/customer/Advisor',
    customerContractsPerStatus: '/api/dashboard/customer-contracts-per-status',
    customerPost: '/api/Customer',
    cvr: '/api/Cvr',
    document: '/api/Document/',
    documentByType: (documentType: DocumentType): string =>
        `/api/Document?DocumentType=${documentType}`,
    documentUpdate: '/api/Document/update',
    documentByAsset: '/api/Document/by-asset/',
    documentByAssetGetImages: (assetNumber: string): string =>
        `/api/Document/by-asset/${assetNumber}?imagesOnly=true`,
    documentByAssetImages: (assetNumber: string): string =>
        `/api/Document/by-asset/${assetNumber}/images`,
    documentByContract: '/api/Document/by-contract/',
    documentByCustomer: '/api/Document/by-customer/',
    documentGenerate: '/api/Document/generate',
    documentGenerateInvoice: '/api/Document/generate/invoice',
    documentGenerateDeliveryAcceptance:
        'api/Document/generate/delivery-acceptance',
    documentByPartnerUploadedUsage: '/api/Document/by-partner/uploaded-usages',
    documentTemplate: '/api/DocumentTemplate',
    documentTemplateProductMap: '/api/DocumentTemplate/productTemplates',
    ERPSetup: '/api/Setup',
    ERPSetupActive: '/api/Setup/Active',
    ERPSetupAllData: '/api/Setup/allErpData',
    ERPSetupCustomerGroups: '/api/Setup/customerGroups',
    ERPSetupLayouts: '/api/Setup/layouts',
    ERPSetupPaymentTerms: '/api/Setup/paymentTerms',
    ERPSetupProducts: '/api/Setup/products',
    DocumentSignatureSystem: '/api/Setup/documentSignatureSystem',
    error: '/api/error',
    export: '/api/export',
    estimateProlongationOffer: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/prolongation/estimate`,
    externalAuthorization: '/api/ExternalAuthorization',
    file: '/api/file',
    firstInvoiceReport: '/api/Invoice/firstInvoiceReport',
    forecastContractsPaymentPerMonth:
        '/api/dashboard/forecast-contracts-payments-per-month',
    healthcheck: '/api/setup/connectionCheck?setupType=',
    invoice: 'api/invoice',
    invoiceActionsSendReminder: '/api/invoice/actions/send-reminder',
    invoiceActionsSend: '/api/invoice/actions/send',
    invoiceActionsUpdatePaidStatus: '/api/invoice/actions/update-paid-status',
    invoiceActionsGenerateEndInvoice:
        '/api/invoice/actions/generate-end-invoice',
    invoiceActionsResendInvoiceEmail: (invoiceNumber: string) =>
        `/api/invoice/${invoiceNumber}/actions/resend-email`,
    invoiceActionsCredit: '/api/invoice/actions/credit',
    invoiceExport: 'api/Invoice/export',
    invoiceExported: 'api/Invoice/exported',
    invoiceGenerateNext: '/api/invoice/actions/generate-periodic-invoices',
    invoiceGenerateNextContract:
        '/api/invoice/actions/generate-periodic-invoices/contract',
    invoicePaginated: 'api/Invoice/paginated',
    invoiceFinancialBaseReport: '/api/Invoice/financialBaseReport',
    invoiceLineReport: '/api/Invoice/invoiceLineReport',
    location: '/api/Location',
    matchInterest: '/api/contract/Actions/match-interest',
    moduleType: '/api/Module/moduleType',
    modules: '/api/Module/allModules',
    parameter: 'api/Parameter',
    parameterDefinitions: 'api/Parameter/definitions',
    partnerActive: 'api/Partner/active?includeLogo=true',
    partnerDetails: 'api/Partner/details',
    partnerLogoUI: '/api/Partner/logo/ui',
    partnerLogoGeneral: '/api/Partner/logo/general',
    partnerCurrentUser: 'api/Partner/current-user',
    penneo: '/api/penneo',
    penneoSign: '/api/Penneo/document/sign',
    penneoStatus: '/api/Penneo/document/refresh-penneo-status',
    periodicInvoiceReport: '/api/Invoice/periodicInvoiceReport',
    product: '/api/Product',
    service: '/api/Service',
    insurance: '/api/BaseInsurances',
    signers: '/api/signers',
    setupActive: '/api/setup/active',
    scriveSign: '/api/Scrive/document/sign',
    translateDictionary: '/translate/dictionary',
    usage: '/api/usage',
    usageUpload: '/api/Usage/upload',
    user: '/api/user',
    userDictionary: '/api/User/dictionary',
    userPartner: '/api/user/change-partner',
    userSelf: '/api/user/self',
    variableInterest: '/api/variable-interest',
    variableInterestLimit: '/api/variable-interest/limit',
    vat: '/api/Vat',
    copyContractAsset: '/api/asset/copy-asset',
    copyBaseAsset: '/api/asset/base/copy-asset',
    assetTypes: '/api/AssetType',
    assetScheduleFutureEnd: (contractAssetNumber: string) =>
        `api/asset/${contractAssetNumber}/schedule-future-end`,
    contractScheduleFutureEnd: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/actions/schedule-future-end`,
    contractDebtorChangeOffer: (contractNumber: string): string =>
        `/api/contract/${contractNumber}/debtor-change`,
    contractDebtorChange: `/api/contract/Actions/debtor-change`,
    contractDiscounts: (contractNumber: string): string =>
        `api/contract/discounts/${contractNumber}`,
}
export default API_ENDPOINTS
