import { ReactElement, useState } from 'react'

import { Button, Grid, GridItem, Input, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalFileGETAPI } from '../../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    downloadFileWithContent,
} from '../../../../utils/functions.utils'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { DocumentExtension } from '../../../../utils/types/types'

export default function PeriodicReportPage(): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [date, setDate] = useState<string>(() => {
        const now = new Date()
        return `${now.getFullYear()}-${`0${now.getMonth() + 1}`.slice(-2)}`
    })
    const extensions = Object.keys(DocumentExtension)
    const dateURL = (fileType: number = DocumentExtension.Xlsx): string => {
        const dateObj = new Date(date)
        return `${
            API_ENDPOINTS.periodicInvoiceReport
        }?year=${dateObj.getFullYear()}&month=${
            dateObj.getMonth() + 1
        }&fileType=${fileType}`
    }
    const onDownloadReport = async (ext: DocumentExtension): Promise<void> => {
        const loadingId = globalLoading()
        try {
            const response = await generalFileGETAPI(dateURL(ext))
            if (response.isOk) {
                downloadFileWithContent(
                    response.data,
                    'PeriodicInvoiceReport',
                    extensions
                        .find((key) => DocumentExtension[key] === ext)
                        ?.toLowerCase()
                )
                toast(baseSuccessToastOptions(translate('documentDownloaded')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        } finally {
            stopGlobalLoading(loadingId)
        }
    }

    return (
        <>
            <Grid gap={4} templateColumns={'1fr 1fr 1fr'}>
                <GridItem colSpan={2}>
                    <Input
                        type="month"
                        defaultValue={date}
                        onChange={(e): void => setDate(e.target.value)}
                    />
                </GridItem>
                {[DocumentExtension.Xlsx, DocumentExtension.Csv].map((ext) => (
                    <GridItem key={ext} colSpan={1}>
                        <Button
                            px={8}
                            onClick={(): any => onDownloadReport(ext)}
                        >
                            {translate('download')}{' '}
                            {extensions.find(
                                (key) => DocumentExtension[key] === ext
                            )}
                        </Button>
                    </GridItem>
                ))}
            </Grid>
        </>
    )
}
