import './imageGallery.scss'

import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { AddIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Img,
    Text,
    useToast,
} from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalFileGETAPI,
    generalFilePostAPI,
    generalGetAPI,
} from '../../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import {
    DocumentExtension,
    GetBaseAssetDetail,
} from '../../../../utils/types/types'

const MAX_NUMBER_IMAGES = 50

export default function AssetImageGallery(
    baseAsset: GetBaseAssetDetail
): ReactElement {
    const [images, setImages] = useState<ImageListType>([])
    const [activeImage, setActiveImage] = useState(0)
    const translate = useTranslation().t
    const toast = useToast()
    const onChange = (imageList: ImageListType): void => {
        setImages(imageList)
    }
    function blobToDataURL(blob: Blob, callback: Function): void {
        const a = new FileReader()
        a.onload = (e): void => callback(e?.target?.result)
        a.readAsDataURL(blob)
    }

    async function getFiles(
        fileDetails: any,
        tempArray: ImageListType
    ): Promise<void> {
        await generalFileGETAPI(
            `${API_ENDPOINTS.document}${fileDetails.id}`
        ).then((fileResponse) => {
            const arrayBufferView = new Uint8Array(fileResponse.data)
            const blob = new Blob([arrayBufferView], {
                type: `image/${DocumentExtension[
                    fileDetails.extension
                ].toLowerCase()}`,
            })
            const file = new File([blob], fileDetails.name, {
                type: blob.type,
            })

            blobToDataURL(blob, (e: any) => {
                tempArray.push({
                    dataURL: e,
                    file,
                    isOld: true,
                    id: fileDetails.id,
                })
                setImages([...tempArray])
            })
        })
    }

    useEffect(() => {
        baseAsset.baseAssetNumber &&
            generalGetAPI(
                API_ENDPOINTS.documentByAssetGetImages(
                    baseAsset.baseAssetNumber
                )
            ).then((response) => {
                const tempArray: ImageListType = []
                response.data.forEach(async (fileDetails: any) => {
                    getFiles(fileDetails, tempArray)
                })
            })
    }, [])

    function RenderImagePreview(
        imageList: ImageListType,
        onImageUpload: () => void,
        dragProps: {
            onDrop: (e: any) => void
            onDragEnter: (e: any) => void
            onDragLeave: (e: any) => void
            onDragOver: (e: any) => void
            onDragStart: (e: any) => void
        }
    ): ReactElement {
        return (
            <>
                <GenericBox
                    height={'500px'}
                    width="100%"
                    display={'flex'}
                    borderRadius="4px"
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection="column"
                    position={'relative'}
                >
                    {imageList.length > 0 && (
                        <Img
                            alt="mainImage"
                            p={6}
                            height={'inherit'}
                            aspectRatio={'1 / 1'}
                            src={imageList[activeImage]?.dataURL || ''}
                            zIndex={0}
                        ></Img>
                    )}
                    <Button
                        {...dragProps}
                        zIndex={1}
                        bg={'#00000080'}
                        _hover={{ opacity: 0.8 }}
                        onClick={onImageUpload}
                        height={'100%'}
                        width={'100%'}
                        position={'absolute'}
                        top={0}
                        left={0}
                    >
                        <Flex alignItems={'end'} gap={2}>
                            <AddIcon
                                mt={8}
                                opacity={0.5}
                                fontSize="24px"
                            ></AddIcon>

                            <Heading as="h3" size={'lg'}>
                                {translate('clickToUpload')}
                            </Heading>
                        </Flex>
                    </Button>
                </GenericBox>
                <Text margin={4} marginLeft={0} zIndex={1} fontSize="xs">
                    {imageList[activeImage]
                        ? imageList[activeImage].file?.name
                        : translate('fileTypesAndSize')}
                </Text>
            </>
        )
    }

    function RenderImageThumbnailsAndControls(
        imageList: ImageListType,
        onImageUpdate: (index: number) => void,
        onImageRemove: (index: number) => void
    ): ReactElement {
        return (
            <GenericBox p={6}>
                <Flex gap={7} wrap="wrap" mb={4}>
                    {imageList.map((image, index) => (
                        <Box
                            h={150}
                            w={150}
                            key={index}
                            className="image-item"
                            position={'relative'}
                            display="flex"
                            alignContent={'center'}
                            justifyContent="center"
                        >
                            <Img
                                opacity={activeImage === index ? 0.8 : 0.4}
                                cursor={'pointer'}
                                objectFit="cover"
                                h="100%"
                                w="100%"
                                src={image.dataURL}
                                onClick={(): void => setActiveImage(index)}
                                alt={`assetImage${index}`}
                            />
                            <Flex
                                className="image-item__btn-wrapper"
                                position={'absolute'}
                                bottom={4}
                                right={'25%'}
                                gap={2}
                                margin="auto"
                            >
                                <IconButton
                                    aria-label="Update Image"
                                    icon={<RepeatIcon />}
                                    onClick={(): void => onImageUpdate(index)}
                                />
                                <IconButton
                                    aria-label="Remove Image"
                                    icon={<DeleteIcon />}
                                    onClick={(): void => {
                                        if (image.isOld) {
                                            generalDeleteAPI(
                                                `${API_ENDPOINTS.document}/${image.id}`
                                            )
                                        }
                                        onImageRemove(index)
                                    }}
                                />
                            </Flex>
                        </Box>
                    ))}
                </Flex>
            </GenericBox>
        )
    }

    function uploadImageList(): void {
        images.forEach(async (image) => {
            const formData = new FormData()
            if (!image.isOld) {
                image.file && formData.append('file', image.file)

                const uploadRequest = await generalFilePostAPI(
                    API_ENDPOINTS.documentByAssetImages(
                        baseAsset?.baseAssetNumber || ''
                    ),
                    formData
                )

                if (!uploadRequest.isOk) {
                    toast(baseErrorToastOptions(uploadRequest.message))
                }
            }
        })
    }

    return (
        <Box w="100%" h="100%">
            <ImageUploading
                multiple
                allowNonImageType={false}
                acceptType={['png', 'jpg', 'svg', 'jpeg']}
                value={[...images]}
                onChange={onChange}
                maxNumber={MAX_NUMBER_IMAGES}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    dragProps,
                }): ReactElement => (
                    <Box className="upload__image-wrapper">
                        {RenderImagePreview(
                            imageList,
                            onImageUpload,
                            dragProps
                        )}

                        {imageList.length > 0 && (
                            <Flex
                                direction={'column'}
                                mt={6}
                                width={'100%'}
                                justifyContent={'start'}
                                gap={2}
                            >
                                {RenderImageThumbnailsAndControls(
                                    imageList,
                                    onImageUpdate,
                                    onImageRemove
                                )}
                                <Button onClick={uploadImageList} size="md">
                                    {translate('upload')}
                                </Button>
                            </Flex>
                        )}
                    </Box>
                )}
            </ImageUploading>
        </Box>
    )
}
