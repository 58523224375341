import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
/* eslint-disable react/jsx-key */
import { MutableRefObject, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Checkbox, SimpleGrid, Text } from '@chakra-ui/react'

import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../hoc/modal.hoc'
import { useService } from '../../../services/contexts/Service.context'
import {
    GetVatDetail,
    ProductDTO,
    UpdateBaseTransientFeeCommand,
} from '../../../utils/types/types'
import { EditTransientFeeFormFields } from './TransientFees.config'

interface EditTransientFeeInterface extends ModalSharedProps {
    handleSave(transientFee: UpdateBaseTransientFeeCommand): void
    products: ProductDTO[]
    VAT: GetVatDetail[]
    editTransientFee: UpdateBaseTransientFeeCommand
}

function EditTransientFee({
    handleSave,
    products,
    VAT,
    editTransientFee,
}: EditTransientFeeInterface): ReactElement {
    const translate = useTranslation().t
    const { ERPProducts } = useService()
    const [transientFee, setTransientFee] =
        useState<UpdateBaseTransientFeeCommand>(editTransientFee)
    const [isLoading, setLoading] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    const updateProductTransientFees = (
        productId: number,
        isSelectable: boolean
    ) => {
        setSubmitting(false)
        setTransientFee({
            ...transientFee,
            productTransientFees: transientFee.productTransientFees.map(
                (elem) => {
                    if (elem.productId === productId) {
                        return { ...elem, isSelectable }
                    }
                    return elem
                }
            ),
        })
    }

    return (
        <Box p={2}>
            <DynamicForm<UpdateBaseTransientFeeCommand>
                data={transientFee}
                formFields={EditTransientFeeFormFields(
                    transientFee,
                    ERPProducts,
                    VAT
                )}
                columns={1}
                onFormChange={(data: UpdateBaseTransientFeeCommand): void => {
                    setTransientFee({
                        ...data,
                        productTransientFees: transientFee.productTransientFees,
                    })
                }}
                onSubmit={(data: UpdateBaseTransientFeeCommand): void => {
                    setLoading(true)
                    handleSave({
                        ...data,
                        productTransientFees: transientFee.productTransientFees,
                    })
                    setLoading(false)
                }}
                hideSubmit
                formSubmitReference={formSubmitReference}
            />
            <Text fontWeight={'medium'}>{translate('contractTemplate')}</Text>
            <Box overflowY={'auto'}>
                <SimpleGrid columns={[1]} mt={'2'} maxHeight={'sm'}>
                    {sortBy(transientFee.productTransientFees, 'id')?.map(
                        (product) => {
                            const foundProduct = find(products, {
                                id: product.productId,
                            })
                            return (
                                <Box my={2} key={product.productId}>
                                    <Checkbox
                                        key={product.productId}
                                        isChecked={product.isSelectable}
                                        onChange={(event: any) => {
                                            updateProductTransientFees(
                                                product.productId,
                                                event.target.checked
                                            )
                                        }}
                                    >
                                        {foundProduct?.name}
                                    </Checkbox>
                                </Box>
                            )
                        }
                    )}
                </SimpleGrid>
            </Box>
            <Button
                float={'right'}
                mt={'4'}
                onClick={() => {
                    if (formSubmitReference) {
                        formSubmitReference.current?.click()
                        setSubmitting(true)
                    }
                }}
                isLoading={isLoading || isSubmitting}
            >
                {translate('submit')}
            </Button>
        </Box>
    )
}

export default withModalHOC<EditTransientFeeInterface>(EditTransientFee)
