import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react'

import { useService } from '../../../../services/contexts/Service.context'
import FileUpload from '../../../fileUpload/FileUpload.component'

function ServiceInfoComponent() {
    const { service, VATCodes, isEditingMode, handleServiceUpdate } =
        useService()
    const translate = useTranslation().t

    const handleVATSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const SelectedVAT = VATCodes.find(
            (VATCode) => VATCode.id === parseInt(event.target.value)
        )
        if (!SelectedVAT) return
        handleServiceUpdate({
            vatId: SelectedVAT.id,
            vatPercentage: SelectedVAT.percentage,
        })
    }
    return (
        <Box mt={8}>
            <Flex flexDirection={'column'} gap={4}>
                <FormControl>
                    <FormLabel>{translate('description')}</FormLabel>
                    <Input
                        value={service?.description ?? ''}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            if (isEditingMode) return
                            handleServiceUpdate({
                                description: event.target.value,
                            })
                        }}
                        isDisabled={isEditingMode}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('selectVATCode')}</FormLabel>

                    <Select value={service?.vatId} onChange={handleVATSelect}>
                        <option value={0}>{translate('selectVATCode')}</option>
                        {VATCodes.map((VATCode, i) => (
                            <option key={i} value={VATCode.id}>
                                {VATCode.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('contractText')}</FormLabel>
                    <Input
                        value={service?.contractText ?? ''}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            handleServiceUpdate({
                                contractText: event.target.value,
                            })
                        }}
                    />
                </FormControl>
                <FileUpload
                    handleSubmission={(data: any) => {
                        console.log('file upload', data)
                    }}
                />
            </Flex>
        </Box>
    )
}

export default ServiceInfoComponent
