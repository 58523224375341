import { useTranslation } from 'react-i18next'

import { Box, Button, Flex } from '@chakra-ui/react'

import withModalHOC from '../../hoc/modal.hoc'
import { useSigners } from '../../services/contexts/Signers.context'
import { SignerDto } from '../../utils/types/types'
import SignersForm from './Signers.form'
import SignersTable from './Signers.table'

function SignerContainer(): JSX.Element {
    const {
        selectedSigners,
        signerFormItem,
        isSignersFormOpen,
        isSignersLoading,
        isSentToSignersLoading,
        signatureSystem,
        handleSignerChangeRequest,
        handleSignersSentRequest,
        closeSignersForm,
    } = useSigners()
    const translate = useTranslation().t

    return (
        <Box mb={4}>
            <Flex justifyContent={'flex-end'} mb={4}>
                {!isSignersFormOpen && (
                    <Button
                        isDisabled={isSignersLoading || isSignersFormOpen}
                        onClick={(): void => {
                            handleSignerChangeRequest({} as SignerDto)
                        }}
                    >
                        {translate('add')}
                    </Button>
                )}
            </Flex>

            {!isSignersLoading && isSignersFormOpen && (
                <SignersForm signerItem={signerFormItem} />
            )}

            {!isSignersFormOpen && (
                <SignersTable
                    isLoading={isSignersLoading}
                    signatureSystem={signatureSystem}
                />
            )}
            <Flex justifyContent={'space-between'} mb={4} mt={4}>
                {isSignersFormOpen ? (
                    <Button
                        variant={'ghost'}
                        onClick={(): void => closeSignersForm()}
                    >
                        {translate('back')}
                    </Button>
                ) : (
                    <div></div>
                )}
                <Button
                    isDisabled={
                        isSignersLoading || selectedSigners.length === 0
                    }
                    isLoading={isSentToSignersLoading}
                    onClick={handleSignersSentRequest}
                >
                    {translate('signatureSend')}
                </Button>
            </Flex>
        </Box>
    )
}

export default withModalHOC(SignerContainer)
