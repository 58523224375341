import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useBoolean } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import { IFormField } from '../../../../features/dynamicForm/DynamicForm.interfaces'
import { IAssetBalance } from '../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    getFirstDayOfTheMonth,
    getLastDayOfTheMonth,
    offsetTimeZoneDifference,
    standaloneToast,
} from '../../../../utils/functions.utils'
import AssetReportTableComponent from './AssetReportTable.component'

export default function AssetReportPage(): ReactElement {
    const translate = useTranslation().t
    const [assetBalanceReport, setAssetBalanceReport] = useState([])
    const [requestLoading, setRequestLoading] = useBoolean(false)
    const getAssetBalanceReport = async (
        assetBalance: IAssetBalance
    ): Promise<void> => {
        setRequestLoading.on()
        const response = await generalPostAPI(
            API_ENDPOINTS.assetBalanceReport,
            {
                assetNumber: assetBalance.assetNumber,
                periodEndLow: offsetTimeZoneDifference(
                    new Date(assetBalance.startDate)
                ).toISOString(),
                periodEndHigh: offsetTimeZoneDifference(
                    new Date(assetBalance.endDate)
                ).toISOString(),
            }
        )
        setRequestLoading.off()
        if (response.isOk) {
            standaloneToast(baseSuccessToastOptions(response.message))
            setAssetBalanceReport(response.data)
        } else {
            standaloneToast(baseErrorToastOptions(response.message))
        }
    }

    const assetReportForm: IFormField<IAssetBalance>[] = [
        {
            slug: 'assetNumber',
            label: 'assetNumber',
            inputProps: { type: 'text' },
        },
        {
            slug: 'startDate',
            label: 'startDate',
            inputProps: { type: 'date' },
            value: getFirstDayOfTheMonth().toISOString(),
        },
        {
            slug: 'endDate',
            label: 'endDate',
            inputProps: { type: 'date' },
            value: getLastDayOfTheMonth().toISOString(),
        },
    ]

    const defaultAssetBalance = {
        assetNumber: '',
        startDate: getFirstDayOfTheMonth(),
        endDate: getLastDayOfTheMonth(),
    }

    return (
        <Box data-testid="asset-report-page">
            <Box w={'100%'} p={4}>
                <DynamicForm
                    hideSubmit={false}
                    onSubmit={(assetBalance: IAssetBalance): void => {
                        getAssetBalanceReport(assetBalance)
                    }}
                    data={defaultAssetBalance}
                    formFields={assetReportForm}
                    submitText={translate('apply') || ''}
                    submitButtonProps={{
                        float: 'right',
                        isLoading: requestLoading,
                    }}
                    columns={[1, 1, 2, 2, 3, 3]}
                />
            </Box>
            <Box>
                <AssetReportTableComponent
                    isLoading={requestLoading}
                    items={assetBalanceReport}
                />
            </Box>
        </Box>
    )
}
