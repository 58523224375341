import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'

interface ILoadingContext {
    isGlobalLoading: boolean
    globalLoading(): number
    stopGlobalLoading: (loadingId: number) => void
}

interface ILoadingContextProviderProps {
    children: ReactNode
}

export const loadingContext = createContext({} as ILoadingContext)

export const useLoading = (): ILoadingContext => useContext(loadingContext)

const useProviderLoading = (): ILoadingContext => {
    const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false)
    const [globalLoadingItems, setGlobalLoadingItems] = useState<number[]>([])

    function globalLoading(): number {
        const loadingId = Date.now()
        setGlobalLoadingItems([...globalLoadingItems, loadingId])
        setIsGlobalLoading(true)
        return loadingId
    }

    function stopGlobalLoading(loadingId: number): void {
        setTimeout((): void => {
            const filteredItems = globalLoadingItems.filter(
                (item) => item !== loadingId
            )
            setIsGlobalLoading(filteredItems.length > 0)
            setGlobalLoadingItems(filteredItems)
        }, 250)
    }

    return {
        isGlobalLoading,
        globalLoading,
        stopGlobalLoading,
    }
}

export const LoadingContextProvider = ({
    children,
}: ILoadingContextProviderProps): ReactElement => {
    const loading: ILoadingContext = useProviderLoading()

    return (
        <loadingContext.Provider value={loading}>
            {children}
        </loadingContext.Provider>
    )
}
