import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
    icon: {
        color: 'primary.900',
        _dark: {
            color: 'white',
        },
    },

    field: {
        border: '1px solid',
        borderColor: 'gray.200',
        background: 'white',
        borderRadius: '.25rem',
        color: 'black',

        _dark: {
            borderColor: 'secondary.500',
            background: 'secondary.850',
            color: 'white',
        },
    },
    addon: {
        border: '1px solid',
        borderColor: 'gray.200',
        background: 'white',
        borderRadius: '4px',
        color: 'primary.900',

        _dark: {
            borderColor: 'secondary.500',
            background: 'secondary.850',
            color: 'white',
        },
    },
})

export const selectTheme = defineMultiStyleConfig({ baseStyle })
