import ContractDetailWrapper from '../../pages/contractDetail/ContractDetail.page'
import ContractsPage from '../../pages/contractList/Contracts.page'
import CustomerDetailsWithContext from '../../pages/customerDetails/CustomerDetails.page'
import CustomersPage from '../../pages/customerList/Customers.page'
import DashboardPage from '../../pages/dashboard/Dashboard.page'
import FinancialPage from '../../pages/financial/Financial.page'
import InventoryDetailPage from '../../pages/inventoryDetail/InventoryDetail.page'
import InventoryPage from '../../pages/inventoryList/Inventory.page'
import OfferPageWithContext from '../../pages/offer/Offer.page'
import ProfilePage from '../../pages/profile/Profile.page'
import SetupPage from '../../pages/setup/Setup.page'
import { ExternalPartnerSetup, UserGroup } from '../../utils/types/types'
import FINANCIAL_ROUTES from './financial.routes'
import SETUP_ROUTES from './setup.routes'

export interface IBaseRoute {
    path: string
    Component: any
    breadcrumb?: string[]
    breadcrumbName?: string
    translateKey?: string
    subRoutes?: IBaseRoute[]
    isUnauthorized?: boolean
    hideFromMenu?: boolean
}

const BASE_ROUTES = (
    userGroup: UserGroup,
    activeSetup: ExternalPartnerSetup
): IBaseRoute[] => [
    {
        path: '/',
        Component: ContractsPage,
        breadcrumb: ['home'],
        breadcrumbName: 'home',
        translateKey: 'home',
    },
    {
        path: '/profile',
        Component: ProfilePage,
        breadcrumb: ['profile'],
        breadcrumbName: 'profile',
        translateKey: 'profile',
    },
    {
        path: '/contracts',
        Component: ContractsPage,
        breadcrumb: ['contracts'],
        breadcrumbName: 'contracts',
        translateKey: 'contracts',
    },
    {
        path: '/contracts/:id/*',
        Component: ContractDetailWrapper,
        breadcrumb: ['contracts'],
        breadcrumbName: 'contract',
        translateKey: 'contract',
    },
    {
        path: '/inventory',
        Component: InventoryPage,
    },
    {
        path: 'inventory/:id',
        Component: InventoryDetailPage,
    },
    {
        path: '/setup/*',
        Component: SetupPage,
        breadcrumb: ['setup'],
        breadcrumbName: 'setup',
        translateKey: 'setup',
        subRoutes: [...SETUP_ROUTES],
        isUnauthorized: userGroup <= UserGroup.RegularUser,
    },
    {
        path: '/financial/*',
        Component: FinancialPage,
        breadcrumb: ['financial'],
        breadcrumbName: 'financial',
        translateKey: 'financial',
        subRoutes: [...FINANCIAL_ROUTES(activeSetup)],
        isUnauthorized: userGroup <= UserGroup.RegularUser,
    },
    {
        path: '/new-offer',
        Component: OfferPageWithContext,
        breadcrumb: ['new-offer'],
        breadcrumbName: 'new-offer',
        translateKey: 'newOffer',
    },
    {
        path: '/customers',
        Component: CustomersPage,
        breadcrumb: ['customers'],
        breadcrumbName: 'customers',
        translateKey: 'customers',
    },
    {
        path: '/customers/:customerNumber',
        Component: CustomerDetailsWithContext,
        breadcrumb: ['customers'],
        breadcrumbName: 'edit',
        translateKey: 'edit',
    },
    {
        path: '/dashboard',
        Component: DashboardPage,
        breadcrumb: ['dashboard'],
        breadcrumbName: 'dashboard',
        translateKey: 'dashboard',
    },
]

export default BASE_ROUTES
