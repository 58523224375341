import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Select,
    Spinner,
    Switch,
} from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useProduct } from '../../../../../services/contexts/Product.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import { DEFAULT_ASSET } from '../../../../../utils/constants.utils'
import {
    baseErrorToastOptions,
    deepCopy,
    standaloneToast,
} from '../../../../../utils/functions.utils'
import { ContractAssetDTO, ContractDTO } from '../../../../../utils/types/types'
import {
    ASSET_COPY_FIELDS,
    CONTRACT_COPY_FIELDS,
    DEFAULT_CONTRACT_POST,
} from './CopyContract.config'

function CopyContractComponent(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const { contract } = useContractService()
    const [productId, setProductId] = useState<number>(contract.productId ?? 0)
    const { products, isLoading } = useProduct()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const navigation = useNavigate()
    const [shouldGenerateNewAssets, setShouldGenerateNewAssets] =
        useState<boolean>(false)

    const handleToggleSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
        const {
            target: { checked },
        } = event
        setShouldGenerateNewAssets(checked)
    }

    const handleCopyContract = async (): Promise<void> => {
        if (!contract || !productId) return

        const loadingID = globalLoading()

        const contractDetails: Partial<ContractDTO> = {}
        const contractAssets: Partial<ContractAssetDTO[]> = []

        CONTRACT_COPY_FIELDS.forEach((key: string) => {
            contractDetails[key] = contract[key]
        })

        contract.contractAssets?.forEach((contractAsset: any) => {
            const newAsset = deepCopy(DEFAULT_ASSET)
            newAsset.assetNumber = ''
            newAsset.baseAssetNumber = ''
            const copyAssetFields = [...ASSET_COPY_FIELDS]
            if (!shouldGenerateNewAssets) {
                copyAssetFields.push('baseAssetNumber')
            }
            copyAssetFields.forEach((key: string) => {
                newAsset[key] = contractAsset[key]
            })
            contractAssets.push(newAsset)
        })

        const newContract = {
            ...deepCopy(DEFAULT_CONTRACT_POST),
            ...contractDetails,
            productId,
            contractAssets,
        }
        props.onClose()

        const request = await generalPostAPI(
            API_ENDPOINTS.contract,
            newContract
        )
        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(request.message))
            return
        }

        navigation(`/contracts/${request.data.contract.contractNumber}`)
        stopGlobalLoading(loadingID)
    }

    return (
        <Box>
            <FormControl mb={3}>
                <FormLabel>{translate('selectProduct')}</FormLabel>
                {isLoading ? (
                    <Spinner size={'md'} />
                ) : (
                    <Select
                        onChange={(
                            event: ChangeEvent<HTMLSelectElement>
                        ): void => {
                            const selectedProductId = parseInt(
                                event.target.value
                            )
                            setProductId(selectedProductId)
                        }}
                        value={productId}
                        placeholder={translate('selectProduct')}
                    >
                        {products.map((product, key) => (
                            <option key={key} value={product.id}>
                                {product!.name}
                            </option>
                        ))}
                    </Select>
                )}
            </FormControl>
            <FormControl mb={3}>
                <FormLabel>{translate('generateNewAssets')}</FormLabel>
                <Switch
                    isChecked={shouldGenerateNewAssets}
                    onChange={handleToggleSwitch}
                />
            </FormControl>
            <HStack
                mt={4}
                w={'100%'}
                display={'flex'}
                justifyContent={'flex-end'}
            >
                <Button
                    isDisabled={!productId || isLoading}
                    onClick={handleCopyContract}
                    textTransform={'capitalize'}
                >
                    {translate('copyContract')}
                </Button>
            </HStack>
        </Box>
    )
}

export default withModalHOC(CopyContractComponent)
