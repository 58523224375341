import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    // define the part you're going to style
    // container: {
    //     // ...
    // },
    // thumb: {
    //     bg: 'red.500',
    // },
    track: {
        bg: mode('gray.200', 'gray.800')(props),
        _checked: {
            bg: mode('primary.500', 'secondary.450')(props),
        },
    },
}))

export const switchTheme = defineMultiStyleConfig({ baseStyle })
