import camelCase from 'lodash/camelCase'
import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Button, IconButton, Tooltip, useToast } from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import PopupActionButton, {
    ButtonVariant,
} from '../../../features/popupActionButton/PopupActionButton.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import { CountryCode, LocationDTO } from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import { FORM_FIELDS_LOCATION } from './Locations.config'

interface ILocationsPage {
    isModal?: boolean
    customerNumber?: string
}

export default function LocationsPage({
    isModal = false,
    customerNumber,
}: ILocationsPage): ReactElement {
    const [selectedLocation, setSelectedLocation] =
        useState<LocationDTO | null>(null)
    const [locations, setLocations] = useState<LocationDTO[]>()
    const translate = useTranslation().t
    const toast = useToast()
    const actionButtonRef = useRef<any>()
    const popupCloseReference = useRef<any>()
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    const fetchLocations = async (): Promise<void> => {
        const endpoint = customerNumber
            ? `${
                  API_ENDPOINTS.location
              }?customerNumber=${customerNumber}&locationType=${
                  customerNumber ? 2 : 1
              }`
            : `${API_ENDPOINTS.location}?locationType=${customerNumber ? 2 : 1}`

        const response = await generalGetAPI(endpoint)
        if (!response.isOk) return
        setLocations(response.data)
    }

    const handleDeleteLocation = async (data: LocationDTO): Promise<void> => {
        const deletion = await generalDeleteAPI(
            `${API_ENDPOINTS.location}/${data.locationNumber}`
        )
        if (deletion.isOk) {
            toast(baseSuccessToastOptions(translate('locationRemoved')))
            fetchLocations()
        } else {
            toast(baseErrorToastOptions(translate('apiFail')))
        }
    }

    const handleEditLocation = (data: LocationDTO): void => {
        setSelectedLocation(data)
        actionButtonRef?.current?.click()
    }

    const handleAddLocation = (): void => {
        setSelectedLocation(null)
        actionButtonRef?.current?.click()
    }

    const addLocation = async (data: LocationDTO): Promise<void> => {
        const location = {
            ...data,
            latitude: 0,
            longitude: 0,
            locationNumber: null,
            locationType: customerNumber ? 2 : 1,
        }
        if (customerNumber) location.customerNumber = customerNumber
        popupCloseReference?.current?.click()
        const record = await generalPostAPI(API_ENDPOINTS.location, location)
        if (record.isOk) {
            toast(baseSuccessToastOptions(translate('locationAdded')))
            fetchLocations()
        } else {
            toast(baseErrorToastOptions(translate('apiFail')))
        }
    }

    const updateLocation = async (data: LocationDTO): Promise<void> => {
        popupCloseReference?.current?.click()
        const record = await generalPutAPI(
            `${API_ENDPOINTS.location}/${data.locationNumber}`,
            data
        )
        if (record.isOk) {
            toast(baseSuccessToastOptions(translate('locationUpdated')))
            fetchLocations()
        } else {
            toast(baseErrorToastOptions(translate('apiFail')))
        }
    }

    function popupConfirmEvent(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    useEffect((): void => {
        fetchLocations()
    }, [])

    const columns = [
        {
            field: 'locationNumber',
            headerName: translate('locationNumber'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'locationName',
            headerName: translate('locationName'),

            filter: 'agTextColumnFilter',
        },
        {
            field: 'countryCode',
            headerName: translate('countryCode'),
            filter: 'agTextColumnFilter',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof CountryCode>(
                            CountryCode,
                            params.data.countryCode
                        )
                    )
                ),
        },
        {
            field: 'city',
            headerName: translate('city'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'street',
            headerName: translate('street'),

            filter: 'agTextColumnFilter',
        },
        {
            field: 'building',
            headerName: translate('building'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'floor',
            headerName: translate('floor'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'postalCode',
            headerName: translate('postalCode'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'contactName',
            headerName: translate('contactName'),

            filter: 'agTextColumnFilter',
        },
        {
            field: 'phone',
            headerName: translate('phone'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'latitude',
            headerName: translate('latitude'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'longitude',
            headerName: translate('longitude'),
            filter: 'agTextColumnFilter',
        },
        {
            field: 'customerNumber',
            headerName: translate('customerNumber'),
            filter: 'agTextColumnFilter',
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <>
                        <Tooltip
                            label={translate('edit')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                mr={2}
                                aria-label="Edit"
                                size="sm"
                                variant={'outline'}
                                onClick={(): void =>
                                    handleEditLocation(params.data)
                                }
                                icon={<EditIcon />}
                            />
                        </Tooltip>

                        <Tooltip
                            label={translate('delete')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                aria-label="delete"
                                size="sm"
                                variant={'outline'}
                                onClick={(): Promise<void> =>
                                    handleDeleteLocation(params.data)
                                }
                                icon={<DeleteIcon />}
                            />
                        </Tooltip>
                    </>
                ),
                100
            ),
        },
    ]

    function renderLocationContent(): ReactElement {
        return (
            <>
                <DynamicGrid
                    tableId="locationsPageTable"
                    boxProps={{ mt: '1rem' }}
                    columns={columns}
                    rowData={locations}
                    pagination={true}
                    rowMultiSelectWithClick={false}
                    headers={
                        <Button onClick={handleAddLocation}>
                            {translate('addRow')}
                        </Button>
                    }
                />

                <PopupActionButton
                    title={
                        selectedLocation
                            ? translate('editLocation')
                            : translate('addLocation')
                    }
                    content={
                        <DynamicForm<Partial<LocationDTO>>
                            formSubmitReference={formSubmitReference}
                            data={selectedLocation || {}}
                            formFields={FORM_FIELDS_LOCATION}
                            hideSubmit={false}
                            onSubmit={
                                selectedLocation ? updateLocation : addLocation
                            }
                            submitButtonProps={{ width: '100%' }}
                            columns={[2]}
                        />
                    }
                    onConfirm={popupConfirmEvent}
                    isModal={true}
                    buttonVariant={ButtonVariant.SOLID}
                    actionName={'hidden'}
                    buttonProps={{ display: 'none' }}
                    buttonRef={actionButtonRef}
                    popupCloseReference={popupCloseReference}
                    hasActions={false}
                />
            </>
        )
    }

    return !isModal ? (
        <PageContainer>{renderLocationContent()}</PageContainer>
    ) : (
        renderLocationContent()
    )
}
