import { ReactElement } from 'react'

import { Button, Flex, Heading, useColorModeValue } from '@chakra-ui/react'

export default function CustomFallbackComponent({
    error,
    componentStack,
    resetErrorBoundary,
}: any): ReactElement {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        componentStack && console.error(componentStack)
    }
    return (
        <Flex
            w="100%"
            borderRadius={4}
            bg={useColorModeValue('primary.50', 'secondary.700')}
            my={16}
            p={8}
            alignItems={'center'}
            flexDirection={'column'}
        >
            <Heading size={'sm'}>An error occurred: {error.message}</Heading>
            <Button onClick={resetErrorBoundary} mt={8}>
                Try again
            </Button>
        </Flex>
    )
}
