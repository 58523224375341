import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tooltip,
    useBoolean,
} from '@chakra-ui/react'

import ErrorBoundaryWrapper from '../../../../../features/errorBoundary/ErrorBoundary.feature'
import AssetServicesTable from '../../../../../features/genericTables/assetServicesTable/AssetServicesTable.component'
import { useBaseServicesContext } from '../../../../../services/contexts/BaseServices.context'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import { CustomServiceType } from '../../../../../services/contract/Contract.services.config'
import {
    ContractAssetDTO,
    ContractStatus,
} from '../../../../../utils/types/types'
import AddContractAssetService from './AddContractAssetService.component'
import { updateContractAssetService } from './ContractAssetServices.api'
import { matchAssetServices } from './ContractAssetServices.config'
import EditContractAssetService from './EditContractAssetService.component'

interface IActivateAsset {
    assetItem: ContractAssetDTO
    isOffer?: boolean
    isOpen: boolean
    onClose: () => void
    onConfirmResult: (localAsset: ContractAssetDTO) => void
    setSelectedAsset?: Function
}

export default function AssetServicesPopup({
    assetItem,
    isOpen,
    onClose = (): void => {},
    onConfirmResult,
    setSelectedAsset = (): void => {},
}: IActivateAsset): ReactElement {
    const { baseServices } = useBaseServicesContext()
    const { actionsAllowed, contract, getContract } = useContractService()
    const [isEditAssetServiceOpen, editAssetServiceOpen] = useBoolean(false)
    const { globalLoading, stopGlobalLoading } = useLoading()
    const translate = useTranslation().t
    const [tabIndex, setTabIndex] = useState(
        assetItem?.assetServices?.length === 0 ? 1 : 0
    )
    const [selectedService, setSelectedService] = useState<CustomServiceType>()
    const [matchedServices, setMatchedServices] = useState<CustomServiceType[]>(
        []
    )
    const [localAsset, setLocalAsset] = useState<ContractAssetDTO>(assetItem)

    useEffect(() => {
        if (
            contract?.status === ContractStatus.Active ||
            actionsAllowed?.canStartManually
        ) {
            setTabIndex(0)
        }
    }, [contract?.status, JSON.stringify(actionsAllowed)])

    function handleTabsChange(index: number): void {
        setTabIndex(index)
    }

    function handleEditService(data: CustomServiceType): void {
        setSelectedService(data)
        editAssetServiceOpen.on()
    }

    async function handleDeleteService(data: CustomServiceType): Promise<void> {
        const loadingID = globalLoading()
        const filteredServices =
            matchedServices.filter(
                (item) => item.baseServiceNumber !== data.baseServiceNumber
            ) || []
        const selectedAsset = {
            ...localAsset,
            assetServices: [...filteredServices],
        }
        setSelectedAsset(null)
        setSelectedAsset(selectedAsset)

        if (
            localAsset?.assetNumber &&
            !localAsset?.assetNumber.includes('tmp')
        ) {
            await updateContractAssetService(
                localAsset.assetNumber,
                [...filteredServices],
                () => getContract()
            )
        }

        setMatchedServices(filteredServices)
        stopGlobalLoading(loadingID)
    }

    function goToFirstTab(): void {
        setTabIndex(0)
    }

    useEffect(() => {
        if (baseServices) {
            setSelectedAsset(localAsset)
            setMatchedServices(
                matchAssetServices(
                    localAsset?.assetServices ?? [],
                    baseServices
                )
            )
        }
    }, [
        JSON.stringify(selectedService),
        JSON.stringify(localAsset?.assetServices),
        JSON.stringify(baseServices),
    ])

    const getTableActions = (data: any) => {
        const isDisabled =
            actionsAllowed?.canStartManually ||
            contract.status === ContractStatus.Active
        return (
            <>
                <Tooltip label={translate('edit')} placement="top" hasArrow>
                    <IconButton
                        aria-label="Edit"
                        size="sm"
                        isDisabled={isDisabled}
                        variant={'outline'}
                        onClick={(): void => {
                            handleEditService(data)
                        }}
                        icon={<EditIcon />}
                    />
                </Tooltip>
                <Tooltip label={translate('delete')} placement="top" hasArrow>
                    <IconButton
                        aria-label="Delete"
                        size="sm"
                        isDisabled={isDisabled}
                        variant={'outline'}
                        onClick={(): void => {
                            handleDeleteService(data)
                        }}
                        icon={<DeleteIcon />}
                    />
                </Tooltip>
            </>
        )
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody p={8}>
                    <ErrorBoundaryWrapper id="contract-asset-services-error">
                        <Tabs
                            index={tabIndex}
                            onChange={handleTabsChange}
                            isLazy
                        >
                            <TabList>
                                <Tab>{translate('services')}</Tab>
                                <Tab
                                    isDisabled={
                                        contract?.isLocked ||
                                        contract?.status ===
                                            ContractStatus.Active ||
                                        actionsAllowed?.canStartManually
                                    }
                                >
                                    {translate('addService')}
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={0}>
                                    {matchedServices.length > 0 ? (
                                        <Box pt={4}>
                                            <Stack spacing={8}>
                                                <AssetServicesTable
                                                    services={matchedServices}
                                                    tableActions={
                                                        getTableActions
                                                    }
                                                />
                                            </Stack>
                                            {selectedService &&
                                                isEditAssetServiceOpen && (
                                                    <EditContractAssetService
                                                        asset={localAsset}
                                                        service={
                                                            selectedService
                                                        }
                                                        onClose={
                                                            editAssetServiceOpen.off
                                                        }
                                                        setSelectedAsset={
                                                            setLocalAsset
                                                        }
                                                    />
                                                )}
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </TabPanel>
                                <TabPanel p={0}>
                                    <Box pt={4}>
                                        <AddContractAssetService
                                            productId={contract.productId}
                                            onAdd={goToFirstTab}
                                            asset={localAsset}
                                            setSelectedAsset={setLocalAsset}
                                        />
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ErrorBoundaryWrapper>
                </ModalBody>

                <ModalFooter gap={4} borderTop={'1px solid lightgrey'}>
                    <Button onClick={onClose} variant={'solid'}>
                        {translate('cancel')}
                    </Button>
                    <Button
                        leftIcon={<CheckIcon />}
                        mr={3}
                        onClick={(): void => {
                            onConfirmResult(localAsset)
                            onClose()
                        }}
                    >
                        {translate('ok')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
