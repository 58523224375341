import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import { Button, Flex, Text, useToast } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC from '../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import { PaymentType } from '../../../../utils/types/types'
import { MatchInterestFormFields } from './MatchInterest.config'

interface IMatchInterest {
    isModalOpen: boolean
    maxWidth?: string
    modalTitle: String
    onClose: Function
}

function MatchInterest(props: IMatchInterest): ReactElement {
    const [formKey, setFormKey] = useState(v4())
    const [data, setData] = useState<any>()
    const { product, contract, financing, saveContract } = useContractService()
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const [calculatedInterest, setCalculatedInterest] = useState<number | null>(
        null
    )
    const { isGlobalLoading, globalLoading, stopGlobalLoading } = useLoading()

    const translate = useTranslation().t
    const toast = useToast()

    const matchInterest = async (matchInterestData: any): Promise<void> => {
        if (!product) return

        const loadingID = globalLoading()
        const response = await generalPostAPI(API_ENDPOINTS.matchInterest, {
            ...matchInterestData,
            recurrence: product.recurrence,
            periodicPayment: matchInterestData.periodicPayment, // TODO: change matchInterestData.newMonthlyPayment to matchInterestData.monthlyPayment when BE is done
        })
        if (response?.isOk) {
            setCalculatedInterest(response.data)
        } else {
            toast(baseErrorToastOptions(response?.message))
        }
        stopGlobalLoading(loadingID)
    }

    const applyInterest = async (): Promise<void> => {
        const loadingID = globalLoading()
        await saveContract({ ...contract, interest: calculatedInterest })
        props.onClose()
        setTimeout(() => {
            stopGlobalLoading(loadingID)
            window.location.reload()
        }, 1000)
    }

    useEffect(() => {
        setData({
            interest: contract?.interest ?? 0,
            leasingPeriod: contract?.leasingPeriod ?? 0,
            paymentType: contract?.paymentType ?? PaymentType.BeginningOfPeriod,
            periodicPayment: financing?.totalPeriodicPayment ?? 0,
            totalPrincipal: financing?.totalPrincipal ?? 0,
            totalRestValue: financing?.totalRestValue ?? 0,
        })
        setFormKey(v4())
    }, [JSON.stringify(financing)])

    return (
        <>
            <DynamicForm
                key={formKey}
                columns={2}
                data={data}
                formFields={MatchInterestFormFields(financing, contract)}
                formSubmitReference={formSubmitReference}
                hideSubmit
                onSubmit={matchInterest}
                submitButtonProps={{ isLoading: isGlobalLoading }}
                submitText={'MatchOffer'}
            />
            <Flex my={6} justifyContent={'space-between'} alignItems="baseline">
                <Button
                    onClick={() => {
                        if (formSubmitReference) {
                            formSubmitReference.current?.click()
                        }
                    }}
                    size={'md'}
                >
                    {translate('calculateInterest')}
                </Button>
                <Text
                    data-testid="calculated-interest"
                    fontSize="4xl"
                    fontWeight={'medium'}
                >
                    {calculatedInterest
                        ? `${calculatedInterest.toFixed(2)} %`
                        : '_.__ %'}
                </Text>
            </Flex>
            <Button
                isDisabled={!calculatedInterest}
                mb={2}
                mt={6}
                onClick={applyInterest}
                size={'lg'}
                width={'100%'}
            >
                {translate('applyInterest')}
            </Button>
        </>
    )
}

export default withModalHOC(MatchInterest)
