import pretty from 'pretty'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon, CloseIcon, CopyIcon, EditIcon } from '@chakra-ui/icons'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { html } from '@codemirror/lang-html'
import { githubLight } from '@uiw/codemirror-theme-github'
import CodeMirror from '@uiw/react-codemirror'

import { baseSuccessToastOptions } from '../../utils/functions.utils'
import GenericBox from '../genericBox/genericBox'

interface ICodeEditor {
    initialCode: string
    onSave: (code: string) => void
    onCancel?: () => void
    isDisabled?: boolean
    height: string
}

const CodeEditor: React.FC<ICodeEditor> = ({
    initialCode,
    onSave,
    onCancel,
    isDisabled = false,
    height,
}) => {
    const toast = useToast()
    const [isEditing, setIsEditing] = useState(false)
    const [code, setCode] = useState(initialCode)
    const translate = useTranslation().t

    const handleSave = (): void => {
        onSave(code)
        setIsEditing(false)
    }

    const handleCancel = (): void => {
        onCancel && onCancel()
        setIsEditing(false)
    }

    useEffect(() => {
        setCode(pretty(initialCode))
        setIsEditing(false)
    }, [initialCode])

    return (
        <GenericBox>
            <Flex flexDirection={'column'}>
                <CodeMirror
                    width="100%"
                    editable={isEditing}
                    value={code}
                    height={height}
                    theme={githubLight}
                    extensions={[html()]}
                    onChange={(value: any): void => setCode(value)}
                />

                <Flex
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    color={'gray.200'}
                    borderTop={'1px solid currentColor'}
                >
                    <CopyIcon
                        cursor={'pointer'}
                        onClick={(): void => {
                            navigator.clipboard.writeText(code)
                            toast(
                                baseSuccessToastOptions(
                                    translate('copiedToClipboard')
                                )
                            )
                        }}
                    ></CopyIcon>
                    <Flex gap={2}>
                        {isEditing ? (
                            <>
                                <Button
                                    colorScheme="primary"
                                    size="sm"
                                    onClick={handleCancel}
                                    variant={'outline'}
                                >
                                    <CloseIcon mr={2} />
                                    {translate('cancel')}
                                </Button>
                                <Button
                                    colorScheme="primary"
                                    size="sm"
                                    onClick={handleSave}
                                >
                                    <CheckIcon mr={2} />
                                    {translate('save')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    colorScheme="primary"
                                    size="sm"
                                    width={'fit-content'}
                                    onClick={(): void => setIsEditing(true)}
                                    isDisabled={isDisabled}
                                >
                                    <EditIcon mr={2} />
                                    {translate('edit')}
                                </Button>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </GenericBox>
    )
}

export default CodeEditor
