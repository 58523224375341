import './DatePicker.component.scss'

import { useField } from 'formik'
import { ReactElement } from 'react'

import { Input } from '@chakra-ui/react'

import { offsetTimeZoneDifference } from '../../../../utils/functions.utils'
import { CustomFormikProps } from '../formTextInput/FormTextInput.component'

export default function DatePickerField({
    name,
    ...props
}: CustomFormikProps): ReactElement {
    const [field, , { setValue }] = useField(name)

    return (
        <Input
            onClick={() => {
                const inputDateElement = document.querySelector(
                    `input[type="date"]#${name}`
                ) as any
                inputDateElement.showPicker()
            }}
            id={name}
            type="date"
            width={'100%'}
            className={'datePicker'}
            {...(field as any)}
            {...(props as any)}
            min={
                (props.min &&
                    new Date(props.min).toISOString().split('T')[0]) ||
                undefined
            }
            max={
                (props.max &&
                    new Date(props.max).toISOString().split('T')[0]) ||
                undefined
            }
            value={
                (field.value &&
                    new Date(field.value).toISOString().split('T')[0]) ||
                ''
            }
            onChange={(e: any): void => {
                const val = e?.target?.value
                setValue(offsetTimeZoneDifference(new Date(val)).toISOString())
                if (props.onBlur) {
                    props.onBlur(val)
                }
            }}
        />
    )
}
