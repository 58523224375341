import { ContractAssetDTO, Recurrence } from '../../utils/types/types'

export interface OfferInterface {
    productId: number
    customer: any
    assets: ContractAssetDTO[]
    creationFee: number
    leasingPeriod: number
    periodicPayment: number | null
    recurrence: Recurrence
    interest: number | null
}

export const DEFAULT_OFFER: OfferInterface = {
    productId: 0,
    customer: {},
    assets: [],
    creationFee: 0,
    leasingPeriod: 24,
    periodicPayment: null,
    recurrence: 0,
    interest: null,
}
