import { ReactElement } from 'react'

import PageContainer from '../../components/pageContainer/PageContainer.component'
import ErrorBoundaryWrapper from '../../features/errorBoundary/ErrorBoundary.feature'
import ContractTable from '../../features/genericTables/contractsTable/ContractsTable.component'

function ContractsPage(): ReactElement {
    return (
        <PageContainer>
            <ErrorBoundaryWrapper id="contracts-page-error">
                <ContractTable />
            </ErrorBoundaryWrapper>
        </PageContainer>
    )
}

export default ContractsPage
