import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    Stack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import DocumentsTable from '../../../../features/genericTables/documentsTable/DocumentsTable.component'
import UploadDocumentComponent from '../../../../features/genericTables/documentsTable/UploadDocument.component'
import SignerContainer from '../../../../features/signers/Signers.feature'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalFilePostAPI,
    generalGetAPI,
} from '../../../../services/API/general.api'
import {
    SignersContextProvider,
    useSigners,
} from '../../../../services/contexts/Signers.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
    isAllowedToUpload,
    isFileAlreadyUploaded,
} from '../../../../utils/functions.utils'
import {
    DocumentMetaDataDto,
    ForeignSignatureSystem,
    ProductTemplateDTO,
    UpdateDocumentCommand,
} from '../../../../utils/types/types'
import {
    ACCEPTED_DOCUMENT_TYPES,
    AvailableDocumentType,
    RefreshPenneoStatus,
} from './ContractDetailDocument.config'
import GenerateDocuments from './GenerateDocuments.component'

export const ContractDetailDocuments = (): ReactElement => {
    const { contract, documents, fetchDocuments, updateDocuments } =
        useContractService()
    const toast = useToast()
    const [localLoading, setLocalLoading] = useState<boolean>(false)
    const [isUploadOpen, setUploadOpen] = useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const translate = useTranslation().t
    const {
        isSignersModalOpen,
        signatureSystem,
        loadInitialSignersData,
        closeModal,
        openModal,
        signerDocumentStatuses,
        handleSignersDocumentStatuses,
    } = useSigners()
    const [availableDocumentTypes, setAvailableDocumentTypes] = useState<
        AvailableDocumentType[]
    >([])

    const getProductDocumentTypes = async (
        productId: string
    ): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.product}/${productId}`
        )
        if (response.isOk) {
            setAvailableDocumentTypes(
                (response.data?.templates || [])
                    .filter((template: ProductTemplateDTO) =>
                        ACCEPTED_DOCUMENT_TYPES.includes(template.documentType)
                    )
                    ?.map((template: ProductTemplateDTO) => ({
                        key: template.name,
                        value: template.documentType,
                    }))
            )
        }
        if (!response.isOk) toast(baseErrorToastOptions(response.message))
    }

    useEffect((): void => {
        if (contract?.productId !== undefined) {
            getProductDocumentTypes(String(contract.productId))
        }
    }, [])

    useEffect(() => {
        let isMounted = true
        if (isMounted && contract?.contractNumber) fetchDocuments()

        return () => {
            isMounted = false
        }
    }, [contract?.contractNumber])

    const uploadDocument = async (
        file: any,
        documentToUpload: Partial<UpdateDocumentCommand>
    ): Promise<void> => {
        try {
            setUploadOpen(false)
            setLocalLoading(true)

            if (!file) {
                toast(baseInfoToastOptions(translate('chooseFile')))
            } else if (!isAllowedToUpload(file.name)) {
                toast(baseErrorToastOptions(translate('fileExtNotAllowed')))
            } else if (isFileAlreadyUploaded(file.name, documents)) {
                toast(baseErrorToastOptions(translate('fileAlreadyUploaded')))
            } else {
                const formData = new FormData()
                formData.append('file', file)

                const uploadUrl = `${API_ENDPOINTS.documentByContract}${contract.contractNumber}?DocumentType=${documentToUpload.documentType}&UploadedDocumentStatus=${documentToUpload.documentStatus}`
                const response = await generalFilePostAPI(uploadUrl, formData)
                if (!response.isOk) {
                    toast(baseErrorToastOptions(translate('fileError')))
                } else {
                    fetchDocuments()
                    toast(
                        baseSuccessToastOptions(translate('documentUploaded'))
                    )
                }
            }
        } catch (error) {
            console.error('An error occurred during document upload:', error)
            toast(baseErrorToastOptions(translate('uploadError')))
        } finally {
            setLocalLoading(false)
        }
    }

    const handleSignersInitialization = (
        document: DocumentMetaDataDto
    ): void => {
        loadInitialSignersData(
            contract?.customerNumber ?? '',
            contract.contractNumber,
            document.id
        )
        openModal()
    }

    useEffect(() => {
        const newDocuments = documents?.map((doc) => {
            const found = signerDocumentStatuses.find(
                (signerStatus: RefreshPenneoStatus) =>
                    signerStatus.documentId === doc.id
            )
            if (found) {
                doc.documentStatus = found.penneoDocumentStatus
            }
            return doc
        })

        updateDocuments(newDocuments)
    }, [JSON.stringify(signerDocumentStatuses)])

    return (
        <Box data-testid="contract-detail-documents">
            <Stack spacing={4}>
                <Flex align="center" justifyContent="flex-end" gap={4}>
                    {signatureSystem === ForeignSignatureSystem.Penneo && (
                        <Button
                            data-testid="refresh-penneo-button"
                            onClick={() => {
                                if (contract?.contractNumber) {
                                    handleSignersDocumentStatuses(
                                        contract.contractNumber
                                    )
                                }
                            }}
                        >
                            {translate('refreshPenneoStatus')}
                        </Button>
                    )}

                    <Button
                        data-testid="upload-document-button"
                        onClick={() => setUploadOpen(true)}
                    >
                        {translate('uploadDocument')}
                    </Button>
                    <Button
                        isDisabled={availableDocumentTypes?.length === 0}
                        data-testid="generate-document-button"
                        onClick={onOpen}
                    >
                        {translate('generateDocument')}
                    </Button>
                </Flex>
                <Box data-testid="documents-table">
                    <DocumentsTable
                        isLoading={localLoading}
                        documents={documents}
                        fetchDocuments={fetchDocuments}
                        isSignersEnabled={true}
                        signersHandler={handleSignersInitialization}
                        signatureSystem={signatureSystem}
                    />
                </Box>
                <UploadDocumentComponent
                    modalTitle={translate('uploadDocument')}
                    isModalOpen={isUploadOpen}
                    onClose={(): void => {
                        setUploadOpen(false)
                    }}
                    onUploadDocument={uploadDocument}
                />
                <GenerateDocuments
                    availableDocumentTypes={availableDocumentTypes}
                    modalTitle={translate('generateDocument')}
                    isModalOpen={isOpen}
                    onClose={(): void => {
                        onClose()
                    }}
                    fetchDocuments={fetchDocuments}
                />
            </Stack>
            {/* Modals */}

            <SignerContainer
                isModalOpen={isSignersModalOpen}
                onClose={closeModal}
                modalTitle={translate('signers')}
            />
        </Box>
    )
}

const ContractDetailDocumentsWithContext = (): ReactElement => (
    <SignersContextProvider>
        <ContractDetailDocuments />
    </SignersContextProvider>
)

export default ContractDetailDocumentsWithContext
