import { toast } from 'react-toastify'

import { generalGetAPI } from '../../services/API/general.api'

export async function getAllPagesHandlerPrompt(
    endpoint: string,
    setterCallback: (data: any) => void,
    params = {}
): Promise<void> {
    const fetchAndProcess = async (currentEndpoint: string): Promise<void> => {
        const response = await generalGetAPI(currentEndpoint, {
            ...(!currentEndpoint.includes('pageSize') && {
                params: { PageSize: 500, ...params },
            }),
        })

        if (!response.isOk) {
            toast.success(response.message)
            return
        }

        if (response.data && !response.data?.items) {
            setterCallback(response.data)
            return
        }

        setterCallback(response.data.items)

        if (response.data.next) {
            await fetchAndProcess(response.data.next)
        }
    }

    await fetchAndProcess(endpoint)
}
