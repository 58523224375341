import { ReactElement } from 'react'

import { chakra, Flex, Skeleton, useColorModeValue } from '@chakra-ui/react'

import PageContainer from '../../pageContainer/PageContainer.component'
import TableSkeleton from '../table/table.skeleton.component'

export default function PageSkeleton(): ReactElement {
    return (
        <PageContainer>
            <chakra.div mb={10}>
                <Skeleton
                    height="20px"
                    width="200px"
                    startColor={useColorModeValue(
                        'primary.500',
                        'secondary.500'
                    )}
                    endColor={useColorModeValue('primary.300', 'secondary.300')}
                />
                <Flex my={3} gap="1rem">
                    <Skeleton
                        height="20px"
                        width="100px"
                        startColor={useColorModeValue(
                            'primary.500',
                            'secondary.500'
                        )}
                        endColor={useColorModeValue(
                            'primary.300',
                            'secondary.300'
                        )}
                    />
                    <Skeleton
                        height="20px"
                        width="100px"
                        startColor={useColorModeValue(
                            'primary.500',
                            'secondary.500'
                        )}
                        endColor={useColorModeValue(
                            'primary.300',
                            'secondary.300'
                        )}
                    />
                    <Skeleton
                        height="20px"
                        width="100px"
                        startColor={useColorModeValue(
                            'primary.500',
                            'secondary.500'
                        )}
                        endColor={useColorModeValue(
                            'primary.300',
                            'secondary.300'
                        )}
                    />
                </Flex>
            </chakra.div>
            <TableSkeleton />
        </PageContainer>
    )
}
