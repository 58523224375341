import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    FormControl,
    Heading,
    Select,
    Spinner,
    useToast,
} from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { useOffer } from '../../../../services/contexts/Offer.context'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import { ContractBundleDTO, ProductDTO } from '../../../../utils/types/types'

function ProductComponent({
    excludeDisabled = false,
}: {
    excludeDisabled?: boolean
}): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const {
        offer,
        products,
        selectedProduct,
        setSelectedProduct,
        setSelectedProductBundle,
    } = useOffer()
    const [bundles, setBundles] = useState<ContractBundleDTO[]>([])
    const excludeDisabledFn = (product: ProductDTO): boolean =>
        product.validToDateTime && excludeDisabled
            ? new Date(product.validToDateTime) > new Date(Date.now())
            : true

    useEffect(() => {
        if (selectedProduct?.id) handleFetchBundles(selectedProduct.id)
    }, [selectedProduct])

    const handleFetchBundles = async (productId: number): Promise<void> => {
        try {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.contractBundle}?productId=${productId}`
            )
            if (response.isOk) {
                setBundles(response.data)
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error(error)
            setBundles([])
        }
    }

    return (
        <Box w={'33%'}>
            <Heading size={'md'} mb={4}>
                {translate('contractTemplate')}
            </Heading>
            <FormControl>
                {products.length > 0 ? (
                    <Select
                        onChange={(
                            event: ChangeEvent<HTMLSelectElement>
                        ): void => {
                            const productId = parseInt(event.target.value)
                            const product = products.find(
                                (productItem) => productItem.id === productId
                            )
                            setSelectedProduct(product as ProductDTO)
                        }}
                        value={offer.productId}
                        data-testid="t-product-select"
                    >
                        <option value={0}>{translate('selectProduct')}</option>
                        {products
                            .filter(excludeDisabledFn)
                            .map((product, key) => (
                                <option
                                    key={key}
                                    value={product.id}
                                    data-testid="t-product-option"
                                >
                                    {product!.name}
                                </option>
                            ))}
                    </Select>
                ) : (
                    <Spinner size={'md'} />
                )}
            </FormControl>
            <Heading size={'md'} mb={4} mt={4}>
                {translate('contractBundle')}
            </Heading>
            <FormControl>
                {products.length > 0 ? (
                    <Select
                        isDisabled={!bundles?.length}
                        key={selectedProduct?.id}
                        placeholder={translate('contractBundle')}
                        onChange={(
                            event: ChangeEvent<HTMLSelectElement>
                        ): void => {
                            const productBundleId = parseInt(event.target.value)
                            setSelectedProductBundle(productBundleId)
                        }}
                    >
                        {bundles.map((bundle) => (
                            <option key={bundle.id} value={bundle.id}>
                                {bundle?.contractBundleName}
                            </option>
                        ))}
                    </Select>
                ) : (
                    <Spinner size={'md'} />
                )}
            </FormControl>
        </Box>
    )
}

export default ProductComponent
