import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Tooltip } from '@chakra-ui/react'

import DynamicGrid from '../../pages/demo/DynamicAGGrid.component'
import { useSigners } from '../../services/contexts/Signers.context'
import { ColumnActionConfiguration } from '../../utils/functions.utils'
import {
    ElectronicID,
    ForeignSignatureSystem,
    SignatureDigitalType,
    SignatureReceiver,
} from '../../utils/types/types'

interface ISignersTable {
    signatureSystem: ForeignSignatureSystem | null
    isLoading: boolean
}

const SignersTableComponent = ({
    signatureSystem,
}: ISignersTable): ReactElement => {
    const {
        signersList,
        handleSignerChangeRequest,
        handleSignerDeleteRequest,
        handleSignerSelection,
    } = useSigners()
    const translate = useTranslation().t
    const hasElectronicID = signatureSystem === ForeignSignatureSystem.Scrive
    const tableActions = (data: any) => (
        <>
            <Tooltip label={translate('edit')} placement="top" hasArrow>
                <IconButton
                    aria-label="Edit"
                    size="sm"
                    variant={'outline'}
                    onClick={(): void => {
                        handleSignerChangeRequest(data)
                    }}
                    icon={<EditIcon />}
                />
            </Tooltip>
            <Tooltip label={translate('delete')} placement="top" hasArrow>
                <IconButton
                    aria-label="Delete"
                    size="sm"
                    variant={'outline'}
                    onClick={(): void => {
                        handleSignerDeleteRequest(data)
                    }}
                    icon={<DeleteIcon />}
                />
            </Tooltip>
        </>
    )

    function onSelectionChanged(data: any) {
        const selectedNodes = data.api.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        handleSignerSelection(selectedData)
    }

    const getTableHeaders = () => {
        const headers: any = [
            {
                headerName: translate('name'),
                field: 'name',
                filter: true,
                headerCheckboxSelection: true,
                checkboxSelection: true,
            },
            { headerName: translate('email'), field: 'email', filter: true },
            {
                headerName: translate('receiver'),
                field: 'receiver',
                filter: true,
                valueGetter: (params: any) =>
                    translate(
                        camelCase(SignatureReceiver[params.data.receiver])
                    ) || '',
            },
            {
                headerName: translate('digitalType'),
                field: 'digitalType',
                filter: true,
                valueGetter: (params: any) =>
                    translate(
                        camelCase(SignatureDigitalType[params.data.digitalType])
                    ) || '',
            },
        ]

        if (hasElectronicID) {
            headers.push({
                headerName: translate('electronicID'),
                field: 'electronicID',
                filter: true,
                valueGetter: (params: any) =>
                    ElectronicID[params.data?.electronicID || 0],
            })
            headers.push({
                headerName: translate('signOrder'),
                filter: true,
                field: 'signOrder',
            })
        }
        headers.push({
            ...ColumnActionConfiguration(translate, (params: any) =>
                tableActions(params.data)
            ),
        })

        return headers
    }

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                tableId="signersTable"
                columns={getTableHeaders()}
                rowData={signersList}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
            />
        </Flex>
    )
}

export default SignersTableComponent
