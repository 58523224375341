import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'

import { LocationDTO } from '../../utils/types/types'
import { generalGetAPI } from '../API/general.api'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { useUser } from './user.context'

interface LocationsContextInterface {
    locations: LocationDTO[]
    isLoading: boolean
    retrieveLocations: () => void
}

export const LocationsContext = createContext<LocationsContextInterface>(
    {} as LocationsContextInterface
)

interface ILocationsContextProvider {
    children: ReactNode
}

export const LocationsContextProvider = ({
    children,
}: ILocationsContextProvider): ReactElement => {
    const { user } = useUser()
    const [locations, setLocations] = useState<LocationDTO[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const retrieveLocations = async (): Promise<void> => {
        if (user.email) {
            setIsLoading(true)
            const response = await generalGetAPI(API_ENDPOINTS.location)
            setIsLoading(false)
            response.isOk && setLocations(response.data)
        }
    }

    useEffect(() => {
        let isMounted = true

        if (isMounted && user.userNumber) {
            retrieveLocations()
        }

        return (): void => {
            isMounted = false
        }
    }, [user])

    return (
        <LocationsContext.Provider
            value={{
                locations,
                retrieveLocations,
                isLoading,
            }}
        >
            {children}
        </LocationsContext.Provider>
    )
}

export const useLocationsContext = (): LocationsContextInterface =>
    useContext(LocationsContext)
