import { useField } from 'formik'
import { ReactElement } from 'react'

import { Switch } from '@chakra-ui/react'

export default function SwitchField({ ...props }: any): ReactElement {
    const [field, , { setValue }] = useField(props)
    const fieldWasString = typeof field.value === 'string'
    if (fieldWasString) {
        field.value = field.value === 'true'
    }
    if (field.value === null) {
        field.value = false
    }

    return (
        <Switch
            {...field}
            {...props}
            float={'left'}
            name={field.name}
            checked={field.value}
            defaultChecked={field.value}
            onChange={(event): void => {
                if (fieldWasString) {
                    setValue((event.target.checked === true).toString())
                } else {
                    setValue(event.target.checked === true)
                }
            }}
        />
    )
}
