import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip, useToast } from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import PopupActionButton, {
    ButtonVariant,
} from '../../../features/popupActionButton/PopupActionButton.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import { useChecklistInteractions } from '../../../services/checklistInteractions/ChecklistInteraction.service'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    ColumnActionConfiguration,
} from '../../../utils/functions.utils'
import {
    ChecklistHumanInteractionTypeBO,
    CreateChecklistHumanInteractionTypeCommand,
} from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import ChecklistInteractionsEditComponent from './ChecklistInteractionsEdit.component'

export default function ChecklistInteractions(): ReactElement {
    const translate = useTranslation().t
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const { humanInteractionTypes, getInteractions } =
        useChecklistInteractions()
    const [itemToEdit, setItemToEdit] =
        useState<ChecklistHumanInteractionTypeBO>()
    const toast = useToast()

    function onPopupConfirm(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    useEffect(() => {
        !humanInteractionTypes && getInteractions()
    }, [])

    return (
        <PageContainer>
            <DynamicGrid
                tableId="checklistInteractionsTable"
                columns={[
                    { headerName: translate('name'), field: 'name' },
                    {
                        headerName: translate('description'),
                        field: 'description',
                    },
                    {
                        headerName: translate('isActive'),
                        field: 'isActive',
                        flex: 1,
                    },
                    {
                        ...ColumnActionConfiguration(
                            translate,
                            (params: any) => (
                                <>
                                    <Tooltip
                                        label={translate('edit')}
                                        placement="top"
                                        hasArrow
                                    >
                                        <IconButton
                                            mr={2}
                                            aria-label="Edit"
                                            size="sm"
                                            variant={'outline'}
                                            onClick={(): void =>
                                                setItemToEdit(params.data)
                                            }
                                            icon={<EditIcon />}
                                        />
                                    </Tooltip>

                                    <Tooltip
                                        label={translate('delete')}
                                        placement="top"
                                        hasArrow
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            size="sm"
                                            variant={'outline'}
                                            onClick={(): void => {
                                                generalDeleteAPI(
                                                    `${API_ENDPOINTS.checklistCreateHumanType}/${params.data.id}`
                                                ).then((response) => {
                                                    if (response.isOk) {
                                                        toast(
                                                            baseInfoToastOptions(
                                                                translate(
                                                                    'deletedWithSuccess'
                                                                )
                                                            )
                                                        )
                                                    } else {
                                                        toast(
                                                            baseErrorToastOptions(
                                                                translate(
                                                                    response.message
                                                                )
                                                            )
                                                        )
                                                    }
                                                })
                                            }}
                                            icon={<DeleteIcon />}
                                        />
                                    </Tooltip>
                                </>
                            ),
                            100
                        ),
                    },
                ]}
                rowData={humanInteractionTypes}
                headers={
                    <PopupActionButton
                        actionName={'createInteraction'}
                        buttonVariant={ButtonVariant.SOLID}
                        onConfirm={onPopupConfirm}
                        content={
                            <DynamicForm<CreateChecklistHumanInteractionTypeCommand>
                                formFields={[
                                    {
                                        slug: 'name',
                                        label: 'name',
                                        inputProps: { type: 'text' },
                                    },
                                    {
                                        slug: 'description',
                                        label: 'description',
                                        inputProps: { type: 'text' },
                                    },
                                    {
                                        slug: 'isActive',
                                        label: 'active',
                                        inputProps: { type: 'boolean' },
                                    },
                                ]}
                                hideSubmit={true}
                                formSubmitReference={formSubmitReference}
                                data={{
                                    name: '',
                                    description: '',
                                    isActive: false,
                                }}
                                onSubmit={(
                                    data: CreateChecklistHumanInteractionTypeCommand
                                ): void => {
                                    generalPostAPI(
                                        API_ENDPOINTS.checklistCreateHumanType,
                                        data
                                    ).then((response) => {
                                        if (response.isOk) {
                                            getInteractions()
                                            toast(
                                                baseInfoToastOptions(
                                                    translate(
                                                        'interactionCreatedWithSuccess'
                                                    )
                                                )
                                            )
                                        } else {
                                            toast(
                                                baseErrorToastOptions(
                                                    translate(response.message)
                                                )
                                            )
                                        }
                                    })
                                }}
                            ></DynamicForm>
                        }
                        title={'createInteraction'}
                    ></PopupActionButton>
                }
            ></DynamicGrid>
            {itemToEdit !== undefined && (
                <ChecklistInteractionsEditComponent
                    onSubmit={(data: any) => {
                        generalPutAPI(
                            `${API_ENDPOINTS.checklistCreateHumanType}/${itemToEdit.id}`,
                            data
                        ).then((response) => {
                            setItemToEdit(undefined)
                            if (response.isOk) {
                                getInteractions()
                                toast(
                                    baseInfoToastOptions(
                                        translate(
                                            'interactionEditedWithSuccess'
                                        )
                                    )
                                )
                            } else {
                                toast(
                                    baseErrorToastOptions(
                                        translate(response.message)
                                    )
                                )
                            }
                        })
                    }}
                    interaction={itemToEdit}
                    isModalOpen={itemToEdit !== undefined}
                    modalTitle={translate('editInteraction')}
                    onClose={() => setItemToEdit(undefined)}
                ></ChecklistInteractionsEditComponent>
            )}
        </PageContainer>
    )
}
