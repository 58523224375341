import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useBoolean } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import { IFormField } from '../../../../features/dynamicForm/DynamicForm.interfaces'
import { IContractBalance } from '../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    getFirstDayOfTheMonth,
    getLastDayOfTheMonth,
    offsetTimeZoneDifference,
    standaloneToast,
} from '../../../../utils/functions.utils'
import ContractReportTableComponent from './ContractReportTable.component'

export default function ContractReportPage(): ReactElement {
    const translate = useTranslation().t
    const navigate = useTranslation().t
    const [contractBalanceReport, setContractBalanceReport] = useState([])
    const [requestLoading, setRequestLoading] = useBoolean(false)
    const defaultContractBalance = {
        contractNumber: '',
        startDate: getFirstDayOfTheMonth(),
        endDate: getLastDayOfTheMonth(),
    }

    const getContractBalanceReport = async (
        contractBalance: IContractBalance
    ): Promise<void> => {
        setRequestLoading.on()
        const response = await generalPostAPI(
            API_ENDPOINTS.contractBalanceReport,
            {
                contractNumber: contractBalance.contractNumber,
                periodEndLow: offsetTimeZoneDifference(
                    new Date(contractBalance.startDate)
                ).toISOString(),
                periodEndHigh: offsetTimeZoneDifference(
                    new Date(contractBalance.endDate)
                ).toISOString(),
            }
        )
        setRequestLoading.off()
        if (response.isOk) {
            standaloneToast(baseSuccessToastOptions(response.message))
            setContractBalanceReport(response.data)
        } else {
            standaloneToast(baseErrorToastOptions(response.message))
        }
    }

    const contractReportForm: IFormField<IContractBalance>[] = [
        {
            slug: 'contractNumber',
            label: 'contractNumber',
            inputProps: { type: 'text' },
        },
        {
            slug: 'startDate',
            label: 'startDate',
            inputProps: { type: 'date' },
        },
        {
            slug: 'endDate',
            label: 'endDate',
            inputProps: { type: 'date' },
        },
    ]

    return (
        <>
            <Box
                data-testid="contract-report-page"
                display={'flex'}
                alignItems="center"
            >
                <Box w={'100%'} p={4}>
                    <DynamicForm<IContractBalance>
                        hideSubmit={false}
                        onSubmit={(contractBalance: IContractBalance): void => {
                            getContractBalanceReport(contractBalance)
                        }}
                        data={defaultContractBalance}
                        formFields={contractReportForm}
                        submitText={translate('apply') || ''}
                        submitButtonProps={{
                            float: 'right',
                            isLoading: requestLoading,
                        }}
                        columns={[1, 1, 2, 2, 3, 3]}
                    />
                </Box>
            </Box>

            <Box>
                <ContractReportTableComponent
                    items={contractBalanceReport}
                    navigate={navigate}
                />
            </Box>
        </>
    )
}
