import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    StackDivider,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import PageContainer from '../../../../components/pageContainer/PageContainer.component'
import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalFileGETAPI } from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { BREAK_POINTS_WIDTH } from '../../../../utils/constants.utils'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    downloadFileWithContent,
} from '../../../../utils/functions.utils'
import AssetReportPage from './AssetReport.page'
import ContractReportPage from './ContractReport.page'
import PeriodicReportPage from './PeriodicReport.page'
import FirstInvoiceReportPage from './FirstInvoiceReport.page'

const ContractReportPopUp = withModalHOC<ModalSharedProps>(ContractReportPage)
const AssetReportPopUp = withModalHOC<ModalSharedProps>(AssetReportPage)
const PeriodicReportPopUp = withModalHOC<ModalSharedProps>(PeriodicReportPage)
const FirstInvoiceReportPopUp = withModalHOC<ModalSharedProps>(
    FirstInvoiceReportPage
)

export default function ReportPage(): ReactElement {
    const translate = useTranslation().t
    const [isContractReportOpen, setContractReport] = useBoolean()
    const [isAssetReportOpen, setAssetReport] = useBoolean()
    const [isMontlhyReportOpen, setPeriodicReport] = useBoolean()
    const [isInvoiceReportOpen, setInvoiceReport] = useBoolean()
    const { stopGlobalLoading, globalLoading } = useLoading()
    const toast = useToast()

    const downloadFinancialBaseReport = async (): Promise<void> => {
        const loadingId = globalLoading()
        try {
            const response = await generalFileGETAPI(
                `${API_ENDPOINTS.invoiceFinancialBaseReport}`
            )
            if (response.isOk) {
                downloadFileWithContent(
                    response.data,
                    'FinancialBaseReport',
                    'xlsx'
                )
                toast(baseSuccessToastOptions(translate('documentDownloaded')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error(error)
        } finally {
            stopGlobalLoading(loadingId)
        }
    }

    const downloadInvoiceLinesReport = async (): Promise<void> => {
        const loadingId = globalLoading()
        try {
            const response = await generalFileGETAPI(
                API_ENDPOINTS.invoiceLineReport
            )
            if (response.isOk) {
                downloadFileWithContent(
                    response.data,
                    'InvoiceLineReport',
                    'xlsx'
                )
                toast(baseSuccessToastOptions(translate('documentDownloaded')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error(error)
        } finally {
            stopGlobalLoading(loadingId)
        }
    }

    return (
        <PageContainer data-testid="report-page">
            <Heading size={'md'}>{translate('balanceReports')}</Heading>
            <Stack
                divider={<StackDivider />}
                spacing="4"
                mt={4}
                mb={6}
                maxWidth={BREAK_POINTS_WIDTH.MOBILE}
            >
                <Box
                    animation="alternate"
                    boxShadow={'md'}
                    p={6}
                    textAlign={'center'}
                    width={'100%'}
                >
                    <Flex
                        alignItems="center"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={8}
                        justifyContent={'space-between'}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('contractBalanceReport')}
                                </Heading>
                            </Box>
                            <Button size={'sm'} onClick={setContractReport.on}>
                                {translate('open')}
                            </Button>
                        </Flex>

                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('assetBalanceReport')}
                                </Heading>
                            </Box>
                            <Button size={'sm'} onClick={setAssetReport.on}>
                                {translate('open')}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Stack>

            <Heading size="md">{translate('financialBaseReports')}</Heading>
            <Stack
                divider={<StackDivider />}
                maxWidth={BREAK_POINTS_WIDTH.MOBILE}
                mb={6}
                mt={4}
                spacing="4"
            >
                <Box
                    animation="alternate"
                    boxShadow={'md'}
                    p={6}
                    textAlign={'center'}
                    width={'100%'}
                >
                    <Flex
                        alignItems="center"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={8}
                        justifyContent={'space-between'}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('financialBaseReport')}
                                </Heading>
                            </Box>
                            <Button
                                size={'sm'}
                                onClick={(): Promise<void> =>
                                    downloadFinancialBaseReport()
                                }
                            >
                                {translate('download')}
                            </Button>
                        </Flex>
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('invoiceLinesReport')}
                                </Heading>
                            </Box>
                            <Button
                                size={'sm'}
                                onClick={(): Promise<void> =>
                                    downloadInvoiceLinesReport()
                                }
                            >
                                {translate('open')}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Stack>

            <Heading size="md">{translate('periodicReport')}</Heading>
            <Stack
                divider={<StackDivider />}
                maxWidth={BREAK_POINTS_WIDTH.MOBILE}
                mb={6}
                mt={4}
                spacing="4"
            >
                <Box
                    animation="alternate"
                    boxShadow={'md'}
                    p={6}
                    textAlign={'center'}
                    width={'100%'}
                >
                    <Flex
                        alignItems="center"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={8}
                        justifyContent={'space-between'}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('periodicReport')}
                                </Heading>
                            </Box>
                            <Button size={'sm'} onClick={setPeriodicReport.on}>
                                {translate('open')}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Stack>

            <Heading size="md">{translate('firstInvoiceReport')}</Heading>
            <Stack
                divider={<StackDivider />}
                maxWidth={BREAK_POINTS_WIDTH.MOBILE}
                mb={6}
                mt={4}
                spacing="4"
            >
                <Box
                    animation="alternate"
                    boxShadow={'md'}
                    p={6}
                    textAlign={'center'}
                    width={'100%'}
                >
                    <Flex
                        alignItems="center"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={8}
                        justifyContent={'space-between'}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {translate('firstInvoiceReport')}
                                </Heading>
                            </Box>
                            <Button size={'sm'} onClick={setInvoiceReport.on}>
                                {translate('open')}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Stack>

            <ContractReportPopUp
                isModalOpen={isContractReportOpen}
                modalTitle={translate('contractBalanceReport')}
                onClose={setContractReport.off}
            />
            <AssetReportPopUp
                isModalOpen={isAssetReportOpen}
                modalTitle={translate('assetBalanceReport')}
                onClose={setAssetReport.off}
            />
            <PeriodicReportPopUp
                isModalOpen={isMontlhyReportOpen}
                modalTitle={translate('periodicReport')}
                onClose={setPeriodicReport.off}
            />
            <FirstInvoiceReportPopUp
                isModalOpen={isInvoiceReportOpen}
                modalTitle={translate('firstInvoiceReport')}
                onClose={setInvoiceReport.off}
            />
        </PageContainer>
    )
}
