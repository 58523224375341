import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import sortBy from 'lodash/sortBy'
/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, useToast } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPutAPI } from '../../../../../services/API/general.api'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    getFirstDayOfTheMonth,
    isValidDate,
    offsetTimeZoneDifference,
} from '../../../../../utils/functions.utils'
import { ContractDTO } from '../../../../../utils/types/types'

interface IStartContract extends ModalSharedProps {
    parentContract?: ContractDTO
}

function StartContract({
    parentContract,
    onClose,
}: IStartContract): ReactElement {
    const { contract, getContract } = useContractService()
    const [isLoading, setLoading] = useState(false)
    const contractToUse = parentContract?.contractNumber
        ? parentContract
        : contract

    const lasActivationAssetDateString = sortBy(
        contractToUse?.contractAssets,
        'dateTimeActivation'
    )[0]?.dateTimeActivation
    const lasActivationAssetDate = lasActivationAssetDateString
        ? new Date(lasActivationAssetDateString)
        : new Date()
    const [startDate, setStartDate] = useState(
        getFirstDayOfTheMonth(
            new Date(
                lasActivationAssetDate.getFullYear(),
                lasActivationAssetDate.getMonth() + 1,
                1
            )
        )
    )
    const translate = useTranslation().t
    const toast = useToast()

    const disableDates = (): void => {
        const datepickerCells = document.querySelectorAll('button[aria-label]')

        datepickerCells.forEach((cell: any) => {
            const dateLabel = cell.getAttribute('aria-label')
            const cellDate = new Date(dateLabel)
            const cellDay = cellDate.getDate()

            if (isValidDate(cellDate)) {
                if (
                    cellDay !== 1 ||
                    cellDate.getTime() < lasActivationAssetDate.getTime()
                ) {
                    cell.disabled = true
                    cell.style.color = 'gray'
                }
            }
        })
    }

    useEffect(() => {
        disableDates()
    }, [])

    const activateContractManually = async (): Promise<void> => {
        setLoading(true)
        const response = await generalPutAPI(
            API_ENDPOINTS.contractActionsStartContract,
            {
                contractNumber: contract.contractNumber,
                startDate: startDate.toISOString(),
            }
        )
        if (response.isOk) {
            toast(baseSuccessToastOptions(translate('successfulContractStart')))
            getContract()
            onClose()
        } else {
            setLoading(false)
            toast(baseErrorToastOptions(response.message))
        }
    }

    return (
        <Box
            onClick={(e): void => {
                e.stopPropagation()
                setTimeout(() => {
                    disableDates()
                }, 50)
            }}
        >
            <SingleDatepicker
                name="startDate"
                date={startDate}
                onDateChange={(date: Date): void => {
                    if (date) {
                        setStartDate(offsetTimeZoneDifference(date))
                    }
                }}
            />
            <Button
                onClick={activateContractManually}
                mt="4"
                float={'right'}
                isDisabled={isLoading}
                isLoading={isLoading}
            >
                {translate('submit')}
            </Button>
        </Box>
    )
}

export default withModalHOC(StartContract)
