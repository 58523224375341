import { ReactElement } from 'react'
import PageContainer from '../../../components/pageContainer/PageContainer.component'
import { ServiceContextProvider } from '../../../services/contexts/Service.context'
import { VATContextProvider } from '../../../services/contexts/vat.context'
import { TransientFeesTable } from './TransientFees.component'

export default function TransientFeesPage(): ReactElement {
    return (
        <ServiceContextProvider>
            <VATContextProvider>
                <PageContainer>
                    <TransientFeesTable hasActions />
                </PageContainer>
            </VATContextProvider>
        </ServiceContextProvider>
    )
}
