import { IFormField } from '../../../../../features/dynamicForm/DynamicForm.feature'
import {
    ParameterDTO,
    ValueType,
    PartnerParameterDefinitionDTO,
} from '../../../../../utils/types/types'

export default function addProductParameterFields(
    definition: PartnerParameterDefinitionDTO
): IFormField<Partial<Partial<ParameterDTO>>>[] {
    function getTypeOfField(value: ValueType): string {
        switch (value) {
            case ValueType.Boolean:
                return 'boolean'
            case ValueType.Date:
                return 'date'
            case ValueType.Numeric:
                return 'number'
            default:
                return 'text'
        }
    }

    return [
        {
            slug: 'value',
            label: 'value',
            enableDecimal: false,
            stringValue: true,
            inputProps: {
                type: getTypeOfField(
                    definition.valueType || definition.valueType
                ),
            },
        },
    ]
}
