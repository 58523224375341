import { MutableRefObject, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFile } from 'react-icons/fa'
import { FiFile } from 'react-icons/fi'
import { useFileUpload } from 'use-file-upload'

import { Button, Flex, Icon, InputGroup } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../hoc/modal.hoc'
import {
    UpdateDocumentCommand,
    UploadedDocumentStatus,
} from '../../../utils/types/types'
import DynamicForm from '../../dynamicForm/DynamicForm.feature'
import { UploadDocumentFormFields } from './DocumentsTable.config'

interface IUploadDocument extends ModalSharedProps {
    onUploadDocument: Function
}

function UploadDocument({ onUploadDocument }: IUploadDocument): ReactElement {
    const translate = useTranslation().t
    const [file, selectFile] = useFileUpload()
    const newDocument: Partial<UpdateDocumentCommand> = {}
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    return (
        <Flex gap={4} flexDirection={'column'}>
            <InputGroup
                onClick={() => {
                    selectFile({ accept: '*', multiple: false }, () => {})
                }}
                display={'flex'}
                alignItems={'center'}
                gap={6}
            >
                <input type={'file'} multiple={false} hidden />
                <Button leftIcon={<Icon as={FiFile} />}>
                    {translate('upload')}
                </Button>
                {file ? (
                    <Flex alignItems={'center'} gap={2}>
                        <FaFile /> {(file as any)?.name}
                    </Flex>
                ) : (
                    <p>{translate('noFileSelected')}</p>
                )}
            </InputGroup>

            <DynamicForm
                formSubmitReference={formSubmitReference}
                data={newDocument}
                formFields={UploadDocumentFormFields()}
                hideSubmit={false}
                onSubmit={(formData: UpdateDocumentCommand): void => {
                    const documentToUpload: Partial<UpdateDocumentCommand> = {
                        documentType: formData.documentType,
                        documentStatus:
                            formData.documentStatus ??
                            UploadedDocumentStatus.NotSigned,
                    }
                    onUploadDocument((file as any).file, documentToUpload)
                }}
            />
        </Flex>
    )
}

export default withModalHOC(UploadDocument)
