import { ReactElement, useState } from 'react'

import { Box } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import {
    baseSuccessToastOptions,
    standaloneToast,
} from '../../../../utils/functions.utils'
import { CreateVariableInterestCommand } from '../../../../utils/types/types'

const defaultCreateVarInterest: CreateVariableInterestCommand = {
    startDate: new Date().toISOString(),
    value: 0,
}

interface ICreateInterest {
    onInterestCreated?: Function
}

export default function CreateInterest({
    onInterestCreated,
}: ICreateInterest): ReactElement {
    const [createVarInterest, setCreateVarInterest] =
        useState<CreateVariableInterestCommand>(defaultCreateVarInterest)
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data: CreateVariableInterestCommand) => {
        setLoading(true)
        const response = await generalPostAPI(
            API_ENDPOINTS.variableInterest,
            data
        )
        if (response.isOk) {
            standaloneToast(baseSuccessToastOptions('VariableInterestCreated'))
        }
        setCreateVarInterest(defaultCreateVarInterest)
        setLoading(false)
        if (onInterestCreated) onInterestCreated(data)
    }

    return (
        <Box
            p={6}
            width={'100%'}
            textAlign={'center'}
            animation="alternate"
            boxShadow={'sm'}
            borderRadius={'md'}
        >
            <DynamicForm<CreateVariableInterestCommand>
                data={createVarInterest}
                columns={{ md: 1, lg: 3 }}
                formFields={[
                    {
                        slug: 'startDate',
                        label: 'startDate',
                        inputProps: {
                            type: 'date',
                        },
                    },
                    {
                        slug: 'value',
                        label: 'presentReferenceRate',
                        decimalHouses: 5,
                        enableDecimal: true,
                        validation: (value: number) =>
                            !Number.isNaN(value) && value > 0 && value !== null,
                        inputProps: {
                            type: 'number',
                        },
                    },
                ]}
                onSubmit={onSubmit}
                submitInline
                submitButtonProps={{
                    isLoading: loading,
                    loadingText: 'creating',
                    minWidth: 140,
                    marginTop: { md: 0, lg: '14px' },
                }}
            />
        </Box>
    )
}
