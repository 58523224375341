import camelCase from 'lodash/camelCase'
import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, Tooltip } from '@chakra-ui/react'

import GenericBox from '../../../../../../components/genericBox/genericBox'
import DynamicForm from '../../../../../../features/dynamicForm/DynamicForm.feature'
import { IFormField } from '../../../../../../features/dynamicForm/DynamicForm.interfaces'
import PopupActionButton, {
    ButtonVariant,
} from '../../../../../../features/popupActionButton/PopupActionButton.feature'
import { IApiResponse } from '../../../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../../../services/API/general.api'
import { useChecklistInteractions } from '../../../../../../services/checklistInteractions/ChecklistInteraction.service'
import {
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../../../../utils/functions.utils'
import {
    ChecklistDTO,
    ChecklistHumanInteractionTypeBO,
    ChecklistItemDTO,
    ChecklistItemType,
    DomainOperationType,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import {
    checklistFormFields,
    checklistItemFormFields,
} from './ContractTemplateChecklist.config'
import { ContractTemplateChecklistEditItemModal } from './ContractTemplateChecklistEditItem.modal'

export default function ContractTemplateChecklist({
    productId,
}: {
    productId: number
}): ReactElement {
    const translate = useTranslation().t
    const { humanInteractionTypes, getInteractions } =
        useChecklistInteractions()
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const [checklists, setChecklists] = useState<ChecklistDTO[]>()
    const [availableTypes, setAvailableTypes] = useState<any>()
    const [selectedList, setSelectedList] = useState<ChecklistDTO>()

    const [itemToEdit, setItemToEdit] = useState<ChecklistItemDTO>()
    const [itemsFormFields, setItemsFormFields] = useState<
        IFormField<ChecklistItemDTO>[]
    >([])

    useEffect(() => {
        buildChecklist()
    }, [])

    useEffect(() => {
        if (!humanInteractionTypes && checklists) {
            getInteractions()
        }
    }, [JSON.stringify(checklists)])

    useEffect(() => {
        if (!availableTypes && checklists) {
            getAvailableTypesForChecklist()
        }
    }, [JSON.stringify(checklists)])

    async function getAvailableTypesForChecklist(): Promise<void> {
        const listTypesPromiseArray: Promise<IApiResponse>[] = []
        Object.keys(DomainOperationType)
            .filter((v) => !Number.isNaN(Number(v)))
            .forEach(async (value) => {
                listTypesPromiseArray.push(
                    generalGetAPI(
                        API_ENDPOINTS.checklistDomainOperationType + value
                    )
                )
            })
        const response = await Promise.all(listTypesPromiseArray)

        const list: Array<string | number>[][] = []

        response.forEach((types, index) => {
            const selectOptions = types.data.checklistItemTypes.map(
                (type: number) => [type, ChecklistItemType[type]]
            )
            list.push(selectOptions)
        })

        setAvailableTypes(list)
    }

    async function buildChecklist(): Promise<void> {
        const promisesArray: Promise<IApiResponse>[] = []
        Object.keys(DomainOperationType)
            .filter((v) => !Number.isNaN(Number(v)))
            .forEach(async (value) => {
                promisesArray.push(
                    generalGetAPI(
                        API_ENDPOINTS.checklistProducts(
                            productId.toString(),
                            value
                        )
                    )
                )
            })
        const localChecklist: any[] = []
        Promise.all(promisesArray)
            .then((response) => {
                response.forEach((list) => {
                    if (list.data) {
                        localChecklist.push(list.data)
                    }
                })
            })
            .finally(() => {
                if (selectedList) {
                    setSelectedList(
                        localChecklist[
                            getChecklistIndex(
                                localChecklist,
                                selectedList
                            ) as number
                        ]
                    )
                }
                setChecklists(localChecklist)
            })
    }

    function onPopupConfirm(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    async function createNewChecklist(data: ChecklistDTO): Promise<void> {
        const createChecklist: Partial<ChecklistDTO> = { ...data }
        delete createChecklist.id
        generalPostAPI(API_ENDPOINTS.checklists, createChecklist).then(() =>
            buildChecklist()
        )
    }

    async function onSubmitItemToEdit(data: any): Promise<void> {
        if (selectedList?.checklistItems) {
            selectedList.checklistItems.findIndex(
                (item) => item.id === itemToEdit?.id
            )

            await generalPutAPI(API_ENDPOINTS.checklists, {
                ...selectedList,
            }).then(() => {
                buildChecklist()
                setItemToEdit(undefined)
            })
        }
    }

    const globalColumnsCheckList = [
        {
            headerName: translate('description'),
            field: 'description',
        },
        {
            headerName: translate('domainTypeOperation'),
            field: 'domainTypeOperation',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof DomainOperationType>(
                            DomainOperationType,
                            params.data.domainOperationType
                        )
                    )
                ) || '',
        },
        {
            headerName: translate('checkListItems'),
            field: 'checklistItemsLength',
            valueGetter: (params: any) =>
                params.data?.checklistItems?.length > 0
                    ? params.data.checklistItems.length
                    : 0,
        },
        {
            headerName: translate('isActive'),
            field: 'isActive',
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <Tooltip
                        label={translate('select')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            mr={2}
                            aria-label="select"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => setSelectedList(params.data)}
                            icon={<ViewIcon />}
                        />
                    </Tooltip>
                ),
                100
            ),
        },
    ]

    const checklistItemsColumns = [
        {
            headerName: translate('itemType'),
            field: 'itemType',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof ChecklistItemType>(
                            ChecklistItemType,
                            params.data.itemType
                        )
                    )
                ),
        },
        {
            headerName: translate('isOptional'),
            field: 'isOptional',
        },
        {
            headerName: translate('isActive'),
            field: 'isActive',
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <Tooltip label={translate('edit')} placement="top" hasArrow>
                        <IconButton
                            mr={2}
                            aria-label="edit"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => setItemToEdit(params.data)}
                            icon={<EditIcon />}
                        />
                    </Tooltip>
                ),
                100
            ),
        },
    ]

    function renderAllChecklists(): ReactElement {
        return (
            <Box mt={8} mb={16}>
                <Heading size={'lg'} mb={4}>
                    {translate('checklists')}
                </Heading>
                <GenericBox p={4}>
                    <DynamicGrid
                        tableId="contractTemplateChecklistTable"
                        columns={globalColumnsCheckList}
                        rowData={checklists}
                        pagination={true}
                        rowMultiSelectWithClick={true}
                        headers={
                            <PopupActionButton
                                actionName={'createChecklist'}
                                buttonVariant={ButtonVariant.SOLID}
                                onConfirm={onPopupConfirm}
                                content={
                                    <>
                                        <DynamicForm<ChecklistDTO>
                                            data={{
                                                domainOperationType:
                                                    DomainOperationType.ContractActivation,
                                                checklistItems: [],
                                                isActive: false,
                                                id: -1,
                                                productId,
                                            }}
                                            formFields={checklistFormFields()}
                                            hideSubmit={true}
                                            formSubmitReference={
                                                formSubmitReference
                                            }
                                            onSubmit={createNewChecklist}
                                        ></DynamicForm>
                                    </>
                                }
                                title={'createChecklist'}
                            />
                        }
                    ></DynamicGrid>
                </GenericBox>
            </Box>
        )
    }

    function buildChecklistItemsFields(data: ChecklistItemDTO): void {
        const index = checklists?.findIndex(
            (item) => item.id === selectedList?.id
        )
        if (index !== undefined) {
            availableTypes &&
                setItemsFormFields(
                    checklistItemFormFields(
                        data,
                        availableTypes[index],
                        humanInteractionTypes as ChecklistHumanInteractionTypeBO[]
                    )
                )
        }
    }

    async function submitChecklistItem(data: ChecklistItemDTO): Promise<void> {
        const index = getChecklistIndex(checklists, selectedList)
        if (index !== undefined && checklists) {
            checklists[index].checklistItems.push(data)

            generalPutAPI(API_ENDPOINTS.checklists, checklists[index]).then(
                () => {
                    buildChecklist()
                }
            )
        }
    }

    function renderSelectedChecklistDetail(): ReactElement | boolean {
        return (
            selectedList !== undefined && (
                <>
                    <Heading size={'lg'} mb={4}>
                        <Box mr={2} display={'inline'}>
                            {selectedList.description}
                        </Box>
                        <PopupActionButton
                            actionName={'edit'}
                            buttonVariant={ButtonVariant.SOLID}
                            onConfirm={onPopupConfirm}
                            content={
                                <>
                                    <DynamicForm<ChecklistDTO>
                                        data={selectedList}
                                        formFields={checklistFormFields()}
                                        hideSubmit={true}
                                        formSubmitReference={
                                            formSubmitReference
                                        }
                                        onSubmit={(
                                            data: ChecklistDTO
                                        ): void => {
                                            generalPutAPI(
                                                API_ENDPOINTS.checklists,
                                                data
                                            ).then(() => {
                                                buildChecklist()
                                            })
                                        }}
                                    ></DynamicForm>
                                </>
                            }
                            title={'createChecklist'}
                        />
                    </Heading>
                    <GenericBox mb={4} p={4}>
                        <DynamicGrid
                            tableId="contractTemplateChecklistTable2"
                            columns={checklistItemsColumns}
                            rowData={selectedList.checklistItems}
                            pagination={false}
                            rowMultiSelectWithClick={false}
                            headers={
                                <PopupActionButton
                                    actionName={'addItems'}
                                    buttonVariant={ButtonVariant.SOLID}
                                    onConfirm={onPopupConfirm}
                                    content={
                                        <>
                                            <DynamicForm<ChecklistItemDTO>
                                                data={{
                                                    id: 0,
                                                    itemType:
                                                        ChecklistItemType.ContractDocumentCreated,
                                                    checklistHumanInteractionTypeId:
                                                        undefined,
                                                    order: 0,
                                                    isOptional: false,
                                                    isActive: false,
                                                    isCompleted: false,
                                                    userNote: '',
                                                }}
                                                formFields={itemsFormFields}
                                                onFormChange={(
                                                    data: ChecklistItemDTO
                                                ): void =>
                                                    buildChecklistItemsFields(
                                                        data
                                                    )
                                                }
                                                hideSubmit={true}
                                                formSubmitReference={
                                                    formSubmitReference
                                                }
                                                onSubmit={submitChecklistItem}
                                            ></DynamicForm>
                                        </>
                                    }
                                    title={'addChecklistItem'}
                                />
                            }
                        ></DynamicGrid>
                    </GenericBox>
                </>
            )
        )
    }

    return (
        <Flex flexDirection={'column'}>
            {renderAllChecklists()}
            {renderSelectedChecklistDetail()}
            {itemToEdit && (
                <ContractTemplateChecklistEditItemModal
                    itemToEdit={itemToEdit}
                    checklistItemFormFields={() =>
                        checklistItemFormFields(
                            itemToEdit,
                            availableTypes[
                                getChecklistIndex(
                                    checklists,
                                    selectedList
                                ) as number
                            ],
                            humanInteractionTypes as ChecklistHumanInteractionTypeBO[],
                            true
                        )
                    }
                    onSubmitItemToEdit={onSubmitItemToEdit}
                    isModalOpen={true}
                    modalTitle={`${translate('editChecklistItem')}: ${translate(
                        camelCase(
                            ChecklistItemType[itemToEdit.itemType as number]
                        )
                    )}`}
                    onClose={(): void => setItemToEdit(undefined)}
                ></ContractTemplateChecklistEditItemModal>
            )}
        </Flex>
    )
}
function getChecklistIndex(
    checklists: ChecklistDTO[] | undefined,
    selectedList: ChecklistDTO | undefined
) {
    return checklists?.findIndex((item) => item.id === selectedList?.id)
}
