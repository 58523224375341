import { AgChartOptions } from 'ag-charts-community'
import { AgCharts } from 'ag-charts-react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

import { Box, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import {
    MonthlyFinancialSummary,
    ProductType,
} from '../../../utils/types/types'

// Utility function to format large numbers
const formatNumber = (number: number) => {
    if (number >= 1000000) return `${(number / 1000000).toFixed(1)}M`
    if (number >= 1000) return `${(number / 1000).toFixed(1)}k`
    return number.toString()
}

interface TransformedData {
    month: string
    expected: number
    committed: number
}

// Transform API data into a format suitable for the chart
const transformData = (
    apiData: MonthlyFinancialSummary[]
): TransformedData[] => {
    const sortedData = apiData.sort(
        (a, b) => a.year - b.year || a.month - b.month
    )

    return sortedData.map((item) => ({
        month: new Date(item.year, item.month).toLocaleString('default', {
            month: 'short',
        }),
        expected: item.revenue,
        committed: item.revenue - 20,
    }))
}

const mockData: MonthlyFinancialSummary[] = [
    { year: 2024, month: 0, revenue: 150 },
    { year: 2024, month: 1, revenue: 160 },
    { year: 2024, month: 2, revenue: 170 },
    { year: 2024, month: 3, revenue: 180 },
    { year: 2024, month: 4, revenue: 190 },
    { year: 2024, month: 5, revenue: 200 },
    { year: 2024, month: 6, revenue: 210 },
    { year: 2024, month: 7, revenue: 220 },
    { year: 2024, month: 8, revenue: 230 },
    { year: 2024, month: 9, revenue: 240 },
    { year: 2024, month: 10, revenue: 250 },
    { year: 2024, month: 11, revenue: 260 },
]

interface MRRLineChartProps {
    selectedProduct?: ProductType
    contractTemplate?: number
    startDate: Date
    endDate: Date
}

const MRRLineChart = ({
    selectedProduct,
    contractTemplate,
    startDate,
    endDate,
}: MRRLineChartProps) => {
    const [data, setData] = useState<TransformedData[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [currentRevenue, setCurrentRevenue] = useState<number | null>(null)
    const [previousRevenue, setPreviousRevenue] = useState<number | null>(null)
    const [percentageIncrease, setPercentageIncrease] = useState<number | null>(
        null
    )
    const [previousRevenues, setPreviousRevenues] = useState<number[]>([])
    const backgroundColor = useColorModeValue('#fff', '#2d3748')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.dashboardMonthlyRevenue,
                    {
                        startDate: format(startDate, 'yyyy-MM-dd'),
                        endDate: format(endDate, 'yyyy-MM-dd'),
                        productId: contractTemplate,
                        productType: selectedProduct,
                    }
                )

                if (!response.isOk) {
                    throw new Error('Network response was not ok')
                }

                const apiData: MonthlyFinancialSummary[] = response.data
                handleChartData(apiData)
            } catch (err) {
                console.error('Failed to fetch data:', err)
                handleChartData(mockData)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [selectedProduct, contractTemplate, startDate, endDate])

    const handleChartData = (apiData: MonthlyFinancialSummary[]) => {
        const sortedChartData = [...apiData].sort(
            (entryA, entryB) =>
                entryA.year - entryB.year || entryA.month - entryB.month
        )
        setData(transformData(sortedChartData))

        const lastMonthRevenue =
            sortedChartData[sortedChartData.length - 1]?.revenue || 0
        const prevMonthRevenue =
            sortedChartData[sortedChartData.length - 2]?.revenue || 0
        const lastSixMonthsRevenues = sortedChartData
            .slice(-6)
            .reverse()
            .map((item) => item.revenue)

        setCurrentRevenue(lastMonthRevenue)
        setPreviousRevenue(prevMonthRevenue)
        setPreviousRevenues(lastSixMonthsRevenues)

        const increase =
            ((lastMonthRevenue - prevMonthRevenue) / prevMonthRevenue) * 100
        setPercentageIncrease(increase)
    }

    if (loading) {
        return (
            <Flex justifyContent="center" alignItems="center" flex="1">
                <Spinner size="xl" color="teal.500" />
            </Flex>
        )
    }

    const options: AgChartOptions = {
        data,
        series: [
            {
                type: 'line',
                xKey: 'month',
                yKey: 'expected',
                yName: 'Expected MRR',
            },
            {
                type: 'line',
                xKey: 'month',
                yKey: 'committed',
                yName: 'Committed MRR',
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: { rotation: 0 },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params: { value: number }) =>
                        formatNumber(params.value),
                },
            },
        ],
        legend: {
            enabled: true,
            position: 'bottom',
        },
    }

    return (
        <Box position="relative">
            <Flex
                height="400px"
                direction="column"
                bg={backgroundColor}
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
                p={4}
                borderRadius="lg"
                flex="1"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
            >
                <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
                    Monthly Recurring Revenue
                </Text>

                <Box flex="1" width="100%" height="300px">
                    {data.length > 0 ? (
                        <AgCharts options={options} />
                    ) : (
                        <Text color="red.500">
                            No data available for rendering.
                        </Text>
                    )}
                </Box>

                <Flex
                    position="absolute"
                    gap={4}
                    alignItems={'center'}
                    top="40px"
                    right="20px"
                    bg="white"
                    p={2}
                    borderRadius="md"
                    boxShadow="md"
                >
                    <Text fontSize="2xl" fontWeight="bold">
                        ${formatNumber(currentRevenue as number)}
                    </Text>
                    <Text fontSize="md" color="gray.500">
                        from ${formatNumber(previousRevenue as number)}
                    </Text>
                    <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color={
                            percentageIncrease && percentageIncrease > 0
                                ? 'green.500'
                                : 'red.500'
                        }
                    >
                        {percentageIncrease
                            ? `${percentageIncrease.toFixed(2)}%`
                            : ''}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                mt={4}
                width="100%"
                bg={backgroundColor}
                p={4}
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
                <Box>
                    <Text fontSize="2xl" fontWeight="bold">
                        ${formatNumber(currentRevenue as number)}
                    </Text>
                    <Text color="gray.500">Current MRR</Text>
                </Box>
                {previousRevenues.map(
                    (rev, idx) =>
                        idx > 0 && (
                            <Box key={idx} textAlign="center">
                                <Text fontSize="lg" fontWeight="bold">
                                    ${formatNumber(rev)}
                                </Text>
                                <Text color="gray.500">
                                    {idx === 1
                                        ? '1 Month'
                                        : `${idx + 1} Months`}
                                </Text>
                            </Box>
                        )
                )}
            </Flex>
        </Box>
    )
}

export default MRRLineChart
