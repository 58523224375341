import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    SimpleGrid,
} from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import { ContractAssetDTO } from '../../../../../utils/types/types'
import DatePicker from '../../../../../components/datePicker/DatePicker.component'

interface SubscriptionClosePopupInterface extends ModalSharedProps {
    asset?: ContractAssetDTO | null
    onConfirm: (asset: ContractAssetDTO | null | undefined) => void
}

function SubscriptionClosePopupContainer({
    asset,
    onConfirm,
    onClose,
}: SubscriptionClosePopupInterface): ReactElement {
    const translate = useTranslation().t
    const [assetItem, setAssetItem] = useState<
        ContractAssetDTO | null | undefined
    >(asset)

    return (
        <Flex gap={10} direction={'column'}>
            <SimpleGrid columns={2} gap={4}>
                <FormControl>
                    <FormLabel>{translate('lockInPeriod')}</FormLabel>
                    <Input
                        value={assetItem?.lockInPeriod}
                        type="number"
                        min={0}
                        isDisabled={true}
                    ></Input>
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('cancellationPeriod')}</FormLabel>
                    <Input
                        value={assetItem?.cancellationPeriod}
                        type="number"
                        min={0}
                        isDisabled={true}
                    ></Input>
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('endDate')}</FormLabel>
                    <DatePicker
                        name="endDate"
                        value={assetItem?.endDate ?? undefined}
                        onChange={(date: Date): void => {
                            if (date) {
                                setAssetItem({
                                    ...assetItem,
                                    endDate: date.toISOString(),
                                } as any)
                            }
                        }}
                    />
                </FormControl>
            </SimpleGrid>
            <HStack alignItems={'center'} justifyContent={'flex-end'}>
                <Button
                    onClick={(): void => {
                        onClose()
                    }}
                >
                    {translate('cancel')}
                </Button>
                <Button
                    onClick={(): void => {
                        onConfirm(assetItem)
                        onClose()
                    }}
                >
                    {translate('confirm')}
                </Button>
            </HStack>
        </Flex>
    )
}

export default withModalHOC<SubscriptionClosePopupInterface>(
    SubscriptionClosePopupContainer
)
