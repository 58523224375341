import { Checkbox, Heading } from '@chakra-ui/react'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import GenericBox from '../../../../components/genericBox/genericBox'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import {
    ERPParametersDTO,
    UpdateExternalPartnerSystemSetupCommand,
} from '../../../../utils/types/types'
import {
    GroupInvoiceLinesByMappingKey,
    getGroupInvoiceLinesByMappingValue,
} from '../ERPSetup.config'
import { useERP } from '../../../../services/erp/ERP.services'

interface IERPSetupSettings {
    product: ERPParametersDTO
    ERPSetup: UpdateExternalPartnerSystemSetupCommand
    isGroupERPChecked: boolean
    setIsGroupERPChecked: Function
}

export default function ERPSetupSettings({
    isGroupERPChecked,
    setIsGroupERPChecked,
}: IERPSetupSettings): ReactElement {
    const { ERPSetup, product } = useERP()
    const translate = useTranslation().t

    const loadGroupInvoiceLinesByMapping = async (
        productId: number
    ): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.parameter}?ProductId=${productId}&ParameterKey=${GroupInvoiceLinesByMappingKey}`
        )
        if (response.isOk) {
            setIsGroupERPChecked(
                getGroupInvoiceLinesByMappingValue(productId, response.data)
            )
        } else {
            setIsGroupERPChecked(false)
        }
    }

    useEffect((): void => {
        if (product?.productId) {
            loadGroupInvoiceLinesByMapping(product.productId)
        }
    }, [ERPSetup, product?.productId])

    return (
        <>
            <Heading
                alignItems="center"
                display="flex"
                mb={4}
                mt={8}
                size={'lg'}
            >
                {translate('settings')}
            </Heading>
            <GenericBox
                animation="alternate"
                borderRadius={'md'}
                flexDirection={'column'}
                mb={4}
                p={6}
                width={'100%'}
            >
                <Checkbox
                    onChange={(): void =>
                        setIsGroupERPChecked(!isGroupERPChecked)
                    }
                    isChecked={isGroupERPChecked}
                >
                    {translate('groupERP')}
                </Checkbox>
            </GenericBox>
        </>
    )
}
