import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
} from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    standaloneToast,
} from '../../../../../utils/functions.utils'

function CreateBundleComponent(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const { contract } = useContractService()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [bundleName, setBundleName] = useState<string>('')

    const handleBundleNameChange = (event: any): void =>
        setBundleName(event.target.value)

    const handleCreateBundle = async (): Promise<void> => {
        if (!contract?.contractNumber) return

        const loadingID = globalLoading()

        const request = await generalPostAPI(
            API_ENDPOINTS.contractActionsCreateBundle(contract.contractNumber),
            {
                bundleName,
                contractNumber: contract.contractNumber,
            }
        )
        if (!request.isOk) {
            standaloneToast(baseErrorToastOptions(request.message))
            return
        }

        standaloneToast(baseSuccessToastOptions(translate('bundleCreated')))
        stopGlobalLoading(loadingID)
        props.onClose()
    }

    return (
        <Box>
            <FormControl mb={3}>
                <FormLabel>{translate('bundleName')}</FormLabel>
                <Input onChange={handleBundleNameChange}></Input>
            </FormControl>
            <HStack
                mt={4}
                w={'100%'}
                display={'flex'}
                justifyContent={'flex-end'}
            >
                <Button isDisabled={!bundleName} onClick={handleCreateBundle}>
                    {translate('createBundle')}
                </Button>
            </HStack>
        </Box>
    )
}

export default withModalHOC(CreateBundleComponent)
