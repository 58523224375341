import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Select, Text, useToast } from '@chakra-ui/react'

import DynamicForm from '../../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../../../utils/functions.utils'
import {
    ParameterDTO,
    PartnerParameterDefinitionDTO,
    ProductDTO,
} from '../../../../../utils/types/types'
import addProductParameterFields from './AddProductParameter.config'

interface AddProductParameterProps extends ModalSharedProps {
    definitions: PartnerParameterDefinitionDTO[]
    onSubmitCallback: Function
    products: ProductDTO[]
    parameters: ParameterDTO[]
}
function AddProductParameter({
    onSubmitCallback,
    onClose,
    definitions,
    products,
    parameters,
}: AddProductParameterProps): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()

    const newParameter: ParameterDTO = {
        key: '',
        value: '',
    }
    const [selectedDefinition, setSelectedDefinition] =
        useState<PartnerParameterDefinitionDTO>()
    const [selectedProduct, setSelectedProduct] = useState<ProductDTO>()

    return (
        <Box mt={4}>
            <Text fontWeight={'500'}>{translate('parameter')}</Text>
            <Select
                my={'8px'}
                placeholder="selectParameter"
                onChange={(elem): void =>
                    setSelectedDefinition(
                        definitions.find(
                            (item) => item.key === elem.target.value
                        )
                    )
                }
            >
                {definitions.map((item) => (
                    <option key={item.key} value={item.key}>
                        {item.key}
                    </option>
                ))}
            </Select>
            <Text fontWeight={'500'}>{translate('product')}</Text>

            <Select
                my={'8px'}
                placeholder="Select Product"
                onChange={(elem): void => {
                    setSelectedProduct(
                        products.find(
                            (item) => item.id.toString() === elem.target.value
                        )
                    )
                }}
            >
                {products.map((item) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Select>
            {selectedDefinition && (
                <DynamicForm<Partial<ParameterDTO>>
                    data={newParameter}
                    formFields={addProductParameterFields(selectedDefinition)}
                    onSubmit={async (data: ParameterDTO): Promise<void> => {
                        const parameter = {
                            ...data,
                            productId: selectedProduct?.id || null,
                            key: selectedDefinition?.key,
                        }

                        const existingParameter = parameters.some(
                            (item) =>
                                item.key === selectedDefinition.key &&
                                item.productId === selectedProduct?.id
                        )
                        if (!existingParameter) {
                            selectedProduct &&
                                (await generalPostAPI(
                                    `${
                                        API_ENDPOINTS.parameter
                                    }${`?productId=${selectedProduct.id}`}`,
                                    parameter
                                ))
                            onSubmitCallback(parameter)
                            onClose()
                        } else {
                            toast(
                                baseErrorToastOptions(
                                    translate('parameterAlreadyExists')
                                )
                            )
                        }
                    }}
                    submitText={'confirm'}
                    submitButtonProps={{ float: 'right', mt: 2 }}
                />
            )}
        </Box>
    )
}

export default withModalHOC(AddProductParameter)
