import { ReactElement } from 'react'

import {
    Flex,
    Skeleton,
    Stack,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react'

export default function TableSkeleton(): ReactElement {
    const [isMobile] = useMediaQuery('(max-width: 640px)')
    // TODO: Add Animation timeout and fallback message
    const lightColor = useColorModeValue('primary.500', 'secondary.500')
    const darkColor = useColorModeValue('primary.300', 'secondary.300')
    return (
        <Stack>
            {isMobile ? (
                <Stack spacing={3} mt={3}>
                    <Skeleton
                        height="160px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="160px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="160px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="160px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Flex m={'0.5rem 0'} gap="1rem" justifyContent={'flex-end'}>
                        <Skeleton
                            height="20px"
                            width="60px"
                            startColor={lightColor}
                            endColor={darkColor}
                        />
                        <Skeleton
                            height="20px"
                            width="60px"
                            startColor={lightColor}
                            endColor={darkColor}
                        />
                    </Flex>
                </Stack>
            ) : (
                <Stack spacing={4} mt={10}>
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Skeleton
                        height="20px"
                        startColor={lightColor}
                        endColor={darkColor}
                    />
                    <Flex
                        m={'0.5rem 0'}
                        gap="0.5rem"
                        justifyContent={'flex-end'}
                    >
                        <Skeleton
                            height="20px"
                            width="60px"
                            startColor={lightColor}
                            endColor={darkColor}
                        />
                        <Skeleton
                            height="20px"
                            width="60px"
                            startColor={lightColor}
                            endColor={darkColor}
                        />
                    </Flex>
                </Stack>
            )}
        </Stack>
    )
}
