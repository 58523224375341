import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import AddCustomerComponent from '../../../../features/addCustomer/AddCustomer.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import { useOffer } from '../../../../services/contexts/Offer.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
} from '../../../../utils/functions.utils'
import { CustomerDTO } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'

function CustomerTableComponent({
    setCustomerEmail,
}: {
    setCustomerEmail: Function
}): ReactElement {
    const [isAddCustomerOpen, setAddCustomer] = useBoolean()
    const [currCustomer, setCurrCustomer] = useState<CustomerDTO>()
    const translate = useTranslation().t
    const toast = useToast()

    const { creditSafeCustomers, updateOffer, companyCustomers } = useOffer()

    const setCustomer = (customer: any): void => {
        setCurrCustomer(customer)
        if (!customer?.customerNumber) {
            setAddCustomer.on()
            return
        }

        const offerCustomer = {
            ...customer,
            shouldCreateCustomer: !customer.cvr,
            creditSafeConnectId: customer.creditSafeConnectId,
            cpr: customer.cpr,
        }

        updateOffer('customer', offerCustomer)
        if (setCustomerEmail) {
            setCustomerEmail(offerCustomer?.email ?? '')
        }
    }

    const handleSaveCustomer = async (customer: CustomerDTO): Promise<void> => {
        const response = await generalPostAPI(API_ENDPOINTS.customer, customer)
        if (response?.isOk) {
            toast(baseSuccessToastOptions(translate('customerCreated')))
        } else {
            toast(baseErrorToastOptions(response?.message))
        }
        setAddCustomer.off()
        setCustomer(response?.data)
    }

    const customersHeaders = [
        { headerName: translate('customerNumber'), field: 'customerNumber' },
        { headerName: translate('name'), field: 'name' },
        { headerName: translate('customerCvr'), field: 'cvr', flex: 1 },
        {
            ...ColumnActionConfiguration(translate, (params: any) => (
                <Tooltip
                    label={translate('addCustomer')}
                    placement="top"
                    hasArrow
                >
                    <IconButton
                        aria-label="Add"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void => setCustomer(params.data)}
                        icon={<AddIcon />}
                    />
                </Tooltip>
            )),
        },
    ]

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                tableId="customerComponentTable"
                columns={customersHeaders}
                rowData={[...creditSafeCustomers, ...companyCustomers]}
                headers={
                    <Button
                        onClick={(): void => {
                            setAddCustomer.on()
                        }}
                    >
                        {translate('addRow')}
                    </Button>
                }
            />
            <AddCustomerComponent
                isModalOpen={isAddCustomerOpen}
                onClose={() => setAddCustomer.off()}
                modalTitle={translate('addProduct')}
                handleSaveProduct={(data: CustomerDTO) =>
                    handleSaveCustomer(data)
                }
                customer={currCustomer}
                maxWidth="1000px"
            />
        </Flex>
    )
}

export default CustomerTableComponent
