import { ReactElement } from 'react'

import { Box, Flex } from '@chakra-ui/react'

interface GenericHeaderComponentProps {
    elements: ReactElement[]
}
export default function GenericHeaderComponent({
    elements,
}: GenericHeaderComponentProps): ReactElement {
    function renderElements(): ReactElement[] {
        return elements?.map((element: any, index: number) => (
            <Box key={index} display={'flex'} gap={4} flex="1">
                {element}
            </Box>
        ))
    }

    return (
        <Box w="100%" height={'inherit'}>
            <Box
                className="contract-detail-header"
                alignItems={'center'}
                justifyContent={'center'}
                flexWrap={'wrap'}
                height={'inherit'}
                width="100%"
                gap={0}
                display={['none', 'none', 'flex']}
            >
                <Flex
                    w="100%"
                    mb={5}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    width="100%"
                    gap={0}
                    mt={5}
                >
                    {renderElements()}
                </Flex>
            </Box>
        </Box>
    )
}
