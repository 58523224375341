import orderBy from 'lodash/orderBy'

import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import { BaseServiceDTO } from '../../../utils/types/types'

type OptionalBaseServiceDTO = Partial<BaseServiceDTO>

export const servicesHeaders = (
    selectionEnabled: boolean,
    translate: any,
    actionsFn: any
): any[] => {
    const headers: any = [
        {
            headerCheckboxSelection: selectionEnabled,
            checkboxSelection: selectionEnabled,
            field: 'baseServiceNumber',
            filter: 'agTextColumnFilter',
            headerName: translate('id'),
        },
        {
            field: 'description',
            filter: 'agTextColumnFilter',
            headerName: translate('description'),
            flex: 1,
        },
    ]

    if (actionsFn !== undefined) {
        headers.push({
            ...ColumnActionConfiguration(
                translate,
                (params: any) => actionsFn(params),
                100
            ),
        })
    }
    return headers
}
export const getServicesRows = (items?: Partial<BaseServiceDTO>[]): any =>
    items ? orderBy(items, 'id', 'desc') : undefined
export const DEFAULT_SERVICE_ITEM: OptionalBaseServiceDTO = {}
