import { useTranslation } from 'react-i18next'

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Flex, Tag, Text } from '@chakra-ui/react'

import { toCamelCase } from '../../../../utils/functions.utils'
import { AssetStatus, GetBaseAssetDetail } from '../../../../utils/types/types'

function InventoryHeaderComponent({
    baseAsset,
}: {
    baseAsset: GetBaseAssetDetail
}) {
    const translate = useTranslation().t
    return (
        <Flex gap={2} mt={4}>
            <Text fontSize="2xl">{`${baseAsset?.baseAssetNumber} - ${baseAsset?.assetType?.assetArcheType}`}</Text>
            <Tag size={'sm'} fontSize={12} variant="subtle">
                {translate(toCamelCase(AssetStatus[baseAsset?.assetStatus]))}
            </Tag>
        </Flex>
    )
}

export default InventoryHeaderComponent
