import { formatDate } from '../../../../utils/localization/culture.utils'

export const columnsConfig = (
    translate: Function,
    hasSelection: boolean,
    formatValue: Function
) => [
    {
        field: 'assetNumber',
        filter: 'agTextColumnFilter',
        headerName: translate('assetNumber'),
        headerCheckboxSelection: hasSelection,
        checkboxSelection: hasSelection,
    },
    {
        field: 'contractNumber',
        filter: 'agTextColumnFilter',
        headerName: translate('contract'),
    },
    {
        field: 'initialBalance',
        filter: 'agNumberColumnFilter',
        headerName: translate('initialBalance'),
        valueFormatter: (params: any) => formatValue(params, 'initialBalance'),
    },
    {
        field: 'period',
        filter: 'agNumberColumnFilter',
        headerName: translate('period'),
    },
    {
        field: 'endBalance',
        filter: 'agNumberColumnFilter',
        headerName: translate('endBalance'),
        valueFormatter: (params: any) => formatValue(params, 'endBalance'),
    },
    {
        field: 'periodStartDate',
        filter: 'agDateColumnFilter',
        headerName: translate('periodStart'),
        valueGetter: (params: any) => formatDate(params?.data?.periodStartDate),
    },
    {
        field: 'periodEndDate',
        filter: 'agDateColumnFilter',
        headerName: translate('periodEnd'),
        valueGetter: (params: any) => formatDate(params?.data?.periodEndDate),
    },
    {
        field: 'periodicPayment',
        filter: 'agNumberColumnFilter',
        headerName: translate('periodicPayment'),
    },
    {
        field: 'depreciation',
        filter: 'agNumberColumnFilter',
        headerName: translate('depreciation'),
        valueFormatter: (params: any) => formatValue(params, 'depreciation'),
    },
    {
        field: 'interestPayed',
        filter: 'agNumberColumnFilter',
        headerName: translate('interestPayed'),
        valueFormatter: (params: any) => formatValue(params, 'interestPayed'),
    },
]
