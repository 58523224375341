import { ReactElement } from 'react'

import { chakra, Flex, Skeleton, useColorModeValue } from '@chakra-ui/react'

export default function ToolbarSkeleton(): ReactElement {
    return (
        <chakra.div mt={3}>
            <Flex gap="0.8rem" justifyContent={'flex-end'}>
                <Skeleton
                    height="30px"
                    width="100px"
                    startColor={useColorModeValue(
                        'primary.500',
                        'secondary.500'
                    )}
                    endColor={useColorModeValue('primary.300', 'secondary.300')}
                />
                <Skeleton
                    height="30px"
                    width="100px"
                    startColor={useColorModeValue(
                        'primary.500',
                        'secondary.500'
                    )}
                    endColor={useColorModeValue('primary.300', 'secondary.300')}
                />
                <Skeleton
                    height="30px"
                    width="100px"
                    startColor={useColorModeValue(
                        'primary.500',
                        'secondary.500'
                    )}
                    endColor={useColorModeValue('primary.300', 'secondary.300')}
                />
            </Flex>
        </chakra.div>
    )
}
