import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'

import { getProductRuleByType } from '../../../utils/functions.utils'
import { ProductRuleDTO, ProductRuleType } from '../../../utils/types/types'
import {
    CALCULATION_TYPE_PAIRS,
    USAGE_TYPE_PAIRS,
} from '../../addAsset/addAsset.feature.config'
import { IAssetForm } from '../AssetForm.utils'

interface IAssetUsageForm extends IAssetForm {
    buildErrorList: any
    productRules: ProductRuleDTO[]
}

export default function AssetUsageForm({
    handleInputChange,
    contractAsset,
    actionType,
    buildErrorList,
    productRules,
}: IAssetUsageForm): ReactElement {
    const translate = useTranslation().t

    return (
        <>
            <FormControl>
                <FormLabel>{translate('usageType')}</FormLabel>
                <Select
                    value={contractAsset?.usageType}
                    isDisabled={actionType === 'view'}
                    placeholder={translate('select')}
                    onChange={(e): void => {
                        handleInputChange(Number(e.target.value), 'usageType')
                    }}
                >
                    {USAGE_TYPE_PAIRS.map((option: any, index: number) => (
                        <option key={index} value={option[0]}>
                            {translate(option[1])}
                        </option>
                    ))}
                </Select>
                {/* {buildErrorList?.('usageType')} */}
            </FormControl>
            <FormControl>
                <FormLabel>
                    {translate('usagePriceCalculationMethod')}
                </FormLabel>
                <Select
                    value={contractAsset?.usagePriceCalculationMethod}
                    isDisabled={actionType === 'view'}
                    placeholder={translate('select')}
                    onChange={(e) => {
                        handleInputChange(
                            Number(e.target.value),
                            'usagePriceCalculationMethod'
                        )
                    }}
                >
                    {CALCULATION_TYPE_PAIRS.map(
                        (option: any, index: number) => (
                            <option key={index} value={option[0]}>
                                {translate(option[1])}
                            </option>
                        )
                    )}
                </Select>
                {buildErrorList('usagePriceCalculationMethod')}
            </FormControl>
            <FormControl>
                <FormLabel>
                    {getProductRuleByType(
                        ProductRuleType.UsagePrice,
                        productRules
                    )?.customText || translate('pricePerUnitUnderUsageLimit')}
                </FormLabel>
                <Input
                    type="number"
                    isDisabled={actionType === 'view'}
                    value={contractAsset?.pricePerUnitUnderUsageLimit}
                    onChange={(e) =>
                        handleInputChange(
                            e.target.value,
                            'pricePerUnitUnderUsageLimit'
                        )
                    }
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    {getProductRuleByType(
                        ProductRuleType.UsagePriceOverLimit,
                        productRules
                    )?.customText || translate('pricePerUnitOverUsageLimit')}
                </FormLabel>
                <Input
                    type="number"
                    isDisabled={actionType === 'view'}
                    value={contractAsset?.pricePerUnitOverUsageLimit}
                    onChange={(e) =>
                        handleInputChange(
                            e.target.value,
                            'pricePerUnitOverUsageLimit'
                        )
                    }
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    {getProductRuleByType(
                        ProductRuleType.UsagePriceOverLimit,
                        productRules
                    )?.customText || translate('usageLimit')}
                </FormLabel>
                <Input
                    type="number"
                    isDisabled={actionType === 'view'}
                    value={contractAsset?.usageLimit}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'usageLimit')
                    }
                />
            </FormControl>
        </>
    )
}
