import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Stack, useToast } from '@chakra-ui/react'

import ExportedQueueInvoiceLines from '../../../../../../app/config/download/exportedQueueInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import { CustomerDetailInvoiceLineConfig } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.config'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import { useERP } from '../../../../../../services/erp/ERP.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../../../utils/functions.utils'
import {
    ExportStatus,
    ExternalPartnerSetup,
    InvoiceDTO,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'

export default function ExportQueuePage(): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const toast = useToast()
    const [data, setData] = useState<InvoiceDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [isSendEmailEnabled] = useState(true)

    const { activeSetup } = useERP()
    const isManualCSV = useMemo(
        () => activeSetup === ExternalPartnerSetup.ErpAlternativeFileCreation,
        []
    )

    const getData = () => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Initialized}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }

    useEffect(() => getData(), [])

    const sendInvoicesEmail = (invoices: InvoiceDTO[]) => {
        generalPostAPI(API_ENDPOINTS.invoiceActionsSend, {
            invoiceNumbers: invoices.map((i) => i.invoiceNumber),
        }).then((response) => {
            const failedInvoices = response.data?.failedInvoices
            if (!response.isOk && failedInvoices.length > 0) {
                toast(baseErrorToastOptions(translate('invoice sent failed')))
            } else if (response.isOk) {
                toast(
                    baseSuccessToastOptions(translate('invoice sent success'))
                )
            } else toast(baseErrorToastOptions(response.message))
        })
    }

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const fields = EXPORT_HEADERS(
        translate,
        formatValue,
        false,
        isDownloadEnabled || isSendEmailEnabled
    )

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="exportedQueueTable"
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <>
                            <Flex
                                justifyContent={'flex-end'}
                                alignItems="center"
                                gap={2}
                            >
                                {isManualCSV && (
                                    <Button
                                        isDisabled={!selectedItems?.length}
                                        onClick={() => {
                                            sendInvoicesEmail(selectedItems)
                                            setSelectedItems([])
                                        }}
                                    >
                                        {translate('send email')}{' '}
                                        {!!selectedItems?.length &&
                                            `(${selectedItems.length})`}
                                    </Button>
                                )}
                                <DownloadActions
                                    isDownloadEnabled={isDownloadEnabled}
                                    setDownloadEnabled={setDownloadEnabled}
                                    selectAllElements={selectAllElements}
                                    selectedItems={selectedItems}
                                    downloadConfig={ExportedQueueInvoiceLines}
                                    resetSelectedItems={() =>
                                        setSelectedItems([])
                                    }
                                />
                            </Flex>
                        </>
                    }
                    columns={[...fields]}
                    masterDetail={true}
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: CustomerDetailInvoiceLineConfig(
                                translate,
                                formatValue
                            ),
                        },
                        getDetailRowData: (params: any) => {
                            params.successCallback(params.data.lines)
                        },
                    }}
                    rowData={data}
                />
            </Stack>
        </>
    )
}
