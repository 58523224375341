import find from 'lodash/find'
import { GetVatDetail } from '../../../../../utils/types/types'

export const insuranceHeaders = (
    translate: any,
    VAT: GetVatDetail[],
    formatValue: Function
): any[] => {
    const headers: any = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: 'baseInsuranceNumber',
            filter: 'agTextColumnFilter',
            headerName: translate('id'),
        },
        {
            field: 'description',
            filter: 'agTextColumnFilter',
            headerName: translate('description'),
        },
        {
            field: 'vatId',
            filter: 'agTextColumnFilter',
            headerName: translate('vat'),
            valueGetter: (params: any) =>
                `${find(VAT, { id: params.data.vatId })?.percentage}%` || '',
        },
        {
            headerName: translate('deductiblePrice'),
            filter: true,
            field: 'deductiblePrice',
            valueFormatter: (params: any) =>
                formatValue(params, 'deductiblePrice'),
        },
        {
            headerName: translate('netPrice'),
            filter: true,
            field: 'netPrice',
            valueFormatter: (params: any) => formatValue(params, 'netPrice'),
        },
        {
            headerName: translate('premiumPrice'),
            filter: true,
            field: 'premiumPrice',
            valueFormatter: (params: any) =>
                formatValue(params, 'premiumPrice'),
        },
    ]
    return headers
}
