import { ReactElement, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Box } from '@chakra-ui/react'

import CustomFallbackComponent from './Fallback.component'

export default function ErrorBoundaryWrapper({
    children,
    id,
}: {
    children: ReactNode
    id: string
}): ReactElement {
    return (
        <Box id={id} h="inherit">
            <ErrorBoundary FallbackComponent={CustomFallbackComponent}>
                {children}
            </ErrorBoundary>
        </Box>
    )
}
