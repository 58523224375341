import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Stack, useToast } from '@chakra-ui/react'

import FailedInvoiceLines from '../../../../../../app/config/download/failedInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import { CustomerDetailInvoiceLineConfig } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.config'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../../../utils/functions.utils'
import { ExportStatus, InvoiceDTO } from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'

export default function FailedExportPage(): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const [data, setData] = useState<InvoiceDTO[]>()
    const toast = useToast()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)

    const onAddToQueue = async (): Promise<void> => {
        const exportedInvoicesList = selectedItems.map(
            (selectedInvoice: InvoiceDTO) => selectedInvoice.invoiceNumber
        )
        const response = await generalPostAPI(API_ENDPOINTS.export, {
            exportedInvoices: [...exportedInvoicesList],
        })

        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
            return
        }
        setSelectedItems([])
        getData()
        toast(baseSuccessToastOptions(translate('invoiceQueueOk')))
    }

    const fields = EXPORT_HEADERS(translate, formatValue, false, true)

    useEffect(() => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Failed}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }, [])

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const getData = () => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Failed}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="failedExportTable"
                    columns={[...fields]}
                    masterDetail={true}
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: CustomerDetailInvoiceLineConfig(
                                translate,
                                formatValue
                            ),
                        },
                        getDetailRowData: (params: any) => {
                            params.successCallback(params.data.lines)
                        },
                    }}
                    rowData={data}
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <Flex gap={2}>
                            <Button
                                isDisabled={!selectedItems?.length}
                                onClick={onAddToQueue}
                            >
                                {translate('addToQueue')}
                            </Button>
                            <DownloadActions
                                isDownloadEnabled={isDownloadEnabled}
                                setDownloadEnabled={setDownloadEnabled}
                                selectAllElements={selectAllElements}
                                selectedItems={selectedItems}
                                downloadConfig={FailedInvoiceLines}
                                resetSelectedItems={() => setSelectedItems([])}
                            />
                        </Flex>
                    }
                />
            </Stack>
        </>
    )
}
