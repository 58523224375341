import { ReactElement, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import HighlightComponent, {
    HighlightInterface,
} from '../../components/highlight/Highlight.component'
import { useUser } from '../../services/contexts/user.context'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../utils/localization/localization.utils'

function HighlightCustomText({ ...props }: any): ReactElement {
    return <HighlightComponent {...props} info={props.value} />
}

type NumericTextFieldProps = NumericFormatProps<HighlightInterface>

export default function NumberFormatterKeyInformation({
    ...props
}: NumericTextFieldProps): ReactElement {
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [JSON.stringify(user), JSON.stringify(props)])

    return (
        <NumericFormat
            {...props}
            key={JSON.stringify(format)}
            thousandSeparator={format.thousands}
            decimalSeparator={format.decimals}
            customInput={HighlightCustomText}
        />
    )
}
