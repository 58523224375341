import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { InfoIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, Select, Text } from '@chakra-ui/react'

import { ContractDTO } from '../../../../../utils/types/types'

interface ISelectPreterminationOffer {
    offers: ContractDTO[]
}

export default function SelectPreterminationOffer({
    offers,
}: ISelectPreterminationOffer): ReactElement {
    const translate = useTranslation().t
    const [selectedOffer, setSelectedOffer] = useState<string | undefined>()
    const navigate = useNavigate()

    return (
        <Box maxW={'2xl'}>
            <FormControl>
                <Flex>
                    <Select
                        onChange={(e: any): void =>
                            setSelectedOffer(e?.target?.value)
                        }
                        placeholder={translate('selectPreterminationOffer')}
                        borderRightRadius={0}
                        isDisabled={!offers.length}
                    >
                        {offers.map((offer) => (
                            <option
                                key={offer?.contractNumber}
                                value={offer?.contractNumber}
                            >
                                {offer?.contractNumber}
                            </option>
                        ))}
                    </Select>
                    <Button
                        borderLeftRadius={0}
                        isDisabled={!selectedOffer}
                        onClick={(): void => {
                            navigate(`/contracts/${selectedOffer ?? ''}`)
                            window.location.reload()
                        }}
                        px={6}
                    >
                        {translate('updateOffer')}
                    </Button>
                </Flex>
                {!offers.length && (
                    <Text mt={4} fontWeight={'medium'}>
                        <InfoIcon mr={2} />
                        {translate('noExistingPreterminationOffers')}
                    </Text>
                )}
            </FormControl>
        </Box>
    )
}
