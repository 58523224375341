import { AgCharts } from 'ag-charts-react'
import { useCallback, useEffect, useState } from 'react'

import { Box, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import { CustomerStatisticDto, ProductType } from '../../../utils/types/types'

// Helper function to transform API data into chart-compatible format
const transformData = (apiData: CustomerStatisticDto[]) =>
    apiData
        .sort((a, b) => a.year - b.year || a.month - b.month)
        .map((item) => ({
            year: item.year,
            month: new Date(item.year, item.month - 1).toLocaleString(
                'default',
                {
                    month: 'short',
                }
            ),
            churnRate: item.churnRate,
        }))

const getAllMonths = () =>
    Array.from({ length: 12 }, (_, index) =>
        new Date(0, index).toLocaleString('default', { month: 'short' })
    )

const normalizeData = (
    dataByYear: Record<
        number,
        { year: number; month: string; churnRate: number }[]
    >,
    allMonths: string[]
) =>
    Object.keys(dataByYear).reduce((acc, year: string) => {
        acc[Number(year)] = allMonths.map((month) => {
            const monthData = dataByYear[parseInt(year, 10)].find(
                (item) => item.month === month
            )
            return (
                monthData || { year: parseInt(year, 10), month, churnRate: 0 }
            )
        })
        return acc
    }, {} as Record<number, { year: number; month: string; churnRate: number }[]>)

interface ChurnRateBarChartProps {
    selectedProduct?: ProductType
    contractTemplate?: number
}

const ChurnRateBarChart: React.FC<ChurnRateBarChartProps> = ({
    selectedProduct,
    contractTemplate,
}) => {
    const [data, setData] = useState<
        { year: number; month: string; churnRate: number }[]
    >([])
    const [series, setSeries] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const backgroundColor = useColorModeValue('#fff', '#2d3748')

    // Fetch data function
    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await generalPostAPI(
                API_ENDPOINTS.dashboardCustomerChurnRate,
                {
                    productId: contractTemplate,
                    productType: selectedProduct,
                }
            )
            if (response.isOk) {
                const transformedData = transformData(response.data)
                setData(transformedData)
            } else {
                throw new Error('Network response was not ok')
            }
        } catch (err) {
            console.error('Failed to fetch data:', err)
        } finally {
            setLoading(false)
        }
    }, [contractTemplate, selectedProduct])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (data.length > 0) {
            const allMonths = getAllMonths()

            // Group data by year
            const groupedData = data.reduce((acc, item) => {
                const { year } = item
                if (!acc[year]) {
                    acc[year] = []
                }
                acc[year].push(item)
                return acc
            }, {} as Record<number, { month: string; churnRate: number }[]>)

            const normalizedData = normalizeData(groupedData, allMonths)

            // Generate series for each year
            const generatedSeries = Object.keys(normalizedData).map((year) => ({
                type: 'bar',
                xKey: 'month',
                yKey: 'churnRate',
                yName: `Churn Rate ${year}`,
                data: normalizedData[parseInt(year, 10)],
                fillOpacity: 0.8,
                tooltip: {
                    renderer: (params) => ({
                        content: `${params.xKey}: ${params.datum.churnRate}%`,
                    }),
                },
            }))

            setSeries(generatedSeries)
        }
    }, [data])

    if (loading) {
        return (
            <Flex justifyContent="center" alignItems="center" flex="1">
                <Spinner size="xl" color="teal.500" />
            </Flex>
        )
    }

    const options = {
        series,
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: { rotation: 0 },
                title: {
                    text: 'Month',
                    enabled: true,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params: { value: number }) =>
                        `${params.value}%`,
                },
                title: {
                    text: 'Churn Rate (%)',
                    enabled: true,
                },
            },
        ],
        legend: {
            enabled: true,
            position: 'bottom',
        },
        paddingInner: 0.1,
        paddingOuter: 0.2,
    }

    return (
        <Flex
            height={400}
            direction="column"
            bg={backgroundColor}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            p={4}
            borderRadius="lg"
            flex="1"
            overflow="hidden"
        >
            <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
                Monthly Churn Rate
            </Text>

            <Box flex="1" width="100%" height="100%" overflow="hidden">
                {series.length > 0 ? (
                    <AgCharts options={options} />
                ) : (
                    <Text color="red.500">
                        No data available for rendering.
                    </Text>
                )}
            </Box>
        </Flex>
    )
}

export default ChurnRateBarChart
