import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex } from '@chakra-ui/react'

import useDownload from '../../services/hooks/useDownload.hook'

export interface TableDownloadProps<T> {
    selectedItems: Array<T>
    downloadConfig: any
    resetSelectedItems?: Dispatch<SetStateAction<T[]>>
}
interface IDownloadActions<T> extends TableDownloadProps<T> {
    isDownloadEnabled: boolean
    setDownloadEnabled: Dispatch<SetStateAction<boolean>>
    selectAllElements: () => Promise<void>
    isDownloadLoading?: boolean
}

export default function DownloadActions<T>({
    selectedItems,
    selectAllElements,
    downloadConfig,
    isDownloadEnabled,
    setDownloadEnabled,
    resetSelectedItems,
    isDownloadLoading = false,
}: IDownloadActions<T>): ReactElement {
    const translate = useTranslation().t
    const { handleDownloadSelected, handleClose } = useDownload(
        selectedItems,
        downloadConfig,
        setDownloadEnabled
    )
    const [enableDownloadAll, setEnableDownloadAll] = useState(false)

    useEffect(() => {
        if (enableDownloadAll) {
            handleDownloadSelected(selectedItems)
            setEnableDownloadAll(false)
            resetSelectedItems?.([])
        }
    }, [JSON.stringify(selectedItems)])
    return (
        <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
            <React.Fragment>
                <Button
                    variant={isDownloadEnabled ? 'outline' : 'solid'}
                    onClick={() => {
                        !isDownloadEnabled
                            ? setDownloadEnabled(true)
                            : handleClose()
                    }}
                    isLoading={isDownloadLoading}
                >
                    {isDownloadEnabled
                        ? translate('cancel')
                        : translate('download')}
                </Button>
                {isDownloadEnabled && (
                    <>
                        <Button
                            onClick={(): void => {
                                handleDownloadSelected(selectedItems)
                                resetSelectedItems?.([])
                            }}
                        >
                            {translate('selectedRows')}
                        </Button>
                        <Button
                            variant="solid"
                            onClick={async (): Promise<void> => {
                                setEnableDownloadAll(true)
                                await selectAllElements()
                            }}
                        >
                            {translate('allData')}
                        </Button>
                    </>
                )}
            </React.Fragment>
        </Flex>
    )
}
