import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../utils/functions.utils'
import { CustomerDTO } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI, generalPutAPI } from '../API/general.api'

interface ICustomerContext {
    retrieveCustomer: Function
    updateCustomer: Function
    customer: Partial<CustomerDTO>
}

interface ICustomerContextProviderProps {
    children: ReactNode
}

export const CustomerContext = createContext({} as ICustomerContext)
export const useCustomer = (): ICustomerContext => useContext(CustomerContext)

function useProviderCustomer(): ICustomerContext {
    const toast = useToast()
    const translate = useTranslation().t
    const [customer, setCustomer] = useState<Partial<CustomerDTO>>({})
    const retrieveCustomer = async (customerNumber: string): Promise<void> => {
        const request = await generalGetAPI(
            `${API_ENDPOINTS.customer}/${customerNumber}`
        )
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
            return
        }
        setCustomer(request.data)
    }

    const updateCustomer = async (
        customerNumber: string,
        updatedCustomer: CustomerDTO
    ): Promise<void> => {
        const request = await generalPutAPI(
            API_ENDPOINTS.customerPut(customerNumber),
            updatedCustomer
        )
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
            return
        }
        setCustomer(request.data)
        toast(baseSuccessToastOptions(translate('customerUpdated')))
    }

    return { retrieveCustomer, customer, updateCustomer }
}

export const CustomerContextProvider = ({
    children,
}: ICustomerContextProviderProps): ReactElement => {
    const customer: ICustomerContext = useProviderCustomer()

    return (
        <CustomerContext.Provider value={customer}>
            {children}
        </CustomerContext.Provider>
    )
}
