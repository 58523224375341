import { useField } from 'formik'
import debounce from 'lodash/debounce'
import { FocusEventHandler, ReactElement, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'

import {
    chakra,
    Flex,
    Input,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react'

import { useUser } from '../../../../services/contexts/user.context'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../../../utils/localization/localization.utils'

export interface CustomFormikProps {
    type: string
    name: any
    onBlur: FocusEventHandler<HTMLInputElement>
    bgColor?: string
    borderLeftRadius?: string | number
    borderRightRadius?: string | number
    value: any
    disabled: boolean
    enableDecimal?: boolean
    decimalHouses?: number
    props: any
    stringValue?: boolean
}

export default function FormNumberInput({
    type,
    name,
    onBlur,
    borderLeftRadius,
    borderRightRadius,
    value,
    disabled,
    enableDecimal = true,
    decimalHouses = 2,
    stringValue = false,
    ...props
}: CustomFormikProps): ReactElement {
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})

    const [field, errors, { setValue }] = useField(name)
    const { ...otherFieldProps } = field
    const isColor = type === 'color'

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [JSON.stringify(user)])

    const debounceCallback = debounce((e) => {
        setValue(stringValue ? e.floatValue.toString() : e.floatValue)
    }, 1000)

    return (
        <chakra.div flexDirection={'column'} width="100%">
            <Flex gap={2} alignItems="center">
                <NumericFormat
                    {...otherFieldProps}
                    {...props.props}
                    value={value}
                    onValueChange={(e) => {
                        debounceCallback(e)
                    }}
                    step="any"
                    type="text"
                    onChange={undefined}
                    thousandSeparator={format.thousands}
                    decimalSeparator={format.decimals}
                    customInput={Input}
                    {...(isColor ? { b: field.value } : {})}
                    disabled={disabled}
                    onBlur={onBlur}
                    borderLeftRadius={borderLeftRadius}
                    borderRightRadius={borderRightRadius}
                    fixedDecimalScale={enableDecimal ? true : undefined}
                    decimalScale={enableDecimal ? decimalHouses : undefined}
                ></NumericFormat>
                {isColor && <Text>{field.value}</Text>}
            </Flex>

            {errors.error && (
                <UnorderedList>
                    {JSON.parse(errors.error).map(
                        (errorStr: string, index: number) => {
                            if (errorStr) {
                                return (
                                    <ListItem color="red" key={index}>
                                        {errorStr}
                                    </ListItem>
                                )
                            }
                            return null
                        }
                    )}
                </UnorderedList>
            )}
        </chakra.div>
    )
}
