import { Box, Flex, Heading, Text, chakra } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface ICloseContractCustomer {
    customerNumber: string
    customerName: string
}

export default function CloseContractCustomer({
    customerNumber,
    customerName,
}: ICloseContractCustomer): ReactElement {
    const translate = useTranslation().t

    return (
        <Box data-testid="close-contract-customer">
            <Heading as="h4" size="md" textTransform={'capitalize'} mt={4}>
                {translate('customer')}
            </Heading>
            <Flex
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Text textTransform={'capitalize'}>
                    <chakra.b>{customerNumber}</chakra.b>
                    {` - `}
                    <span>{customerName}</span>
                </Text>
            </Flex>
        </Box>
    )
}
