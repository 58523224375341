import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import { ColumnActionConfiguration } from '../../utils/functions.utils'

export const columnsConfig = (
    translate: Function,
    navigate: Function,
    selectionEnabled: boolean
) => [
    {
        field: 'customerNumber',
        headerName: translate('customerNumber'),
        filter: 'agTextColumnFilter',
        editable: false,
        headerCheckboxSelection: selectionEnabled,
        checkboxSelection: selectionEnabled,
    },
    {
        field: 'name',
        headerName: translate('name'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'cvr',
        headerName: translate('customerCvr'),
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'street',
        headerName: translate('street'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'city',
        headerName: translate('city'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            `${params?.data?.postalCode} ${params?.data?.city}` ?? '',
    },
    {
        field: 'department',
        headerName: translate('branchName'),
        valueGetter: 'data.advisor.branchName',
        filter: 'agTextColumnFilter',
    },
    {
        field: 'advisor',
        headerName: translate('advisor'),
        valueGetter: 'data.advisor.name',
        filter: 'agTextColumnFilter',
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('view')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="View"
                        size="sm"
                        variant={'outline'}
                        onClick={() =>
                            navigate(
                                `/customers/${params?.data.customerNumber}`,
                                {
                                    state: params?.data,
                                }
                            )
                        }
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
