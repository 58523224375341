/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import ErrorBoundaryWrapper from '../../../features/errorBoundary/ErrorBoundary.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../utils/functions.utils'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import { columnsConfig } from './Integrations.config'
import PenneoSetupContainer from './PenneoSetup.page'
import { ExternalPartnerSetup } from '../../../utils/types/types'

interface IIntegrationRows {
    name: string
    status: string
}

export default function IntegrationsPage(): ReactElement {
    const [isPenneoModalOpen, setIsPenneoModalOpen] = useState(false)
    const [integrations, setIntegrations] = useState<IIntegrationRows[]>()
    const translate = useTranslation().t
    const toast = useToast()

    const retrieveERPIntegration = async (): Promise<void> => {
        let integrations: any[] = []
        const request = await generalGetAPI(API_ENDPOINTS.ERPSetupActive)
        if (request.isOk) {
            integrations = request.data.map((integration: any) => ({
                name: integration.setupType,
                status: 'connected',
            }))
            setIntegrations(integrations)
        }
    }

    const startExternalAuthorization = async (
        externalSystem: ExternalPartnerSetup
    ): Promise<void> => {
        const response = await generalPostAPI(
            API_ENDPOINTS.externalAuthorization,
            {
                externalSystem,
            }
        )
        if (response.isOk && response.data.shouldForward) {
            window.location.replace(response.data.forwardTo)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    useEffect(() => {
        retrieveERPIntegration()
    }, [])

    return (
        <PageContainer>
            <ErrorBoundaryWrapper id="integrations-page-error">
                <DynamicGrid
                    tableId="integrationsTable"
                    columns={columnsConfig(
                        translate,
                        startExternalAuthorization
                    )}
                    rowData={integrations}
                    pagination={true}
                    rowMultiSelectWithClick={false}
                />
                <PenneoSetupContainer
                    modalTitle={'PenneoSetup'}
                    isModalOpen={isPenneoModalOpen}
                    onClose={(): void => setIsPenneoModalOpen(false)}
                />
            </ErrorBoundaryWrapper>
        </PageContainer>
    )
}
