import { ReactElement } from 'react'

import DynamicForm from '../../../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../../../../hoc/modal.hoc'
import { ChecklistItemDTO } from '../../../../../../utils/types/types'
import { ItemToEdit } from './ContractTemplateChecklist.component'

export interface ContractTemplateChecklistEditItem extends ModalSharedProps {
    itemToEdit: ItemToEdit
    checklistItemFormFields: Function
    onSubmitItemToEdit: Function
}

function ContractTemplateChecklistEditItem({
    itemToEdit,
    checklistItemFormFields,
    onSubmitItemToEdit,
}: ContractTemplateChecklistEditItem): ReactElement {
    return (
        <DynamicForm<ChecklistItemDTO>
            data={itemToEdit?.itemToEdit as ChecklistItemDTO}
            formFields={checklistItemFormFields()}
            onSubmit={(data: any): void => onSubmitItemToEdit(data)}
        ></DynamicForm>
    )
}

export const ContractTemplateChecklistEditItemModal = withModalHOC(
    ContractTemplateChecklistEditItem
)
