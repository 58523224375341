import {
    ContractAssetUsagePriceCalculationMethod,
    UsageType,
} from '../../utils/types/types'

export const USAGE_TYPE_PAIRS = [
    [UsageType.Hourly, 'usageHourly'],
    [UsageType.Daily, 'usageDaily'],
    [UsageType.Count, 'usageCount'],
    [UsageType.Distance, 'usageDistance'],
]

export const CALCULATION_TYPE_PAIRS = [
    [
        ContractAssetUsagePriceCalculationMethod.RoundUpToTheMinimum,
        'roundUpToTheMinimum',
    ],
    [
        ContractAssetUsagePriceCalculationMethod.PreciseCalculation,
        'preciseCalculation',
    ],
]
