import { CustomContractFinancingType } from '../../../../services/contract/Contract.services.config'
import { ContractDTO } from '../../../../utils/types/types'

export function MatchInterestFormFields(
    financing: CustomContractFinancingType,
    contract: Partial<ContractDTO>
) {
    return [
        {
            slug: 'totalPeriodicPayment',
            label: 'currentMonthlyPayment',
            inputProps: { type: 'number' },
            value: financing?.totalPeriodicPayment,
            disabled: true,
        },
        {
            slug: 'interest',
            label: 'currentInterest',
            inputProps: { type: 'number' },
            value: contract?.interest,
            disabled: true,
        },
        {
            slug: 'periodicPayment',
            label: 'newMonthlyPayment',
            inputProps: { type: 'number' },
        },
    ]
}
