import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Flex, useToast } from '@chakra-ui/react'

import FileUpload from '../../../../features/fileUpload/FileUpload.component'
import DocumentsTable from '../../../../features/genericTables/documentsTable/DocumentsTable.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalFilePostAPI,
    generalGetAPI,
} from '../../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
    getFileType,
    isAllowedToUpload,
    isFileAlreadyUploaded,
} from '../../../../utils/functions.utils'
import { DocumentMetaDataDto } from '../../../../utils/types/types'

const addMissingFields = (fileArr: any, addOns: any = null): any =>
    fileArr.map((file: any) => {
        const { fileType, fileExtension, documentType } = getFileType(
            file.name,
            file
        )
        if (addOns) {
            return { ...file, fileType, fileExtension, documentType, ...addOns }
        }
        return { ...file, fileType, fileExtension, documentType }
    })

export default function CustomerDetailsDocuments(): ReactElement {
    const [documents, setDocuments] = useState<DocumentMetaDataDto[]>()
    const toast = useToast()
    const [localLoading, setLocalLoading] = useState<string[]>([])
    const translate = useTranslation().t
    const params = useParams<{ customerNumber?: string }>()
    const CustomerNumber = params.customerNumber

    const fetchDocuments = async (): Promise<void> => {
        setLocalLoading([...localLoading, 'loading'])
        const documentsResponse = await generalGetAPI(
            API_ENDPOINTS.documentByCustomer + CustomerNumber
        )
        setLocalLoading(localLoading.splice(0, 1))
        if (documentsResponse.isOk) {
            setDocuments(addMissingFields(documentsResponse.data))
        }
    }

    useEffect((): void => {
        fetchDocuments()
    }, [])

    const uploadDocument = async (file: any): Promise<void> => {
        setLocalLoading([...localLoading, 'loading'])

        if (!file) {
            toast(baseInfoToastOptions(translate('chooseFile')))
        } else if (!isAllowedToUpload(file.name)) {
            toast(baseErrorToastOptions(translate('fileExtNotAllowed')))
        } else if (isFileAlreadyUploaded(file.name, documents)) {
            toast(baseErrorToastOptions(translate('fileAlreadyUploaded')))
        } else {
            const formData = new FormData()
            formData.append('file', file)

            const response = await generalFilePostAPI(
                `${API_ENDPOINTS.documentByCustomer}${CustomerNumber}`,
                formData
            )
            if (!response.isOk) {
                setLocalLoading(localLoading.splice(0, 1))
                toast(baseErrorToastOptions(translate('fileError')))
                return
            }
            fetchDocuments()
            toast(baseSuccessToastOptions(translate('documentUploaded')))
        }
        setLocalLoading(localLoading.splice(0, 1))
    }

    return (
        <>
            <Flex mb={8} flexDirection="column" width={'100%'} gap={4}>
                <FileUpload handleSubmission={uploadDocument} />
            </Flex>
            <DocumentsTable
                documents={documents}
                fetchDocuments={fetchDocuments}
            />
        </>
    )
}
