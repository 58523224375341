type ColorInput = string
type ColorFormat = 'hex' | 'rgb'

function hexToNumber(value: string): number {
    let hex = value.replace('#', '')
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map((char) => char + char)
            .join('')
    }
    return parseInt(hex, 16)
}

function rgbStringToNumber(rgb: string): number {
    const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/
    const match = rgb.match(rgbRegex)

    if (!match) {
        throw new Error('Invalid RGB color.')
    }

    const [r, g, b] = match.slice(1, 4).map(Number)

    return r * 256 * 256 + g * 256 + b
}

export function colorToNumber(color: ColorInput): number {
    if (color.startsWith('#')) {
        return hexToNumber(color)
    }
    if (color.startsWith('rgb')) {
        return rgbStringToNumber(color)
    }
}

export function isValidColor(color: ColorInput): boolean {
    const hexRegex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/
    const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/

    if (color.startsWith('#')) {
        return hexRegex.test(color)
    }
    if (color.startsWith('rgb')) {
        const match = color.match(rgbRegex)
        if (!match) {
            return false
        }
        const [r, g, b] = match.slice(1, 4).map(Number)
        return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255
    }
    return false
}

export function numberToColor(
    num: number,
    format: ColorFormat = 'hex'
): string {
    const r = Math.floor(num / (256 * 256))
    const g = Math.floor((num % (256 * 256)) / 256)
    const b = num % 256

    if (format === 'rgb') {
        return `rgb(${r}, ${g}, ${b})`
    } // format === 'hex'
    return `#${r.toString(16).padStart(2, '0')}${g
        .toString(16)
        .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`.toUpperCase()
}
