import i18next, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { IApiResponse } from '../interfaces/interfaces'
import { CountryCultures } from '../utils/localization/culture.utils'
import { CountryCode } from '../utils/types/types'
import { translationApiInstance } from './API'
import API_ENDPOINTS from './API/apiEndpoints.constants'
import { generalGetAPI } from './API/general.api'
import fallbackDictionary from './fallback-dictionary.json'

i18next.use(initReactI18next).init({
    resources: fallbackDictionary,
    lng: fetchCurrentLanguage(),
    fallbackLng: CountryCultures[CountryCode.GB],
    load: 'all',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    // debug:
    //     !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
})

generalGetAPI(
    API_ENDPOINTS.translateDictionary,
    undefined,
    translationApiInstance
).then((result) => {
    i18next.use(initReactI18next).init({
        resources: fetchAndBuildResources(result),
        lng: fetchCurrentLanguage(),
        fallbackLng: CountryCultures[CountryCode.GB],
        load: 'all',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        debug: false,
        //     !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    })
})

function fetchAndBuildResources(result: IApiResponse): Resource {
    let resources = {}
    result?.data &&
        Object.keys(result.data).forEach((key) => {
            resources = {
                ...resources,
                [key]: { translation: result.data[key] },
            }
        })
    sessionStorage.setItem('dictionary', JSON.stringify(resources))
    return resources
}

function fetchCurrentLanguage(): string {
    let userLocale = CountryCultures[CountryCode.GB]
    if (sessionStorage.getItem('culture_id')) {
        const cultureNumber = sessionStorage.getItem('culture_id')
            ? parseInt(sessionStorage.getItem('culture_id') as string)
            : undefined
        cultureNumber && (userLocale = CountryCultures[cultureNumber])
    }
    sessionStorage.setItem('locale', userLocale)
    return userLocale
}

export function updateCurrentLanguage(cultureId: number | string): void {
    if (!i18next.changeLanguage) return
    const userLocale = CountryCultures[parseInt(cultureId as string)]
    try {
        i18next.changeLanguage(userLocale)
        sessionStorage.setItem('locale', userLocale ?? 'en-GB')
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Something went wrong changing language', err)
    }
}

export default i18next
