import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        _disabled: {
            background: 'gray.200',
            opacity: 1,
        },
    },
})

const customFilled = definePartsStyle({
    field: {
        border: '1px solid',
        borderColor: 'gray.200',
        background: 'white',
        borderRadius: '.25rem',
        color: 'black',

        _dark: {
            borderColor: 'secondary.500',
            background: 'secondary.850',
            color: 'white',
        },
    },
    addon: {
        border: '1px solid',
        borderColor: 'gray.200',
        background: 'white',
        borderRadius: '4px',
        color: 'primary.900',

        _dark: {
            borderColor: 'secondary.500',
            background: 'secondary.850',
            color: 'white',
        },
    },
})

const inputTheme = defineMultiStyleConfig({
    baseStyle,
    variants: { customFilled },
    defaultProps: {
        variant: 'customFilled',
    },
})

export default inputTheme
