import { IFormField } from '../../features/dynamicForm/DynamicForm.feature'
import {
    buildListOfKeyValues,
    listOfOptionsLanguage,
} from '../../utils/functions.utils'
import {
    CountryCode,
    PartnerDTO,
    UserDTO,
    UserGroup,
} from '../../utils/types/types'

export const UserGroupMap = {
    [UserGroup.AdminUser]: 'adminUser',
    [UserGroup.DisabledUser]: 'disabledUser',
    [UserGroup.GlobalAdminUser]: 'globalAdminUser',
    [UserGroup.RegularUser]: 'regularUser',
    [UserGroup.RestrictedUser]: 'restrictedUser',
    [UserGroup.SuperUser]: 'superUser',
    [UserGroup.SystemUser]: 'systemUser',
}

export interface UserWithPartner extends UserDTO {
    partnerId: number
}

export function getFormFieldsForProfile(
    userWithPartnerData: UserWithPartner,
    partners: PartnerDTO[],
    culture: CountryCode
): IFormField<UserWithPartner>[] {
    return [
        {
            slug: 'name',
            label: 'name',
            value: userWithPartnerData.name,
            inputProps: {
                type: 'text',
                isDisabled: true,
            },
        },
        {
            slug: 'email',
            label: 'email',
            value: userWithPartnerData.email,
            inputProps: {
                type: 'email',
                isDisabled: true,
            },
        },
        {
            slug: 'userGroup',
            label: 'userGroup',
            value: userWithPartnerData.userGroup,
            optionsKeysValues:
                buildListOfKeyValues<typeof UserGroup>(UserGroup),
            inputProps: {
                type: 'select',
                disabled: true, // TODO: improve this since disabled is not safe, we should not render the input at all and only show information as read only
            },
        },
        {
            slug: 'branchName',
            label: 'branchName',
            value: userWithPartnerData.branchName,
            inputProps: {
                type: 'text',
                isDisabled: true,
            },
        },
        {
            slug: 'culture',
            label: 'culture',
            value: userWithPartnerData.culture,
            skipTranslation: true,
            inputProps: { type: 'select' },
            optionsKeysValues: listOfOptionsLanguage,
        },
        {
            slug: 'formatting',
            label: 'formatting',
            value: userWithPartnerData.formatting,
            inputProps: { type: 'select' },
            skipTranslation: true,
            optionsKeysValues: listOfOptionsLanguage,
        },
        {
            slug: 'partnerId',
            label: 'partner',
            value: userWithPartnerData.partnerId,
            inputProps: { type: 'select' },
            skipTranslation: true,
            optionsKeysValues: [
                ...(partners?.map((partner) => [partner.id, partner.name]) ??
                    []),
            ],
        },
    ]
}
