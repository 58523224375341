import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { ProductType } from '../../../utils/types/types'
import { ContractProlongationAssetDTO } from './ProlongationAssetsTable.config'

interface IProlongationTable {
    productType?: ProductType
    assets: ContractProlongationAssetDTO[]
    onChangeAgreedRestValue: Function
}
export default function ProlongationTable({
    productType,
    assets,
    onChangeAgreedRestValue,
}: IProlongationTable): ReactElement {
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()
    const getProlongationHeaders = [
        { headerName: translate('asset'), filter: true, field: 'assetNumber' },
        { headerName: translate('make'), filter: true, field: 'make' },
        { headerName: translate('model'), filter: true, field: 'model' },
        {
            headerName: translate('price'),
            filter: true,
            field: 'purchaseValue',
        },
        ...([ProductType.Subscription, ProductType.Rental].includes(
            productType as any
        )
            ? []
            : [
                  {
                      headerName: 'restValue',
                      filter: true,
                      field: 'restValue',
                      valueFormatter: (params: any) =>
                          formatValue(params.data.restValue),
                  },
                  {
                      headerName: translate('targetRestValue'),
                      onCellValueChanged: (params: any) => {
                          onChangeAgreedRestValue(
                              params.data.targetRestValue,
                              params.data.assetNumber
                          )
                      },
                      editable: true,
                      pinned: 'right',
                      cellEditor: 'agNumberCellEditor',
                      field: 'targetRestValue',
                      cellClass: 'ln-editable-cell',
                      cellEditorParams: {
                          showStepperButtons: true,
                      },

                      valueFormatter: (params: any) =>
                          formatValue(params.data.targetRestValue),
                  },
              ]),
    ]
    return (
        <DynamicGrid
            tableId="prolongationAssetsTable"
            columns={getProlongationHeaders}
            rowData={assets}
        />
    )
}
