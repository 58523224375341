import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import DynamicGrid from '../../pages/demo/DynamicAGGrid.component'
import { ContractAssetDTO, GetBaseAssetDetail } from '../../utils/types/types'
import { ASSET_LIST_HEADERS } from './existingAsset.feature.config'

interface CopyAssetInterface extends ModalSharedProps {
    copyAsset: Function
    existingContractAssets?: ContractAssetDTO[]
}

function CopyAssetContainer({
    copyAsset,
    existingContractAssets = [],
    onClose,
}: CopyAssetInterface): ReactElement {
    const [selectedItems, setSelectedItems] = useState<
        GetBaseAssetDetail[] | ContractAssetDTO[]
    >([])
    const translate = useTranslation().t

    return (
        <Box mt={8}>
            <DynamicGrid
                columns={ASSET_LIST_HEADERS(translate, false, true)}
                onSelectionChanged={(data: any): void => {
                    const selectedNodes = data.api.getSelectedNodes()
                    const selectedData = selectedNodes.map(
                        (node: any) => node.data
                    )
                    setSelectedItems(selectedData)
                }}
                rowData={existingContractAssets}
                tableId={'copyAssetTable'}
            />
            <Button
                mt={8}
                width={'100%'}
                onClick={(): void => {
                    const data = selectedItems as ContractAssetDTO[]
                    copyAsset(data)
                    onClose()
                }}
            >
                {translate('copySelectedAssets')}
            </Button>
        </Box>
    )
}

export default withModalHOC<CopyAssetInterface>(CopyAssetContainer)
