import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react'
import { generalGetAPI } from '../../../services/API/general.api'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { GetVatDetail } from '../../../utils/types/types'
import { useInsurance } from '../../../services/contexts/Insurance.context'

function InsuranceDetailsComponent() {
    const [VATCodes, setVATCodes] = useState<GetVatDetail[]>([])
    const translate = useTranslation().t
    const { insurance, handleInsuranceUpdate, isEditingMode } = useInsurance()

    useEffect(() => {
        retrieveVATCodes()
    }, [])

    const retrieveVATCodes = async () => {
        const request = await generalGetAPI(API_ENDPOINTS.vat)
        if (!request.isOk) return
        setVATCodes(request.data)
    }

    const handleVATSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const SelectedVAT = VATCodes.find(
            (VATCode) => VATCode.id === parseInt(event.target.value)
        )
        if (!SelectedVAT) return
        handleInsuranceUpdate({
            vatId: SelectedVAT.id,
            vatPercentage: SelectedVAT.percentage,
        })
    }

    return (
        <Box mt={8}>
            <Flex flexDirection={'column'} gap={4}>
                <FormControl>
                    <FormLabel>{translate('description')}</FormLabel>
                    <Input
                        value={insurance?.description ?? ''}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            if (isEditingMode) return
                            handleInsuranceUpdate({
                                description: event.target.value,
                            })
                        }}
                        isDisabled={isEditingMode}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('selectVATCode')}</FormLabel>
                    <Select value={insurance?.vatId} onChange={handleVATSelect}>
                        <option value={0}>{translate('selectVATCode')}</option>
                        {VATCodes.map((VATCode, i) => (
                            <option key={i} value={VATCode.id}>
                                {VATCode.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Flex>
        </Box>
    )
}

export default InsuranceDetailsComponent
