import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import {
    BaseInsuranceDto,
    BaseInsurancePriceStepDto,
} from '../../utils/types/types'
import { DEFAULT_INSURANCE_ITEM } from '../../pages/setup/insurance/Insurance.config'
import InsuranceDetailsComponent from '../../features/insurance/components/InsuranceDetails.component'
import ProductsComponent from '../../features/insurance/components/Products.component'
import InsuranceStepsTable from '../../features/insurance/components/PriceStepsTable.component'

interface InsuranceContextInterface {
    steps: InsuranceStepType[]
    handleInsuranceUpdate: Function
    initialDataSettling: Function
    insurance: OptionalBaseInsuranceDTO
    isEditingMode: boolean
}

const deepEqual = (x: any, y: any): any => {
    const ok = Object.keys
    const tx = typeof x
    const ty = typeof y
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length &&
              ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y
}

const InsuranceContext = createContext({
    steps: [],
    insurance: { ...DEFAULT_INSURANCE_ITEM },
    handleInsuranceUpdate: Function,
    initialDataSettling: Function,
    isEditingMode: false,
} as InsuranceContextInterface)

type OptionalBaseInsuranceDTO = Partial<BaseInsuranceDto>

type InsuranceStepType = {
    label: string
    content: ReactNode
    isDisabled?: boolean
}

export const InsuranceContextProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const [insurance, setInsurance] = useState<OptionalBaseInsuranceDTO>({
        ...DEFAULT_INSURANCE_ITEM,
    })

    const isEditingMode = useMemo(
        () => !!insurance?.baseInsuranceNumber,
        [JSON.stringify(insurance)]
    )

    const initialDataSettling = (
        insuranceItem: OptionalBaseInsuranceDTO
    ): void => {
        if (deepEqual(insurance, insuranceItem)) return
        setInsurance({ ...insuranceItem })
    }

    const updateInsurancePriceSteps = (
        insurancePriceSteps: BaseInsurancePriceStepDto[]
    ) =>
        setInsurance({
            ...insurance,
            baseInsurancePriceSteps: insurancePriceSteps,
        })

    const steps: InsuranceStepType[] = [
        {
            label: 'insuranceDetails',
            content: <InsuranceDetailsComponent />,
            isDisabled: !(insurance?.description && insurance?.vatId),
        },
        {
            label: 'insurancePriceSteps',
            content: (
                <InsuranceStepsTable
                    steps={insurance.baseInsurancePriceSteps ?? []}
                    setSteps={updateInsurancePriceSteps}
                />
            ),
            isDisabled: !(insurance?.description && insurance?.vatId),
        },
        {
            label: 'product',
            content: <ProductsComponent />,
            isDisabled: !(
                insurance?.productInsurances &&
                insurance?.productInsurances.length > 0
            ),
        },
    ]

    const handleInsuranceUpdate = (objectToUpdate: {
        [key: string]: any
    }): void => {
        setInsurance({ ...insurance, ...objectToUpdate })
    }

    return (
        <InsuranceContext.Provider
            value={{
                steps,
                handleInsuranceUpdate,
                insurance,
                initialDataSettling,
                isEditingMode,
            }}
        >
            {children}
        </InsuranceContext.Provider>
    )
}

export const useInsurance = () => useContext(InsuranceContext)
