import { ReactElement } from 'react'

import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import LocationsPage from '../../../setup/locations/Locations.page'

interface CustomerLocationInterface extends ModalSharedProps {
    customerNumber: string
}

function CustomerLocationComponent({
    customerNumber,
}: CustomerLocationInterface): ReactElement {
    return <LocationsPage isModal={true} customerNumber={customerNumber} />
}

export default withModalHOC<CustomerLocationInterface>(
    CustomerLocationComponent
)
