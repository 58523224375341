import { IFormFieldSections } from '../../../features/dynamicForm/DynamicForm.interfaces'
import { LocationDTO, PartnerDetailsDTO } from '../../../utils/types/types'

export default function PartnerDetailFormConfig(
    partner: PartnerDetailsDTO,
    locations: LocationDTO[]
): IFormFieldSections<PartnerDetailsDTO> {
    return {
        section1: {
            columns: 2,
            title: 'information',
            fields: [
                {
                    value: partner.name,
                    slug: 'name',
                    label: 'name',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.email,
                    slug: 'email',
                    label: 'email',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.shortCode,
                    slug: 'shortCode',
                    label: 'shortCode',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner?.locationNumber || '',
                    slug: 'locationNumber',
                    label: 'location',
                    skipTranslation: true,
                    optionsKeysValues: [
                        ...locations.map((location) => [
                            location.locationNumber,
                            location.locationName,
                        ]),
                    ],
                    inputProps: { type: 'select' },
                    isValueString: true,
                },
            ],
        },
        section2: {
            title: 'paymentTerms',
            fields: [
                {
                    value: partner.firstPaymentTerms,
                    slug: 'firstPaymentTerms',
                    label: 'firstPaymentTerms',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.daysPerMonth,
                    slug: 'daysPerMonth',
                    label: 'daysPerMonth',
                    inputProps: { type: 'number' },
                },
                // {
                //     value: partner.logoDocumentId,
                //     slug: 'logoDocumentId',
                //     label: 'logoDocumentId',
                //     inputProps: { type: 'text', isDisabled: true },
                // },
            ],
        },
        section3: {
            columns: 3,
            title: 'configuration',
            fields: [
                {
                    value: partner.smtpUserName,
                    slug: 'smtpUserName',
                    label: 'smtpUserName',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.smtpPassword,
                    slug: 'smtpPassword',
                    label: 'smtpPassword',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.smtpHost,
                    slug: 'smtpHost',
                    label: 'smtpHost',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.smtpPort,
                    slug: 'smtpPort',
                    label: 'smtpPort',
                    inputProps: { type: 'text' },
                },
                {
                    value: partner.smtpEnableSsl,
                    slug: 'smtpEnableSsl',
                    label: 'smtpEnableSsl',
                    inputProps: { type: 'boolean' },
                },
            ],
        },
    }
}
