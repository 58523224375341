import React from 'react'

import { Box, Checkbox, SimpleGrid, Spinner } from '@chakra-ui/react'

import { useService } from '../../../../services/contexts/Service.context'
import { ProductServiceDTO } from '../../../../utils/types/types'

function ProductsComponent(): JSX.Element {
    const { service, products, handleServiceUpdate } = useService()

    const handleProductUpdate = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        const product = products.find(
            (iteratedProduct) => iteratedProduct.id === parseInt(value)
        )
        if (!product) return
        const newProductDTOInstance: ProductServiceDTO = {
            isSelectable: true,
            productId: product.id,
        }
        if (!service?.productServices) {
            handleServiceUpdate({ productServices: [newProductDTOInstance] })
            return
        }
        if (
            service.productServices.some(
                (productService) =>
                    productService.productId === newProductDTOInstance.productId
            )
        ) {
            const newProducts = service.productServices.filter(
                (productService) =>
                    productService.productId !== newProductDTOInstance.productId
            )
            handleServiceUpdate({ productServices: [...newProducts] })
        } else {
            handleServiceUpdate({
                productServices: [
                    ...service.productServices,
                    newProductDTOInstance,
                ],
            })
        }
    }

    const isCheckboxChecked = (productId: number): boolean => {
        if (!service?.productServices) return false
        return service.productServices.some((ps) => ps.productId === productId)
    }

    return (
        <Box mt={8}>
            <SimpleGrid columns={[1, 1, 2, 3, 3]}>
                {!products && <Spinner />}
                {products.map((product, index) => (
                    <Checkbox
                        isChecked={isCheckboxChecked(product.id)}
                        key={index}
                        my={1}
                        onChange={handleProductUpdate}
                        value={product.id}
                    >
                        {product?.name ?? ''}
                    </Checkbox>
                ))}
            </SimpleGrid>
        </Box>
    )
}
export default ProductsComponent
