import { InvoiceLineType } from '../../../utils/types/types'
import { toCamelCase } from '../../../utils/functions.utils'

export const CustomerDetailInvoiceLineConfig = (
    translate: Function,
    formatValue: Function
): any[] => [
    {
        headerName: translate('invoiceLineNumber'),
        filter: 'agTextColumnFilter',
        field: 'invoiceLineNumber',
    },
    {
        headerName: translate('lineType'),
        field: 'lineType',
        valueGetter: (params: any) =>
            translate(toCamelCase(InvoiceLineType[params?.data?.lineType])),
    },
    {
        headerName: translate('lineText'),
        field: 'lineText',
    },
    {
        headerName: translate('assetNumber'),
        field: 'assetNumber',
    },
    {
        headerName: translate('amountExcludingVat'),
        field: 'amountExcludingVat',
        valueGetter: (params: any) =>
            formatValue(params?.data?.amountExcludingVat),
    },
]
