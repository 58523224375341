/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

import { Button } from '@chakra-ui/react'

import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import { ExternalPartnerSetup } from '../../../utils/types/types'

function findEnumValueByName(enumObj: any, searchString: string) {
    // Convert the search string to lowercase for case-insensitive search
    const lowerSearchString = searchString.toLowerCase()

    // Iterate over the enum keys
    for (const key in enumObj) {
        // Ensure we are only checking the enum keys, not the reverse mapping values
        if (Number.isNaN(Number(key))) {
            if (key.toLowerCase().includes(lowerSearchString)) {
                return enumObj[key]
            }
        }
    }

    // If no match is found, return null or any other appropriate value
    return null
}

const AllowedERPTypesToAuthorize = [
    'Economics',
    'BusinessCentral',
    'Fortnox',
    'EAccounting',
]

export const columnsConfig = (translate: Function, onAuthorize: Function) => [
    {
        field: 'service',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => params?.data?.name,
    },
    {
        field: 'status',
        filter: 'agTextColumnFilter',
        valueGetter: () => translate('connected'),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(translate, (params: any) => (
            <>
                <Button
                    isDisabled={
                        !AllowedERPTypesToAuthorize.includes(params?.data?.name)
                    }
                    justifyContent={'start'}
                    variant={'link'}
                    cursor={'pointer'}
                    onClick={(): void =>
                        onAuthorize(
                            findEnumValueByName(
                                ExternalPartnerSetup,
                                params?.data?.name
                            )
                        )
                    }
                >
                    {translate('authorize')}
                </Button>
            </>
        )),
    },
]
