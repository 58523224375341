import { MutableRefObject, ReactElement, useRef } from 'react'

import withModalHOC, { ModalSharedProps } from '../../../hoc/modal.hoc'
import {
    DocumentMetaDataDto,
    UpdateDocumentCommand,
    UploadedDocumentStatus,
} from '../../../utils/types/types'
import DynamicForm from '../../dynamicForm/DynamicForm.feature'
import DocumentFormFields from './DocumentsTable.config'

interface IEditDocument extends ModalSharedProps {
    document: DocumentMetaDataDto
    onEditDocument: Function
}

function EditDocument({
    document,
    onEditDocument,
}: IEditDocument): ReactElement {
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    return (
        <DynamicForm
            formSubmitReference={formSubmitReference}
            data={document}
            formFields={DocumentFormFields(document)}
            hideSubmit={false}
            onSubmit={(formData: DocumentMetaDataDto): void => {
                const documentToUpdate: UpdateDocumentCommand = {
                    documentId: formData.id,
                    newDocumentName: formData.name ?? '',
                    documentType: formData.documentType,
                    documentStatus:
                        formData.documentStatus ??
                        UploadedDocumentStatus.NotSigned,
                }
                onEditDocument(documentToUpdate)
            }}
        />
    )
}

export default withModalHOC(EditDocument)
