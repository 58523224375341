import get from 'lodash/get'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Card,
    CardBody,
    Flex,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'

import Timeline from '../../../../features/timeline/Timeline.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { usePartner } from '../../../../services/contexts/partner.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    toFirstCharLowercase,
} from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { ContractTraceType, ProductType } from '../../../../utils/types/types'
import NumberFormatterKeyInformation from '../../../../features/numberFormatterKeyInformation/numberFormatterKeyInformation.feature'

export default function ContractDetailTracer(): ReactElement {
    const { contract, product } = useContractService()
    const { activePartner } = usePartner()
    const translate = useTranslation().t
    const toast = useToast()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [contractTraceItems, setContractTraceItems] = useState([])
    const [calculationData, setCalculationData] = useState({})
    const [calculationExcludeRules, setCalculationExcludeRules] = useState<
        any[]
    >([])

    const getContractTraceData = async () => {
        const loadingID = globalLoading()
        const response = await generalGetAPI(
            `${API_ENDPOINTS.contractTrace}?contractNumber=${contract.contractNumber}`
        )
        if (response.isOk) {
            setContractTraceItems(response.data?.items)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loadingID)
    }

    const getContractCalculations = async () => {
        const loadingID = globalLoading()
        const response = await generalPostAPI(
            API_ENDPOINTS.contractActionsCalculate,
            { contract }
        )

        if (response.isOk) {
            setCalculationData(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loadingID)
    }

    useEffect(() => {
        getContractTraceData()
    }, [])

    useEffect(() => {
        if (contract) getContractCalculations()
    }, [contract])

    useEffect(() => {
        const baseExcludeRules = [/[a-z]*Vat/i, /startDate/]
        if (
            ![
                ProductType.FinancialLeasing,
                ProductType.OperationalLeasing,
            ].includes(product.productType)
        ) {
            baseExcludeRules.push(/totalInterestEarning/)
        }
        setCalculationExcludeRules(baseExcludeRules)
    }, [product])

    return (
        <Box pb={12}>
            <Box
                pb={12}
                display={['flex', 'flex', 'flex', 'flex', 'flex']}
                flexWrap={'nowrap'}
                overflowX={'auto'}
                alignItems={'center'}
                gap={4}
                sx={{
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                <SimpleGrid
                    minChildWidth="200px"
                    spacing="4"
                    mt="1rem"
                    width="100%"
                >
                    {Object.keys(calculationData)
                        .filter(
                            (k) =>
                                !calculationExcludeRules.some((r) =>
                                    r.test(k)
                                ) && (get(calculationData, k, 0) || 0) > 0
                        )
                        .map((key: string) => (
                            <Box key={key}>
                                <Card size={'md'} boxShadow={'sm'}>
                                    <CardBody>
                                        <Box>
                                            <Stack gap={1}>
                                                <Heading size="md">
                                                    {translate(key)}
                                                </Heading>
                                            </Stack>
                                            <Stack gap={1}>
                                                <NumberFormatterKeyInformation
                                                    value={
                                                        get(
                                                            calculationData,
                                                            key,
                                                            0
                                                        ) || 0
                                                    }
                                                    currency={
                                                        activePartner?.currencyName
                                                    }
                                                />
                                            </Stack>
                                        </Box>
                                    </CardBody>
                                </Card>
                            </Box>
                        ))}
                    {Object.keys(
                        contract?.calculatedFieldsInfo?.partRegistrationInfo ||
                            {}
                    )
                        .filter(
                            (k) =>
                                (get(
                                    contract?.calculatedFieldsInfo
                                        ?.partRegistrationInfo,
                                    k,
                                    0
                                ) || 0) > 0
                        )
                        .map((key: string) => (
                            <Box key={key}>
                                <Card size={'md'} boxShadow={'sm'}>
                                    <CardBody>
                                        <Box>
                                            <Stack gap={1}>
                                                <Heading size="md">
                                                    {translate(key)}
                                                </Heading>
                                            </Stack>
                                            <Stack gap={1}>
                                                <NumberFormatterKeyInformation
                                                    value={
                                                        get(
                                                            contract
                                                                ?.calculatedFieldsInfo
                                                                ?.partRegistrationInfo,
                                                            key,
                                                            0
                                                        ) || 0
                                                    }
                                                    currency={
                                                        activePartner?.currencyName
                                                    }
                                                />
                                            </Stack>
                                        </Box>
                                    </CardBody>
                                </Card>
                            </Box>
                        ))}
                </SimpleGrid>
            </Box>

            <Timeline />

            <Stack spacing="4" mt={'1rem'}>
                {contractTraceItems.map((item: any) => (
                    <Card
                        size={'md'}
                        boxShadow={'sm'}
                        key={`${item?.type}-${item?.date}`}
                    >
                        <CardBody>
                            <Flex justifyContent={'space-between'}>
                                <Stack gap={1}>
                                    <Heading size="md">
                                        {translate(
                                            toFirstCharLowercase(
                                                ContractTraceType[item?.type]
                                            )
                                        )}
                                    </Heading>
                                    <Text size="md">
                                        {item?.userName ?? item?.userNumber}
                                    </Text>
                                </Stack>
                                <Stack gap={1}>
                                    <Text size="md">
                                        {formatDate(item?.date)}
                                    </Text>
                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>
                ))}
            </Stack>
        </Box>
    )
}
