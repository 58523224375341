import { ReactElement } from 'react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import { ChecklistItemSpecificStateDTO } from '../../../../utils/types/types'

export interface IContractChecklistEditItem extends ModalSharedProps {
    itemToEdit: ChecklistItemSpecificStateDTO
    checklistItemFormFields: Function
    onSubmitItemToEdit: Function
}

function ContractChecklistEditItem({
    itemToEdit,
    checklistItemFormFields,
    onSubmitItemToEdit,
}: IContractChecklistEditItem): ReactElement {
    return (
        <DynamicForm<ChecklistItemSpecificStateDTO>
            data={itemToEdit}
            formFields={checklistItemFormFields()}
            onSubmit={(data: any): void => onSubmitItemToEdit(data)}
        ></DynamicForm>
    )
}

export const ContractChecklistEditItemModal = withModalHOC(
    ContractChecklistEditItem
)
