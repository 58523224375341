import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading, useToast } from '@chakra-ui/react'

import { useSigners } from '../../services/contexts/Signers.context'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
} from '../../utils/functions.utils'
import {
    ForeignSignatureSystem,
    SignatureDigitalType,
    SignatureReceiver,
    SignerDto,
} from '../../utils/types/types'
import DynamicForm from '../dynamicForm/DynamicForm.feature'
import { IFormField } from '../dynamicForm/DynamicForm.interfaces'
import { DefaultSignersFormFields, SignerValidation } from './Signers.config'

function SignersForm({ signerItem }: { signerItem: SignerDto }): JSX.Element {
    const { handleSignerSave, signersList, signatureSystem } = useSigners()
    const [signer, setSigner] = useState<SignerDto>({ ...signerItem })
    const [formFields, setFormFiends] = useState<IFormField<SignerDto>[]>([])

    useEffect(() => {
        setSigner({ ...signerItem })
    }, [JSON.stringify(signerItem)])

    const translate = useTranslation().t
    const toast = useToast()

    const hasElectronicID = signatureSystem === ForeignSignatureSystem.Scrive

    const handleSubmit = (data: SignerDto): void => {
        // Prevent duplicate emails
        const hasDuplicates = signersList.some(
            (signerListItem) =>
                signerListItem.id !== data.id &&
                signerListItem.email === data.email
        )
        if (hasDuplicates) {
            toast(baseErrorToastOptions(translate('signerDuplicate')))
            return
        }

        // Digital Copy Receiver can't be Touch Type
        if (
            data.receiver === SignatureReceiver.Copy &&
            data.digitalType === SignatureDigitalType.Touch
        ) {
            toast(baseInfoToastOptions(translate('signersCopyCondition')))
            data.digitalType = SignatureDigitalType.Digital
        }

        if (data.receiver === SignatureReceiver.Copy) {
            data.electronicID = undefined
        }

        // In Scrive Foreign Signature System occurs MIDId / It has chosen in Digital Signature type
        if (signatureSystem === ForeignSignatureSystem.Scrive) {
            if (
                data.digitalType === SignatureDigitalType.Digital &&
                data?.electronicID === null
            ) {
                toast(
                    baseErrorToastOptions(translate('electronicIDRequiredRule'))
                )
                return
            }
            if (
                (data.digitalType !== SignatureDigitalType.Digital &&
                    data?.electronicID !== null) ||
                data.receiver === SignatureReceiver.Copy
            ) {
                if (data.receiver !== SignatureReceiver.Copy) {
                    toast(
                        baseInfoToastOptions(
                            translate('electronicIDNotRequiredRule')
                        )
                    )
                }
                data.electronicID = undefined
            }

            if (data.receiver === SignatureReceiver.Copy) {
                data.digitalType = SignatureDigitalType.Touch
            }
        }

        handleSignerSave(data)
    }

    useEffect(() => {
        buildFormFields(signer, hasElectronicID)
    }, [JSON.stringify(signer), hasElectronicID])

    /* eslint-disable  @typescript-eslint/no-shadow */
    function buildFormFields(
        signer: SignerDto,
        hasElectronicID: boolean
    ): void {
        const fields = [...DefaultSignersFormFields(signer, hasElectronicID)]
        setFormFiends(fields)
    }

    return (
        <Box>
            <Heading as="h4" size="md" mb={4}>
                {signer.id
                    ? translate('updateSigner')
                    : translate('createSigner')}
            </Heading>
            {signer && (
                <DynamicForm<SignerDto>
                    data={signer}
                    formFields={formFields}
                    columns={2}
                    onSubmit={handleSubmit}
                    onFormChange={(data: any) => {
                        buildFormFields(data, hasElectronicID)
                    }}
                    onFormValidate={SignerValidation}
                />
            )}
        </Box>
    )
}

export default SignersForm
