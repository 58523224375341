import orderBy from 'lodash/orderBy'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import { useLoading } from '../../../services/contexts/Loading.context'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    downloadFile,
} from '../../../utils/functions.utils'
import {
    DocumentMetaDataDto,
    ForeignSignatureSystem,
    UpdateDocumentCommand,
} from '../../../utils/types/types'
import { columnsConfig } from './DocumentsTable.config'
import EditDocumentComponent from './EditDocument.component'

interface IDocumentsTable {
    documents?: DocumentMetaDataDto[]
    fetchDocuments: Function
    isSignersEnabled?: boolean
    signersHandler?: Function
    isLoading?: boolean
    signatureSystem?: ForeignSignatureSystem | null
}

export default function DocumentsTable({
    documents,
    fetchDocuments,
    isSignersEnabled = false,
    signersHandler = (): void => {},
}: IDocumentsTable): ReactElement {
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [editDocument, setEditDocument] =
        useState<DocumentMetaDataDto | null>(null)
    const [isEditOpen, setEditOpen] = useState<boolean>(false)
    const toast = useToast()
    const translate = useTranslation().t

    const onRowRemoved = async (
        documentToDelete: DocumentMetaDataDto
    ): Promise<void> => {
        try {
            if (!documentToDelete.id) return

            const response = await generalDeleteAPI(
                API_ENDPOINTS.document + documentToDelete.id,
                null
            )

            if (response.isOk) {
                fetchDocuments()
                toast(baseInfoToastOptions(translate('fileDeleted')))
            }
        } catch (error) {
            toast(baseErrorToastOptions(translate('deleteError') + error))
        }
    }

    const onEditDocument = async (
        documentToUpdate: UpdateDocumentCommand
    ): Promise<void> => {
        const loadingID = globalLoading()

        try {
            setEditOpen(false)
            if (!documentToUpdate.documentId) return

            const response = await generalPutAPI(
                API_ENDPOINTS.documentUpdate,
                documentToUpdate
            )

            if (response.isOk) {
                fetchDocuments()
                toast(baseInfoToastOptions(translate('fileEdited')))
            }
        } catch (error) {
            toast(baseErrorToastOptions(translate('updateError') + error))
        } finally {
            stopGlobalLoading(loadingID)
        }
    }

    const onEdit = (document: DocumentMetaDataDto): void => {
        setEditDocument(document)
        setEditOpen(true)
    }
    const onDelete = (document: DocumentMetaDataDto): void => {
        onRowRemoved(document)
    }
    const onDownload = (document: DocumentMetaDataDto): void => {
        downloadFile(document.id, document?.name ?? '')
    }

    return (
        <>
            <DynamicGrid
                tableId={'documentsTable'}
                columns={columnsConfig(
                    translate,
                    onEdit,
                    onDelete,
                    onDownload,
                    isSignersEnabled,
                    signersHandler
                )}
                rowData={orderBy(documents, 'creationDate', 'desc')}
                pagination={true}
                rowMultiSelectWithClick={false}
            />
            {editDocument && (
                <EditDocumentComponent
                    isModalOpen={isEditOpen}
                    document={editDocument}
                    onEditDocument={onEditDocument}
                    modalTitle={translate('editDocument')}
                    onClose={() => {
                        setEditDocument(null)
                        setEditOpen(false)
                    }}
                />
            )}
        </>
    )
}
