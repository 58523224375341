import { ReactElement } from 'react'

import PageContainer from '../../../../components/pageContainer/PageContainer.component'
import InterestTableComponent from './InterestTable.component'

export default function InterestPage(): ReactElement {
    return (
        <PageContainer>
            <InterestTableComponent />
        </PageContainer>
    )
}
