import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'

// TODO: Should be the component handling this not the provider

interface StepperContextInterface {
    currentStep: number
    setCurrentStep: Function
    movePrev(): void
    moveNext(): void
}

const StepperContext = createContext({} as StepperContextInterface)

export const StepperContextProvider = ({
    children,
    length,
}: {
    children: ReactNode
    length: number
}): ReactElement => {
    const [currentStep, setCurrentStep] = useState<number>(1)

    const movePrev = (): void => {
        if (currentStep <= 2) {
            setCurrentStep(1)
            return
        }
        setCurrentStep(currentStep - 1)
    }
    const moveNext = (): void => {
        if (currentStep >= length - 1) {
            setCurrentStep(length)
            return
        }
        setCurrentStep(currentStep + 1)
    }
    return (
        <StepperContext.Provider
            value={{ currentStep, setCurrentStep, movePrev, moveNext }}
        >
            {children}
        </StepperContext.Provider>
    )
}

export const useStepper = (): StepperContextInterface =>
    useContext(StepperContext)
