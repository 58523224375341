import {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'

import { DEFAULT_OFFER, OfferInterface } from '../../pages/offer/Offer.config'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    offsetTimeZoneDifference,
} from '../../utils/functions.utils'
import {
    createCustomer,
    makeContractFromOffer,
    makeNewOffer,
} from '../../utils/offer/offer.utils'
import {
    CreateContract,
    CustomerDTO,
    ProductDTO,
    ProductRuleType,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI, generalPostAPI } from '../API/general.api'
import { applyRulesToOffer } from '../rules/ProductRulesEnforcer.services'
import { useUser } from './user.context'

const getOfferEndDate = (leasingPeriod = 0, now = new Date()): string =>
    offsetTimeZoneDifference(
        new Date(now.getFullYear(), now.getMonth() + leasingPeriod + 1, 1)
    ).toISOString()
interface OfferContextInterface {
    companyCustomers: CustomerDTO[]
    companyCustomersLoading: boolean
    createContract(): void
    creditSafeCustomers: CustomerDTO[]
    customerSearch: string
    customerSearchPending: boolean
    getMonthlyPayment(): void
    handleCustomerSearch(): void
    handleResetCustomerSearch(): void
    offer: OfferInterface
    productHasUsage: boolean
    products: ProductDTO[]
    requestPending: boolean
    requestPendingContract: boolean
    retrieveCustomers(term: string, searchOnline: boolean): Promise<void>
    selectedProduct: Partial<ProductDTO>
    selectedProductBundle: Number | null
    setCustomerSearch: Function
    setProductHasUsage: Dispatch<SetStateAction<boolean>>
    setSelectedProduct: Dispatch<SetStateAction<Partial<ProductDTO>>>
    setSelectedProductBundle: Function
    updateOffer(field: string, value: any): void
    updateOfferFields(fields: IUpdateFieldsProps): void
}

export const OfferContext = createContext({} as OfferContextInterface)

type IUpdateFieldsProps = { [key: string]: any }

export const OfferContextProvider = ({
    children,
}: {
    children: ReactNode
}): ReactElement => {
    const [offer, setOffer] = useState<OfferInterface>(DEFAULT_OFFER)
    const [products, setProducts] = useState<ProductDTO[]>([])
    const [selectedProduct, setSelectedProduct] = useState<Partial<ProductDTO>>(
        {}
    )
    const [selectedProductBundle, setSelectedProductBundle] =
        useState<Number | null>(null)
    const [companyCustomers, setCompanyCustomer] = useState<CustomerDTO[]>([])
    const [creditSafeCustomers, setCreditSafeCustomers] = useState<
        CustomerDTO[]
    >([])
    const [companyCustomersLoading, setCustomerCustomersLoading] =
        useState<boolean>(true)
    const [requestPending, setRequestPending] = useState<boolean>(false)
    const [requestPendingContract, setRequestPendingContract] =
        useState<boolean>(false)
    const [customerSearch, setCustomerSearch] = useState<string>('')
    const [customerSearchPending, setCustomerSearchPending] =
        useState<boolean>(false)
    const [productHasUsage, setProductHasUsage] = useState<boolean>(false)
    const toast = useToast()
    const { user } = useUser()
    const translate = useTranslation().t
    const navigation = useNavigate()
    const [interestRatesLoading, setInterestRatesLoading] =
        useState<boolean>(true)
    const [interestRates, setInterestRates] = useState<any[]>([])

    const retrieveProducts = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.product)
        if (response.isOk) setProducts(response.data)
    }

    const retrieveCustomers = async (
        term = '',
        searchOnline = true
    ): Promise<void> => {
        const response = await generalGetAPI(
            API_ENDPOINTS.customerSearch(term, searchOnline)
        )
        setCustomerCustomersLoading(false)
        if (response.isOk) setCompanyCustomer(response.data)
    }

    const retrieveInterestRates = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.variableInterest)
        setInterestRatesLoading(false)
        if (response.isOk) setInterestRates(response.data)
    }

    useEffect((): void => {
        retrieveProducts()
        retrieveCustomers()
        retrieveInterestRates()
    }, [])

    // Live search
    /* useEffect((): void => {
        const debouncedSearch = debounce(() => {
            retrieveCustomers(customerSearch)
        }, 500)
        debouncedSearch()
        return (() => debouncedSearch.cancel()) as any
    }, [customerSearch]) */

    useEffect(() => {
        setProductHasUsage(!!selectedProduct?.isUsageEnabled)
        updateOfferFields({
            productId: selectedProduct.id,
            assets: [],
            recurrence: selectedProduct.recurrence,
        })
    }, [JSON.stringify(selectedProduct)])

    const handleCustomerSearch = async (): Promise<void> => {
        if (customerSearchPending) {
            toast(baseInfoToastOptions(translate('requestPending')))
            return
        }
        setCustomerSearchPending(true)
        retrieveCustomers(customerSearch, true)
        setCustomerSearchPending(false)
    }

    const handleResetCustomerSearch = (): void => {
        setCustomerSearch('')
        setCreditSafeCustomers([])
        setCustomerSearchPending(false)
    }

    const updateOffer = (field: string, value: any): void => {
        if (!field) return
        setOffer((prevValue) => ({ ...prevValue, [field]: value }))
    }
    function updateOfferFields(updateFields: IUpdateFieldsProps): void {
        /* eslint-disable prefer-object-spread */
        const updateObject = Object.assign({}, offer, { ...updateFields })
        setOffer(updateObject)
    }

    const getMonthlyPayment = async (): Promise<void> => {
        const contractSpy = makeContractFromOffer(
            offer,
            selectedProduct?.productRules ?? []
        )
        if (requestPending) {
            toast(baseInfoToastOptions(translate('requestPending')))
            return
        }
        setRequestPending(true)
        const request = await generalPostAPI(
            API_ENDPOINTS.contractActionsCalculate,
            { contract: contractSpy }
        )
        setRequestPending(false)
        if (!request.isOk) return
        updateOffer(
            'periodicPayment',
            request.data.totalPeriodicPaymentWithServices
        )
    }

    const createContract = async (): Promise<void> => {
        if (requestPendingContract || interestRatesLoading) {
            toast(baseInfoToastOptions(translate('requestPending')))
            return
        }
        setRequestPendingContract(true)
        const offerElement = { ...offer }
        if (
            offer.customer.shouldCreateCustomer ||
            !offer.customer?.customerNumber
        ) {
            const customer = await createCustomer(offer.customer, user)
            if (!customer.isOk) {
                setRequestPendingContract(false)
                toast(
                    baseErrorToastOptions(
                        translate('customerAlreadyExistsError')
                    )
                )
                return
            }
            updateOffer('customer', customer.data)
            offerElement.customer = customer.data
        }
        let contract: CreateContract = makeNewOffer(
            offerElement,
            selectedProduct?.productRules ?? []
        )
        contract.endDate = getOfferEndDate(contract.leasingPeriod)
        contract.endActualDate = getOfferEndDate(contract.leasingPeriod)
        let offerProductRules = []
        const response = await generalGetAPI(
            `${API_ENDPOINTS.product}/${contract.productId}`
        )
        if (response.isOk) {
            offerProductRules = response?.data?.productRules ?? []
        }

        contract = applyRulesToOffer(offerProductRules, contract)

        const interestRule = offerProductRules?.find(
            (pr: any) => pr.ruleType === ProductRuleType.Interest
        )
        const isVariableInterestRule = offerProductRules?.find(
            (pr: any) => pr.ruleType === ProductRuleType.IsVariableInterest
        )
        const interestRate = interestRates.find((ir) => ir.endDate === null)

        if (interestRule && interestRate) {
            contract.interest =
                Number(interestRule.defaultValue ?? 0) +
                Number(interestRate.value ?? 0)
        }

        contract.isVariableInterest =
            isVariableInterestRule?.defaultValue === 'true'
        contract.isVariableInterestLocked =
            isVariableInterestRule?.locked === 'true'
        contract.variableInterest = Number(contract?.variableInterest)
        contract.deposit = Number(contract?.deposit)
        contract.creationFee = Number(contract?.creationFee)
        contract.leasingPeriod = Number(contract?.leasingPeriod)
        contract.interest = Number(contract.interest)

        const endpointToCall = selectedProductBundle
            ? API_ENDPOINTS.contractActionsCreateBundleContract(
                  selectedProductBundle.toString(),
                  contract?.customerNumber ?? '˙'
              )
            : API_ENDPOINTS.contract

        const request = await generalPostAPI(
            endpointToCall,
            selectedProductBundle ? {} : contract
        )

        setRequestPendingContract(false)
        if (!request.isOk) {
            toast(baseErrorToastOptions(translate('notValidContract')))
            return
        }
        navigation(
            `/contracts/${
                request.data?.contract?.contractNumber ||
                request.data?.contractNumber
            }`
        )
    }

    return (
        <OfferContext.Provider
            value={{
                companyCustomers,
                companyCustomersLoading,
                createContract,
                creditSafeCustomers,
                customerSearch,
                customerSearchPending,
                getMonthlyPayment,
                handleCustomerSearch,
                handleResetCustomerSearch,
                offer,
                productHasUsage,
                products,
                requestPending,
                requestPendingContract,
                selectedProduct,
                selectedProductBundle,
                setCustomerSearch,
                setProductHasUsage,
                setSelectedProduct,
                setSelectedProductBundle,
                updateOffer,
                updateOfferFields,
                retrieveCustomers,
            }}
        >
            {children}
        </OfferContext.Provider>
    )
}

export const useOffer = (): OfferContextInterface => useContext(OfferContext)
