import { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

import DynamicForm from '../../../../../features/dynamicForm/DynamicForm.feature'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import { ParameterAndDefinition } from '../../SetupGeneral.page'
import editProductParameterFields from './EditProductParameter.config'

interface EditProductParameterProps extends ModalSharedProps {
    parameter: ParameterAndDefinition
    onSubmitCallback: Function
}
function EditProductParameter({
    parameter,
    onSubmitCallback,
    onClose,
}: EditProductParameterProps): ReactElement {
    return (
        <Box mt={4}>
            <DynamicForm<Partial<ParameterAndDefinition>>
                data={parameter}
                formFields={editProductParameterFields(parameter)}
                onSubmit={(data: ParameterAndDefinition): void => {
                    onSubmitCallback(data)
                    onClose()
                }}
                submitText={'confirm'}
                submitButtonProps={{ float: 'right', mt: 2 }}
            />
        </Box>
    )
}

export default withModalHOC(EditProductParameter)
