/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon } from '@chakra-ui/icons'
import { Button, Flex, IconButton, Tooltip, useToast } from '@chakra-ui/react'

import UsersDownloadConfig from '../../../app/config/download/users.config.json'
import DownloadActions from '../../../components/downloadActions/DownloadActions.component'
import PageContainer from '../../../components/pageContainer/PageContainer.component'
import UserContainer from '../../../features/user/User.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import useIsMounted from '../../../services/hooks/isMounted'
import { baseErrorToastOptions } from '../../../utils/functions.utils'
import { UserDTO } from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import { columnsConfig, DEFAULT_USER } from './Users.config'

function UsersPage(): ReactElement {
    const gridApiRef = useRef(null)
    const [selectedItems, setSelectedItems] = useState<UserDTO[]>([])
    const [users, setUsers] = useState<UserDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [userToEdit, setUserToEdit] = useState<UserDTO>(DEFAULT_USER)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const translate = useTranslation().t
    const toast = useToast()
    const isMounted = useIsMounted()

    async function getUsers(): Promise<void> {
        const response = await generalGetAPI(API_ENDPOINTS.user)
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
        }
        setUsers(response.data.items as UserDTO[])
    }

    const handleUserSave = async (user: UserDTO): Promise<void> => {
        setUsers(undefined)
        setModalOpen(false)
        setUserToEdit({ ...DEFAULT_USER })
        let request

        if (user.userNumber === '0') {
            delete user.userNumber
            request = await generalPostAPI(API_ENDPOINTS.user, user)
        } else {
            request = await generalPutAPI(API_ENDPOINTS.user, user)
        }

        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
        }
        if (request.isOk) {
            getUsers()
        }
    }

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const getSelectedData = (): void => {
        const selectedNodes = (gridApiRef?.current as any)?.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedItems(selectedData)
    }

    const selectAllElements = async (): Promise<void> => {
        users && setSelectedItems([...users])
    }

    useEffect((): void => {
        isMounted() && getUsers()
    }, [])

    return (
        <PageContainer>
            <DynamicGrid
                tableId="usersPageTable"
                onGridReady={onGridReady}
                columns={columnsConfig(
                    translate,
                    (params: any): any => (
                        <>
                            <Tooltip
                                label={translate('edit')}
                                placement="top"
                                hasArrow
                            >
                                <IconButton
                                    mr={2}
                                    aria-label="Edit"
                                    size="sm"
                                    variant={'outline'}
                                    onClick={(): any => {
                                        setUserToEdit(params?.data as UserDTO)
                                        setModalOpen(true)
                                    }}
                                    icon={<EditIcon />}
                                />
                            </Tooltip>
                        </>
                    ),
                    isDownloadEnabled
                )}
                rowSelection={'multiple'}
                rowData={users}
                pagination={true}
                rowMultiSelectWithClick={false}
                onSelectionChanged={(): void => {
                    getSelectedData()
                }}
                headers={
                    <Flex
                        justifyContent={'flex-end'}
                        alignItems="center"
                        gap={2}
                        py={2}
                    >
                        <DownloadActions
                            isDownloadEnabled={isDownloadEnabled}
                            setDownloadEnabled={setDownloadEnabled}
                            selectAllElements={selectAllElements}
                            selectedItems={selectedItems}
                            downloadConfig={UsersDownloadConfig}
                        />
                        <Button
                            onClick={(): void => {
                                setUserToEdit(DEFAULT_USER as UserDTO)
                                setModalOpen(true)
                            }}
                        >
                            {translate('addRow')}
                        </Button>
                    </Flex>
                }
            />
            <UserContainer
                modalTitle={translate('user')}
                isModalOpen={isModalOpen}
                onClose={(): void => {
                    setModalOpen(false)
                }}
                userObject={{ ...userToEdit }}
                handleUserSave={handleUserSave}
            />
        </PageContainer>
    )
}

export default UsersPage
