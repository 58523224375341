const offsetTimeZoneDifference = (date: Date): Date =>
    new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000))

const nextMonthFirst = (now = new Date()): string =>
    offsetTimeZoneDifference(
        new Date(now.getFullYear(), now.getMonth() + 1, 1)
    ).toISOString()
export const CONTRACT_COPY_FIELDS = [
    'customerName',
    'customerNumber',
    'recurrence',
    'productId',
    'totalFinancialCost',
    'isVariableInterest',
    'interest',
    'variableInterest',
    'variableInterestLimitId',
    'creationFee',
    'deposit',
    'paymentType',
    'insuredByName',
    'thirdPartyCvr',
    'thirdPartyName',
    'thirdPartyRoadAndNumber',
    'thirdPartyPostalCode',
    'thirdPartyCity',
    'thirdPartyState',
    'thirdPartyCountry',
    'vendorCvr',
    'vendorName',
    'vendorRoadAndNumber',
    'vendorPostalCode',
    'vendorCity',
    'vendorState',
    'vendorCountry',
    'primaryUserCity',
    'primaryUserCountry',
    'primaryUserIsDifferent',
    'primaryUserName',
    'primaryUserPostalCode',
    'primaryUserRoadAndNumber',
    'primaryUserState',
    'placementCity',
    'placementCountry',
    'placementIsDifferent',
    'placementName',
    'placementPostalCode',
    'placementRoadAndNumber',
    'placementState',
    'deliveredAtCity',
    'deliveredAtCountry',
    'deliveredAtIsDifferent',
    'deliveredAtName',
    'deliveredAtPostalCode',
    'deliveredAtRoadAndNumber',
    'leasingPeriod',
    'deliveredAtState',
    'contactEmail',
    'contactName',
    'contactPhone',
    'startActualDate',
    'endActualDate',
    'signedDate',
    'firstInvoiceDueDate',
    'externalReferenceString1',
    'externalReferenceString2',
    'externalReferenceString3',
    'externalReferenceString4',
    'externalReferenceText',
    'externalReferenceMoney1',
    'externalReferenceMoney2',
    'isZendeskTicketOpen',
    'vendorPaymentAmount',
    'vendorPaymentVatAmount',
    'vendorCreditAmount',
    'vendorCreditVatAmount',
    'canReopen',
    'isAdministrationFeeFree',
]

export const ASSET_COPY_FIELDS = [
    'make',
    'model',
    'registrationNumber',
    'vin',
    'serialNumber',
    'originalPurchaseValue',
    'assetTypeId',
    'assetTypeData',
    'power',
    'exteriorColor',
    'interiorColor',
    'modelYear',
    'variant',
    'productNumber',
    'fuelType',
    'gtin',
    'addons',
    'purchaseValue',
    'deliveryCost',
    'restValue',
    'registrationValue',
    'principal',
    'excessPaymentFee',
    'isPartRegistration',
    'isAssetUsed',
    'ageInMonths',
    'usageLimit',
    'usageStandAtStart',
    'pricePerUnitOverUsageLimit',
    'pricePerUnitUnderUsageLimit',
    'downpayment',
    'assetStatus',
]

export const DEFAULT_CONTRACT_POST = {
    customerId: 0,
    customerName: '',
    customerNumber: '',
    // contractType: 20,
    canReopen: false,
    productId: 1,
    creationDate: new Date().toISOString(),
    startDate: nextMonthFirst(),
    leasingPeriod: 0,
    totalFinancialCost: 0,
    activationDate: null,
    endDate: null,
    isVariableInterest: true,
    interest: 0,
    variableInterest: 0,
    isVariableInterestLocked: false,
    variableInterestLimitId: 0,
    downpayment: 0,
    creationFee: 0,
    deposit: 0,
    paymentType: 1,
    includeCreationFee: false,
    insuredByName: '',
    thirdPartyCvr: '',
    thirdPartyName: '',
    thirdPartyRoadAndNumber: '',
    thirdPartyPostalCode: '',
    thirdPartyCity: '',
    thirdPartyState: '',
    thirdPartyCountry: 0,
    vendorCvr: '',
    vendorName: '',
    vendorRoadAndNumber: '',
    vendorPostalCode: '',
    vendorCity: '',
    vendorState: '',
    vendorCountry: 0,
    primaryUserCity: '',
    primaryUserCountry: 0,
    primaryUserIsDifferent: false,
    primaryUserName: '',
    primaryUserPostalCode: '',
    primaryUserRoadAndNumber: '',
    primaryUserState: '',
    placementCity: '',
    placementCountry: 0,
    placementIsDifferent: false,
    placementName: '',
    placementPostalCode: '',
    placementRoadAndNumber: '',
    placementState: '',
    deliveredAtCity: '',
    deliveredAtCountry: 0,
    deliveredAtIsDifferent: false,
    deliveredAtName: '',
    deliveredAtPostalCode: '',
    deliveredAtRoadAndNumber: '',
    deliveredAtState: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    endActualDate: new Date().toISOString(),
    expectedDeliveryDate: new Date().toISOString(),
    signedDate: null,
    firstInvoiceDueDate: new Date().toISOString(),
    externalReferenceString1: '',
    externalReferenceString2: '',
    externalReferenceString3: '',
    externalReferenceString4: '',
    externalReferenceMoney1: 0,
    externalReferenceMoney2: 0,
    canStatusProgress: true,
    splitCustomer: null,
    contractId: 0,
    contractNumber: '',
    contractServices: [],
    contractAssets: [
        {
            id: 0,
            assetNumber: '',
            make: '',
            model: '',
            registrationNumber: '',
            vin: '',
            serialNumber: '',
            originalPurchaseValue: 0,
            assetType: 14,
            firstRegistrationDateTime: new Date().toISOString(),
            expectedDeliveryDate: new Date().toISOString(),
            power: '',
            exteriorColor: '',
            interiorColor: '',
            modelYear: '',
            variant: '',
            productNumber: '',
            fuelType: null,
            gtin: '',
            addons: '',
            purchaseValue: 0,
            deliveryCost: 0,
            restValue: 0,
            registrationValue: 0,
            principal: 0,
            excessPaymentFee: 0,
            isPartRegistration: false,
            isAssetUsed: true,
            ageInMonths: 0,
            usageLimit: 0,
            usageStandAtStart: 0,
            pricePerUnitOverUsageLimit: 0,
            pricePerUnitUnderUsageLimit: 0,
            downpayment: 0,
            assetStatus: 0,
            dateTimeActivation: null,
        },
    ],
    isAdministrationFeeFree: false,
    transientFees: [],
    createdById: 0,
    createdByName: '',
    createdByBranch: '',
    lastUpdateById: 0,
    lastUpdateByName: '',
    lastUpdateDate: new Date().toISOString(),
    lastUpdateByBranch: '',
    isZendeskTicketOpen: false,
    vendorPaymentAmount: 0,
    vendorPaymentVatAmount: 0,
    isLocked: false,
    status: 0,
    vendorCreditAmount: 0,
    vendorCreditVatAmount: 0,
}
