import { MutableRefObject, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button,
    ButtonProps,
    chakra,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'

import { SizeOptions } from '../../utils/enums.utils'
import ErrorBoundaryWrapper from '../errorBoundary/ErrorBoundary.feature'

export enum ButtonVariant {
    SOLID = 'solid',
    OUTLINE = 'outline',
    GHOST = 'ghost',
    LINK = 'link',
}
export interface IPopupActionButton {
    actionName: string | ReactElement
    buttonProps?: ButtonProps
    buttonVariant: ButtonVariant
    cancelText?: string
    confirmText?: string
    content: ReactNode
    isModal?: boolean
    onCancel?: Function
    onConfirm?: Function
    size?: SizeOptions
    popupCloseReference?: any
    title: string
    buttonRef?: MutableRefObject<any> | undefined
    hasActions?: boolean
}

export default function PopupActionButton({
    actionName,
    buttonProps,
    buttonRef = undefined,
    buttonVariant,
    cancelText,
    confirmText,
    content,
    hasActions = true,
    isModal = true,
    onCancel,
    onConfirm,
    popupCloseReference,
    size,
    title,
}: IPopupActionButton): ReactElement {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const translate = useTranslation().t
    const bgColor = useColorModeValue('primary.800', 'secondary.850')
    const bodybg = useColorModeValue('white', 'secondary.750')
    const borderColor = useColorModeValue('primary.500', 'secondary.500')

    function onCloseHandler(): void {
        onCancel?.()
        onClose()
    }

    function onConfirmHandler(): void {
        onConfirm?.()
        onClose()
    }

    function renderFooterActions(): ReactElement {
        return (
            <>
                <Button
                    variant="solid"
                    mr={3}
                    onClick={onCloseHandler}
                    ref={popupCloseReference}
                >
                    {cancelText || translate('cancel')}
                </Button>
                <Button mr={3} onClick={onConfirmHandler}>
                    {confirmText || translate('confirm')}
                </Button>
            </>
        )
    }

    function RenderModal(): ReactElement {
        return (
            <Modal
                size={
                    size || {
                        base: 'xs',
                        sm: 'sm',
                        md: '2xl',
                        lg: '4xl',
                        xl: '6xl',
                    }
                }
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottom={'1px'} borderColor={bgColor}>
                        {title}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody bg={bodybg}>
                        <ErrorBoundaryWrapper id="pop-up-action-button-error">
                            {content}
                        </ErrorBoundaryWrapper>
                    </ModalBody>

                    <ModalFooter
                        borderTop={'1px'}
                        borderColor={borderColor}
                        hidden={!hasActions}
                    >
                        {renderFooterActions()}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    function RenderDrawer(): ReactElement {
        return (
            <Drawer
                trapFocus={false}
                onClose={onClose}
                isOpen={isOpen}
                size={SizeOptions.MD}
                placement={'right'}
            >
                <DrawerContent>
                    <chakra.div
                        width={'100%'}
                        alignItems="center"
                        borderBottom={'1px'}
                    >
                        <DrawerHeader>
                            <Flex
                                width={'100%'}
                                gap={2}
                                align={'start'}
                                justifyContent="space-between"
                            >
                                {title}
                            </Flex>
                        </DrawerHeader>
                        <DrawerCloseButton />
                    </chakra.div>

                    <DrawerBody>
                        <chakra.div pt={2}>{content}</chakra.div>
                    </DrawerBody>
                    <DrawerFooter borderTop={'1px'}>
                        {renderFooterActions()}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        )
    }
    return (
        <>
            <Button
                variant={buttonVariant}
                onClick={onOpen}
                ref={buttonRef}
                {...buttonProps}
            >
                {typeof actionName === 'string'
                    ? translate(actionName)
                    : actionName}
            </Button>
            {isModal && isOpen ? RenderModal() : RenderDrawer()}
        </>
    )
}
