import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
} from '@chakra-ui/react'

import { AddAssetWithoutModal } from '../../../../../features/addAsset/addAsset.feature'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../services/contexts/NumberFormatter.context'
import { baseErrorToastOptions } from '../../../../../utils/functions.utils'
import { formatDate } from '../../../../../utils/localization/culture.utils'
import {
    AssetBalanceDTO,
    ContractAssetDTO,
} from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'

interface IAmortizationTable extends ModalSharedProps {
    assetNumber: string
    assetData: ContractAssetDTO
}

export function AmortizationTableContainer({
    assetNumber,
    assetData,
}: IAmortizationTable): ReactElement {
    const toast = useToast()
    const [balances, setBalances] = useState<AssetBalanceDTO[] | null>(null)

    const [tabIndex, setTabIndex] = useState(0)
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()

    const getAssetBalance = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.assetBalance}/${assetNumber}`
        )
        if (response.isOk) {
            setBalances(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    useEffect(() => {
        assetNumber && getAssetBalance()
    }, [assetNumber])

    const amortizationHeaders = [
        { headerName: translate('month'), field: 'month', filter: true },
        {
            headerName: translate('periodStart'),
            field: 'periodStartDate',
            filter: true,
            valueFormatter: (params: any) =>
                formatDate(params.data.periodStartDate),
        },
        {
            headerName: translate('periodEnd'),
            field: 'periodEndDate',
            filter: true,
            valueFormatter: (params: any) =>
                formatDate(params.data.periodEndDate),
        },
        {
            headerName: translate('primoBalance'),
            field: 'initialBalance',
            valueFormatter: (params: any) =>
                formatValue(params.data.initialBalance),
            filter: true,
        },
        {
            headerName: translate('earnings'),
            field: 'interestPayed',
            valueFormatter: (params: any) =>
                formatValue(params.data.interestPayed),
            filter: true,
        },
        {
            headerName: translate('depreciation'),
            field: 'depreciation',
            valueFormatter: (params: any) =>
                formatValue(params.data.depreciation),
            filter: true,
        },
        {
            headerName: translate('ultimoBalance'),
            field: 'endBalance',
            valueFormatter: (params: any) =>
                formatValue(params.data.endBalance),
            filter: true,
        },
        {
            headerName: translate('periodicPayment'),
            field: 'periodicPayment',
            valueFormatter: (params: any) =>
                formatValue(params.data.periodicPayment),
            filter: true,
        },
        { headerName: translate('invoiced'), field: 'isCiborLocked' },
    ]

    return (
        <Box minHeight={'60vh'}>
            <Tabs
                mt={2}
                mb={2}
                onChange={(index): void => setTabIndex(index)}
                index={tabIndex}
            >
                <TabList>
                    <Tab> {translate('amortizationTable')}</Tab>
                    <Tab> {translate('assetDetails')}</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {balances && (
                            <DynamicGrid
                                tableId="amortizationTable"
                                columns={amortizationHeaders}
                                rowData={balances}
                            />
                        )}
                    </TabPanel>
                    <TabPanel>
                        <AddAssetWithoutModal
                            assetItem={assetData}
                            actionType={'view'}
                            modalTitle={`View Asset: ${assetData?.make}`}
                            isModalOpen={false}
                            onClose={function (): void {
                                // close
                            }}
                        ></AddAssetWithoutModal>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default withModalHOC<IAmortizationTable>(AmortizationTableContainer)
