import { ReactElement, ReactNode } from 'react'

import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react'

interface IGenericBox extends BoxProps {
    children: ReactNode
}

export default function GenericBox({
    children,
    ...props
}: IGenericBox): ReactElement {
    return (
        <Box
            bg={useColorModeValue('white', 'secondary.800')}
            boxShadow={useColorModeValue(`0 0 12px -2px #00000020`, '')}
            borderRadius={'md'}
            {...props}
        >
            {children}
        </Box>
    )
}
