import React from 'react'

const ELEMENT_TAGS = {
    ul: 'ul',
    li: 'li',
    ol: 'ol',
    h1: 'h1',
    h2: 'h2',
    p: 'p',
}

const MARK_TAGS = {
    em: 'italic',
    strong: 'bold',
    u: 'underline',
}

export const getKeyByValue = (object: Object, value: any) =>
    Object.keys(object).find((key) => object[key] === value)

const Element = ({ attributes, children, element }) => {
    const style = {
        textAlign: element.align,
        fontWeight: 'revent',
        fontSize: 'revert',
    }

    return React.createElement(
        getKeyByValue(ELEMENT_TAGS, element.type) || ('p' as string),
        { style, ...attributes },
        children
    )
}

const Leaf = ({ attributes, children, leaf }) => {
    const wrapped = Object.entries(MARK_TAGS).reduce(
        (acc, [key, value]) =>
            leaf[value] ? React.createElement(key, {}, acc) : acc,
        children
    )

    return React.createElement('span', { ...attributes }, wrapped)
}

export { Leaf, Element, ELEMENT_TAGS, MARK_TAGS }
