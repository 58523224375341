import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    InputGroup,
    InputRightElement,
    useToast,
} from '@chakra-ui/react'

import TableSkeleton from '../../../../components/skeleton/table/table.skeleton.component'
import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import {
    CustomerChangeContextProvider,
    useCustomerChange,
} from '../../../../services/customer/CustomerChange.service'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    validateEmail,
} from '../../../../utils/functions.utils'
import { filterCustomers } from '../../../../utils/offer/offer.utils'
import CustomerTableComponent from './CustomerTable.component'

interface CustomerChangeInterface extends ModalSharedProps {
    handleCustomerChange: Function
    canChangeCustomer?: boolean
}

export function CustomerChangeComponent({
    handleCustomerChange,
    canChangeCustomer = true,
    isModalOpen = false,
}: CustomerChangeInterface): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const {
        customer,
        companyCustomers,
        customerSearch,
        setCustomerSearch,
        creditSafeCustomers,
        customerSearchPending,
        setCustomer,
        handleCustomerSearch,
        handleResetCustomerSearch,
    } = useCustomerChange()
    const [customerEmail, setCustomerEmail] = useState<string>(
        customer?.email ?? ''
    )

    useEffect(() => {
        if (!customer?.email) {
            setCustomerEmail('')
        }
        if (customer?.name && customer?.email) {
            setCustomerEmail(customer.email)
        }
    }, [customer?.name])

    const filteredCustomers = useMemo(
        () =>
            filterCustomers(
                companyCustomers,
                creditSafeCustomers,
                customerSearch
            ),
        [JSON.stringify(creditSafeCustomers), JSON.stringify(companyCustomers)]
    )

    const handleEmailUpdate = (): void => {
        if (!customerEmail) {
            toast(baseErrorToastOptions(translate('emailRequiredRule')))
        } else if (!validateEmail(customerEmail)) {
            toast(baseErrorToastOptions(translate('emailNotValid')))
        } else {
            setCustomer({ ...customer, email: customerEmail })
            toast(baseSuccessToastOptions(translate('emailUpdated')))
        }
    }

    const handleCustomerReset = (): void => {
        setCustomer({})
    }

    return isModalOpen ? (
        <Box>
            {!customer?.name && (
                <form
                    onSubmit={(event): void => {
                        event.preventDefault()
                        handleCustomerSearch()
                    }}
                >
                    <Box
                        mb={4}
                        display={'grid'}
                        gridTemplateColumns={'4fr 1fr'}
                        gap={4}
                        alignItems={'end'}
                    >
                        <FormControl>
                            <FormLabel>{translate('searchCustomer')}</FormLabel>
                            <InputGroup>
                                <Input
                                    placeholder={
                                        translate('searchCustomer') || ''
                                    }
                                    value={customerSearch}
                                    onChange={(event): void =>
                                        setCustomerSearch(event.target.value)
                                    }
                                    isDisabled={customerSearchPending}
                                />
                                {customerSearch?.length > 0 && (
                                    <InputRightElement
                                        onClick={handleResetCustomerSearch}
                                    >
                                        <CloseIcon />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <Button onClick={handleCustomerSearch}>
                                {translate('searchCustomer')}
                            </Button>
                        </FormControl>
                    </Box>
                </form>
            )}

            {!customer?.name &&
                (customerSearchPending ? (
                    <TableSkeleton />
                ) : (
                    <CustomerTableComponent customers={filteredCustomers} />
                ))}

            {customer?.name && (
                <Box>
                    <Grid
                        gridTemplateColumns={{
                            base: '1fr',
                            md: 'repeat(3, 1fr)',
                        }}
                        gap={4}
                        mb={4}
                    >
                        <FormControl>
                            <FormLabel>{translate('customerName')}</FormLabel>
                            <Input value={customer?.name} isDisabled={true} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{translate('customerCvr')}</FormLabel>
                            <Input value={customer?.cvr} isDisabled={true} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{translate('email')}</FormLabel>
                            <Input
                                value={customerEmail}
                                onChange={(event): void =>
                                    setCustomerEmail(event.target.value)
                                }
                                readOnly={false}
                            />
                        </FormControl>
                    </Grid>
                    <Flex gap={2}>
                        <Button onClick={handleEmailUpdate}>
                            {translate('updateEmail')}
                        </Button>
                        {canChangeCustomer && (
                            <Button onClick={handleCustomerReset}>
                                {translate('changeCustomer')}
                            </Button>
                        )}
                    </Flex>
                    <Flex gap={2} mt={8}>
                        <Button
                            onClick={() => handleCustomerChange(customer)}
                            isDisabled={!customer?.email}
                        >
                            {translate('save')}
                        </Button>
                    </Flex>
                </Box>
            )}
        </Box>
    ) : (
        <></>
    )
}

const CustomerComponentWithContext = (props: CustomerChangeInterface) => (
    <CustomerChangeContextProvider>
        <CustomerChangeComponent {...props} />
    </CustomerChangeContextProvider>
)

export default withModalHOC<CustomerChangeInterface>(
    CustomerComponentWithContext
)
