import '../../services/i18n.service'

import * as AgGridEnterprise from 'ag-grid-enterprise'
import Cookies from 'js-cookie'
import { ReactElement, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { chakra, ChakraProvider } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'

import Auth0ProviderContainer from '../../Auth0ProviderContainer'
import GlobalLoader from '../../components/globalLoader/GlobalLoader.component'
import { ChecklistInteractionContextProvider } from '../../services/checklistInteractions/ChecklistInteraction.service'
import { LoadingContextProvider } from '../../services/contexts/Loading.context'
import { LocationsContextProvider } from '../../services/contexts/LocationServices.context'
import { ModuleContextProvider } from '../../services/contexts/Module.context'
import { NumberFormatterProvider } from '../../services/contexts/NumberFormatter.context'
import { PartnerContextProvider } from '../../services/contexts/partner.context'
import PenneoContextProvider from '../../services/contexts/penneo.context'
import { ProductContextProvider } from '../../services/contexts/Product.context'
import { UserContextProvider } from '../../services/contexts/user.context'
import { ContractServiceProvider } from '../../services/contract/Contract.services'
import { ERPContextProvider } from '../../services/erp/ERP.services'
import {
    ColorsContextProvider,
    useColorContext,
} from '../../services/hooks/color.hook'
import ABSTRACT_THEME from '../../theme/theme'
import { useScreenSizeClass } from '../../utils/media-query'
import AppContent from './app-content.component'

export default function Root(): ReactElement {
    const { ToastContainer } = createStandaloneToast()
    const screenSizeClass = useScreenSizeClass()

    AgGridEnterprise.LicenseManager.setLicenseKey(
        'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064931}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LENEO_A/S}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Leneo}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Leneo}_need_to_be_licensed___{Leneo}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[0102]_MTc1NDk1MzIwMDAwMA==9b52952ccdb9702b9f38ee5144cccfab'
    )
    return (
        <BrowserRouter>
            <ColorsContextProvider>
                <LoadingContextProvider>
                    <Auth0ProviderContainer>
                        <UserContextProvider>
                            <NumberFormatterProvider>
                                <PenneoContextProvider>
                                    <ProductContextProvider>
                                        <ContractServiceProvider>
                                            <ChakraWrapper>
                                                <ERPContextProvider>
                                                    <LocationsContextProvider>
                                                        <PartnerContextProvider>
                                                            <ModuleContextProvider>
                                                                <ChecklistInteractionContextProvider>
                                                                    <chakra.div
                                                                        overflow={
                                                                            'hidden'
                                                                        }
                                                                        height={
                                                                            '100%'
                                                                        }
                                                                        className={`app ${screenSizeClass}`}
                                                                    >
                                                                        <AppContent />
                                                                        <GlobalLoader />
                                                                    </chakra.div>
                                                                </ChecklistInteractionContextProvider>
                                                            </ModuleContextProvider>
                                                        </PartnerContextProvider>
                                                        <ToastContainer />
                                                    </LocationsContextProvider>
                                                </ERPContextProvider>
                                            </ChakraWrapper>
                                        </ContractServiceProvider>
                                    </ProductContextProvider>
                                </PenneoContextProvider>
                            </NumberFormatterProvider>
                        </UserContextProvider>
                    </Auth0ProviderContainer>
                </LoadingContextProvider>
            </ColorsContextProvider>
        </BrowserRouter>
    )
}

function ChakraWrapper({ children }: any): ReactElement {
    const { primary, definePrimary, secondary, defineSecondary } =
        useColorContext()

    useEffect(() => {
        const color1 = Cookies.get('primary')
        const color2 = Cookies.get('secondary')
        if (color1) definePrimary?.(color1)
        if (color2) defineSecondary?.(color2)
    }, [])
    return (
        <ChakraProvider
            theme={ABSTRACT_THEME(primary, secondary)}
            cssVarsRoot="body"
        >
            {children}
        </ChakraProvider>
    )
}
