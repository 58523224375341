/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    HStack,
    Stack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import { useLoading } from '../../../services/contexts/Loading.context'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { useContractService } from '../../../services/contract/Contract.services'
import { SizeOptions } from '../../../utils/enums.utils'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
    translateWithPrefix,
} from '../../../utils/functions.utils'
import { formatDate } from '../../../utils/localization/culture.utils'
import {
    ContractStatus,
    InvoiceDTO,
    InvoiceLineDTO,
    InvoiceType,
    ProductRuleType,
} from '../../../utils/types/types'
import PopupActionButton, {
    ButtonVariant,
} from '../../popupActionButton/PopupActionButton.feature'
import { CustomerDetailInvoiceLineConfig } from '../invoiceLinesTable/InvoiceLinesTable.config'
import { sumDepreciation, sumInterest } from './InvoicesTable.config'

interface IInvoicesTable {
    hasGenerateNext?: boolean
    hasSendEmail?: boolean
    enablePay?: boolean
    isContract?: boolean
    dataEndpoint?: string
    invoices?: InvoiceDTO[]
}

export default function InvoicesTable({
    hasGenerateNext = false,
    hasSendEmail = false,
    isContract = false,
    dataEndpoint,
    invoices,
}: IInvoicesTable): ReactElement {
    const [data, setData] = useState<InvoiceDTO[]>([])
    const [interestRates, setInterestRates] = useState<any[]>([])
    const [selectedItems, setSelectedItems] = useState<InvoiceLineDTO[]>([])

    const translate = useTranslation().t
    const { contract, product } = useContractService()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const toast = useToast()
    const { formatValue } = useNumberFormatterContext()

    const isVariableInterestRules = product?.productRules?.find(
        (pr) => pr.ruleType === ProductRuleType.IsVariableInterest
    )

    const getData = async (): Promise<void> => {
        const response = await generalGetAPI(dataEndpoint as string)
        if (response.isOk) {
            setData(response.data.items)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    const getInterestData = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.variableInterest)
        if (response.isOk) {
            setInterestRates(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    useEffect(() => {
        getInterestData()
    }, [])

    useEffect(() => {
        dataEndpoint && getData()
    }, [dataEndpoint])

    useEffect(() => {
        invoices && setData(invoices)
    }, [invoices])

    const onGenerateNext = async (): Promise<void> => {
        const loadingID = globalLoading()
        const endpoint = contract?.contractNumber
            ? `${API_ENDPOINTS.invoiceGenerateNextContract}/${contract?.contractNumber}`
            : `${API_ENDPOINTS.invoiceGenerateNextContract}`
        const request = await generalPostAPI(endpoint, {})

        if (!request.isOk) {
            stopGlobalLoading(loadingID)
            toast(baseErrorToastOptions(request.message))
            return
        }
        if (!request.data?.invoiceCreatedId) {
            toast(baseInfoToastOptions(translate('invoiceLinesAreUpToDate')))
        } else {
            const count = request.data?.invoiceCreatedId?.length || 0
            toast(
                baseSuccessToastOptions(
                    count > 0
                        ? `${translate('generatedCount')}: ${count}`
                        : translate('invoiceLinesAreUpToDate')
                )
            )
            await getData()
        }

        stopGlobalLoading(loadingID)
    }

    const onConfirmHandler = (): void => {
        generalPostAPI(
            `${API_ENDPOINTS.invoiceGenerateNext}/contract/${contract.contractNumber}`,
            {
                generateUpUntilToday: true,
            }
        ).then(() => {
            getData()
        })
    }

    const InvoicesHeaders = (): any => {
        const fields = [
            ...(isContract
                ? [
                      {
                          headerName: translate('customerNumber'),
                          filter: 'agTextColumnFilter',
                          field: 'customerNumber',
                          headerCheckboxSelection: hasSendEmail,
                          checkboxSelection: hasSendEmail,
                          cellRenderer: 'agGroupCellRenderer',
                      },
                      {
                          headerName: translate('customerName'),
                          field: 'customerName',
                          filter: 'agTextColumnFilter',
                      },
                  ]
                : [
                      {
                          headerName: translate('contractNumber'),
                          field: 'contractNumber',
                          filter: 'agTextColumnFilter',
                          headerCheckboxSelection: hasSendEmail,
                          checkboxSelection: hasSendEmail,
                      },
                  ]),
            {
                headerName: translate('invoiceNumber'),
                field: 'invoiceNumber',
                filter: 'agTextColumnFilter',
                cellRenderer: isContract ? undefined : 'agGroupCellRenderer',
            },
            {
                headerName: translate('invoiceType'),
                field: 'invoiceType',
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) =>
                    translateWithPrefix(
                        translate,
                        'invoiceType',
                        InvoiceType[params.data.invoiceType]
                    ),
            },
            {
                headerName: translate('amountExcludingVat'),
                field: 'amountExcludingVat',
                filter: 'agTextColumnFilter',
                valueFormatter: (params: any) =>
                    formatValue(params.data.amountExcludingVat),
            },
            {
                headerName: translate('creationDate'),
                field: 'creationDateTime',
                filter: 'agTextColumnFilter',
                valueFormatter: (params: any) =>
                    formatDate(params.data.creationDateTime),
            },
            {
                headerName: translate('dueDate'),
                field: 'dueDate',
                filter: 'agTextColumnFilter',
                valueFormatter: (params: any) =>
                    formatDate(params.data.dueDate),
            },
            ...(isContract
                ? [
                      {
                          headerName: translate('periodStart'),
                          field: 'periodStartDate',
                          filter: 'agTextColumnFilter',
                          valueFormatter: (params: any) =>
                              formatDate(params.data.periodStartDate),
                      },
                      {
                          headerName: translate('periodEnd'),
                          field: 'periodEndDate',
                          filter: 'agTextColumnFilter',
                          valueFormatter: (params: any) =>
                              formatDate(params.data.periodEndDate),
                      },
                  ]
                : [
                      {
                          headerName: translate('periodStart'),
                          field: 'periodStartDate',
                          filter: 'agTextColumnFilter',
                          valueFormatter: (params: any) =>
                              formatDate(params.data.periodStartDate),
                      },
                  ]),
            {
                headerName: translate('depreciation'),
                field: 'depreciation',
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) =>
                    formatValue(sumDepreciation(params.data)),
            },
            {
                headerName: translate('earnings'),
                field: 'earnings',
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) =>
                    formatValue(sumInterest(params.data)),
            },
            {
                headerName: translate('isPaid'),
                field: 'isPaid',
                flex: 1,
            },
        ]

        return fields
    }

    const onSendEmail = () => {
        console.log(selectedItems)
        // Deselect after sending email
    }

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="invoicesTable"
                    columns={InvoicesHeaders()}
                    masterDetail={true}
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: CustomerDetailInvoiceLineConfig(
                                translate,
                                formatValue
                            ),
                        },
                        getDetailRowData: (params: any) => {
                            params.successCallback(params.data.lines)
                        },
                    }}
                    onSelectionChanged={(data: any): void => {
                        const selectedNodes = data.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    rowData={data}
                    headers={
                        <Flex gap={2}>
                            {hasSendEmail && (
                                <PopupActionButton
                                    actionName={translate('sendEmail')}
                                    buttonVariant={ButtonVariant.SOLID}
                                    content={
                                        <VStack mt={5}>
                                            <Box py={'16px'}>
                                                {translate(
                                                    'areYouSureSendEmail'
                                                )}
                                            </Box>
                                        </VStack>
                                    }
                                    title={translate('confirmation')}
                                    size={SizeOptions.SM}
                                    onConfirm={onSendEmail}
                                />
                            )}
                            {hasGenerateNext && (
                                <>
                                    <PopupActionButton
                                        actionName={translate(
                                            'generateAllInvoicesUpToToday'
                                        )}
                                        buttonProps={{
                                            isDisabled:
                                                contract?.status !==
                                                    ContractStatus.Active &&
                                                contract?.status !==
                                                    ContractStatus.ScheduledForClose,
                                        }}
                                        buttonVariant={ButtonVariant.SOLID}
                                        content={
                                            <VStack mt={5}>
                                                <Box py={'16px'}>
                                                    {translate(
                                                        'areYouSureInvoiceGenerateupToToday'
                                                    )}
                                                </Box>
                                            </VStack>
                                        }
                                        onConfirm={onConfirmHandler}
                                        size={SizeOptions.SM}
                                        title={translate('confirmation')}
                                    />
                                    {contract.isVariableInterest &&
                                    !isVariableInterestRules?.hidden ? (
                                        <PopupActionButton
                                            actionName={translate(
                                                'generateNext'
                                            )}
                                            buttonVariant={ButtonVariant.SOLID}
                                            buttonProps={{
                                                isDisabled:
                                                    contract?.status !==
                                                        ContractStatus.Active &&
                                                    contract?.status !==
                                                        ContractStatus.ScheduledForClose,
                                            }}
                                            content={
                                                <VStack mt={5}>
                                                    <HStack>
                                                        <Text
                                                            fontWeight={
                                                                'medium'
                                                            }
                                                        >
                                                            {translate(
                                                                'referenceRate'
                                                            )}
                                                            :
                                                        </Text>
                                                        <Text>
                                                            {interestRates[0]
                                                                ?.value ?? 0}
                                                        </Text>
                                                    </HStack>
                                                    <Box py={'16px'}>
                                                        {translate(
                                                            'areYouSureInvoiceGenerate'
                                                        )}
                                                    </Box>
                                                </VStack>
                                            }
                                            title={translate('confirmation')}
                                            size={SizeOptions.SM}
                                            onConfirm={onGenerateNext}
                                        />
                                    ) : (
                                        <Button
                                            onClick={onGenerateNext}
                                            isDisabled={
                                                contract?.status !==
                                                    ContractStatus.Active &&
                                                contract?.status !==
                                                    ContractStatus.ScheduledForClose
                                            }
                                            variant={ButtonVariant.SOLID}
                                        >
                                            {translate('generateNext')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </Flex>
                    }
                />
            </Stack>
        </>
    )
}
