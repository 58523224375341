/* eslint-disable no-nested-ternary */
import { ReactElement } from 'react'
import {
    Box,
    Text,
    Spinner,
    VStack,
    HStack,
    Alert,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react'
import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'

interface ContractWarningsInterface extends ModalSharedProps {
    warnings: Array<string>
    isLoading: boolean
}

function ContractWarnings({
    warnings,
    isLoading,
}: ContractWarningsInterface): ReactElement {
    return (
        <Box p={4} w="100%" h="100%" mx="auto">
            {isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    h="100%"
                >
                    <Spinner size="lg" />
                </Box>
            ) : warnings.length === 0 ? (
                <Text textAlign="center" color="gray.500">
                    No warnings found for this contract.
                </Text>
            ) : (
                <VStack spacing={4} align="stretch">
                    {warnings.map((warning, index) => (
                        <Alert
                            key={index}
                            status="warning"
                            variant="subtle"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            borderRadius="md"
                            boxShadow="md"
                            p={4}
                            transition="all 0.3s ease"
                            w={'100%'}
                        >
                            <HStack spacing={4}>
                                <AlertIcon />
                                <AlertTitle fontSize="md" fontWeight="bold">
                                    {warning}
                                </AlertTitle>
                            </HStack>
                        </Alert>
                    ))}
                </VStack>
            )}
        </Box>
    )
}

export const ContractWarningsModal = withModalHOC(ContractWarnings)
