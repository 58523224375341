import camelCase from 'lodash/camelCase'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiSend } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'

import {
    Box,
    Button,
    Flex,
    Grid,
    Spinner,
    Text,
    Tooltip,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react'
import { keyBy } from 'lodash'

import FilePreview from '../../../../../components/documentPreview/DocumentPreview.component'
import GenericBox from '../../../../../components/genericBox/genericBox'
import GenericHeaderComponent from '../../../../../components/genericHeader/genericHeader.component'
import PageContainer from '../../../../../components/pageContainer/PageContainer.component'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPostAPIBlobResponse,
} from '../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../services/contexts/NumberFormatter.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    downloadFileWithContent,
    getBlobURL,
} from '../../../../../utils/functions.utils'
import {
    ContractDTO,
    InvoiceDTO,
    InvoiceLineDTO,
    InvoiceLineType,
    InvoiceStatus,
    ParameterDTO,
} from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import MarkInvoicePaidModal from './components/markInvoicePaid/MarkInvoicePaid.modal'
import SingleInvoiceHeader from './components/SingleInvoiceHeader.component'
import SingleInvoiceSummary from './components/SingleInvoiceSummary.component'

export default function SingleInvoiceLine(): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const invoicePreviewHeaderBg = useColorModeValue(
        'primary.50',
        'secondary.700'
    )
    const { pathname } = useLocation()
    const invoiceId = pathname.split('/')[pathname.split('/').length - 1]
    const [invoice, setInvoice] = useState<InvoiceDTO>()
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLineDTO[]>([])
    const [contract, setContract] = useState<ContractDTO>()
    const [parameters, setParameters] = useState<{
        [key: string]: ParameterDTO
    }>()
    const [isLoadingPreview, setIsLoadingPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState('')
    const [showMarkAsPayed, setShowMarkAsPayed] = useState<boolean>(false)
    const { formatValue } = useNumberFormatterContext()
    const productId = useMemo(() => contract?.productId, [contract])
    const singleInvoiceHeaders = [
        { headerName: translate('lineNumber'), field: 'invoiceLineNumber' },
        {
            headerName: translate('lineType'),
            field: 'lineType',
            valueGetter: (params: any) =>
                translate(camelCase(InvoiceLineType[params.data?.lineType])),
        },
        { headerName: translate('lineDescription'), field: 'lineText' },
        { headerName: translate('contractAsset'), field: 'assetNumber' },
        {
            headerName: translate('lineAmount'),
            field: 'receivedAmount',
            valueFormatter: (params: any) =>
                formatValue(params, 'receivedAmount'),
        },
        {
            headerName: translate('lineVatAmount'),
            field: 'vatAmount',
            valueFormatter: (params: any) =>
                `${formatValue(params, 'receivedAmount')} %`,
        },
        {
            headerName: translate('lineTotalAmount'),
            field: 'amountIncludingVat',
            valueFormatter: (params: any) =>
                formatValue(params, 'amountIncludingVat'),
        },
    ]
    const getInvoices = async (): Promise<void> => {
        if (invoiceId) {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.invoice}/${invoiceId}`
            )
            if (response.isOk) {
                setInvoice(response.data)
                setInvoiceLines(response.data.lines)
            }
        }
    }

    const getContract = async (): Promise<void> => {
        if (invoice) {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.contract}/${invoice.contractNumber}`
            )
            if (response.isOk) {
                setContract(response.data.contract)
            }
        }
    }

    const getParameters = async (): Promise<void> => {
        if (productId) {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.parameter}/${productId}`
            )
            if (response.isOk) {
                setParameters(keyBy(response.data, (p: ParameterDTO) => p.key))
            }
        }
    }

    useEffect(() => {
        getInvoices()
    }, [])

    useEffect(() => {
        getContract()
    }, [invoice])

    useEffect(() => {
        getParameters()
    }, [productId])

    function renderHeader(): ReactElement {
        return invoice ? (
            <GenericHeaderComponent
                elements={[
                    <SingleInvoiceHeader
                        key={1}
                        invoice={invoice as InvoiceDTO}
                    />,
                ]}
            />
        ) : (
            <></>
        )
    }

    async function sendReminder(): Promise<void> {
        await generalPostAPI(API_ENDPOINTS.invoiceActionsSendReminder, {
            invoiceNumber: invoice?.invoiceNumber,
        })
    }

    function renderFooter(): ReactElement {
        function checkDueDate(): boolean {
            return (
                !!invoice?.dueDate &&
                new Date('2023-08-01T00:00:00Z') < new Date()
            )
        }

        return (
            <Flex justifyContent={'space-between'} px={2}>
                <Flex gap={4}>
                    <Tooltip
                        isDisabled={!invoice?.isEmailSent || checkDueDate()}
                        hasArrow
                        label={translate('dueDateStillValid')}
                    >
                        <Button
                            isDisabled={!invoice?.isEmailSent || checkDueDate()}
                            onClick={(): Promise<void> => sendReminder()}
                            minW="48"
                            isLoading={isLoadingPreview}
                        >
                            {`${translate('sendReminder')}: #${
                                (invoice?.reminderCountAlreadySent || 0) + 1
                            }`}
                        </Button>
                    </Tooltip>

                    <Button
                        onClick={(): void => setShowMarkAsPayed(true)}
                        minW="48"
                        isLoading={isLoadingPreview}
                    >
                        {translate('markPaid')}
                    </Button>
                </Flex>
                <Flex gap={4}>
                    {parameters?.AutoInvoiceEmail?.value !== 'true' && (
                        <Button
                            onClick={onSendInvoice}
                            leftIcon={<FiSend />}
                            minW="48"
                        >
                            {translate('sendInvoice')}
                        </Button>
                    )}
                    {invoice?.status === InvoiceStatus.Posted && (
                        <Button onClick={onCredit}>
                            {translate('credit')}
                        </Button>
                    )}
                </Flex>
            </Flex>
        )
    }

    async function onDownloadInvoice(): Promise<void> {
        if (invoice?.invoiceNumber) {
            const response = await generalPostAPIBlobResponse(
                API_ENDPOINTS.documentGenerateInvoice,
                {
                    invoiceNumber: invoice.invoiceNumber,
                }
            )
            if (response.isOk && response.data?.type) {
                const contentType = response.data.type
                const extension = contentType.split('/')[1]

                if (extension) {
                    downloadFileWithContent(
                        response.data,
                        `${translate('invoice')} ${invoice.invoiceNumber}`,
                        extension
                    )
                    toast(
                        baseSuccessToastOptions(translate('documentDownloaded'))
                    )
                }
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        }
    }

    async function onPreviewInvoice(): Promise<void> {
        if (invoice?.invoiceNumber) {
            setIsLoadingPreview(true)
            const response = await generalPostAPIBlobResponse(
                API_ENDPOINTS.documentGenerateInvoice,
                {
                    invoiceNumber: invoice.invoiceNumber,
                }
            )
            if (response.isOk) {
                const blobURL = getBlobURL(response.data, 'pdf')
                setPreviewURL(`${blobURL}.pdf`)
            } else {
                toast(baseErrorToastOptions(response.message))
            }
            setIsLoadingPreview(false)
        }
    }

    async function onSendInvoice(): Promise<void> {
        if (invoice?.invoiceNumber) {
            const response = await generalPostAPI(
                API_ENDPOINTS.invoiceActionsResendInvoiceEmail(
                    invoice.invoiceNumber
                )
            )
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('emailSuccessSent')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        }
    }

    async function onCredit(): Promise<void> {
        if (invoice?.invoiceNumber) {
            const response = await generalPostAPI(
                API_ENDPOINTS.invoiceActionsCredit,
                {
                    invoiceNumber: invoice.invoiceNumber,
                }
            )
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('creditSuccess')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        }
    }

    return (
        <PageContainer
            pageHeader={renderHeader()}
            pageFooter={renderFooter()}
            noPadding={true}
        >
            <Grid
                gridTemplateColumns={{
                    base: '1fr',
                    '2xl': `1fr 612px`,
                }}
                maxW="100%"
                gap={4}
                m={8}
            >
                <Box>
                    <Box mb={8}>
                        {invoice && <SingleInvoiceSummary invoice={invoice} />}
                    </Box>
                    <DynamicGrid
                        tableId="singleInvoiceTable"
                        columns={singleInvoiceHeaders}
                        rowData={invoiceLines}
                    />
                </Box>
                <GenericBox transition={'250ms'}>
                    <Flex
                        alignItems={'center'}
                        bg={invoicePreviewHeaderBg}
                        borderTopRadius={'md'}
                        justifyContent={'space-between'}
                        p={'.5rem'}
                    >
                        <Text fontWeight={600} fontSize={'xl'}>
                            {translate('invoicePreview')}
                        </Text>
                        <Flex gap={2}>
                            <Button onClick={onDownloadInvoice} size={'sm'}>
                                {translate('download')}
                            </Button>
                            <Button
                                size={'sm'}
                                onClick={onPreviewInvoice}
                                isLoading={isLoadingPreview}
                                isDisabled={!!previewURL}
                            >
                                {translate('preview')}
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex justifyContent={'center'} py={6}>
                        {previewURL && (
                            <FilePreview
                                url={previewURL}
                                shouldRemoveSuffix={true}
                            />
                        )}
                        {isLoadingPreview && <Spinner size="xl" />}
                    </Flex>
                </GenericBox>
            </Grid>
            <MarkInvoicePaidModal
                isModalOpen={showMarkAsPayed}
                invoice={invoice as InvoiceDTO}
                modalTitle={translate('markPaid')}
                onClose={(): void => {
                    setShowMarkAsPayed(false)
                    window.location.reload()
                }}
                maxWidth="1000px"
            ></MarkInvoicePaidModal>
        </PageContainer>
    )
}
