import { validate as validateUUID } from 'uuid'

import {
    AssetServiceDTO,
    BaseServiceDTO,
    ContractAssetDTO,
    TransientFeeDTO,
} from '../../utils/types/types'

export type CustomServiceType = BaseServiceDTO & AssetServiceDTO

export type CustomContractFinancingType = {
    balanceAtEnd: number | null
    balanceLastYear: number | null
    balanceMaxAtEnd: number | null
    depreciationFirstYear: number | null
    depreciationPercent: number | null
    depreciationRestValuePercent: number | null
    excessPaymentFee: number | null
    firstPayment: number | null
    firstPaymentIncludingVat: number | null
    minRestValueAllowed: number | null
    partRegistrationInfo: null
    startDate: string | null
    totalPeriodicPayment: number | null
    totalPeriodicPaymentIncludingVat: number | null
    totalServicePaymentIncludingVat: number | null
    totalServicePayment: number
    totalPeriodicPaymentWithAll: number
    totalPeriodicPaymentWithAllIncludingVat: number
    estimatedUsagePayment: number
    estimatedUsagePaymentIncludingVat: number
    totalPrincipal: number | null
    totalPrincipalIncludingVat: number | null
    totalPurchase: number | null
    totalPurchaseIncludingVat: number | null
    totalRestValue: number | null
    totalRestValueIncludingVat: number | null
    totalRentalPrice: number | null
}

export const validateAssetServices = (
    asset: ContractAssetDTO
): ContractAssetDTO => {
    if (!asset?.assetServices) return asset
    const assetServices = asset?.assetServices?.map(
        (assetService: AssetServiceDTO) => ({
            id: Object.prototype.hasOwnProperty.call(assetService, 'isNew')
                ? 0
                : assetService.id,
            baseServiceNumber: assetService.baseServiceNumber,
            fixedValueOverride: assetService.fixedValueOverride,
        })
    )
    return { ...asset, assetServices }
}

export const validateContractAssetID = (
    asset: ContractAssetDTO
): ContractAssetDTO => {
    const assetNumber =
        asset?.assetNumber && asset.assetNumber.includes('_')
            ? ''
            : asset.assetNumber

    return {
        ...asset,
        assetNumber,
    }
}

export const validateAssets = (
    assets: ContractAssetDTO[] | undefined
): ContractAssetDTO[] => {
    if (!assets || !assets.length) return []
    return assets?.map((asset: ContractAssetDTO) => {
        let validatedAsset = validateContractAssetID(asset)
        validatedAsset = validateAssetServices(validatedAsset)
        return validatedAsset
    })
}

export const validateTransientFees = (
    fees: TransientFeeDTO[] | undefined
): TransientFeeDTO[] => {
    if (!fees || !fees.length) return []
    return fees?.map((fee: any) => {
        if (validateUUID(fee.id.toString())) {
            delete fee.id
        }
        return fee
    })
}
