import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Flex, Select, Text } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import { generalGetAPI } from '../../../../../services/API/general.api'
import { formatDate } from '../../../../../utils/localization/culture.utils'

interface AssetFutureEndProps extends ModalSharedProps {
    contractAssetNumber: string
    onConfirm: Function
}
function AssetFutureEnd({
    isModalOpen,
    onClose,
    contractAssetNumber,
    onConfirm,
}: AssetFutureEndProps): ReactElement {
    const translate = useTranslation().t
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [availableDates, setAvailableDates] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    async function getAvailableDates(): void {
        setLoading(true)
        const response = await generalGetAPI(
            `api/asset/${contractAssetNumber}/available-future-end-dates`
        )
        if (response.isOk) {
            setAvailableDates(
                response.data.estimatedEndDates?.[0]?.availableFutureEndDates
            )
        }
        setLoading(false)
    }

    useEffect((): void => {
        getAvailableDates()
    }, [isModalOpen])

    return (
        <Box>
            <Flex gap={2}>
                <Text size={'md'} mb={'2'} fontWeight={'bold'}>
                    {translate('firstExpectedEndDate')}:
                </Text>
                <span>{formatDate(availableDates[0])}</span>
            </Flex>
            <Text size={'md'} mb={'2'} fontWeight={'bold'}>
                {translate('availableDates')}
            </Text>
            <Select
                disabled={loading}
                placeholder={translate('selecEndDate')}
                onChange={(value) => {
                    setSelectedDate(new Date(value.currentTarget.value))
                }}
            >
                {availableDates.map((date) => (
                    <option key={date} value={date}>
                        {formatDate(date)}
                    </option>
                ))}
            </Select>
            <Flex gap={2} mt={4} justifyContent="right">
                <Button onClick={() => onClose()}>{translate('cancel')}</Button>

                <Button onClick={() => onConfirm(selectedDate)}>
                    {translate('confirm')}
                </Button>
            </Flex>
        </Box>
    )
}

export default withModalHOC<AssetFutureEndProps>(AssetFutureEnd)
