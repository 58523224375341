import { ReactElement, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import { Text } from '@chakra-ui/react'

import { useUser } from '../../services/contexts/user.context'
import getLocaleFormats, {
    LocalizationConfiguration,
} from '../../utils/localization/localization.utils'

function HighlightCustomText({ ...props }: any): ReactElement {
    return <Text {...props}>{props.value}</Text>
}

export default function NumberFormatterPlainText({
    ...props
}: NumericFormatProps<any>): ReactElement {
    const { user } = useUser()
    const [format, setFormat] = useState<Partial<LocalizationConfiguration>>({})

    useEffect(() => {
        const formatting = getLocaleFormats(user?.formatting) || {}
        setFormat(formatting)
    }, [JSON.stringify(user), JSON.stringify(props)])

    return (
        <NumericFormat
            {...props}
            fixedDecimalScale
            thousandSeparator={format.thousands}
            decimalSeparator={format.decimals}
            customInput={(props?.element as any) || HighlightCustomText}
        />
    )
}
