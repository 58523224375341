import { CountryCode } from '../types/types'

export interface LocalizationConfiguration {
    decimals: string
    thousands: string
    date: string
    currency: string
}
export default function getLocaleFormats(
    locale: CountryCode
): LocalizationConfiguration {
    switch (locale) {
        case CountryCode.DK:
            return {
                decimals: ',',
                thousands: '.',
                date: 'dd/MM/yyyy',
                currency: 'DKK',
            }
        case CountryCode.GB:
            return {
                decimals: '.',
                thousands: ',',
                date: 'dd/MM/yyyy',
                currency: 'GBP',
            }
        case CountryCode.SE:
            return {
                decimals: ',',
                thousands: ' ',
                date: 'yyyy/MM/dd',
                currency: 'SEK',
            }
        case CountryCode.DE:
            return {
                decimals: ',',
                thousands: '.',
                date: 'dd/MM/yyyy',
                currency: 'EUR',
            }
        case CountryCode.NO:
            return {
                decimals: ',',
                thousands: '.',
                date: 'dd/MM/yyyy',
                currency: 'NOK',
            }
        case CountryCode.FI:
            return {
                decimals: ',',
                thousands: ' ',
                date: 'dd/MM/yyyy',
                currency: 'EUR',
            }
        default:
            return {
                decimals: '.',
                thousands: ',',
                date: 'dd/MM/yyyy',
                currency: 'USD',
            }
    }
}
