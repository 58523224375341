import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface ICloseContractSummary {
    hasGeneratedClosingContract: boolean
    checkContractMessage: string
}

export default function CloseContractSummary({
    hasGeneratedClosingContract,
    checkContractMessage,
}: ICloseContractSummary): ReactElement {
    const translate = useTranslation().t

    return (
        <Box data-testid="close-contract-summary">
            <Heading
                as="h4"
                size="md"
                textTransform={'capitalize'}
                mt={4}
                mb={4}
            >
                {translate('summary')}
            </Heading>
            <UnorderedList px={4}>
                <ListItem>{translate('allInvoicesGeneratedWarning')}</ListItem>
                {hasGeneratedClosingContract && (
                    <ListItem>
                        {translate('closingContractSuccessfully')}
                    </ListItem>
                )}
                {checkContractMessage && (
                    <ListItem color={'red.500'}>
                        {checkContractMessage}
                    </ListItem>
                )}
            </UnorderedList>
        </Box>
    )
}
