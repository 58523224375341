/* eslint-disable default-case */
import { AgChartOptions } from 'ag-charts-community'
import { AgCharts } from 'ag-charts-react'
import { useEffect, useState } from 'react'

import { Box, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import {
    MonthlyFinancialSummary,
    ProductType,
} from '../../../utils/types/types'

const formatNumber = (number: number) => {
    if (number >= 1000000) return `${(number / 1000000).toFixed(1)}M`
    if (number >= 1000) return `${(number / 1000).toFixed(1)}k`
    return number.toString()
}

const mockData: MonthlyFinancialSummary[] = [
    { year: 2024, month: 0, revenue: 290000 },
    { year: 2024, month: 1, revenue: 292000 },
    { year: 2024, month: 2, revenue: 295000 },
    { year: 2024, month: 3, revenue: 297000 },
    { year: 2024, month: 4, revenue: 296000 },
    { year: 2024, month: 5, revenue: 298000 },
    { year: 2024, month: 6, revenue: 300000 },
    { year: 2024, month: 7, revenue: 301000 },
    { year: 2024, month: 8, revenue: 303000 },
    { year: 2024, month: 9, revenue: 304000 },
    { year: 2024, month: 10, revenue: 305000 },
    { year: 2024, month: 11, revenue: 306000 },
    { year: 2023, month: 0, revenue: 280000 },
    { year: 2023, month: 1, revenue: 281000 },
    { year: 2023, month: 2, revenue: 282500 },
    { year: 2023, month: 3, revenue: 283000 },
    { year: 2023, month: 4, revenue: 282000 },
    { year: 2023, month: 5, revenue: 284000 },
    { year: 2023, month: 6, revenue: 285000 },
    { year: 2023, month: 7, revenue: 286000 },
    { year: 2023, month: 8, revenue: 287500 },
    { year: 2023, month: 9, revenue: 288000 },
    { year: 2023, month: 10, revenue: 289000 },
    { year: 2023, month: 11, revenue: 290000 },
    { year: 2022, month: 0, revenue: 270000 },
    { year: 2022, month: 1, revenue: 271000 },
    { year: 2022, month: 2, revenue: 272000 },
    { year: 2022, month: 3, revenue: 272500 },
    { year: 2022, month: 4, revenue: 273000 },
    { year: 2022, month: 5, revenue: 273500 },
    { year: 2022, month: 6, revenue: 274000 },
    { year: 2022, month: 7, revenue: 273500 },
    { year: 2022, month: 8, revenue: 274500 },
    { year: 2022, month: 9, revenue: 275000 },
    { year: 2022, month: 10, revenue: 275500 },
    { year: 2022, month: 11, revenue: 276000 },
]

const transformData = (sortedData: MonthlyFinancialSummary[]) =>
    sortedData.map((item) => ({
        month: `${item.year}-${String(item.month + 1).padStart(2, '0')}`,
        expected: item.revenue,
        committed: item.revenue - 20000,
    }))

interface ARRLineChartProps {
    selectedProduct?: ProductType
    contractTemplate?: number
}

const ARRLineChart = ({
    selectedProduct,
    contractTemplate,
}: ARRLineChartProps) => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [currentARR, setCurrentARR] = useState<number | null>(null)
    const [previousARR, setPreviousARR] = useState<number | null>(null)
    const [percentageIncrease, setPercentageIncrease] = useState<number | null>(
        null
    )
    const [previousARRs, setPreviousARRs] = useState<number[]>([])
    const backgroundColor = useColorModeValue('#fff', '#2d3748')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.dashboardTotalRevenue,
                    {
                        productId: contractTemplate,
                        productType: selectedProduct,
                    }
                )
                if (!response.isOk) {
                    throw new Error('Network response was not ok')
                }
                const apiData: MonthlyFinancialSummary[] = response.data
                processChartData(apiData)
            } catch (err) {
                console.error('Failed to fetch data:', err)
                processChartData(mockData)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [selectedProduct, contractTemplate])

    const processChartData = (chartData: MonthlyFinancialSummary[]) => {
        const sortedChartData = [...chartData].sort(
            (entryA, entryB) =>
                entryA.year - entryB.year || entryA.month - entryB.month
        )
        setData(transformData(sortedChartData))

        const { length } = sortedChartData
        const getARRFromIndex = (index: number) =>
            index < 0 || index >= length ? 0 : sortedChartData[index].revenue

        const arrValues = [
            getARRFromIndex(length - 1),
            getARRFromIndex(length - 2),
            getARRFromIndex(length - 3),
            getARRFromIndex(length - 5),
            getARRFromIndex(length - 13),
            getARRFromIndex(length - 25),
        ]

        setCurrentARR(arrValues[0])
        setPreviousARR(arrValues[1])
        setPreviousARRs(arrValues)

        const increase = ((arrValues[0] - arrValues[1]) / arrValues[1]) * 100
        setPercentageIncrease(increase)
    }

    if (loading) {
        return (
            <Flex justifyContent="center" alignItems="center" flex="1">
                <Spinner size="xl" color="teal.500" />
            </Flex>
        )
    }

    const options: AgChartOptions = {
        data,
        series: [
            {
                type: 'line',
                xKey: 'month',
                yKey: 'expected',
                yName: 'Expected ARR',
                stroke: 'hsl(204, 70%, 50%)',
            },
            {
                type: 'line',
                xKey: 'month',
                yKey: 'committed',
                yName: 'Committed ARR',
                stroke: '#2ca02c',
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: { rotation: 0 },
                title: {
                    text: 'Month',
                    enabled: true,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params: { value: number }) =>
                        formatNumber(params.value),
                },
                title: {
                    text: 'Revenue',
                    enabled: true,
                },
            },
        ],
        legend: {
            enabled: true,
            position: 'bottom',
        },
    }

    return (
        <Box position="relative">
            <Flex
                height="400px"
                direction="column"
                bg={backgroundColor}
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
                p={4}
                borderRadius="lg"
                flex="1"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
            >
                <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
                    Annual Recurring Revenue
                </Text>

                <Box flex="1" width="100%" height="300px">
                    {data.length > 0 ? (
                        <AgCharts options={options} />
                    ) : (
                        <Text color="red.500">
                            No data available for rendering.
                        </Text>
                    )}
                </Box>

                <Flex
                    position="absolute"
                    gap={4}
                    alignItems="center"
                    top="40px"
                    right="20px"
                    bg="white"
                    p={2}
                    borderRadius="md"
                    boxShadow="md"
                >
                    <Text fontSize="2xl" fontWeight="bold">
                        ${formatNumber(currentARR || 0)}
                    </Text>
                    <Text fontSize="md" color="gray.500">
                        from ${formatNumber(previousARR || 0)}
                    </Text>
                    <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color={
                            percentageIncrease && percentageIncrease > 0
                                ? 'green.500'
                                : 'red.500'
                        }
                    >
                        {percentageIncrease
                            ? `${percentageIncrease.toFixed(2)}%`
                            : ''}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                mt={4}
                width="100%"
                bg={backgroundColor}
                p={4}
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
                <Box>
                    <Text fontSize="2xl" fontWeight="bold">
                        ${formatNumber(currentARR || 0)}
                    </Text>
                    <Text color="gray.500">Current ARR</Text>
                </Box>
                {previousARRs.map((rev, idx) =>
                    idx <= 5 && idx > 0 ? (
                        <Box key={idx} textAlign="center">
                            <Text fontSize="lg" fontWeight="bold">
                                ${formatNumber(rev)}
                            </Text>
                            <Text color="gray.500">
                                {(() => {
                                    switch (idx) {
                                        case 1:
                                            return '1 Month'
                                        case 2:
                                            return '2 Months'
                                        case 3:
                                            return '4 Months'
                                        case 4:
                                            return '12 Months'
                                        case 5:
                                            return '24 Months'
                                    }
                                })()}
                            </Text>
                        </Box>
                    ) : null
                )}
            </Flex>
        </Box>
    )
}

export default ARRLineChart
