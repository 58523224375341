import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactRouterPrompt from 'react-router-prompt'

import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'

interface IPrompt {
    when: boolean
    onClose: Function
}

export default function Prompt({ when, onClose }: IPrompt): ReactElement {
    const translate = useTranslation().t

    return (
        <ReactRouterPrompt when={when}>
            {({ isActive, onConfirm, onCancel }) => (
                <Modal
                    isOpen={isActive}
                    onClose={() => {
                        onClose()
                    }}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{translate('unsavedChanges')}</ModalHeader>
                        <ModalCloseButton onClick={onCancel} />
                        <ModalBody>
                            <p>{translate('hasChanges')}</p>
                        </ModalBody>

                        <ModalFooter>
                            <Button minWidth={24} mr={3} onClick={onCancel}>
                                {translate('stay')}
                            </Button>
                            <Button
                                minWidth={24}
                                onClick={(e) => {
                                    onConfirm(e)
                                    onClose()
                                }}
                            >
                                {translate('leave')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </ReactRouterPrompt>
    )
}
