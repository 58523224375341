import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../../../../utils/functions.utils'
import {
    ContractBundleDTO,
    ProductDTO,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'

interface IEditContractTemplateDocuments {
    product: ProductDTO
}

export default function ContractTemplateBundles({
    product,
}: IEditContractTemplateDocuments): ReactElement {
    const toast = useToast()
    const translate = useTranslation().t
    const [bundles, setBundles] = useState<ContractBundleDTO[]>()

    const handleFetchBundles = async (): Promise<void> => {
        try {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.contractBundle}?productId=${product.id}`
            )
            if (response.isOk) {
                setBundles(response.data)
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleFetchBundles()
    }, [])

    const bundlesHeaders = [
        { headerName: translate('id'), field: 'id' },
        {
            headerName: translate('contractBundleName'),
            field: 'contractBundleName',
        },
    ]

    return (
        <Box>
            <DynamicGrid
                tableId="contractTemplateBundlesTable"
                columns={bundlesHeaders}
                rowData={bundles}
            />
        </Box>
    )
}
