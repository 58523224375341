import { Box } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generalGetAPI } from '../../../../services/API/general.api'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    CalculatedDiscountValuesDTO,
    ContractStatus,
    DiscountType,
    ProductType,
} from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { columnsConfig } from './Discounts.config'
import { useContractService } from '../../../../services/contract/Contract.services'

export default function ContractDiscountsTab(): ReactElement {
    const { contract, product } = useContractService()
    const translate = useTranslation().t
    const [discounts, setDiscounts] = useState<CalculatedDiscountValuesDTO[]>(
        []
    )

    const getDiscounts = async () => {
        if (contract?.contractNumber) {
            const response = await generalGetAPI(
                API_ENDPOINTS.contractDiscounts(contract.contractNumber)
            )
            if (response.isOk) setDiscounts(response.data)
        }
    }

    useEffect(() => {
        getDiscounts()
    }, [])

    console.log(discounts)

    return (
        <Box>
            <DynamicGrid
                tableId="discountsTable"
                columns={columnsConfig(
                    translate,
                    contract.status === ContractStatus.Offer &&
                        !(
                            product.productType ===
                                ProductType.FinancialLeasing ||
                            product.productType ===
                                ProductType.OperationalLeasing
                        ),
                    product?.canBeDiscounted
                )}
                rowData={discounts}
                pagination={true}
                cellValueChanged={(event: any) => {
                    console.log('Cell value changed:', event.data)
                    // Perform actions based on the changed cell value
                    if (event.data.discountType) {
                        if (
                            event.data.discountType === DiscountType.AssetPrice
                        ) {
                            console.log('AssetPrice', contract)
                        } else if (
                            event.data.discountType ===
                            DiscountType.AssetService
                        ) {
                            console.log('AssetService', contract)
                        } else if (
                            event.data.discountType ===
                            DiscountType.TransientFee
                        ) {
                            console.log('TransientFee', contract)
                        }
                    }
                }}
            />
        </Box>
    )
}
