import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react'

const PenneoContext = createContext<any>({
    penneoPublicToken: '',
    setPenneoPublicToken: null,
    penneoStatus: false,
    setPenneoStatus: null,
})
export const usePenneo = () => useContext(PenneoContext)

interface PenneoContextProviderInterface {
    children: ReactNode
}

const PenneoContextProvider = ({
    children,
}: PenneoContextProviderInterface): ReactElement => {
    const [penneoPublicToken, setPenneoPublicToken] = useState('')
    const [penneoStatus, setPenneoStatus] = useState(false)

    return (
        <PenneoContext.Provider
            value={{
                penneoPublicToken,
                setPenneoPublicToken,
                penneoStatus,
                setPenneoStatus,
            }}
        >
            {children}
        </PenneoContext.Provider>
    )
}

export default PenneoContextProvider
