import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Fade, Heading } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { useContractService } from '../../../../services/contract/Contract.services'
import { CountryCode, LocationDTO } from '../../../../utils/types/types'

export default function CustomerInfoLocations(): ReactElement {
    const [isLoading, setLoading] = useState(true)
    const [locations, setLocations] = useState<LocationDTO[]>([])
    const { contract, updateContract } = useContractService()
    const translate = useTranslation().t

    const onFormChange = (data: any) => {
        updateContract(data)
    }

    const fetchLocations = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.location}?customerNumber=${contract?.customerNumber}&locationType=2`
        )
        setLoading(false)
        if (!response.isOk) return
        setLocations(response.data)
    }

    useEffect(() => {
        fetchLocations()
    }, [])

    const locationsKeyValues = locations.map((location) => [
        `${location.locationNumber}`,
        `${location.locationName} | ${location.city}, ${
            CountryCode[location.countryCode]
        }`,
    ])

    return (
        <Box p={6} rounded="lg">
            <Fade in={!isLoading}>
                <Heading size={'md'} mb={4}>
                    {translate('locations')}
                </Heading>
                <DynamicForm
                    data={contract}
                    columns={[1, 1, 2, 3]}
                    formFields={[
                        {
                            inputProps: { type: 'select' },
                            isValueString: true,
                            label: 'primaryUserLocation',
                            optionsKeysValues: locationsKeyValues,
                            skipTranslation: true,
                            slug: 'primaryUserLocationNumber',
                            value: contract?.primaryUserLocationNumber || '',
                        },
                        {
                            inputProps: { type: 'select' },
                            isValueString: true,
                            label: 'placementCityLocation',
                            optionsKeysValues: locationsKeyValues,
                            skipTranslation: true,
                            slug: 'placementCityLocationNumber',
                            value: contract?.placementCityLocationNumber || '',
                        },
                        {
                            inputProps: { type: 'select' },
                            isValueString: true,
                            label: 'deliveredAtLocation',
                            optionsKeysValues: locationsKeyValues,
                            skipTranslation: true,
                            slug: 'deliveredAtLocationNumber',
                            value: contract?.deliveredAtLocationNumber || '',
                        },
                    ]}
                    onFormChange={onFormChange}
                    hideSubmit={true}
                    onSubmit={undefined}
                />
            </Fade>
        </Box>
    )
}
