/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, useBoolean, useToast } from '@chakra-ui/react'

import CustomerDownloadConfig from '../../app/config/download/customer.config.json'
import DownloadActions from '../../components/downloadActions/DownloadActions.component'
import PageContainer from '../../components/pageContainer/PageContainer.component'
import AddCustomerComponent from '../../features/addCustomer/AddCustomer.component'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI, generalPostAPI } from '../../services/API/general.api'
import useIsMounted from '../../services/hooks/isMounted'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../utils/functions.utils'
import { CustomerDTO } from '../../utils/types/types'
import DynamicGrid from '../demo/DynamicAGGrid.component'
import { columnsConfig } from './Customers.config'

export default function CustomersPage(): ReactElement {
    const gridApiRef = useRef(null)
    const navigate = useNavigate()
    const translate = useTranslation().t
    const [selectedItems, setSelectedItems] = useState<CustomerDTO[]>([])
    const [customers, setCustomers] = useState<CustomerDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isAddCustomerOpen, setAddCustomer] = useBoolean()

    const isMounted = useIsMounted()
    const toast = useToast()

    async function getCustomers(): Promise<void> {
        setLoading(true)
        const response = await generalGetAPI(API_ENDPOINTS.customer)
        setLoading(false)
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
        }
        setCustomers(response.data as CustomerDTO[])
    }

    const handleSaveCustomer = async (customer: CustomerDTO): Promise<void> => {
        const response = await generalPostAPI(API_ENDPOINTS.customer, customer)
        if (response?.isOk) {
            getCustomers()
            toast(baseSuccessToastOptions(translate('customerCreated')))
        } else {
            toast(baseErrorToastOptions(response?.message))
        }
        setAddCustomer.off()
    }

    useEffect((): void => {
        isMounted() && getCustomers()
    }, [])

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const getSelectedData = (): void => {
        const selectedNodes = (gridApiRef?.current as any)?.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedItems(selectedData)
    }

    const selectAllElements = async (): Promise<void> => {
        customers && setSelectedItems([...customers])
    }

    return (
        <PageContainer>
            <Flex flexDirection={'column'} gap={4}>
                <DynamicGrid
                    tableId="customersPageTable"
                    onGridReady={onGridReady}
                    columns={columnsConfig(
                        translate,
                        navigate,
                        isDownloadEnabled
                    )}
                    rowData={customers}
                    pagination={true}
                    rowSelection={'multiple'}
                    rowMultiSelectWithClick={false}
                    onSelectionChanged={(): void => {
                        getSelectedData()
                    }}
                    headers={
                        <Flex
                            justifyContent={'flex-end'}
                            alignItems="center"
                            gap={2}
                            py={2}
                        >
                            <DownloadActions
                                isDownloadEnabled={isDownloadEnabled}
                                setDownloadEnabled={setDownloadEnabled}
                                selectAllElements={selectAllElements}
                                selectedItems={selectedItems}
                                downloadConfig={CustomerDownloadConfig}
                            />
                            <Button
                                onClick={(): void => {
                                    setAddCustomer.on()
                                }}
                            >
                                {translate('addRow')}
                            </Button>
                        </Flex>
                    }
                />
                <AddCustomerComponent
                    isModalOpen={isAddCustomerOpen}
                    onClose={() => setAddCustomer.off()}
                    modalTitle={translate('addProduct')}
                    handleSaveProduct={(data: CustomerDTO) =>
                        handleSaveCustomer(data)
                    }
                    maxWidth="1000px"
                />
            </Flex>
        </PageContainer>
    )
}
