import { IFormField } from '../../../../features/dynamicForm/DynamicForm.interfaces'
import { buildListOfKeyValues } from '../../../../utils/functions.utils'
import {
    NumberSerieDTO,
    NumberSeriesEntityType,
} from '../../../../utils/types/types'

export const FORM_FIELDS_NUMBER_SERIES = (
    existing: NumberSeriesEntityType[] = [],
    isNew = true
) => {
    const existingKeys = existing.map((e) => e.toString())
    const vals = buildListOfKeyValues<typeof NumberSeriesEntityType>(
        NumberSeriesEntityType
    ).filter((v) => !existingKeys.includes(v[0]) || isNew)

    return [
        {
            slug: 'entityType',
            label: 'entityType',
            inputProps: {
                type: 'select',
                isRequired: true,
                isDisabled: !isNew,
            },
            optionsKeysValues: vals,
        },
        {
            slug: 'startDate',
            label: 'startDate',
            inputProps: { type: 'date', isRequired: true },
        },
        {
            slug: 'endDate',
            label: 'endDate',
            inputProps: { type: 'date', isRequired: true },
        },
        {
            slug: 'template',
            label: 'template',
            inputProps: { type: 'text', isRequired: true },
        },
        {
            slug: 'sequenceNumber',
            label: 'sequenceNumber',
            inputProps: { type: 'number', isRequired: true },
        },
    ] as IFormField<Partial<NumberSerieDTO>>[]
}
