import { ReactElement } from 'react'
import { FaEllipsisV } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import {
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Portal,
} from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPutAPI } from '../../../../../services/API/general.api'
import { useLoading } from '../../../../../services/contexts/Loading.context'
import { usePartner } from '../../../../../services/contexts/partner.context'
import { PartnerDTO } from '../../../../../utils/types/types'

export default function PartnersMenu(): ReactElement {
    const { partners } = usePartner()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const navigate = useNavigate()

    const handlePartnerUpdate = async (data: PartnerDTO): Promise<void> => {
        const loadingID = globalLoading()
        const response = await generalPutAPI(API_ENDPOINTS.userPartner, {
            newPartnerId: data.id,
        })
        if (response.isOk) {
            navigate('/')
            window.location.reload()
        }
        stopGlobalLoading(loadingID)
    }

    return (
        <Menu isLazy>
            <MenuButton aria-label="Partner Selection">
                <FaEllipsisV size={18} />
            </MenuButton>
            <Portal>
                <MenuList p={2} gap={4} my={4} boxShadow={'md'} zIndex={100}>
                    <MenuGroup>
                        {partners?.map((partner) => (
                            <MenuItem
                                my={1}
                                borderRadius={'xl'}
                                key={partner?.shortCode}
                                onClick={(): Promise<void> =>
                                    handlePartnerUpdate(partner)
                                }
                            >
                                {partner?.name}
                            </MenuItem>
                        ))}
                    </MenuGroup>
                </MenuList>
            </Portal>
        </Menu>
    )
}
