import { useTranslation } from 'react-i18next'

import { CheckCircleIcon } from '@chakra-ui/icons'
import { Button, HStack } from '@chakra-ui/react'

import { useContractService } from '../../../../../../services/contract/Contract.services'
import { ContractStatus } from '../../../../../../utils/types/types'

interface ICloseContractActions {
    canCloseContract: boolean
    canGenerateEndInvoice: boolean
    closingContractLoading: boolean
    closingDocLoading: boolean
    contractStatus: ContractStatus
    handleCloseContract: Function | any
    handleGenerateClosingDocument: Function | any
    handleGenerateEndInvoice: Function | any
    handleProlongContract: Function | any
    hasGeneratedClosingContract: boolean
    invoicesLoading: boolean
    isChildContract: boolean
}

export default function CloseContractActions({
    canCloseContract,
    canGenerateEndInvoice,
    closingContractLoading,
    closingDocLoading,
    contractStatus,
    handleCloseContract,
    handleGenerateClosingDocument,
    handleGenerateEndInvoice,
    handleProlongContract,
    hasGeneratedClosingContract,
    invoicesLoading,
    isChildContract,
}: ICloseContractActions): any {
    const translate = useTranslation().t
    const { product } = useContractService()

    return (
        <HStack
            mt={12}
            mb={2}
            justifyContent={'flex-end'}
            data-testid="close-contract-actions"
        >
            {!isChildContract && (
                <Button
                    isLoading={closingDocLoading}
                    isDisabled={contractStatus !== ContractStatus.Active}
                    onClick={handleGenerateClosingDocument}
                    textTransform={'capitalize'}
                    variant={hasGeneratedClosingContract ? 'ghost' : 'solid'}
                    leftIcon={
                        hasGeneratedClosingContract ? (
                            <CheckCircleIcon />
                        ) : (
                            <></>
                        )
                    }
                    cursor={hasGeneratedClosingContract ? 'auto' : 'pointer'}
                >
                    {translate('generateClosingContract')}
                </Button>
            )}
            {!isChildContract && (
                <Button
                    isLoading={invoicesLoading}
                    isDisabled={invoicesLoading || !canGenerateEndInvoice}
                    onClick={handleGenerateEndInvoice}
                    textTransform={'capitalize'}
                >
                    {translate('generateLastInvoiceLines')}
                </Button>
            )}
            {!isChildContract && (
                <Button
                    isLoading={closingContractLoading}
                    isDisabled={closingContractLoading || !canCloseContract}
                    onClick={handleCloseContract}
                    textTransform={'capitalize'}
                >
                    {translate('closeContract')}
                </Button>
            )}
            {isChildContract && product?.canBeProlonged && (
                <Button
                    onClick={handleProlongContract}
                    textTransform={'capitalize'}
                >
                    {translate('prolong')}
                </Button>
            )}
        </HStack>
    )
}
