import { ReactElement } from 'react'
import { toast } from 'react-toastify'
import { v4 } from 'uuid'

import {
    createStandaloneToast,
    ToastId,
    UseToastOptions,
} from '@chakra-ui/react'

import API_ENDPOINTS from '../services/API/apiEndpoints.constants'
import { generalFileGETAPI } from '../services/API/general.api'
import {
    ExtensionDownloadTypes,
    ExtensionTypes,
    TagType,
} from './constants.utils'
import {
    CountryCultures,
    CountryCulturesList,
} from './localization/culture.utils'
import { ASSET_CONVERT_KEYS } from './offer/offer.utils'
import {
    ContractAssetDTO,
    ContractDTO,
    ContractStatus,
    ProductRuleDTO,
    ProductRuleType,
} from './types/types'

export function toCamelCase(str: string): string {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '')
}

export function toFirstCharLowercase(str: string): string {
    if (typeof str !== 'string' || str.length === 0) {
        return str
    }

    const firstCharLower = str[0].toLowerCase()

    return firstCharLower + str.slice(1)
}

export const deepCopy = (obj: any): any => JSON.parse(JSON.stringify(obj))

export function findElementFromObjectArray(
    elements: Array<any>,
    lookUpField: string | number,
    key: string | number,
    returnField?: string
): string {
    const find = elements.find(
        (element: any) => `${element[lookUpField]}` === key
    )
    if (find) return find[returnField ?? lookUpField]
    return ''
}

export function getRandomAssetNumber(prefix?: string): string {
    return prefix ? `${prefix}${v4()}` : v4()
}

export function getSpecificKeyFromObject<T extends {}>(
    object: T,
    value: string | number
): keyof typeof object {
    return Object.keys(object)[Object.values(object).indexOf(value)] as keyof T
}

export function buildListOfKeyValues<T>(object: T): string[][] {
    const array = Object.entries(object as unknown as object) as string[][]
    const half = Math.ceil(array.length / 2)
    return array.splice(0, half)
}

function validateContractAssets(assets: ContractAssetDTO[]): boolean {
    let isContractValid = true
    assets.forEach((asset) => {
        isContractValid = isAssetValid(asset)
    })

    return isContractValid
}

export function validateContract(contract: ContractDTO): boolean {
    let isContractValid = true
    if (contract?.contractAssets && !contract?.contractAssets?.length) {
        isContractValid = false
        return isContractValid
    }
    if (contract?.contractAssets?.length) {
        isContractValid =
            contract?.contractAssets &&
            validateContractAssets(contract.contractAssets)
    }

    return isContractValid
}

export function isAssetValid(asset: ContractAssetDTO): boolean {
    if (!asset) return true
    ASSET_CONVERT_KEYS.forEach((convertKey) => {
        if (typeof asset[convertKey] === 'string') {
            asset[convertKey] = Number(asset[convertKey])
        }
    })
    if (asset.isPartRegistration) {
        return !(
            !asset.purchaseValue ||
            asset.purchaseValue <= 0 ||
            (asset?.restValue ?? 0) < 0 ||
            (asset.downpayment && asset.downpayment < 0) ||
            asset.purchaseValue +
                asset.deliveryCost +
                (asset?.registrationValue ?? 0) <
                asset.restValue + (asset?.downpayment ?? 0) ||
            !asset.make ||
            !asset.model ||
            asset.model?.length > 100
        )
    }
    return !(
        !asset.purchaseValue ||
        asset.purchaseValue <= 0 ||
        (asset?.restValue ?? 0) < 0 ||
        (asset?.restValue ?? 0) >= asset.purchaseValue ||
        (asset.downpayment && asset.downpayment < 0) ||
        (asset.downpayment && asset.downpayment >= asset.purchaseValue) ||
        asset.purchaseValue -
            (asset.downpayment ?? 0) -
            (asset?.restValue ?? 0) <=
            0 ||
        !asset.make ||
        !asset.model ||
        asset.model?.length > 100
    )
}

export const offsetTimeZoneDifference = (date: Date): Date =>
    new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000))

export const getFirstDayOfTheMonth = (now = new Date()): Date =>
    offsetTimeZoneDifference(new Date(now.getFullYear(), now.getMonth(), 1))

export const getLastDayOfTheMonth = (now = new Date()): Date =>
    offsetTimeZoneDifference(new Date(now.getFullYear(), now.getMonth() + 1, 0))

export const isAllowedToUpload = (filename: string): boolean => {
    let isAllowed = false
    const fileArr = filename.split('.')
    const Extension = fileArr[fileArr.length - 1]
    const keys = Object.keys(ExtensionTypes)
    keys.forEach((k: string) => {
        if (k.toLocaleLowerCase() === Extension.toLocaleLowerCase()) {
            isAllowed = true
        }
    })
    return isAllowed
}

export const isFileAlreadyUploaded = (
    filename: string,
    files: any
): boolean => {
    let isUploaded = false

    files.forEach((f: any) => {
        if (f.name.toLowerCase() === filename.toLowerCase()) {
            isUploaded = true
        }
    })

    return isUploaded
}

export const baseErrorToastOptions = (message: string): UseToastOptions => ({
    position: 'top-right',
    status: 'error',
    title: message,
    // variant: 'left-accent',
})

export const baseWarningToastOptions = (message: string): UseToastOptions => ({
    position: 'top-right',
    status: 'warning',
    title: message,
    variant: 'left-accent',
})

export const baseInfoToastOptions = (message: string): UseToastOptions => ({
    position: 'top-right',
    status: 'info',
    title: message,
    variant: 'left-accent',
})

export const baseSuccessToastOptions = (message: string): UseToastOptions => ({
    position: 'top-right',
    status: 'success',
    title: message,
    variant: 'left-accent',
})

export const validationStringLengthBiggerThan = (
    value: string,
    size: number
): boolean => value.length > size

export const removeFileNameExtension = (document: string): string => {
    if (!document) return ''
    const documentSplit = document.split('.')
    if (documentSplit.length <= 1) return document
    documentSplit.pop()
    return documentSplit.reduce(
        (prevValue, curValue) => prevValue + curValue,
        ''
    )
}

export const validateEmail = (email: string) =>
    email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

export const findValueInArray = (
    array: any[],
    search: string | number | undefined,
    searchKey: string,
    returnKey?: string
): any => {
    if (typeof search === 'undefined') return ''
    const found = array.find((listElement) => listElement[searchKey] === search)
    if (!found) return ''
    return returnKey ? found[returnKey] : found[searchKey]
}

export const getFileType = (filename: string, file?: any): any => {
    let documentType = ''
    if (file) {
        documentType = DocumentType[file.documentType]
    }
    let fileType = 'application/pdf'
    let fileExtension = 'pdf'
    const fileArr = filename.split('.')
    const Extension = fileArr[fileArr.length - 1]
    const keys = Object.keys(ExtensionDownloadTypes)
    const values = Object.values(ExtensionDownloadTypes)
    keys.forEach((k: string, i: number) => {
        if (k.toLocaleLowerCase() === Extension.toLocaleLowerCase()) {
            fileType = values[i]
            fileExtension = k.toLocaleLowerCase()
        }
    })
    return { fileType, fileExtension, documentType }
}

export const getBlobURL = (content: any, extension = 'csv'): string => {
    const { fileType } = getFileType(extension)
    const blob: Blob = new Blob([content], {
        type: fileType,
    })
    const url = URL.createObjectURL(blob)
    return url
}

export const downloadFileWithContent = (
    content: any,
    fileName = 'download',
    extension = 'csv'
): void => {
    const url = getBlobURL(content)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', `${fileName}.${extension}`)
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}

export const downloadFile = async (
    id: number,
    fileName: string,
    extension?: string
): Promise<void> => {
    const { fileType } = getFileType(fileName)
    const response = await generalFileGETAPI(API_ENDPOINTS.document + id)
    if (response.isOk) {
        const blob: Blob = new Blob([response.data], {
            type: fileType,
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.setAttribute(
            'download',
            `${fileName}${extension ? `.${extension}` : ''}`
        )
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
    } else if (!response.isOk) toast.error("File couldn't be downloaded")
}

export function standaloneToast(options?: UseToastOptions): ToastId {
    const { toast: toastChakra } = createStandaloneToast()
    return toastChakra(options)
}

export function toTitleCase(text: string): string {
    if (!text || text === '') return ''

    const result = text?.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

export const isEmpty = (
    argument: boolean | null | undefined | any[] | object | number | string
): boolean => {
    if (argument === null) {
        return true
    }
    // eslint-disable-next-line default-case
    switch (typeof argument) {
        case 'undefined':
            return true
        case 'boolean':
            return false
        case 'number':
            return Number.isNaN(argument)
        case 'string':
            return argument.trim().length === 0
    }
    if (Array.isArray(argument)) {
        return argument.length === 0
    }
    return Object.keys(argument).length === 0
}

export const SelectAllOnFocus = (event: any): void => event.target.select()

export const getProductRuleByType = (
    ruleType: ProductRuleType,
    productRules: ProductRuleDTO[]
): ProductRuleDTO =>
    productRules?.find((rule) => rule?.ruleType === ruleType) as ProductRuleDTO

export const isValidDate = (date?: any): boolean =>
    date instanceof Date && !Number.isNaN(date.valueOf())

export const listOfOptionsLanguage = [
    ...Object.entries(CountryCulturesList).map((entries) => {
        const languageNames = new Intl.DisplayNames(
            CountryCultures[parseInt(entries[1])],
            {
                type: 'language',
            }
        )
        return [
            entries[0],
            languageNames.of(CountryCultures[parseInt(entries[0])]) as string,
        ]
    }),
]
export const getObjectFields = (obj: Object, regex: any): Object =>
    Object.keys(obj)
        .filter((key) => regex.test(key))
        .reduce((acc, key) => {
            acc[key] = obj[key]
            return acc
        }, {} as Object)

export const buildTranslationWithValue = (
    firstTranslateKey: string,
    secondTranslationKey: string | undefined,
    translate: Function,
    value?: string | number
): string =>
    translate(firstTranslateKey).replace(
        '#',
        value !== undefined ? value : translate(secondTranslationKey)
    )

export function ColumnActionConfiguration(
    translate: Function,
    element: (params: any) => ReactElement,
    maxWidth = 200,
    headerName = 'actions'
) {
    return {
        field: '',
        headerName: translate(headerName),
        pinned: 'right',
        sortable: false,
        filter: false,
        resizable: false,
        suppressSizeToFit: true,
        minWidth: 150,
        maxWidth,
        suppressMovable: true,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: any) => element(params),
    }
}

export const getContractStatusTagType = (
    contractStatus: ContractStatus
): TagType => {
    switch (contractStatus) {
        case ContractStatus.Offer:
            return 'link'
        case ContractStatus.Cancelled:
            return 'danger'
        case ContractStatus.Active:
            return 'success'
        case ContractStatus.Closed:
            return 'warning'
        default:
            return 'default'
    }
}

export function translateWithPrefix(
    translate: Function,
    prefix: string,
    field: string
): string {
    return translate([toCamelCase(prefix), toCamelCase(field)].join('.'))
}

export const setNestedValue = (obj: any, path: string, value: any) => {
    const keys = path.split('.')
    let current = obj

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i]

        // If the current key is null or undefined, initialize it as an empty object
        if (current[key] == null) {
            current[key] = {}
        }

        current = current[key]
    }

    current[keys[keys.length - 1]] = value
}
