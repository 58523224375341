import filter from 'lodash/filter'
import find from 'lodash/find'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Flex, Stack } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../../services/API/general.api'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    standaloneToast,
} from '../../../../utils/functions.utils'
import { AvailableDocumentType } from './ContractDetailDocument.config'

interface IGenerateDocuments extends ModalSharedProps {
    fetchDocuments: Function
    availableDocumentTypes: AvailableDocumentType[]
}

function GenerateDocuments({
    fetchDocuments,
    onClose,
    availableDocumentTypes,
}: IGenerateDocuments): ReactElement {
    const { contract } = useContractService()
    const [checkedDocumentTypes, setCheckedDocumentTypes] = useState<any[]>([])
    const [localLoading, setLocalLoading] = useState<boolean>(false)
    const translate = useTranslation().t

    const toggleType = (e: any, documentType: AvailableDocumentType): void => {
        e.preventDefault()
        if (
            find(checkedDocumentTypes, {
                value: documentType.value,
            })
        ) {
            const newCheckedDocumentTypes = filter(
                checkedDocumentTypes,
                (elem) => elem.value !== documentType.value
            )
            setCheckedDocumentTypes([...newCheckedDocumentTypes])
        } else {
            setCheckedDocumentTypes([...checkedDocumentTypes, documentType])
        }
    }

    const onGenerateNext = async (): Promise<void> => {
        if (!contract || !contract.contractNumber) {
            return
        }

        setLocalLoading(true)

        try {
            const generateDocumentPromises = checkedDocumentTypes.map(
                async (doc: { key: string; value: number }): Promise<void> => {
                    const data = await generalPostAPI(
                        API_ENDPOINTS.documentGenerate,
                        {
                            contractNumber: contract.contractNumber,
                            documentType: doc.value,
                        }
                    )

                    const message = data.isOk
                        ? `${doc.key} successfully generated`
                        : `${doc.key} not generated`

                    standaloneToast(
                        data.isOk
                            ? baseSuccessToastOptions(message)
                            : baseErrorToastOptions(message)
                    )
                }
            )

            await Promise.all(generateDocumentPromises)

            fetchDocuments()
        } catch (error) {
            standaloneToast(baseErrorToastOptions('An error occurred'))
        } finally {
            setLocalLoading(false)
            onClose()
        }
    }

    return (
        <Stack spacing={5} data-testid="generate-documents">
            <Stack spacing={5} direction="column">
                {availableDocumentTypes.map((documentType) => (
                    <Checkbox
                        key={documentType.value}
                        isChecked={find(checkedDocumentTypes, {
                            value: documentType.value,
                        })}
                        onClickCapture={(e: any): void =>
                            toggleType(e, documentType)
                        }
                    >
                        {documentType?.key ? translate(documentType.key) : ''}
                    </Checkbox>
                ))}
            </Stack>
            <Flex align="center" justifyContent="flex-end">
                <Button
                    data-testid="generate-button"
                    disabled={!checkedDocumentTypes?.length}
                    isLoading={localLoading}
                    onClick={onGenerateNext}
                >
                    {translate('generate')}
                </Button>
            </Flex>
        </Stack>
    )
}

export default withModalHOC(GenerateDocuments)
