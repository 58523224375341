/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon, RepeatIcon, Search2Icon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    SimpleGrid,
    Spinner,
    Tooltip,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../services/API/general.api'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
    getObjectFields,
} from '../../../../utils/functions.utils'
import {
    ContractDebtorChangeOfferDTO,
    ContractDTO,
    ContractStatus,
    CustomerDTO,
    DocumentType,
    ProductRuleDTO,
    ProductRuleType,
    UserGroup,
} from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { useContractRulesService } from '../../../../services/rules/ProductRulesEnforcer.services'
import RichText from '../../../../components/richText/RichText'
import { useUser } from '../../../../services/contexts/user.context'

function CreateBundleComponent(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const { contract } = useContractService()
    const { productRules } = useContractRulesService()
    const [isChangeCustomerModalOpen, setIsChangeCustomerModalOpen] =
        useState(false)
    const toast = useToast()
    const { user } = useUser()
    const shadow = useColorModeValue(`0 0 12px -2px #00000020`, '')
    const [customerSearch, setCustomerSearch] = useState('')
    const [companyCustomers, setCompanyCustomers] = useState<CustomerDTO[]>()
    const [customer, setCustomer] = useState<CustomerDTO | null>(null)
    const [debtorOffer, setDebtorOffer] =
        useState<ContractDebtorChangeOfferDTO>()
    const [debtorOfferUpdated, setDebtorOfferUpdated] = useState<
        Partial<ContractDebtorChangeOfferDTO>
    >({ fee: 0, customerNumber: contract.customerNumber })
    const retrieveCustomers = async (
        term = '',
        searchOnline = true
    ): Promise<void> => {
        const response = await generalGetAPI(
            API_ENDPOINTS.customerSearch(term, searchOnline)
        )
        if (response.isOk) setCompanyCustomers(response.data)
    }
    const [isLoading, setIsLoading] = useState(false)

    const onDownloadOffer = async () => {
        try {
            const response = await generalPostAPI(
                API_ENDPOINTS.documentGenerate,
                {
                    contractNumber: contract.contractNumber,
                    documentType: DocumentType.DebtorChangeAllonge,
                }
            )
            if (response.isOk && response.data) {
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                })

                // Create a download link and trigger a click to download the PDF
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `${translate('debtorOffer')}-.pdf`
                link.click()
            }
        } catch (error) {
            console.error('Download error:', error)
        }
    }

    const executeDebtorChange = async () => {
        const confirmation = window.confirm(translate('debtorChangeConfirm'))
        if (confirmation && contract?.contractNumber) {
            const response = await generalPutAPI(
                `/api/contract/Actions/debtor-change`,
                {
                    contractNumber: contract.contractNumber,
                }
            )
            if (response?.isOk) {
                toast(
                    baseSuccessToastOptions(translate('debtorChangeComplete'))
                )
                props.onClose()
                window.location.reload()
            } else {
                toast(baseErrorToastOptions(response?.message))
            }
        }
    }

    const updateDebtorOffer = async () => {
        setIsLoading(true)
        const response = await generalPutAPI(
            `api/contract/${contract.contractNumber}/debtor-change`,
            {
                contractNumber: contract.contractNumber,
                customerNumber: debtorOfferUpdated?.customerNumber,
                fee: debtorOfferUpdated?.fee,
            }
        )
        if (response?.isOk) {
            toast(baseSuccessToastOptions(translate('debtorOfferChanged')))
        } else {
            toast(baseErrorToastOptions(response?.message))
        }
        setIsLoading(false)
    }

    const createDebtorChangeOffer = async () => {
        setIsLoading(true)

        if (contract?.contractNumber) {
            const response = await generalPostAPI(
                API_ENDPOINTS.contractDebtorChangeOffer(
                    contract.contractNumber
                ),
                {
                    contractNumber: contract.contractNumber,
                    customerNumber: debtorOfferUpdated?.customerNumber,
                    fee: debtorOfferUpdated?.fee,
                }
            )
            if (response?.isOk) {
                setDebtorOffer(response.data)
                toast(baseSuccessToastOptions(translate('offerCreated')))
            } else {
                toast(baseErrorToastOptions(response?.message))
            }
        }
        setIsLoading(false)
    }

    const fetchData = async () => {
        setIsLoading(true)
        await generalGetAPI('api/customer').then((response) => {
            if (response.isOk) setCompanyCustomers(response.data)
        })
        await generalGetAPI(
            `api/contract/${contract.contractNumber}/debtor-change`
        ).then((response) => {
            if (response.isOk) {
                setDebtorOffer(response.data)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        debtorOffer && setDebtorOfferUpdated(debtorOffer)
    }, [debtorOffer])

    useEffect(() => {
        if (debtorOffer) {
            companyCustomers?.some((item) => {
                if (item.customerNumber === debtorOffer?.customerNumber) {
                    setCustomer(item)
                    return true
                }
                return false
            })
        }
    }, [JSON.stringify(debtorOffer)])

    const columns = [
        {
            headerName: translate('customerNumber'),
            field: 'customerNumber',
        },
        {
            headerName: translate('name'),
            field: 'name',
        },
        {
            headerName: translate('customerCvr'),
            field: 'cvr',
        },
        {
            headerName: translate('street'),
            field: 'postalCode',
        },
        {
            headerName: translate('city'),
            field: 'city',
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(translate, (params: any) => (
                <Tooltip
                    label={translate('changeCustomer')}
                    placement="top"
                    hasArrow
                >
                    <IconButton
                        mr={2}
                        aria-label="Edit"
                        size="sm"
                        variant={'outline'}
                        onClick={() => {
                            setCustomer(params.data)
                            setDebtorOfferUpdated({
                                ...debtorOfferUpdated,
                                customerNumber: params.data.customerNumber,
                            })
                        }}
                        icon={<RepeatIcon />}
                    />
                </Tooltip>
            )),
        },
    ]

    const externalReferenceStringRules: Record<string, ProductRuleDTO> =
        useMemo(
            () =>
                productRules
                    .filter((pr) =>
                        [
                            ProductRuleType.ExternalReferenceString1,
                            ProductRuleType.ExternalReferenceString2,
                            ProductRuleType.ExternalReferenceString3,
                            ProductRuleType.ExternalReferenceString4,
                        ].includes(pr.ruleType)
                    )
                    .reduce(
                        (prev, curr) => ({
                            ...prev,
                            [ProductRuleType[curr.ruleType].toLowerCase()]:
                                curr,
                        }),
                        {}
                    ),
            [productRules]
        )

    return (
        <Box>
            {isLoading && (
                <Flex
                    justifyContent={'center'}
                    height={'230px'}
                    alignItems={'center'}
                >
                    <Spinner />
                </Flex>
            )}
            {!isLoading && (
                <Flex flexDirection="column">
                    <Heading size={'md'} mb={4}>
                        {translate('customer')}
                    </Heading>
                    <Flex
                        p={2}
                        boxShadow={shadow}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        gap={2}
                        mb={4}
                        borderRadius={'4px'}
                        w={'100%'}
                    >
                        <Heading size={'md'}>
                            {translate('customer')}:{' '}
                            {customer?.name?.toUpperCase() ||
                                contract.customerName?.toUpperCase()}{' '}
                            -{' '}
                            {customer?.customerNumber ||
                                contract.customerNumber}
                        </Heading>
                        <IconButton
                            width={'fit-content'}
                            variant={'outline'}
                            onClick={() =>
                                setIsChangeCustomerModalOpen(
                                    !isChangeCustomerModalOpen
                                )
                            }
                            icon={
                                !isChangeCustomerModalOpen ? (
                                    <RepeatIcon />
                                ) : (
                                    <CheckIcon />
                                )
                            }
                            aria-label={'changeCustomer'}
                        />
                    </Flex>
                    {isChangeCustomerModalOpen && (
                        <Box>
                            <Flex
                                gap={2}
                                mt={4}
                                p={2}
                                boxShadow={`0 0 12px -2px #00000020`}
                            >
                                <Input
                                    border={'none'}
                                    w={'100%'}
                                    placeholder={
                                        translate('searchCustomer') || ''
                                    }
                                    value={customerSearch}
                                    onChange={(event): void => {
                                        setCustomerSearch(event.target.value)
                                    }}
                                />
                                <IconButton
                                    icon={<Search2Icon />}
                                    onClick={() =>
                                        retrieveCustomers(customerSearch)
                                    }
                                    aria-label={'search'}
                                />
                            </Flex>

                            <Box>
                                <DynamicGrid
                                    tableId="debtorChange"
                                    boxProps={{
                                        mt: 4,
                                    }}
                                    height="400px"
                                    enableSearch={false}
                                    columns={columns}
                                    rowData={companyCustomers}
                                />
                            </Box>
                        </Box>
                    )}

                    {!isChangeCustomerModalOpen && (
                        <Box>
                            <Heading size={'md'} mb={4}>
                                {translate('fee')}
                            </Heading>
                            <Flex gap={2}>
                                <Input
                                    disabled={isLoading}
                                    type="number"
                                    placeholder={translate('fee')}
                                    value={debtorOfferUpdated?.fee}
                                    onChange={(event): void => {
                                        setDebtorOfferUpdated({
                                            ...debtorOfferUpdated,
                                            fee:
                                                Number(event.target.value) ||
                                                undefined,
                                        })
                                    }}
                                />
                            </Flex>
                            <SimpleGrid columns={4} spacing={4} my={4}>
                                {Object.keys(
                                    getObjectFields(
                                        contract,
                                        /^externalReferenceString\d+$/
                                    )
                                )
                                    .filter(
                                        (field: string) =>
                                            !externalReferenceStringRules[
                                                field.toLowerCase()
                                            ]?.hidden
                                    )
                                    .map((field: string) => (
                                        <FormControl key={field}>
                                            <FormLabel>
                                                {externalReferenceStringRules[
                                                    field.toLowerCase()
                                                ]?.customText ??
                                                    translate(field)}
                                            </FormLabel>
                                            <Input
                                                type="text"
                                                value={(contract as any)[field]}
                                                isDisabled={
                                                    externalReferenceStringRules[
                                                        field.toLowerCase()
                                                    ]?.locked ||
                                                    (user.userGroup ===
                                                        UserGroup.RestrictedUser &&
                                                        contract.status !==
                                                            ContractStatus.Offer)
                                                }
                                                onChange={(event: any) =>
                                                    console.log(event)
                                                }
                                            />
                                        </FormControl>
                                    ))}
                            </SimpleGrid>

                            <Flex gap={4} mt={8} justifyContent={'flex-end'}>
                                <Button
                                    width={'fit-content'}
                                    onClick={onDownloadOffer}
                                    isDisabled={!debtorOffer}
                                    variant={'outline'}
                                >
                                    {translate('download')}
                                </Button>
                                <Button
                                    width={'fit-content'}
                                    onClick={
                                        !debtorOffer
                                            ? createDebtorChangeOffer
                                            : updateDebtorOffer
                                    }
                                    isDisabled={
                                        (debtorOfferUpdated === debtorOffer &&
                                            debtorOfferUpdated !== undefined) ||
                                        (!debtorOffer &&
                                            debtorOfferUpdated.customerNumber ===
                                                contract.customerNumber)
                                    }
                                >
                                    {translate(
                                        debtorOffer !== undefined
                                            ? 'update'
                                            : 'create'
                                    )}
                                </Button>
                                <Button
                                    width={'fit-content'}
                                    onClick={executeDebtorChange}
                                    isDisabled={!debtorOffer}
                                >
                                    {translate('executeDebtorChange')}
                                </Button>
                            </Flex>
                        </Box>
                    )}
                </Flex>
            )}
        </Box>
    )
}

export default withModalHOC(CreateBundleComponent)
