import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const solid = defineStyle((props) => ({
    border: 'none', // change the appearance of the border
    borderRadius: '4px', // remove the border radius
    fontWeight: 'semibold', // change the font weight
    color: mode('primary.50', 'secondary.50')(props),
    bg: mode('primary.800', 'secondary.650')(props),
    _hover: {
        _disabled: {
            opacity: 0.5,
            bg: mode('primary.800', 'secondary.650')(props),
        },
        bg: mode('primary.500', 'secondary.500')(props),
    },
    _disabled: {
        opacity: 0.5,
    },
}))

const link = defineStyle((props) => ({
    color: mode('primary.600', 'secondary.300')(props),
    textDecoration: 'underline',
}))

export const buttonTheme = defineStyleConfig({
    variants: { solid, link },
    defaultProps: {
        variant: 'solid',
    },
})

export default buttonTheme
