import { IFormField } from '../../../../features/dynamicForm/DynamicForm.feature'
import { buildListOfKeyValues } from '../../../../utils/functions.utils'
import {
    ElectronicID,
    SignatureDigitalType,
    SignatureReceiver,
    SignerBaseDto,
} from '../../../../utils/types/types'

export default function addSignerFormFields(): IFormField<
    Partial<SignerBaseDto>
>[] {
    return [
        {
            slug: 'name',
            label: 'name',
            inputProps: { type: 'text', isRequired: true },
        },
        {
            slug: 'email',
            label: 'email',
            inputProps: { type: 'text', isRequired: true },
        },
        {
            slug: 'receiver',
            label: 'receiver',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues:
                buildListOfKeyValues<typeof SignatureReceiver>(
                    SignatureReceiver
                ),
        },
        {
            slug: 'digitalType',
            label: 'digitalType',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues:
                buildListOfKeyValues<typeof SignatureDigitalType>(
                    SignatureDigitalType
                ),
        },
        {
            slug: 'electronicID',
            label: 'electronicID',
            inputProps: { type: 'select' },
            optionsKeysValues:
                buildListOfKeyValues<typeof ElectronicID>(ElectronicID),
        },
        {
            slug: 'signOrder',
            label: 'signOrder',
            inputProps: { type: 'number' },
        },
    ]
}
