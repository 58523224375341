import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import DynamicGrid from '../../../../pages/demo/DynamicAGGrid.component'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { ColumnActionConfiguration } from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { BaseServiceValueDTO } from '../../../../utils/types/types'

function ServiceFeesTable({
    baseServices = [],
    tableActions = () => [],
}: {
    baseServices: BaseServiceValueDTO[]
    tableActions?: Function
}): ReactElement {
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()

    useEffect((): void => {}, [JSON.stringify(baseServices)])

    const headers = [
        {
            headerName: translate('serviceFee'),
            field: 'value',
            filter: true,
            valueFormatter: (params: any) => formatValue(params.data.value),
        },
        {
            headerName: translate('startDate'),
            field: 'startDate',
            filter: true,
            valueFormatter: (params: any) => formatDate(params.data.startDate),
        },
        {
            headerName: translate('endDate'),
            field: 'endDate',
            filter: true,
            valueFormatter: (params: any) => formatDate(params.data.endDate),
        },
        {
            ...ColumnActionConfiguration(translate, (params: any) =>
                tableActions(params.data)
            ),
        },
    ]

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                tableId="serviceFeesTable"
                columns={headers}
                rowData={baseServices}
            />
        </Flex>
    )
}

export default ServiceFeesTable
