import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFileImport, FaUpload } from 'react-icons/fa'

import {
    Box,
    Heading,
    Icon,
    Img,
    Input,
    useColorModeValue,
} from '@chakra-ui/react'

import GenericBox from '../../components/genericBox/genericBox'

interface IFileUpload {
    handleSubmission: Function
    isFormData?: boolean
    callToActionText?: string
    showPreview?: boolean
    fileData?: string
}

export default function FileUpload({
    handleSubmission,
    isFormData = false,
    callToActionText,
    showPreview,
    fileData,
}: IFileUpload): ReactElement {
    const translate = useTranslation().t
    const hiddenFileInput = useRef<any>(null)
    const [selectedFile, setSelectedFile] = useState<File>()
    const handleClick = (): void => {
        hiddenFileInput?.current?.click()
    }
    const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event?.target?.files) setSelectedFile(event.target.files[0])
    }
    const fileSelectedColor = useColorModeValue('primary.500', 'secondary.500')
    const fileNotSelectedColor = useColorModeValue(
        'primary.150',
        'secondary.800'
    )
    const [preview, setPreview] = useState<string>()

    useEffect(() => {
        if (selectedFile) {
            const objectUrl = URL.createObjectURL(selectedFile)
            setPreview(objectUrl)
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [selectedFile])

    return (
        <>
            <GenericBox
                bg={selectedFile ? fileSelectedColor : fileNotSelectedColor}
                borderRadius={'.25rem'}
                cursor={'pointer'}
                _hover={{ opacity: selectedFile ? 1 : 0.8 }}
                width={'100%'}
                textAlign={'center'}
                animation="alternate"
                data-testid="file-upload"
            >
                <Input
                    ref={hiddenFileInput}
                    type="file"
                    name="file"
                    onChange={changeHandler}
                    display={'none'}
                />
                <Box
                    p={6}
                    width="100%"
                    height="100%"
                    onClick={(): void => {
                        if (selectedFile) {
                            if (isFormData) {
                                const formData = new FormData()
                                formData.append('logoFile', selectedFile)

                                handleSubmission(formData)
                            } else {
                                handleSubmission(selectedFile)
                            }
                            setSelectedFile(undefined)
                        } else {
                            handleClick()
                        }
                    }}
                >
                    {selectedFile ? (
                        <Icon as={FaUpload} mr={2} />
                    ) : (
                        <Icon as={FaFileImport} mr={2} />
                    )}
                    <Heading as="h3" size="md">
                        {selectedFile
                            ? translate(`confirmUpload`)
                            : translate(callToActionText || 'selectDocument')}
                    </Heading>
                </Box>
            </GenericBox>
            {(selectedFile || fileData) && showPreview && (
                <Img maxH={'500px'} src={preview ?? fileData} />
            )}
            {selectedFile && selectedFile.name}
        </>
    )
}
