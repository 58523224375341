import {
    validateEmail,
    validationStringLengthBiggerThan,
} from '../../utils/functions.utils'
import {
    ElectronicID,
    SignatureDigitalType,
    SignatureReceiver,
    SignerDto,
} from '../../utils/types/types'
import { ErrorsOutput, IFormField } from '../dynamicForm/DynamicForm.interfaces'

/** FORM */

export const SignerReceiverOptions = (hasElectronicID: boolean) => {
    const fields = [
        [SignatureReceiver.Copy, 'Copy'],
        [SignatureReceiver.Signer, 'Signer'],
    ]

    if (hasElectronicID) {
        fields.push([SignatureReceiver.Approver, 'Approved'])
    }
    return fields
}

export const SignerDigitalOptions = [
    [SignatureDigitalType.Digital, 'Digital Signature'],
    [SignatureDigitalType.Touch, 'Touch Signature'],
]

export const SignerElectronicIDOptions = [
    [ElectronicID.MitID, 'MitID'],
    [ElectronicID.NorwegianBankID, 'NorwegianBankID'],
    [ElectronicID.SwedishBankID, 'SwedishBankID'],
]

export const DefaultSignersFormFields = (
    item: SignerDto,
    hasElectronicID: boolean
): IFormField<SignerDto>[] => {
    const fields: IFormField<SignerDto>[] = [
        {
            slug: 'name',
            label: 'name',
            inputProps: { type: 'text' },
            value: item?.name || '',
        },
        {
            slug: 'email',
            label: 'email',
            inputProps: { type: 'text' },
            value: item?.email || '',
        },
        {
            slug: 'receiver',
            label: 'receiver',
            value: item.receiver,
            inputProps: { type: 'select' },
            optionsKeysValues: SignerReceiverOptions(hasElectronicID),
        },
    ]

    if (item.receiver !== SignatureReceiver.Copy) {
        fields.push({
            slug: 'digitalType',
            label: 'digitalType',
            value: item.receiver,
            inputProps: { type: 'select' },
            optionsKeysValues: SignerDigitalOptions,
        })
    }

    if (hasElectronicID) {
        if (item.receiver !== SignatureReceiver.Copy) {
            // ElectronicID
            fields.push({
                slug: 'electronicID',
                label: 'electronicID',
                value: item.receiver,
                inputProps: { type: 'select' },
                optionsKeysValues: SignerElectronicIDOptions,
            })
        }

        // SignOrder
        fields.push({
            slug: 'signOrder',
            label: 'signOrder',
            value: item.signOrder,
            inputProps: { type: 'number', min: 1 },
        })
    }
    return fields
}

export const SignerValidation = (
    values: any,
    translate: (key: string) => string
): ErrorsOutput => {
    const errors: ErrorsOutput = {}
    Object.keys(values).forEach((key) => {
        const errorsArray: string[] = []
        const addError = (): void => {
            errorsArray.length > 0 &&
                (errors[key] = JSON.stringify(errorsArray))
        }
        const { email, name, receiver, digitalType, electronicID } = values
        switch (key) {
            case 'email':
                if (!email || !validationStringLengthBiggerThan(email, 0)) {
                    errorsArray.push(translate('emailRequiredRule'))
                }
                if (email && !validateEmail(email)) {
                    errorsArray.push(translate('emailNotValid'))
                }
                addError()

                break
            case 'name':
                if (!name || !validationStringLengthBiggerThan(name, 0)) {
                    errorsArray.push(translate('nameRequiredRule'))
                }
                addError()
                break
            case 'receiver':
                if (receiver === null || !SignatureReceiver[receiver]) {
                    errorsArray.push(translate('receiverRequiredRule'))
                }
                addError()
                break
            case 'digitalType':
                if (
                    digitalType === null ||
                    !SignatureDigitalType[digitalType]
                ) {
                    errorsArray.push(translate('digitalTypeRequiredRule'))
                }
                addError()
                break

            case 'electronicID':
                if (
                    digitalType === SignatureDigitalType.Digital &&
                    electronicID === null
                ) {
                    errorsArray.push(translate('electronicIDRequiredRule'))
                }
                addError()
                break
            default:
                break
        }
    })
    return errors
}
