import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    tab: {
        bg: `none`,
        _selected: {
            bg: mode('white', 'secondary.850')(props),
            color: mode(`primary.900`, `secondary.50`)(props),
            fontWeight: '600',
            borderBottom: '3px solid',
            borderColor: mode(`primary.500`, `secondary.500`)(props),
        },
        padding: '10px 20px !important',
    },
    tablist: {
        borderBottom: '1px solid',
        borderColor: mode('primary.500', 'secondary.500')(props),
        shadow: mode(
            '0 8px 8px -4px  rgb(0 0 0 / 0.1)',
            '0 8px 8px -4px rgb(255 255 255 / 0.1)'
        )(props),
        bg: mode('white', 'secondary.850')(props),
    },
    tabpanel: {
        bg: 'none',
    },
}))

export const tabsTheme = defineMultiStyleConfig({
    baseStyle,
    defaultProps: { variant: '' },
})
