import {
    ChangeEvent,
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import {
    buildLineProductPairs,
    GroupInvoiceLinesByMappingKey,
    loadProperties,
    updateERPSetupsWithProductData,
} from '../../pages/setup/erp/ERPSetup.config'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../utils/functions.utils'
import {
    ErpLineMappingDTO,
    ERPParametersDTO,
    ExternalPartnerSetup,
    ProductDTO,
    UpdateExternalPartnerSystemSetupCommand,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI, generalPostAPI } from '../API/general.api'
import { useUser } from '../contexts/user.context'

interface IERPContext {
    activeSetup: ExternalPartnerSetup
    defaultERPSetup?: UpdateExternalPartnerSystemSetupCommand
    ERPSetup?: UpdateExternalPartnerSystemSetupCommand
    getERPSetupByProduct: Function
    isLoading: boolean
    product: ERPParametersDTO | null
    products: ProductDTO[]
    updateProduct: (newProduct: ERPParametersDTO) => void
    onUpdateERPSetup: (invoiceLines: ErpLineMappingDTO[]) => Promise<void>
    updateGroupInvoiceLinesByMapping: (value: boolean) => Promise<void>
    onChangeProductHandler: (
        event: ChangeEvent<HTMLSelectElement>,
        erpParameters: ERPParametersDTO[]
    ) => void
}

interface IERPContextProviderProps {
    children: ReactNode
}

export const ERPContext = createContext({} as IERPContext)
export const useERP = (): IERPContext => useContext(ERPContext)

function useProviderERP(): IERPContext {
    const [activeSetup, setActiveSetup] = useState<ExternalPartnerSetup>(
        ExternalPartnerSetup.EconomicsErp
    )
    const translate = useTranslation().t
    const toast = useToast()
    const { user } = useUser()
    const [defaultERPSetup, setDefaultERPSetup] =
        useState<UpdateExternalPartnerSystemSetupCommand>()
    const [ERPSetup, setERPSetup] =
        useState<UpdateExternalPartnerSystemSetupCommand>()
    const [product, setProduct] = useState<ERPParametersDTO | null>(
        ERPSetup?.erpSetups !== undefined ? ERPSetup?.erpSetups[0] : null
    )
    const [products, setProducts] = useState<ProductDTO[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (user.email) getERPSetup()
    }, [user])

    const getERPSetupByProduct = async (
        productId: number | undefined,
        defaultERP?: UpdateExternalPartnerSystemSetupCommand
    ): Promise<void> => {
        if (!productId || !defaultERP) {
            return
        }
        const endpoint = `${API_ENDPOINTS.ERPSetup}?productId=${productId}&setupType=${defaultERP.setupType}`
        const response = await generalGetAPI(endpoint)
        if (response.isOk) {
            if (!response.data?.lineProductPairs?.length) {
                response.data.lineProductPairs =
                    buildLineProductPairs(productId)
            }
            setERPSetup(response.data)
        }
    }

    const getERPSetup = async (setupType?: number): Promise<void> => {
        setIsLoading(true)

        const loadedProducts = await loadProperties(API_ENDPOINTS.product)
        setProducts(loadedProducts)

        const endpoint = setupType
            ? `${API_ENDPOINTS.ERPSetup}?setupType=${setupType}`
            : API_ENDPOINTS.ERPSetup

        const response = await generalGetAPI(endpoint)
        let { data } = response
        if (!data?.erpSetups?.length) {
            data = {
                ...data,
                erpSetups: [
                    {
                        productId: loadedProducts?.[0]?.id,
                    },
                ],
            }
        }
        if (response.isOk) {
            if (data && data?.erpSetups?.length) {
                setDefaultERPSetup(data)
                getERPSetupByProduct(data.erpSetups[0]?.productId, data)
                setProduct(data.erpSetups[0])
                if (data?.setupType) setActiveSetup(data.setupType)
            }
        }
        setIsLoading(false)
    }

    // Updating / Changing Product

    const updateProduct = (newProduct: ERPParametersDTO): void =>
        setProduct({ ...newProduct })

    const refreshPage = (productId: string | number): void =>
        window?.history?.replaceState(null, '', `/setup/erp-setup/${productId}`)

    const onChangeProductHandler = (
        event: ChangeEvent<HTMLSelectElement>,
        erpParameters: ERPParametersDTO[]
    ): void => {
        const match = erpParameters.find(
            (item) => item.productId.toString() === event.currentTarget.value
        )
        const productIdToUpdate = match
            ? match.productId
            : parseInt(event.currentTarget.value)
        updateProduct({
            ...(match || { productId: productIdToUpdate }),
        } as ERPParametersDTO)
        refreshPage(productIdToUpdate)
    }

    // Update ERP

    const onUpdateERPSetup = async (
        invoiceLines: ErpLineMappingDTO[]
    ): Promise<void> => {
        try {
            // Create a new ERP setup object with updated lineProductPairs
            const updatedERPSetup = {
                ...ERPSetup,
                lineProductPairs: invoiceLines,
            }

            if (product) {
                // Update erpSetups with product data
                updatedERPSetup.erpSetups = updateERPSetupsWithProductData(
                    updatedERPSetup.erpSetups ?? [],
                    product
                )
            }

            // Send the updated ERP setup to the API
            const response = await generalPostAPI(
                API_ENDPOINTS.ERPSetup,
                updatedERPSetup
            )

            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('eRPSetupUpdated')))
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }

    const updateGroupInvoiceLinesByMapping = async (
        value: boolean
    ): Promise<void> => {
        try {
            await generalPostAPI(
                `${API_ENDPOINTS.parameter}?productId${product?.productId}`,
                {
                    key: GroupInvoiceLinesByMappingKey,
                    value: value ? 'true' : 'false',
                }
            )
        } catch (error: any) {
            toast(baseErrorToastOptions(error.message as string))
        }
    }

    return {
        activeSetup,
        defaultERPSetup,
        ERPSetup,
        getERPSetupByProduct,
        isLoading,
        product,
        products,
        updateProduct,
        onChangeProductHandler,
        onUpdateERPSetup,
        updateGroupInvoiceLinesByMapping,
    }
}

export const ERPContextProvider = ({
    children,
}: IERPContextProviderProps): ReactElement => {
    const context: IERPContext = useProviderERP()

    return <ERPContext.Provider value={context}>{children}</ERPContext.Provider>
}
