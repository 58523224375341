import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { useBoolean } from '@chakra-ui/react'

import { BREAK_POINTS_WIDTH } from '../../utils/constants.utils'

interface LayoutContextInterface {
    isMobile: boolean
    isMenuOpen: boolean
    closeMenu(): void
    openMenu(): void
}

const LayoutContext = createContext({} as LayoutContextInterface)

export const LayoutContextProvider = ({
    children,
}: {
    children: ReactNode
}): ReactElement => {
    const isMobile = BREAK_POINTS_WIDTH.MOBILE > window.innerWidth
    const [isMenuOpen, setMenuOpen] = useBoolean(false)

    const closeMenu = () => {
        if (isMenuOpen) {
            setMenuOpen.off()
        }
    }

    const openMenu = () => {
        if (!isMenuOpen) {
            setMenuOpen.on()
        }
    }

    return (
        <LayoutContext.Provider
            value={{ isMobile, isMenuOpen, closeMenu, openMenu }}
        >
            {children}
        </LayoutContext.Provider>
    )
}

export const useLayout = (): LayoutContextInterface => useContext(LayoutContext)
