import camelCase from 'lodash/camelCase'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Heading, IconButton, Tooltip } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import { IFormField } from '../../../../features/dynamicForm/DynamicForm.interfaces'
import { IApiResponse } from '../../../../interfaces/interfaces'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../services/API/general.api'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../../utils/functions.utils'
import {
    ChecklistItemSpecificStateDTO,
    ChecklistItemType,
    ChecklistSpecificStateDTO,
    ChecklistStatus,
    DomainOperationType,
} from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { ContractChecklistEditItemModal } from './ContractChecklistItemEdit.modal'

export default function ContractChecklist(): ReactElement {
    const { contract } = useContractService()
    const translate = useTranslation().t
    const [productChecklists, setProductChecklists] = useState<any>({})
    const [checklists, setChecklist] = useState<any[]>()
    const [selectedList, setSelectedList] =
        useState<ChecklistSpecificStateDTO>()
    const [editItem, setEditItem] = useState<
        ChecklistItemSpecificStateDTO | undefined
    >()

    async function buildChecklist(): Promise<void> {
        const promisesArray: Promise<IApiResponse>[] = []
        Object.keys(DomainOperationType)
            .filter((v) => !Number.isNaN(Number(v)))
            .forEach(async (value) => {
                promisesArray.push(
                    generalGetAPI(
                        API_ENDPOINTS.checklistProducts(
                            (contract.productId as number).toString(),
                            value
                        )
                    )
                )
            })
        let localChecklist = {}
        Promise.all(promisesArray)
            .then((values) => {
                values.forEach((list, index) => {
                    if (list.data) {
                        localChecklist = {
                            ...localChecklist,
                            [index]: list.data,
                        }
                    }
                })
            })
            .finally(() => {
                Object.keys(localChecklist).length > 0 &&
                    setProductChecklists(localChecklist)
            })
    }

    async function getChecklists(): Promise<void> {
        const promiseArray: Promise<IApiResponse>[] = []

        Object.keys(DomainOperationType)
            .filter((v) => !Number.isNaN(Number(v)))
            .forEach(async (value, index) => {
                if (
                    productChecklists?.[
                        index
                    ]?.domainOperationType.toString() === value
                ) {
                    if (
                        DomainOperationType.ContractActivation.toString() ===
                            value &&
                        contract.productId
                    ) {
                        promiseArray.push(
                            generalGetAPI(
                                API_ENDPOINTS.checklistContract(
                                    contract.productId,
                                    value
                                ),
                                {
                                    params: {
                                        contractNumber: contract.contractNumber,
                                    },
                                }
                            )
                        )
                    }
                    if (
                        DomainOperationType.ContractAssetActivation.toString() ===
                        value
                    ) {
                        promiseArray.push(
                            generalGetAPI(
                                API_ENDPOINTS.checklistContract(
                                    contract.productId as number,
                                    value
                                ),
                                {
                                    params: {
                                        contractNumber: contract.contractNumber,
                                    },
                                }
                            )
                        )
                    }
                }
            })
        const localChecklist: any[] = []
        await Promise.all(promiseArray)
            .then((response) => {
                response.forEach((list) => {
                    localChecklist.push(list.data)
                })
            })
            .finally(() => {
                setChecklist(localChecklist)
            })
    }

    const specificChecklistColumns = [
        { headerName: translate('description'), field: 'checklistDescription' },

        {
            headerName: translate('status'),
            field: 'status',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof ChecklistStatus>(
                            ChecklistStatus,
                            params.data.status
                        )
                    )
                ),
        },

        {
            headerName: translate('items'),
            field: 'checklistItemStatuses',
            valueGetter: (params: any) =>
                params.data?.checklistItemStatuses?.length > 0
                    ? params.data.checklistItemStatuses.length
                    : 0,
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <Tooltip label={translate('view')} placement="top" hasArrow>
                        <IconButton
                            mr={2}
                            aria-label="view"
                            size="sm"
                            variant={'outline'}
                            onClick={(): void => {
                                setSelectedList(params.data)
                            }}
                            icon={<ViewIcon />}
                        />
                    </Tooltip>
                ),
                100
            ),
        },
    ]

    const specificChecklistItemColumns = [
        {
            headerName: translate('note'),
            field: 'note',
            valueGetter: (params: any) =>
                params.data.note ? translate(params.data.note) : 'NA',
        },
        {
            headerName: translate('isCompleted'),
            field: 'isCompleted',
        },
        {
            headerName: translate('isOptional'),
            field: 'isOptional',
        },
        {
            headerName: translate('humanInteractionTypeName'),
            field: 'humanInteractionTypeName',
            valueGetter: (params: any) =>
                params.data.humanInteractionTypeName
                    ? translate(params.data.humanInteractionTypeName)
                    : 'NA',
        },
        {
            headerName: translate('itemType'),
            field: 'itemType',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof ChecklistItemType>(
                            ChecklistItemType,
                            params.data.itemType
                        )
                    )
                ),
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) =>
                    params.data.itemType ===
                    ChecklistItemType.HumanInteractionStatus ? (
                        <Tooltip
                            label={translate('edit')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                mr={2}
                                aria-label="edit"
                                size="sm"
                                variant={'outline'}
                                onClick={(): void => {
                                    setEditItem(params.data)
                                }}
                                icon={<EditIcon />}
                            />
                        </Tooltip>
                    ) : (
                        <>{'NA'}</>
                    ),
                100
            ),
        },
    ]

    async function onSubmitItemToEdit(
        data: ChecklistItemSpecificStateDTO
    ): Promise<void> {
        if (data.humanInteractionRecordId) {
            selectedList &&
                generalPutAPI(
                    `${API_ENDPOINTS.checklistContractUpdate(
                        selectedList.checklistId,
                        editItem?.checklistItemId as number
                    )}/${data.humanInteractionRecordId}`,
                    {
                        userNote: data?.note,
                        isCompleted: data?.isCompleted,
                    },
                    { params: { contractNumber: contract.contractNumber } }
                ).then(() => {
                    getChecklists()
                    setEditItem(undefined)
                })
        } else {
            selectedList &&
                generalPostAPI(
                    API_ENDPOINTS.checklistContractUpdate(
                        selectedList?.checklistId,
                        editItem?.checklistItemId as number
                    ),
                    {
                        userNote: data?.note,
                        isCompleted: data?.isCompleted,
                    },
                    { params: { contractNumber: contract.contractNumber } }
                ).then(() => {
                    getChecklists()
                    setEditItem(undefined)
                })
        }
    }

    function renderChecklists(): ReactElement {
        return (
            <Box mt={8} mb={16}>
                <Heading size={'lg'} mb={4}>
                    {translate('checklist')}
                </Heading>
                <GenericBox p={4}>
                    <DynamicGrid
                        tableId="checklistTable1"
                        columns={specificChecklistColumns}
                        rowData={checklists}
                        pagination={true}
                        rowMultiSelectWithClick={true}
                    />
                </GenericBox>
            </Box>
        )
    }

    function renderSelectedChecklist(): ReactElement {
        return selectedList !== undefined ? (
            <Box mt={8} mb={16}>
                <Heading size={'lg'} mb={4}>
                    {translate('details')}
                </Heading>
                <GenericBox p={4}>
                    <DynamicGrid
                        tableId="checklistTable2"
                        columns={specificChecklistItemColumns}
                        rowData={selectedList.checklistItemStatuses}
                        pagination={true}
                        rowMultiSelectWithClick={true}
                    />
                </GenericBox>
            </Box>
        ) : (
            <></>
        )
    }

    useEffect(() => {
        buildChecklist()
    }, [])

    useEffect(() => {
        getChecklists()
    }, [JSON.stringify(productChecklists)])

    function checklistItemEditFormFields(): IFormField<ChecklistItemSpecificStateDTO>[] {
        return [
            {
                slug: 'note',
                label: 'note',
                inputProps: { type: 'text' },
            },
            {
                slug: 'isCompleted',
                label: 'completed',
                inputProps: { type: 'boolean' },
            },
        ]
    }

    return (
        <>
            {renderChecklists()}
            {renderSelectedChecklist()}
            {editItem && (
                <ContractChecklistEditItemModal
                    itemToEdit={editItem as ChecklistItemSpecificStateDTO}
                    checklistItemFormFields={checklistItemEditFormFields}
                    onSubmitItemToEdit={onSubmitItemToEdit}
                    isModalOpen={true}
                    modalTitle={'editChecklistItem'}
                    onClose={(): void => setEditItem(undefined)}
                ></ContractChecklistEditItemModal>
            )}
        </>
    )
}
