import dayjs from 'dayjs'

import { CountryCode } from '../types/types'
import getLocaleFormats from './localization.utils'

export const CountryCultures: { [key: number]: string } = {
    [CountryCode.GB]: 'en-GB',
    [CountryCode.DK]: 'da-DK',
    [CountryCode.DE]: 'de-DE',
    [CountryCode.SE]: 'sv-SE',
    [CountryCode.NO]: 'nb-NO',
    [CountryCode.FI]: 'fi-FI',
}

export const CountryCulturesList: { [key: number]: string } = {
    [CountryCode.DK]: 'Danish',
    [CountryCode.GB]: 'English',
    [CountryCode.SE]: 'Swedish',
    [CountryCode.DE]: 'German',
    [CountryCode.NO]: 'Norwegian',
    [CountryCode.FI]: 'Finnish',
}

export enum AvailableLanguage {
    EN_GB = 'en-GB',
    DA_DK = 'da-DK',
    DE_DE = 'de-DE',
    SE_SE = 'sv-SE',
    NB_NO = 'nb-NO',
    FI_FI = 'fi-FI',
    // NONE = 'none',
}

const CultureConfigures: {
    [key: number]: { international: Function; domestic: Function }
} = {
    [CountryCode.GB]: {
        international: (num: string): string => `GBP ${num}`,
        domestic: (num: string): string => `${num} pound`,
    },
    [CountryCode.DK]: {
        international: (num: string): string => `DKK ${num}`,
        domestic: (num: string): string => `kr ${num}`,
    },
    [CountryCode.DE]: {
        international: (num: string): string => `EUR ${num}`,
        domestic: (num: string): string => `${num} euro`,
    },
    [CountryCode.SE]: {
        international: (num: string): string => `SEK ${num}`,
        domestic: (num: string): string => `${num} tn`,
    },
    [CountryCode.NO]: {
        international: (num: string): string => `NOK ${num}`,
        domestic: (num: string): string => `${num} NOK`,
    },
    [CountryCode.FI]: {
        international: (num: string): string => `EUR ${num}`,
        domestic: (num: string): string => `${num} euro`,
    },
}

const CultureConfigKeys = Object.keys(CultureConfigures)

export const formatNumber = (value: number | string | null): string => {
    if (Number.isNaN(value)) return ''
    const currentCulture =
        sessionStorage.getItem('locale') ?? AvailableLanguage.EN_GB
    return Number(value).toLocaleString(currentCulture, {
        minimumFractionDigits: 2,
    })
}
export const formatCurrency = (n: number | null, local = false): string => {
    if (!n) return ''
    let currentCultureID =
        sessionStorage.getItem('culture_id') ??
        CountryCode[AvailableLanguage.DE_DE]
    if (!CultureConfigKeys.includes(currentCultureID)) {
        currentCultureID = CountryCode[AvailableLanguage.DE_DE]
    }
    const { domestic, international } = CultureConfigures[currentCultureID]
    if (local) {
        return domestic(formatNumber(n))
    }
    return international(formatNumber(n))
}

export const formatDate = (
    dateString: string | null,
    timestamp?: boolean
): string => {
    if (dateString !== null && dateString !== undefined) {
        const culture = parseInt(sessionStorage.getItem('formatting') || '0')
        const date = timestamp
            ? dayjs(dateString).format(
                  `${getLocaleFormats(culture).date.toUpperCase()} HH:mm`
              )
            : dayjs(dateString).format(
                  getLocaleFormats(culture).date.toUpperCase()
              )
        return date
    }
    return ''
}

export const parseNum = (num: any, defaultValue = 0): number => {
    if (num === null || num === undefined || Number.isNaN(+num)) {
        return defaultValue
    }
    return +num
}
