import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import ERPProductsComponents from '../../features/service/components/erp/ERPProducts.components'
import ServiceFeeComponent from '../../features/service/components/fees/ServiceFees.component'
import ServiceInfoComponent from '../../features/service/components/info/ServiceInfo.component'
import ProductsComponent from '../../features/service/components/products/Products.component'
import { DEFAULT_SERVICE_ITEM } from '../../pages/setup/services/ServicesSetup.config'
import {
    BaseServiceDTO,
    ExternalPartnerSetup,
    GetVatDetail,
    ProductDTO,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI } from '../API/general.api'
import { useProduct } from './Product.context'

interface ServiceContextInterface {
    steps: ServiceStepType[]
    service: OptionalBaseServiceDTO
    initialDataSettling: Function
    VATCodes: GetVatDetail[]
    handleServiceUpdate: Function
    products?: ProductDTO[]
    ERPProducts: ERPProductInterface[]
    isEditingMode: boolean
    ERPSetupType: ExternalPartnerSetup | null
}

const deepEqual = (x: any, y: any): any => {
    const ok = Object.keys
    const tx = typeof x
    const ty = typeof y
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length &&
              ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y
}

const ServiceContext = createContext({
    steps: [],
    service: { ...DEFAULT_SERVICE_ITEM },
    initialDataSettling: Function,
    VATCodes: [],
    handleServiceUpdate: Function,
    products: [],
    ERPProducts: [],
    isEditingMode: false,
    ERPSetupType: null,
} as ServiceContextInterface)

interface OptionalBaseServiceDTO extends Partial<BaseServiceDTO> {
    contractText?: string
}
type ServiceStepType = {
    label: string
    content: ReactNode
    isDisabled?: boolean
}

interface ERPProductInterface {
    name: string
    productNumber: string | number
}

export const ServiceContextProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const [service, setService] = useState<OptionalBaseServiceDTO>({
        ...DEFAULT_SERVICE_ITEM,
    })
    const { products } = useProduct()
    const [VATCodes, setVATCodes] = useState<GetVatDetail[]>([])
    const [ERPProducts, setERPProducts] = useState<ERPProductInterface[]>([])
    const [ERPSetupType, setERPSetupType] =
        useState<ExternalPartnerSetup | null>(null)
    const isEditingMode = useMemo(
        () => !!service?.baseServiceNumber,
        [JSON.stringify(service)]
    )

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            retrieveVATCodes()
            retrieveERPProducts()
        }

        return () => {
            isMounted = false
        }
    }, [])

    const retrieveVATCodes = async () => {
        const request = await generalGetAPI(API_ENDPOINTS.vat)
        if (!request.isOk) return
        setVATCodes(request.data)
    }

    const retrieveERPProducts = async () => {
        const setupResponse = await generalGetAPI(API_ENDPOINTS.ERPSetup)
        if (!setupResponse.isOk) return

        const { setupType } = setupResponse.data
        setERPSetupType(setupType as ExternalPartnerSetup)
        const request = await generalGetAPI(
            `${API_ENDPOINTS.ERPSetupProducts}/${setupType}`
        )
        if (!request.isOk) return

        setERPProducts(request.data)
    }

    const steps: ServiceStepType[] = [
        {
            label: 'serviceInfo',
            content: <ServiceInfoComponent />,
            isDisabled: !(service?.description && service?.vatId),
        },
        {
            label: 'product',
            content: <ProductsComponent />,
            isDisabled: !(
                service?.productServices && service?.productServices.length > 0
            ),
        },
        {
            label: 'serviceFees',
            content: <ServiceFeeComponent />,
            isDisabled: !(
                service?.baseServiceValues &&
                service?.baseServiceValues.length > 0
            ),
        },
        {
            label: 'setERPItem',
            content: <ERPProductsComponents />,
            isDisabled: true,
        },
    ]

    const initialDataSettling = (serviceItem: OptionalBaseServiceDTO): void => {
        if (deepEqual(service, serviceItem)) return
        setService(serviceItem)
    }

    const handleServiceUpdate = (objectToUpdate: {
        [key: string]: any
    }): void => {
        setService({ ...service, ...objectToUpdate })
    }

    return (
        <ServiceContext.Provider
            value={{
                steps,
                service,
                VATCodes,
                products,
                ERPProducts,
                isEditingMode,
                ERPSetupType,
                initialDataSettling,
                handleServiceUpdate,
            }}
        >
            {children}
        </ServiceContext.Provider>
    )
}

export const useService = () => useContext(ServiceContext)
