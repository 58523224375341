import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

/* eslint-disable no-unused-vars */
import { Button, Flex, ToastId, useBoolean, useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { useProduct } from '../../../services/contexts/Product.context'
import { useVAT } from '../../../services/contexts/vat.context'
import { baseErrorToastOptions } from '../../../utils/functions.utils'
import {
    BaseTransientFeeDTO,
    CreateBaseTransientFeeCommand,
    UpdateBaseTransientFeeCommand,
} from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import AddTransientFeeComponent from './AddTransientFee.component'
import EditTransientFeeComponent from './EditTransientFee.component'
import { columnsConfig } from './TransientFees.config'

interface ITransientFeesTable {
    hideAdd?: boolean
    hasSelection?: boolean
    onSelectionChanged?: Function
    hasActions?: boolean
}

export function TransientFeesTable({
    hideAdd = false,
    hasSelection = false,
    hasActions = false,
    onSelectionChanged,
}: ITransientFeesTable): ReactElement {
    const gridApiRef = useRef(null)
    const [transientFees, setTransientFees] = useState<BaseTransientFeeDTO[]>()
    const [editTransientFee, setEditTransientFee] =
        useState<BaseTransientFeeDTO>()
    const toast = useToast()
    const translate = useTranslation().t
    const [isAddFeeOpen, setAddFee] = useBoolean()
    const { products } = useProduct()
    const { VAT } = useVAT()
    const { formatValue } = useNumberFormatterContext()
    const getTransientFees = async () => {
        const response = await generalGetAPI(API_ENDPOINTS.baseTransientFees)
        if (response.isOk) {
            setTransientFees(response.data)
        }
    }

    const saveTransientFee = async (
        data: CreateBaseTransientFeeCommand
    ): Promise<ToastId | void> => {
        try {
            const response = await generalPostAPI(
                API_ENDPOINTS.baseTransientFees,
                {
                    ...data,
                    erpProductId: `${data?.erpProductId}`,
                }
            )

            if (!response.isOk) {
                return toast(baseErrorToastOptions(response.message))
            }

            getTransientFees()
            setAddFee.off()
        } catch (error) {
            console.log(error)
        }
    }

    const updateTransientFee = async (
        data: UpdateBaseTransientFeeCommand
    ): Promise<ToastId | void> => {
        try {
            const response = await generalPutAPI(
                `${API_ENDPOINTS.baseTransientFees}/${data.baseTransientFeeNumber}`,
                data
            )
            if (!response.isOk) {
                return toast(baseErrorToastOptions(response.message))
            }

            getTransientFees()
            setEditTransientFee(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTransientFees()
    }, [])

    const onDeleteBaseTransientFee = async (data: BaseTransientFeeDTO) => {
        const response = await generalDeleteAPI(
            `${API_ENDPOINTS.baseTransientFees}/${data.baseTransientFeeNumber}`
        )
        if (!response.isOk) return toast(baseErrorToastOptions(response.err))
        getTransientFees()
    }

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const getSelectedData = (): void => {
        if (onSelectionChanged && hasSelection) {
            const selectedNodes = (
                gridApiRef?.current as any
            )?.getSelectedNodes()
            const selectedData = selectedNodes.map((node: any) => node.data)
            onSelectionChanged(selectedData)
        }
    }

    return (
        <Flex flexDirection={'column'} gap={4}>
            <DynamicGrid
                tableId="transientFeesTable"
                onGridReady={onGridReady}
                columns={columnsConfig(
                    translate,
                    setEditTransientFee,
                    onDeleteBaseTransientFee,
                    hasSelection,
                    hasActions,
                    VAT,
                    formatValue
                )}
                rowData={transientFees}
                pagination={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={false}
                onSelectionChanged={(): void => {
                    getSelectedData()
                }}
                headers={
                    <Flex alignItems={'center'} justifyContent={'flex-end'}>
                        {!hideAdd && transientFees && (
                            <Button
                                onClick={() => {
                                    setAddFee.on()
                                }}
                            >
                                {translate('addRow')}
                            </Button>
                        )}
                    </Flex>
                }
            />
            {products && (
                <AddTransientFeeComponent
                    modalTitle={translate('addTransientFee')}
                    isModalOpen={isAddFeeOpen}
                    onClose={setAddFee.off}
                    handleSave={(data) => saveTransientFee(data)}
                    products={products}
                    VAT={VAT}
                />
            )}
            {editTransientFee && products && (
                <EditTransientFeeComponent
                    editTransientFee={editTransientFee}
                    modalTitle={translate('editTransientFee')}
                    isModalOpen={!!editTransientFee}
                    onClose={() => setEditTransientFee(undefined)}
                    handleSave={(data) => updateTransientFee(data)}
                    products={products}
                    VAT={VAT}
                />
            )}
        </Flex>
    )
}
