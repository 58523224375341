import shadesOf from 'tailwind-shades'

function colors(primary: string, secondary: string) {
    return {
        colors: {
            transparent: 'transparent',
            black: '#000',
            white: '#fff',
            gray: shadesOf('#718096'),
            primary: shadesOf(primary || '#1BB384', true),
            secondary: shadesOf(secondary || '#214839', true),
        },
    }
}

export default colors
