import { useField } from 'formik'
import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ListItem, Select, UnorderedList } from '@chakra-ui/react'

type FormSelectProps = {
    selectOptions: string[][]
    name: string
    skipTranslation?: boolean | undefined
    props: any
    isValueString?: boolean
}
export default function FormSelectField({
    selectOptions,
    name,
    skipTranslation = false,
    isValueString = false,
    ...props
}: FormSelectProps): ReactElement {
    const [field, errors, { setValue }] = useField(name)
    const translate = useTranslation().t
    const emptyVal = isValueString ? '' : undefined

    return (
        <>
            <Select
                {...field}
                {...props.props}
                name={field.name}
                placeholder={translate('select')}
                value={field.value !== null ? field.value : emptyVal}
                onChange={(event): void => {
                    setValue(
                        isValueString
                            ? event.currentTarget.value
                            : parseInt(event.currentTarget.value)
                    )
                }}
            >
                {selectOptions &&
                    selectOptions.map((option: string[], index: number) => (
                        <option key={index} value={option[0]}>
                            {skipTranslation
                                ? option[1]
                                : translate(camelCase(option[1]))}
                        </option>
                    ))}
            </Select>
            {errors.error && (
                <UnorderedList>
                    {JSON.parse(errors.error).map(
                        (errorStr: string, index: number) => {
                            if (errorStr) {
                                return (
                                    <ListItem color="red" key={index}>
                                        {errorStr}
                                    </ListItem>
                                )
                            }
                            return null
                        }
                    )}
                </UnorderedList>
            )}
        </>
    )
}
