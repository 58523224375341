import './DatePicker.component.scss'

import { ReactElement } from 'react'

import { Box, Input } from '@chakra-ui/react'

export default function DatePicker({ name, ...props }: any): ReactElement {
    let value
    try {
        if (
            !!props?.value &&
            new Date(props?.value) &&
            new Date(props?.value)?.toISOString() &&
            new Date(props?.value)?.toISOString()?.split('T')[0]
        ) {
            value = new Date(props?.value)?.toISOString()?.split('T')[0]
        }
    } catch (e) {
        console.log(e)
    }

    return (
        <Box onClick={(): void => {}}>
            <Input
                id={name}
                type="date"
                width={'100%'}
                className={'datePicker'}
                {...props}
                min={
                    (props.min &&
                        new Date(props.min).toISOString().split('T')[0]) ||
                    undefined
                }
                max={
                    (props.max &&
                        new Date(props.max).toISOString().split('T')[0]) ||
                    undefined
                }
                value={value}
                onChange={(e): void =>
                    props?.onChange(new Date(e?.target?.value))
                }
            ></Input>
        </Box>
    )
}
