import { ReactElement } from 'react'

import { TabPanel, TabPanels } from '@chakra-ui/react'

import PageContainer, {
    TabListType,
} from '../../components/pageContainer/PageContainer.component'
import TabPanelContainer from '../../components/tabPanelContainer/TabPanelContainer.component'
import ErrorBoundaryWrapper from '../../features/errorBoundary/ErrorBoundary.feature'
import { CustomerContextProvider } from '../../services/customer/Customer.services'
import CustomerDetailsCases from './components/cases/CustomerDetailsCases.component'
import CustomerDetailsCreditSafe from './components/creditsafe/CustomerDetailsCreditSafe.component'
import CustomerDetailsDocuments from './components/documents/CustomerDetailsDocuments.component'
import CustomerHeader from './components/header/header.component'
import CustomerDetailsInfo from './components/info/CustomerDetailsInfo.component'
import CustomerLocations from './components/Locations/CustomerLocations.component'
import CustomerDetailsSigners from './components/signers/CustomerDetailsSigners.component'
import { useModule } from '../../services/contexts/Module.context'
import { ModuleType } from '../../utils/types/types'
import CustomerDetailsInvoices from './components/invoices/CustomerDetailsInvoices.component'

function CustomerDetails(): ReactElement {
    const { hasAccess } = useModule()
    const tabList: TabListType[] = [
        { text: 'details', id: 0 },
        { text: 'cases', id: 1 },
        { text: 'documents', id: 2 },
        { text: 'invoices', id: 3 },
        { text: 'signers', id: 4 },
        { text: 'locations', id: 5 },
        (hasAccess(ModuleType.CreditCheck)
            ? { text: 'creditSafe', id: 6 }
            : false) as TabListType,
    ].filter(Boolean)

    return (
        <PageContainer tabsList={tabList} pageHeader={<CustomerHeader />}>
            <TabPanels>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-info-error">
                            <CustomerDetailsInfo />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-cases-error">
                            <CustomerDetailsCases />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-documents-error">
                            <CustomerDetailsDocuments />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-invoices-error">
                            <CustomerDetailsInvoices />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-signers-error">
                            <CustomerDetailsSigners />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                <TabPanel p={0}>
                    <TabPanelContainer>
                        <ErrorBoundaryWrapper id="customer-details-credit-safe-error">
                            <CustomerLocations />
                        </ErrorBoundaryWrapper>
                    </TabPanelContainer>
                </TabPanel>
                {hasAccess(ModuleType.CreditCheck) && (
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ErrorBoundaryWrapper id="customer-details-credit-safe-error">
                                <CustomerDetailsCreditSafe />
                            </ErrorBoundaryWrapper>
                        </TabPanelContainer>
                    </TabPanel>
                )}
            </TabPanels>
        </PageContainer>
    )
}

const CustomerDetailsWithContext = (): ReactElement => (
    <CustomerContextProvider>
        <CustomerDetails />
    </CustomerContextProvider>
)

export default CustomerDetailsWithContext
