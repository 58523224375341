import ContractFinance from '../../pages/financial/components/contractFinance/ContractFinance.page'
import ExportPage from '../../pages/financial/components/export/Export.page'
import InterestPage from '../../pages/financial/components/interest/Interest.page'
import InvoicesPage from '../../pages/financial/components/invoices/Invoices.page'
import SingleInvoiceLine from '../../pages/financial/components/invoices/singleInvoice/SingleInvoice.component'
import ReportPage from '../../pages/financial/components/report/Report.page'
import UsagePage from '../../pages/financial/components/usage/Usage.page'
import { ExternalPartnerSetup } from '../../utils/types/types'
import { IBaseRoute } from './base.routes'

const FINANCIAL_ROUTES = (activeSetup: ExternalPartnerSetup): IBaseRoute[] => [
    {
        path: 'export/*',
        Component: ExportPage,
        breadcrumb: ['financial', 'export'],
        breadcrumbName: 'export',
        translateKey: 'export',
    },
    ...(activeSetup === ExternalPartnerSetup.ErpAlternativeFileCreation
        ? [
              {
                  path: 'invoices',
                  Component: InvoicesPage,
                  breadcrumb: ['financial', 'invoices'],
                  breadcrumbName: 'invoices',
                  translateKey: 'invoices',
              },
              {
                  path: 'invoices/:id',
                  Component: SingleInvoiceLine,
                  breadcrumb: ['financial', 'invoices'],
                  breadcrumbName: 'invoices',
                  translateKey: 'invoices',
              },
          ]
        : []),
    {
        path: 'interest',
        Component: InterestPage,
        breadcrumb: ['financial', 'interest'],
        breadcrumbName: 'interest',
        translateKey: 'interestPage',
    },
    {
        path: 'report',
        Component: ReportPage,
        breadcrumb: ['financial', 'report'],
        breadcrumbName: 'report',
        translateKey: 'report',
    },
    {
        path: 'contract-finance',
        Component: ContractFinance,
        breadcrumb: ['financial', 'contract-finance'],
        breadcrumbName: 'contract-finance',
        translateKey: 'contractFinance',
    },
    {
        path: 'usage',
        Component: UsagePage,
        breadcrumb: ['financial', 'usage'],
        breadcrumbName: 'usage',
        translateKey: 'usage',
    },
]

export default FINANCIAL_ROUTES
