/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import { useContractService } from '../../../../services/contract/Contract.services'
import { useContractRulesService } from '../../../../services/rules/ProductRulesEnforcer.services'
import { ContractStatus, ProductType } from '../../../../utils/types/types'
import MatchInterest from '../MatchInterest/MatchInterest.component'
import CustomContractDetails from './CustomContractDetails.component'

export default function ContractDetailComponent(): ReactElement {
    const translate = useTranslation().t
    const { actionsAllowed, contract, isLoading } = useContractService()
    const [isMatchInterestOpen, setIsMatchInterestOpen] = useState(false)
    const {
        productRules,
        productType,
        isLoading: productLoadng,
    } = useContractRulesService()
    const [hideMatchOffer, setHideMatchOffer] = useState(false)

    useEffect(() => {
        if (
            productType === ProductType.Rental ||
            productType === ProductType.Subscription
        ) {
            setHideMatchOffer(true)
        } else {
            setHideMatchOffer(
                contract.status === ContractStatus.Active &&
                    actionsAllowed?.canStartManually
            )
        }
    }, [productType, contract.status])

    return !isLoading && !productLoadng ? (
        <GenericBox py={4} px={6} rounded="lg" mb={16}>
            <CustomContractDetails
                contract={contract}
                productRules={productRules}
            />
            {!hideMatchOffer && (
                <Flex justifyContent={'flex-end'}>
                    <Button
                        mt={4}
                        ml="auto"
                        mr={0}
                        onClick={() => setIsMatchInterestOpen(true)}
                        isLoading={isLoading}
                        isDisabled={
                            contract.status === ContractStatus.Closed ||
                            contract.isLocked
                        }
                    >
                        {translate('matchOffer')}
                    </Button>
                </Flex>
            )}
            <MatchInterest
                isModalOpen={isMatchInterestOpen}
                onClose={(): void => setIsMatchInterestOpen(false)}
                modalTitle={translate('matchOffer')}
                maxWidth={'680px'}
            />
        </GenericBox>
    ) : (
        <></>
    )
}
