import { ReactElement, useEffect, useState } from 'react'

import { Box, TabPanel, TabPanels } from '@chakra-ui/react'

import PageContainer, {
    TabListType,
} from '../../components/pageContainer/PageContainer.component'
import Prompt from '../../components/prompt/Prompt.component'
import TabPanelContainer from '../../components/tabPanelContainer/TabPanelContainer.component'
import ErrorBoundaryWrapper from '../../features/errorBoundary/ErrorBoundary.feature'
import InvoicesTable from '../../features/genericTables/invoicesTable/InvoicesTable.component'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../services/API/general.api'
import { BaseServicesContextProvider } from '../../services/contexts/BaseServices.context'
import { VATContextProvider } from '../../services/contexts/vat.context'
import {
    ContractServiceProvider,
    useContractService,
} from '../../services/contract/Contract.services'
import { ProductRulesEnforcerServiceProvider } from '../../services/rules/ProductRulesEnforcer.services'
import { ChecklistItemDTO, ContractStatus } from '../../utils/types/types'
import GlobalContractActions from './components/actions/globalActions/GlobalActions.component'
import ContractDetailsAssets from './components/assets/ContractDetailAssets.component'
import ContractChecklist from './components/checklist/ContractChecklist.component'
import ContractDetailHeader from './components/contractDetailHeader/ContractDetailHeader.component'
import CustomerInfoComponent from './components/customerInfo/CustomerInfo.component'
import ContractDetailComponent from './components/details/ContractDetail.component'
import ContractDetailDocuments from './components/documents/ContractDetailDocuments.component'
import ContractDetailTracer from './components/timeline/ContractDetailTracer.component'
import ContractDetailsTransientFees from './components/transientFees/addTransientFees/ContractDetailTransientFees.component'
import ContractUsageTab from './components/usage/ContractUsage.component'
import ContractDiscountsTab from './components/discounts/Discounts.component'

function ContractDetail(): ReactElement {
    const { contract, originalContract, product, setOriginalContract } =
        useContractService()

    const [activeCheckListItems, setActiveCheckListItems] = useState<
        ChecklistItemDTO[]
    >([])

    const tabList: TabListType[] = [
        { id: 0, text: 'details' },
        { id: 1, text: 'customer' },
        { id: 2, text: 'documents' },
        { id: 3, text: 'transientFees' },
        { id: 4, text: 'invoices' },
        { id: 5, text: 'timeline' },
        { id: 6, text: 'discounts' },
        ...(activeCheckListItems.length ? [{ id: 7, text: 'checklist' }] : []),
        ...(product?.isUsageEnabled ? [{ text: 'usage', id: 8 }] : []),
    ]

    const contractToCompare = {
        ...contract,
        deposit: Number(contract?.deposit ?? 0),
        contractAssets:
            contract?.contractAssets?.map((ca) => ({
                ...ca,
                downpayment: Number(ca?.downpayment),
                restValue: Number(ca?.restValue),
                purchaseValue: Number(ca?.purchaseValue),
            })) ?? [],
    }

    const originalContractToCompare = {
        ...originalContract,
        deposit: Number(originalContract?.deposit ?? 0),
        contractAssets:
            originalContract?.contractAssets?.map((ca) => ({
                ...ca,
                downpayment: Number(ca.downpayment),
                restValue: Number(ca.restValue),
                purchaseValue: Number(ca.purchaseValue),
            })) ?? [],
    }

    const getProductCheckLists = async (productId: number): Promise<void> => {
        try {
            const response = await generalGetAPI(
                API_ENDPOINTS.checklistProduct(productId)
            )

            if (response.isOk && Array.isArray(response.data)) {
                const activeItems = response.data.filter(
                    ({ isActive }) => isActive
                )
                setActiveCheckListItems(activeItems)
            } else {
                console.error('Invalid response data or format:', response.data)
            }
        } catch (error) {
            console.error('Failed to fetch product checklists:', error)
        }
    }

    useEffect(() => {
        if (product.id) getProductCheckLists(product.id)
    }, [product.id])

    return (
        <PageContainer
            pageHeader={<ContractDetailHeader />}
            pageFooter={<GlobalContractActions />}
            tabsList={tabList}
        >
            <Box py={8}>
                <TabPanels>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <>
                                <ErrorBoundaryWrapper id="contract-detail-assets-error">
                                    <BaseServicesContextProvider>
                                        <VATContextProvider>
                                            <ContractDetailsAssets />
                                        </VATContextProvider>
                                    </BaseServicesContextProvider>
                                </ErrorBoundaryWrapper>
                                <ErrorBoundaryWrapper id="contract-detail-component-error">
                                    <ContractDetailComponent />
                                </ErrorBoundaryWrapper>
                            </>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ErrorBoundaryWrapper id="customer-info-component-error">
                                <CustomerInfoComponent />
                            </ErrorBoundaryWrapper>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ContractDetailDocuments />
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ErrorBoundaryWrapper id="contract-detail-transient-fees-error">
                                <VATContextProvider>
                                    <ContractDetailsTransientFees />
                                </VATContextProvider>
                            </ErrorBoundaryWrapper>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ErrorBoundaryWrapper id="contract-detail-invoices-error">
                                <InvoicesTable
                                    enablePay={true}
                                    dataEndpoint={`${API_ENDPOINTS.invoice}?ContractNumber=${contract.contractNumber}`}
                                    hasGenerateNext={
                                        contract?.status !==
                                        ContractStatus.Closed
                                    }
                                    isContract={true}
                                />
                            </ErrorBoundaryWrapper>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ContractDetailTracer />
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ContractDiscountsTab />
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ContractChecklist />
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ContractUsageTab />
                        </TabPanelContainer>
                    </TabPanel>
                </TabPanels>
                {contractToCompare && originalContractToCompare && (
                    <Prompt
                        when={
                            contractToCompare &&
                            originalContractToCompare &&
                            !!originalContractToCompare?.contractNumber &&
                            JSON.stringify(contractToCompare) !==
                                JSON.stringify(originalContractToCompare)
                        }
                        onClose={(): void => setOriginalContract()}
                    />
                )}
            </Box>
        </PageContainer>
    )
}

function ContractDetailWrapper(): ReactElement {
    return (
        <ContractServiceProvider>
            <ProductRulesEnforcerServiceProvider>
                <ContractDetail />
            </ProductRulesEnforcerServiceProvider>
        </ContractServiceProvider>
    )
}

export default ContractDetailWrapper
