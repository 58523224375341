import isHotkey from 'is-hotkey'
import { useCallback, useEffect, useMemo } from 'react'
import {
    createEditor,
    Descendant,
    Editor,
    Element as SlateElement,
    Transforms,
} from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, useSlate, withReact } from 'slate-react'

import { Button, ButtonGroup, Flex } from '@chakra-ui/react'
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faBold,
    faH1,
    faH2,
    faItalic,
    faList,
    faListOl,
    faUnderline,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Element, Leaf } from './elements'
import { deserialize, serialize } from './serialize'

const HOTKEYS = {
    'mod+b': 'strong',
    'mod+i': 'i',
    'mod+u': 'u',
}

const LIST_TYPES = ['ol', 'ul']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

interface IRichText {
    onChange: any
    value?: string
    readOnly?: boolean
    disabled?: boolean
}

const RichText = ({
    readOnly = false,
    disabled = false,
    value,
    onChange,
}: IRichText) => {
    const renderElement = useCallback(
        (props: any) => <Element {...props} />,
        []
    )
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const slateVal: Descendant[] = useMemo(
        () => (value ? deserialize(value) : deserialize('<p></p>')),
        [value]
    )
    const hasChanged = useMemo(
        () => serialize(slateVal) !== serialize(editor.children),
        [slateVal]
    )

    useEffect(() => {
        if (!hasChanged) return

        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        })

        Transforms.insertNodes(editor, slateVal, { at: [0] })
    }, [hasChanged])

    return (
        <Slate
            editor={editor}
            initialValue={slateVal}
            onChange={(d: Descendant[]) => {
                onChange && onChange(serialize(d))
            }}
        >
            <Flex style={{ margin: '0 0 5px 0' }}>
                <ButtonGroup
                    variant="outline"
                    isAttached
                    style={{ overflow: 'auto' }}
                >
                    <MarkButton format="bold" icon={faBold} />
                    <MarkButton format="italic" icon={faItalic} />
                    <MarkButton format="underline" icon={faUnderline} />
                    <BlockButton format="h1" icon={faH1} />
                    <BlockButton format="h2" icon={faH2} />
                    <BlockButton format="ol" icon={faListOl} />
                    <BlockButton format="ul" icon={faList} />
                    <BlockButton format="left" icon={faAlignLeft} />
                    <BlockButton format="center" icon={faAlignCenter} />
                    <BlockButton format="right" icon={faAlignRight} />
                    <BlockButton format="justify" icon={faAlignJustify} />
                </ButtonGroup>
            </Flex>
            <Editable
                readOnly={readOnly}
                disabled={disabled}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Enter some text…"
                spellCheck
                autoFocus
                onKeyDown={(event) => {
                    Object.keys(HOTKEYS).forEach((hotkey) => {
                        if (isHotkey(hotkey, event as any)) {
                            event.preventDefault()
                            const mark = HOTKEYS[hotkey]
                            toggleMark(editor, mark)
                        }
                    })
                }}
                style={{
                    minHeight: 150,
                    border: '1px solid',
                    borderRadius: '.25rem',
                    padding: '5px',
                    borderColor: 'var(--chakra-colors-gray-200)',
                }}
            />
        </Slate>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        const listFormat = isList ? 'li' : format
        newProperties = {
            type: isActive ? 'p' : listFormat,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format)
    isActive
        ? Editor.removeMark(editor, format)
        : Editor.addMark(editor, format, true)
}

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor: Editor, format: string) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            variant="outline"
            isActive={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={(event) => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
            leftIcon={<FontAwesomeIcon width={'1rem'} icon={icon} />}
        ></Button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            variant="outline"
            isActive={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
            leftIcon={<FontAwesomeIcon width={'1rem'} icon={icon} />}
        ></Button>
    )
}

export default RichText
