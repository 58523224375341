import { ReactElement } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { useCustomer } from '../../../../services/customer/Customer.services'

function CustomerHeader(): ReactElement {
    const { customer } = useCustomer()

    return (
        <Flex
            className="contract-detail-header"
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            gap={4}
        >
            <Flex alignItems={'center'} gap={3}>
                <Text fontSize="2xl">{customer?.customerNumber}</Text>
                <Text fontSize="xl">{customer?.name}</Text>
            </Flex>
        </Flex>
    )
}

export default CustomerHeader
