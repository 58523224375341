import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, useToast } from '@chakra-ui/react'

import PageContainer from '../../../../components/pageContainer/PageContainer.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../services/API/general.api'
import { useModule } from '../../../../services/contexts/Module.context'
import {
    baseErrorToastOptions,
    baseInfoToastOptions,
    baseSuccessToastOptions,
} from '../../../../utils/functions.utils'
import { ModuleType } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import ContractFinanceContractsTable from './components/contractFinanceReportTable/ContracFinanceReportTable.component'
import { getColumnsConfig } from './ContractFinance.config'

function ContractFinance(): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { ensureAccess } = useModule()
    const [reports, setReports] = useState()
    const [isOpen, setIsOpen] = useState(false)

    const sendEmail = async (documentId: number): Promise<void> => {
        toast(baseInfoToastOptions(translate('processInitialized')))
        const response = await generalPostAPI(
            API_ENDPOINTS.contractFinanceEmail,
            { documentId }
        )
        if (!response.isOk) {
            toast(baseErrorToastOptions(translate('apiFail')))
        } else {
            toast(baseSuccessToastOptions(translate('emailSuccessSent')))
        }
    }

    const loadReports = async (): Promise<void> => {
        const response = await generalGetAPI(
            API_ENDPOINTS.contractFinanceReport
        )
        if (response.isOk) setReports(response.data)
    }

    useEffect(() => {
        loadReports()
    }, [])

    useEffect(() => ensureAccess(ModuleType.ContractFinance), [])

    return (
        <PageContainer>
            <Flex flexDirection={'column'} gap={4}>
                <DynamicGrid
                    tableId="contractFinanceTable"
                    rowData={reports}
                    columns={getColumnsConfig(translate, sendEmail)}
                    pagination={true}
                    rowMultiSelectWithClick={false}
                    headers={
                        <Flex
                            alignItems={'center'}
                            justifyContent="flex-end"
                            gap={4}
                            flexWrap="wrap"
                        >
                            <Button onClick={() => setIsOpen(true)}>
                                {translate('generateReport')}
                            </Button>
                        </Flex>
                    }
                />
            </Flex>

            <ContractFinanceContractsTable
                isModalOpen={isOpen}
                modalTitle={translate('selectOutstandingContracts')}
                onClose={() => {
                    setIsOpen(false)
                    loadReports()
                }}
            ></ContractFinanceContractsTable>
        </PageContainer>
    )
}

export default ContractFinance
