import { ReactElement, useEffect, useState } from 'react'
import {
    Box,
    Fade,
    Flex,
    IconButton,
    useColorModeValue,
    useToast,
    Badge,
    Tooltip,
} from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { useContractService } from '../../../../services/contract/Contract.services'
import KeyInformationFeature from '../keyInformation/KeyInformation.component'
import KeyInformationContractFeature from '../keyInformation/KeyInformationContract.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPostWithErrorsAPI } from '../../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import { ContractWarningsModal } from '../warnings/ContractWarnings.modal'

export default function ContractDetailHeader(): ReactElement {
    const { isLoading } = useContractService()
    const { contract } = useContractService()
    const toast = useToast()
    const [warnings, setWarnings] = useState<Array<string>>([])
    const [isWarningsLoading, setIsWarningsLoading] = useState<boolean>(true)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const getData = async (): Promise<void> => {
        setIsWarningsLoading(true)

        try {
            const response: any = await generalPostWithErrorsAPI(
                API_ENDPOINTS.contractActionsCheckWarnings,
                { contractNumber: contract.contractNumber }
            )

            if (response.isOk) {
                setWarnings([])
            } else if (response.status === 400 && response.errors) {
                const warningsFromErrors = Object.values(
                    response.errors
                ).flatMap((warningArray: any) => warningArray)
                setWarnings(warningsFromErrors)
            } else {
                toast(
                    baseErrorToastOptions(
                        response.message || 'Unexpected error occurred.'
                    )
                )
            }
        } catch (error) {
            toast(baseErrorToastOptions('Failed to fetch contract warnings'))
        } finally {
            setIsWarningsLoading(false)
        }
    }

    useEffect(() => {
        if (contract.contractNumber) {
            getData()
        }
    }, [contract?.contractNumber])

    const handleModalOpen = () => {
        setIsModalOpen(true)
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    return (
        <Box w="100%" bg={useColorModeValue('white', 'secondary.850')} p={4}>
            {!isLoading && (
                <Fade in={!isLoading}>
                    <Box
                        display={['none', 'none', 'none', 'none', 'flex']}
                        justifyContent={'space-between'}
                    >
                        <Flex alignItems={'center'} gap={4}>
                            <KeyInformationContractFeature />
                        </Flex>
                        <Flex alignItems={'center'} gap={4}>
                            <KeyInformationFeature />
                            {contract?.contractNumber &&
                                warnings.length > 0 && (
                                    <Tooltip
                                        label="View Warnings"
                                        placement="bottom"
                                    >
                                        <IconButton
                                            aria-label="Warnings"
                                            icon={<WarningIcon />}
                                            colorScheme="red"
                                            onClick={handleModalOpen}
                                            bg={'red.600'}
                                            _hover={{ bg: 'red.800' }}
                                            variant="solid"
                                            size="lg"
                                        >
                                            {warnings.length > 0 && (
                                                <Badge
                                                    colorScheme="red"
                                                    position="absolute"
                                                    top="-1"
                                                    right="-1"
                                                    borderRadius="full"
                                                >
                                                    {warnings.length}
                                                </Badge>
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                        </Flex>
                    </Box>

                    <Box
                        display={['flex', 'flex', 'flex', 'flex', 'none']}
                        flexWrap={'nowrap'}
                        overflowX={'auto'}
                        alignItems={'center'}
                        gap={4}
                        sx={{
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        <KeyInformationContractFeature />
                        <KeyInformationFeature />
                        {contract?.contractNumber && warnings.length > 0 && (
                            <Tooltip label="View Warnings" placement="top">
                                <IconButton
                                    aria-label="Warnings"
                                    icon={<WarningIcon />}
                                    colorScheme="red"
                                    bg={'red.600'}
                                    _hover={{ bg: 'red.800' }}
                                    onClick={handleModalOpen}
                                    variant="solid"
                                    size="lg"
                                >
                                    {warnings.length > 0 && (
                                        <Badge
                                            colorScheme="red"
                                            position="absolute"
                                            top="-1"
                                            right="-1"
                                            borderRadius="full"
                                        >
                                            {warnings.length}
                                        </Badge>
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Fade>
            )}

            <ContractWarningsModal
                warnings={warnings}
                isLoading={isWarningsLoading}
                isModalOpen={isModalOpen}
                modalTitle="Contract Warnings"
                onClose={handleModalClose}
                maxWidth="xl"
            />
        </Box>
    )
}
