import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'

import { BaseServiceDTO } from '../../utils/types/types'
import apiEndpoint from '../API/apiEndpoints.constants'
import { generalGetAPI } from '../API/general.api'
import { useContractService } from '../contract/Contract.services'

interface BaseServiceContextInterface {
    baseServices: BaseServiceDTO[]
    isLoading: boolean
    getServicesHandler: (productId: number) => void
}

const BaseServicesContext = createContext<BaseServiceContextInterface>(
    {} as BaseServiceContextInterface
)

interface IBaseServicesContextProvider {
    children: ReactNode
}

export const BaseServicesContextProvider = ({
    children,
}: IBaseServicesContextProvider): ReactElement => {
    const { contract } = useContractService()
    const [services, setServices] = useState<BaseServiceDTO[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const getServicesHandler = async (productId: number): Promise<void> => {
        setIsLoading(true)
        const response = await generalGetAPI(
            `${apiEndpoint.service}?ProductId=${productId}`
        )
        if (!response?.isOk) return
        setServices(response?.data)
        setIsLoading(false)
    }

    useEffect(() => {
        if (contract?.productId) {
            getServicesHandler(contract?.productId)
        }
    }, [contract.productId])

    return (
        <BaseServicesContext.Provider
            value={{
                baseServices: services,
                getServicesHandler,
                isLoading,
            }}
        >
            {children}
        </BaseServicesContext.Provider>
    )
}

export const useBaseServicesContext = (): BaseServiceContextInterface =>
    useContext(BaseServicesContext)
