export const getTableLocale = (translate: Function) => ({
    // Set Filter
    selectAll: translate('selectAll'),
    selectAllSearchResults: translate('selectAllSearchResults'),
    addCurrentSelectionToFilter: translate('addCurrentSelectionToFilter'),
    searchOoo: translate('searchOoo'),
    blanks: translate('blanks'),
    noMatches: translate('noMatches'),

    // Number Filter & Text Filter
    filterOoo: translate('filterOoo'),
    equals: translate('equals'),
    notEqual: translate('notEqual'),
    blank: translate('blank'),
    notBlank: translate('notBlank'),
    empty: translate('empty'),

    // Number Filter
    lessThan: translate('lessThan'),
    greaterThan: translate('greaterThan'),
    lessThanOrEqual: translate('lessThanOrEqual'),
    greaterThanOrEqual: translate('greaterThanOrEqual'),
    inRange: translate('inRange'),
    inRangeStart: translate('inRangeStart'),
    inRangeEnd: translate('inRangeEnd'),

    // Text Filter
    contains: translate('contains'),
    notContains: translate('notContains'),
    startsWith: translate('startsWith'),
    endsWith: translate('endsWith'),

    // Date Filter
    dateFormatOoo: translate('dateFormatOoo'),
    before: translate('before'),
    after: translate('after'),

    // Filter Conditions
    andCondition: translate('andCondition'),
    orCondition: translate('orCondition'),

    // Filter Buttons
    applyFilter: translate('applyFilter'),
    resetFilter: translate('resetFilter'),
    clearFilter: translate('clearFilter'),
    cancelFilter: translate('cancelFilter'),

    // Filter Titles
    textFilter: translate('textFilter'),
    numberFilter: translate('numberFilter'),
    dateFilter: translate('dateFilter'),
    setFilter: translate('setFilter'),

    // Group Column Filter
    groupFilterSelect: translate('groupFilterSelect'),

    // Additional keys
    pageSize: translate('pageSize'),
    page: translate('page'),
    to: translate('to'),
    of: translate('of'),
})
