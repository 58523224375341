import { ReactElement, useEffect, useState } from 'react'

import { Grid, GridItem } from '@chakra-ui/layout'
import { Flex } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { useLocationsContext } from '../../../../services/contexts/LocationServices.context'
import { BREAK_POINTS_WIDTH } from '../../../../utils/constants.utils'
import {
    AssetArcheType,
    AssetTypeBO,
    GetBaseAssetDetail,
} from '../../../../utils/types/types'
import {
    CarFields,
    DefaultInventoryFormFields,
    UsageFields,
} from '../../InventoryDetail.config'
import AssetImageGallery from '../imageGallery/ImageGallery.component'

const VehicleAssetTypes = [
    AssetArcheType.Car,
    AssetArcheType.Van,
    AssetArcheType.Truck,
    AssetArcheType.Minibus,
    AssetArcheType.Bus,
    AssetArcheType.Largebus,
    AssetArcheType.Tractors,
    AssetArcheType.HeavyTrailers,
]

interface IInventoryFormProps {
    baseAsset: GetBaseAssetDetail
    handleInventoryUpdate(data: GetBaseAssetDetail): Promise<void>
    productHasUsage: boolean
}

function InventoryFormComponent({
    baseAsset,
    handleInventoryUpdate,
    productHasUsage,
}: IInventoryFormProps): ReactElement {
    const { locations } = useLocationsContext()
    const [baseAssetData, setBaseAssetData] =
        useState<GetBaseAssetDetail>(baseAsset)
    const isDesktop = window.innerWidth > BREAK_POINTS_WIDTH.DESKTOP

    const isVehicle =
        !!baseAssetData?.assetType &&
        VehicleAssetTypes.includes(baseAssetData.assetType.assetArcheType)

    const onFormChangeHandler = (values: GetBaseAssetDetail): void => {
        setBaseAssetData({ ...values })
    }

    const [existingAssetTypes, setExistingAssetTypes] = useState<AssetTypeBO[]>(
        []
    )

    async function fetchAssetTypes() {
        const result = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (result?.isOk) {
            setExistingAssetTypes(result.data)
        }
    }

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    return isDesktop ? (
        <Grid
            templateColumns="repeat(2, 1fr)"
            gap={6}
            width={'100%'}
            textAlign={'center'}
            animation="alternate"
        >
            <GridItem>
                <GenericBox p={6}>
                    <DynamicForm<GetBaseAssetDetail>
                        columns={2}
                        data={baseAssetData}
                        onSubmit={handleInventoryUpdate}
                        onFormChange={onFormChangeHandler}
                        formFields={[
                            ...DefaultInventoryFormFields(
                                baseAssetData,
                                locations,
                                existingAssetTypes
                            ),
                            ...UsageFields(baseAssetData),
                            ...(isVehicle ? CarFields(baseAssetData) : []),
                        ]}
                    ></DynamicForm>
                </GenericBox>
            </GridItem>
            <GridItem>
                {baseAsset && (
                    <AssetImageGallery {...baseAsset}></AssetImageGallery>
                )}
            </GridItem>
        </Grid>
    ) : (
        <Flex flexDirection={'column'}>
            <DynamicForm<GetBaseAssetDetail>
                columns={1}
                data={baseAsset}
                onSubmit={handleInventoryUpdate}
                formFields={[
                    ...DefaultInventoryFormFields(
                        baseAsset,
                        locations,
                        existingAssetTypes
                    ),
                    ...(productHasUsage ? UsageFields(baseAsset) : []),
                    ...(isVehicle ? CarFields(baseAsset) : []),
                ]}
            ></DynamicForm>
            <AssetImageGallery {...baseAsset}></AssetImageGallery>
        </Flex>
    )
}

export default InventoryFormComponent
