import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Heading } from '@chakra-ui/react'

import PageContainer from '../../components/pageContainer/PageContainer.component'
import { OfferContextProvider } from '../../services/contexts/Offer.context'
import { StepperContextProvider } from '../../services/contexts/Stepper.context'
import { ProductRulesEnforcerServiceProvider } from '../../services/rules/ProductRulesEnforcer.services'
import ContractDetailHeader from '../contractDetail/components/contractDetailHeader/ContractDetailHeader.component'
import CustomerComponent from './components/customer/Customer.component'
import NewOfferAction from './components/newOfferAction/NewOfferAction.component'

function OfferPage(): ReactElement {
    const translate = useTranslation().t
    return (
        <PageContainer
            pageHeader={<ContractDetailHeader />}
            pageFooter={<NewOfferAction />}
        >
            <Heading as="h4" size={'lg'} mb={4}>
                {translate('newOffer')}
            </Heading>
            <CustomerComponent />
        </PageContainer>
    )
}

const OfferPageWithContext = (): ReactElement => (
    <ProductRulesEnforcerServiceProvider>
        <OfferContextProvider>
            <StepperContextProvider length={4}>
                <OfferPage />
            </StepperContextProvider>
        </OfferContextProvider>
    </ProductRulesEnforcerServiceProvider>
)
export default OfferPageWithContext
