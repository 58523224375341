import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { toast } from 'react-toastify'

import { ProductDTO } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI } from '../API/general.api'
import { useUser } from './user.context'

interface IProductContext {
    products?: ProductDTO[]
    getProducts: Function
    isLoading: boolean
}

interface IProductContextProviderProps {
    children: ReactNode
}

export const ProductContext = createContext({} as IProductContext)

export const useProduct = () => useContext(ProductContext)

const useProviderProduct = () => {
    const { user } = useUser()
    const [products, setProducts] = useState<ProductDTO[]>()
    const [isLoading, setLoading] = useState(true)

    const getProducts = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.product)
        if (!response.isOk) {
            toast.error(response.message)
            return
        }
        setProducts(response.data)
    }

    useEffect(() => {
        if (user?.email) {
            getProducts()
            setLoading(false)
        }
    }, [user])

    return { products, getProducts, isLoading }
}

export const ProductContextProvider = ({
    children,
}: IProductContextProviderProps) => {
    const productValues: IProductContext = useProviderProduct()

    return (
        <ProductContext.Provider value={productValues}>
            {children}
        </ProductContext.Provider>
    )
}
