import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import { useOffer } from '../../../../services/contexts/Offer.context'
import ProductComponent from '../product/Product.component'
import CustomerTableComponent from './CustomerTable.component'

function CustomerComponent(): ReactElement {
    const translate = useTranslation().t
    const {
        customerSearch,
        customerSearchPending,
        offer,
        handleCustomerSearch,
        handleResetCustomerSearch,
        setCustomerSearch,
    } = useOffer()
    const [customerEmail, setCustomerEmail] = useState<string>(
        offer.customer?.email
    )

    return (
        <Box>
            <GenericBox mb={8} p={6}>
                <Flex gap={4} w={'100%'}>
                    <ProductComponent excludeDisabled={true} />
                    <Box w={'67%'}>
                        {!offer.customer?.name && (
                            <>
                                <Heading size={'md'} mb={4}>
                                    {translate('customer')}
                                </Heading>
                                <form
                                    onSubmit={(event): void => {
                                        event.preventDefault()
                                        handleCustomerSearch()
                                    }}
                                >
                                    <Flex gap={4} alignItems={'end'} w={'100%'}>
                                        <InputGroup>
                                            <Input
                                                w={'100%'}
                                                placeholder={
                                                    translate(
                                                        'searchCustomer'
                                                    ) || ''
                                                }
                                                value={customerSearch}
                                                onChange={(event): void =>
                                                    setCustomerSearch(
                                                        event.target.value
                                                    )
                                                }
                                                isDisabled={
                                                    customerSearchPending
                                                }
                                            />
                                            {customerSearch.length > 0 && (
                                                <InputRightElement
                                                    onClick={
                                                        handleResetCustomerSearch
                                                    }
                                                >
                                                    <CloseIcon />
                                                </InputRightElement>
                                            )}
                                        </InputGroup>
                                        <Button
                                            onClick={handleCustomerSearch}
                                            px={'6'}
                                        >
                                            {translate('searchCustomer')}
                                        </Button>
                                    </Flex>
                                </form>
                            </>
                        )}
                    </Box>
                </Flex>
            </GenericBox>

            {!offer.customer?.name && (
                <CustomerTableComponent setCustomerEmail={setCustomerEmail} />
            )}

            {offer.customer?.name && (
                <Box>
                    <Grid
                        gridTemplateColumns={{
                            base: '1fr',
                            md: 'repeat(3, 1fr)',
                        }}
                        gap={4}
                        mb={4}
                    >
                        <FormControl>
                            <FormLabel>{translate('customerName')}</FormLabel>
                            <Input
                                value={offer.customer?.name}
                                isDisabled={true}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{translate('customerCvr')}</FormLabel>
                            <Input
                                value={
                                    offer.customer?.shouldCreateCustomer
                                        ? offer.customer?.registrationNumber
                                        : offer.customer?.cvr
                                }
                                isDisabled={true}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{translate('email')}</FormLabel>
                            <Input value={customerEmail} isDisabled={true} />
                        </FormControl>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default CustomerComponent
