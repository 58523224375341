/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    ColumnActionConfiguration,
    toTitleCase,
} from '../../../utils/functions.utils'
import { CountryCode, UserDTO, UserGroup } from '../../../utils/types/types'

export const columnsConfig = (
    translate: Function,
    actionsFn: Function,
    selectionEnabled = true
) => [
    {
        headerCheckboxSelection: selectionEnabled,
        checkboxSelection: selectionEnabled,
        field: 'name',
        filter: 'agTextColumnFilter',
        headerName: translate('name'),
    },
    {
        field: 'email',
        filter: 'agTextColumnFilter',
        headerName: translate('email'),
    },
    {
        field: 'branchName',
        filter: 'agTextColumnFilter',
        headerName: translate('branch'),
    },
    {
        field: 'userGroup',
        filter: 'agTextColumnFilter',
        headerName: translate('userGroup'),
        valueGetter: (params: any) =>
            toTitleCase(UserGroup[params?.data?.userGroup]),
    },
    {
        field: 'culture',
        filter: 'agTextColumnFilter',
        headerName: translate('culture'),
        valueGetter: (params: any) =>
            params?.data?.culture ? CountryCode[params?.data?.culture] : '',
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => actionsFn(params),
            100
        ),
    },
]

export const DEFAULT_USER: UserDTO = {
    userNumber: '0',
    culture: CountryCode.DK!,
    email: '',
    name: '',
    userGroup: UserGroup.RegularUser,
    branchName: '',
    theme: 1,
    formatting: CountryCode.AX,
}
