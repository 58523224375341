import { RangeDatepicker } from 'chakra-dayzed-datepicker'
import camelCase from 'lodash/camelCase'
/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Fade, Flex, Heading, Select, SimpleGrid } from '@chakra-ui/react'

import PageContainer from '../../components/pageContainer/PageContainer.component'
import { useProduct } from '../../services/contexts/Product.context'
import { ProductType } from '../../utils/types/types'
import AnnualRevenueChart from './charts/annualRevenue.chart'
import ChurnRateLineChart from './charts/churnRate.chart'
import ActivatedContractsChart from './charts/creationContracts.chart'
import MRRLineChart from './charts/monthlyRevenue.chart'

export default function DashboardPage(): ReactElement {
    const translate = useTranslation().t

    // State for filters
    const [selectedProduct, setSelectedProduct] = useState<
        ProductType | undefined
    >()
    const [contractTemplateId, setContractTemplateId] = useState<
        number | undefined
    >()
    const [selectedDates, setSelectedDates] = useState<Date[]>([
        new Date(),
        new Date(),
    ])

    const datepickerConfig = {
        dateFormat: 'dd/MM/yyyy',
        showMonthAndYearPickers: true,
        usePortal: true,
    }
    const { products, getProducts } = useProduct()

    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        if (products && products[0]) {
            setContractTemplateId(undefined)
        }
    }, [products])

    return (
        <PageContainer>
            <Fade in>
                <Heading mb={4} size={'lg'}>
                    {translate('portfolioDashboard')}
                </Heading>

                {/* Filters */}
                <Flex
                    mb={4}
                    justify="space-between"
                    align="center"
                    wrap="wrap"
                    width={'50%'}
                >
                    {/* Product Filter */}
                    <Select
                        placeholder="Select Product"
                        width="30%"
                        defaultValue={''}
                        value={selectedProduct}
                        onChange={(e) =>
                            setSelectedProduct(parseInt(e.target.value))
                        }
                    >
                        <option value={''}>{translate('all')}</option>
                        {Object.entries(ProductType)
                            .slice(
                                Object.entries(ProductType).length / 2,
                                Object.entries(ProductType).length
                            )
                            .map(([key, value]) => (
                                <option key={key} value={value}>
                                    {translate(camelCase(key))}
                                </option>
                            ))}
                    </Select>

                    {/* Customer Segment Filter */}
                    <Select
                        placeholder="Select Customer Segment"
                        width="30%"
                        value={contractTemplateId}
                        onChange={(e) =>
                            setContractTemplateId(parseInt(e.target.value))
                        }
                    >
                        <option key={0} value={''}>
                            {translate('all')}
                        </option>
                        {products?.map((product) => (
                            <option key={product.id} value={product.id}>
                                {product.name}
                            </option>
                        ))}
                    </Select>

                    {/* Date Range Filter */}
                    <Box width="35%">
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                        />
                    </Box>
                </Flex>

                {/* Charts */}
                <SimpleGrid
                    columns={{ base: 1, sm: 1, lg: 3, xl: 3 }}
                    gap={{ base: 2, sm: 1, lg: 4, xl: 3 }}
                    gridTemplateColumns={{
                        base: '1fr',
                        lg: '2fr 2fr 1fr',
                    }}
                >
                    <AnnualRevenueChart
                        selectedProduct={selectedProduct}
                        contractTemplate={contractTemplateId}
                    />
                    <MRRLineChart
                        selectedProduct={selectedProduct}
                        contractTemplate={contractTemplateId}
                        startDate={selectedDates[0]}
                        endDate={selectedDates[1]}
                    />
                    <Box></Box>

                    <ActivatedContractsChart
                        selectedProduct={selectedProduct}
                        contractTemplate={contractTemplateId}
                        startDate={selectedDates[0]}
                        endDate={selectedDates[1]}
                    />
                    <ChurnRateLineChart
                        selectedProduct={selectedProduct}
                        contractTemplate={contractTemplateId}
                    />
                </SimpleGrid>
            </Fade>
        </PageContainer>
    )
}
