import { DownloadIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import {
    ColumnActionConfiguration,
    downloadFile,
} from '../../../../../utils/functions.utils'
import { formatDate } from '../../../../../utils/localization/culture.utils'

export const getColumnsConfig = (translate: Function) => [
    {
        field: 'name',
        headerName: translate('name'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'customerNumber',
        headerName: translate('receiver'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'creationDate',
        filter: 'agDateColumnFilter',
        headerName: translate('sendDate'),
        valueGetter: (params: any) => formatDate(params?.data?.creationDate),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <>
                    <Tooltip hasArrow label={translate('download')}>
                        <IconButton
                            variant={'outline'}
                            mr={2}
                            size={'sm'}
                            icon={<DownloadIcon />}
                            onClick={(): void => {
                                downloadFile(
                                    params.data.id,
                                    params.data?.name || 'file'
                                )
                            }}
                            aria-label={'download'}
                        />
                    </Tooltip>
                </>
            ),
            100
        ),
    },
]
