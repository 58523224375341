import {
    ContractDTO,
    ContractRelationType,
    DocumentMetaDataDto,
    DocumentType,
} from '../../../../../utils/types/types'

export interface ICloseContract {
    isModalOpen: boolean
    onClose: Function
    modalTitle: String
}

// Its a child contract when it has a parent contract number and relation type of Prolongation
export const isChildContract = (
    contract: ContractDTO | Partial<ContractDTO>
): boolean =>
    !!contract?.parentContractNumber &&
    contract?.parentContractRelationType === ContractRelationType.Prolongation

// It has generated a closing contract if theres a contract docucment with type ContractClosing
export const hasGeneratedClosingContract = (
    documents: DocumentMetaDataDto[]
): boolean =>
    !!documents.filter(
        (doc: DocumentMetaDataDto) =>
            doc?.documentType === DocumentType.ContractClosing
    )?.length
