import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Tooltip,
    useToast,
} from '@chakra-ui/react'
import ServiceFeesTable from '../../../features/service/components/fees/ServiceFeesTable.component'
import useIsMounted from '../../../services/hooks/isMounted'
import {
    offsetTimeZoneDifference,
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../utils/functions.utils'
import { BaseServiceValueDTO } from '../../../utils/types/types'

type PartialBaseServiceValueDTO = Partial<BaseServiceValueDTO>

const getNoTimeDate = (date: string | Date): Date => {
    let cleanDate = typeof date === 'string' ? new Date(date) : date
    cleanDate = offsetTimeZoneDifference(cleanDate)
    cleanDate.setUTCHours(0, 0, 0, 0)
    cleanDate.setUTCHours(0)
    return cleanDate
}

const getPastDay = (date: string | Date): Date => {
    const nextDate = typeof date === 'string' ? new Date(date) : date
    nextDate.setDate(nextDate.getDate() - 1)
    return getNoTimeDate(nextDate)
}

const getCurrentDay = (date: string | Date): Date => {
    const nextDate = typeof date === 'string' ? new Date(date) : date
    nextDate.setDate(nextDate.getDate() + 0)
    return getNoTimeDate(nextDate)
}

const getNextDay = (date: string | Date): Date => {
    const nextDate = typeof date === 'string' ? new Date(date) : date
    nextDate.setDate(nextDate.getDate() + 1)
    return getNoTimeDate(nextDate)
}

function EditServiceFees({
    service,
    handleServiceUpdate,
    isEditingMode = false,
}: any): JSX.Element {
    const translate = useTranslation().t
    const toast = useToast()
    const isMounted = useIsMounted()
    const [currentServiceFee, setCurrentServiceFee] =
        useState<PartialBaseServiceValueDTO>({
            id: 0,
            value: 0,
            startDate: new Date().toISOString(),
        })
    const [minimumDateAllowed, setMinimumDateAllowed] = useState<string>(
        getCurrentDay(new Date()).toISOString()
    )

    useEffect(() => {
        if (isMounted()) {
            updateMinimalDate()
        }
    }, [service.baseServiceValues])

    const updateMinimalDate = () => {
        let day = new Date().toDateString()
        day = getCurrentDay(day).toISOString()
        service.baseServiceValues?.forEach((baseServiceValue: any) => {
            if (!baseServiceValue?.endDate) {
                day = baseServiceValue.startDate
                day = getNextDay(day).toISOString()
            }
        })

        // check if day is below today.
        if (new Date(day).getTime() < new Date().getTime()) {
            day = new Date().toDateString()
            day = getCurrentDay(day).toISOString()
        }

        setMinimumDateAllowed(day)
        setCurrentServiceFee({
            id: 0,
            value: 0,
            startDate: day,
        })
    }

    const createNewBaseService = () => ({
        id: new Date().getTime(),
        value: currentServiceFee.value,
        startDate: getNoTimeDate(currentServiceFee.startDate!).toISOString(),
        isCurrent: true,
        endDate: undefined,
    })

    const updateEndDate = (
        currentServiceList: PartialBaseServiceValueDTO[],
        newServiceValue: PartialBaseServiceValueDTO
    ): PartialBaseServiceValueDTO[] =>
        currentServiceList.map((currentService, index) => {
            if (
                !currentService.endDate &&
                index !== currentServiceList.length - 1
            ) {
                currentService.endDate = newServiceValue.startDate
            }
            return currentService
        })

    const updateIsCurrent = (
        currentServiceList: PartialBaseServiceValueDTO[]
    ) => {
        const currentTime = new Date()
        return currentServiceList.map((currentService) => {
            // Current should be equal or greater than startDate and less than endDate if it exist
            currentService.isCurrent =
                new Date(currentService.startDate!) <= currentTime &&
                (currentService?.endDate
                    ? new Date(currentService.endDate) > currentTime
                    : true)

            return currentService
        })
    }

    const handleCurrentServiceFeeSave = (): void => {
        if (!currentServiceFee.value || !currentServiceFee?.startDate) {
            toast(baseErrorToastOptions(translate('fillAllFieldsMessage')))
            return
        }

        if (
            getCurrentDay(currentServiceFee.startDate!).getTime() <
                getCurrentDay(minimumDateAllowed).getTime() &&
            !currentServiceFee?.id
        ) {
            toast(baseErrorToastOptions(translate('invalidStartDate')))
            return
        }

        let currentServiceList: PartialBaseServiceValueDTO[] =
            service?.baseServiceValues ? [...service.baseServiceValues] : []
        const newServiceValue: PartialBaseServiceValueDTO =
            currentServiceFee?.id ? currentServiceFee : createNewBaseService()

        currentServiceList = currentServiceFee?.id
            ? currentServiceList.map((currentServiceItem) => {
                  if (currentServiceItem.id === currentServiceFee.id) {
                      return currentServiceFee
                  }
                  return currentServiceItem
              })
            : [...currentServiceList, newServiceValue]
        currentServiceList = updateEndDate(currentServiceList, newServiceValue)
        currentServiceList = updateIsCurrent(currentServiceList)

        toast(baseSuccessToastOptions(translate('serviceFeeAdded')))
        handleServiceUpdate({ baseServiceValues: [...currentServiceList] })
    }

    const handleDeleteServiceFee = (
        serviceFee: PartialBaseServiceValueDTO
    ): void => {
        let updatedBaseServiceValues = service.baseServiceValues?.filter(
            (baseServiceValue: any) => baseServiceValue.id !== serviceFee.id!
        )
        updatedBaseServiceValues = updatedBaseServiceValues?.map(
            (baseServiceValue: any) => {
                if (baseServiceValue.endDate === serviceFee.startDate) {
                    // In case you are removing the last base service id it will set this one to undefined
                    // if you remove something in the middle it will assigned removed end-date to previous base service fee
                    baseServiceValue.endDate = serviceFee?.endDate
                }
                return baseServiceValue
            }
        )
        handleServiceUpdate({
            baseServiceValues: updatedBaseServiceValues
                ? [...updatedBaseServiceValues]
                : [],
        })
    }

    const tableActions = (serviceItem: PartialBaseServiceValueDTO) => (
        <>
            {!isEditingMode && (
                <Tooltip label={translate('edit')} placement="top" hasArrow>
                    <IconButton
                        aria-label="Edit"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void => {
                            setCurrentServiceFee({ ...serviceItem })
                        }}
                        icon={<EditIcon />}
                    />
                </Tooltip>
            )}
            {!isEditingMode && !serviceItem?.isCurrent && (
                <Tooltip label={translate('delete')} placement="top" hasArrow>
                    <IconButton
                        aria-label="Delete"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void => {
                            handleDeleteServiceFee({ ...serviceItem })
                        }}
                        icon={<DeleteIcon />}
                    />
                </Tooltip>
            )}
        </>
    )

    return (
        <Box mt={8}>
            <Grid
                gridTemplateColumns={{
                    base: '1fr',
                    md: 'repeat(2, 1fr) 0.5fr',
                }}
                gap={4}
                alignItems="flex-end"
                mb={4}
            >
                <FormControl>
                    <FormLabel>{translate('serviceFee')}</FormLabel>
                    <Input
                        value={currentServiceFee?.value ?? 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentServiceFee({
                                ...currentServiceFee,
                                value: Number.isNaN(parseInt(e.target.value))
                                    ? 0
                                    : Math.abs(parseInt(e.target.value)),
                            })
                        }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{translate('startDate')}</FormLabel>
                    <SingleDatepicker
                        name="date-input"
                        disabled={!!currentServiceFee?.id}
                        date={
                            currentServiceFee?.startDate
                                ? new Date(currentServiceFee?.startDate)
                                : new Date(minimumDateAllowed)
                        }
                        minDate={getPastDay(minimumDateAllowed)}
                        onDateChange={(date: Date) => {
                            setCurrentServiceFee({
                                ...currentServiceFee,
                                startDate: getNoTimeDate(date).toISOString(),
                            })
                        }}
                    />
                </FormControl>
                <Button onClick={handleCurrentServiceFeeSave} my={0}>
                    {translate('save')}
                </Button>
            </Grid>

            <ServiceFeesTable
                baseServices={service.baseServiceValues ?? []}
                tableActions={tableActions}
            />
        </Box>
    )
}

export default EditServiceFees
