import { useNavigate } from 'react-router-dom'

import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderContainer = ({ children }: any) => {
    const navigate = useNavigate()

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname)
    }

    const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_API } =
        process.env

    return (
        <Auth0Provider
            domain={REACT_APP_AUTH0_DOMAIN || ''}
            clientId={REACT_APP_AUTH0_CLIENT_ID || ''}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            audience={REACT_APP_API}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderContainer
